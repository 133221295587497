import React from 'react';
import Map, { MapInstance, MapProps, MapRef } from 'react-map-gl';
import { MapContext, MapContextValue } from 'react-map-gl/dist/esm/components/map';

export type MapBoxRef<T = unknown> = MapRef & T;

export interface IMapBoxProps extends MapProps {
    mapRef ?: React.RefCallback<MapBoxRef>;
}

const MapBox = (props : Omit<IMapBoxProps, 'transformRequest'>) => {
    const {
        mapRef,
        ...rest
    } = props;

    return (
        <Map
            {...rest}
            ref={mapRef}
            transformRequest={(url : string) => {
                if (!url.includes(GEO_SERVER_URL)) return { url };
                return {
                    url: `${url}&authkey=${process.env.GeoServerAccessToken}`,
                };
            }}
        />
    );
};

export default MapBox;


export interface MapBoxInitialState {
    longitude : number;
    latitude : number;
    zoom : number;
}

export const MapboxContext = MapContext;
export type MapboxContextValue = MapContextValue<MapBoxRef<MapInstance>>;