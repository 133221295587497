import { AppBar, Toolbar, Typography } from '@material-ui/core';
import lodash from 'lodash';
import React from 'react';
import { createSelector } from 'reselect';
import { IPhenologyEntry } from '../../../types/model/phenology/entry';

interface IPhenologyEntrySectionViewProps {
    title : string;
    value : IPhenologyEntry;
}

interface IPhenologyEntrySectionViewState {}

export default class PhenologyEntrySectionView extends React.PureComponent<IPhenologyEntrySectionViewProps, IPhenologyEntrySectionViewState> {
    constructor(props : IPhenologyEntrySectionViewProps) {
        super(props);
        this.state = {
        };
    }

    private getValue = (props : IPhenologyEntrySectionViewProps) => props.value;
    private getTitle = (props : IPhenologyEntrySectionViewProps) => props.title;
    private getResults = createSelector(
        [this.getValue, this.getTitle],
        (value, title) => {
            return lodash
                .chain(value.results)
                .filter(x => x.section === title)
                .value();
        },
    );

    public render = () => {
        const { title } = this.props;

        const results = this.getResults(this.props);
        return (
            <div className='fdc ais mb5'>
                <AppBar className='fdc ais' variant='outlined' position='static'>
                    <Toolbar variant='dense' className={'fdr aic jcc'}>
                        <Typography className={'cw'} variant='h6'>
                            {title.toLocaleUpperCase()}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {
                    results.map(n => (
                        <div key={n.option}>
                            {
                                !n.result &&
                                <Typography variant='h6'>
                                    {n.option.toLocaleUpperCase()}
                                </Typography>
                            }
                            {
                                n.result &&
                                !n.result.value &&
                                <Typography variant='h6'>
                                    {n.option.toLocaleUpperCase()} - {n.result.name.toLocaleUpperCase()}
                                </Typography>
                            }
                            {
                                !!n.result?.value &&
                                <Typography variant='h6'>
                                    {n.option.toLocaleUpperCase()} - {n.result.name.toLocaleUpperCase()}: {n.result.value}
                                </Typography>
                            }
                        </div>
                    ))
                }
            </div>
        );
    };
}
