import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import { Add, Check, Close, Edit } from '@material-ui/icons';
import CultivarEditDialog from './dialog/Edit';
import moment from 'moment';
import { ICultivar } from '../../../types/model/masterData/cultivars';
import { IRootState } from '../../../@types/redux';
import CultivarFunctions from '../../../store/masterData/cultivar/functions';
import { CROP } from '../../../appConstants';
import MaterialTable from '../../customComponents/materialTable/Table';
import StandardFab from '../../customComponents/button/StandardFab';

interface ICultivarListProps extends RouteComponentProps {
    cultivars : Array<ICultivar>;
    isLoading : boolean;
}

interface ICultivarListState {
    selected : ICultivar | null;
}

class CultivarListComponent extends React.PureComponent<ICultivarListProps, ICultivarListState> {
    constructor(props : ICultivarListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    public readonly load = () => {
        CultivarFunctions.getList();
    };

    public onAddClick = () => {
        this.setState({
            selected: {
                id: '',
                crop: this.getCrop(this.props) ?? 'almonds',
                name: '',
                isActive: true,
                createdBy: '',
                createdByEmployee: '',
                createdByName: '',
                createdOn: moment.utc().valueOf(),
                updatedBy: '',
                updatedByEmployee: '',
                updatedByName: '',
                updatedOn: moment.utc().valueOf(),
            },
        });
    };

    private readonly getSearch = (props : ICultivarListProps) => props.location.search;
    private readonly getData = (props : ICultivarListProps) => props.cultivars;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getTypes = createSelector(
        [
            this.getData,
            this.getCrop,
        ],
        (cultivars, crop) => {
            return cultivars.filter(x => x.crop === crop);
        },
    );

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const {
            cultivars,
        } = this.props;

        const value = cultivars.find(x => x.id === event.currentTarget.value);
        if (!value) return;

        this.setState({
            selected: {
                ...value,
            },
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selected: null,
        });
    };

    public render = () => {
        const {
            isLoading,
        } = this.props;
        const {
            selected,
        } = this.state;

        const cultivars = this.getTypes(this.props);
        
        return (
            <div className={'fdc hfill mh0 mw0 bcg0'}>
                <div className='fdc flx1 p20'>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ICultivar>
                            id='cultivarsTable'
                            data={cultivars}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton color='primary' value={row.id} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Close className='cpr' />) : (<Check className='cdg' />),
                            }]}
                        />
                    </Card>
                    <CultivarEditDialog
                        value={selected}
                        onClose={this.onEditClose}
                        isLoading={isLoading}
                    />
                </div>
                <div className='fdr aife jcfe'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                                <Add />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        cultivars: state.masterData.cultivar.cultivars,
        isLoading: state.masterData.cultivar.isLoading,
    };
};


const CultivarList = withRouter(connect(
    mapStateToProps,
)(CultivarListComponent));

export default CultivarList;