import React from 'react';
import { ITrapEntry, TrapEntryHelper } from '../../../types/model/trap/trapEntry';
import { ITrap, TrapHelper } from '../../../types/model/trap/trap';
import GeneralFunctions from '../../../store/general/functions';
import TrapSurveyEntryView from './SurveyEntryView';
import { IUserSession } from '../../../types/model/user';
import { CircularProgress, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import ImageCarouselModal, { IImageCarouselImage } from '../../customComponents/modal/ImageCarousel';
import AppFunctionsService from '../../../services/appFunctionServices';

interface ITrapSurveyViewProps {
    trap : ITrap;
    
    session ?: IUserSession | null;
}

interface ITrapSurveyViewState {
    entries : Array<ITrapEntry>;
    isLoading : boolean;

    imageOpen : boolean;
    selectedImageId : string | null;
}

export default class TrapSurveyView extends React.PureComponent<ITrapSurveyViewProps, ITrapSurveyViewState> {
    private listener ?: () => void;

    constructor(props : ITrapSurveyViewProps) {
        super(props);
        this.state = {
            entries: [],
            isLoading: false,
            imageOpen: false,
            selectedImageId: null,
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public async load() : Promise<void> {
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = TrapEntryHelper.collection()
            .where('trapRef', '==', TrapHelper.doc(this.props.trap.id))
            .orderBy('date', 'desc')
            .onSnapshot((event) => {
                const entries = this.state.entries.slice();

                event.docChanges().forEach((element) => {
                    switch (element.type) {
                        case 'added':
                            entries.splice(element.newIndex, 0, element.doc.data());
                            break;
                        case 'modified':
                            entries.splice(
                                element.newIndex,
                                1,
                                element.doc.data(),
                            );
                            break;
                        case 'removed':
                            entries.splice(element.oldIndex, 1);
                            break;
                    }
                });

                this.setState({
                    entries,
                    isLoading: false,
                });
            }, (ex) => {
                GeneralFunctions.generalShowErrorSnackbar('Error loading entries', ex);
            });
    }

    private readonly getEntries = (state : ITrapSurveyViewState) => state.entries;
    private readonly getImages = createSelector(
        [this.getEntries],
        (entries) => {
            const result : Array<IImageCarouselImage> = entries.flatMap(x =>
                x.files?.map(file => ({
                    id: file.path,
                    url: file.url,
                    thumbnailUrl: file.thumbnailUrl,
                    legend: AppFunctionsService.formatDateTimeToDateOnly(x.date),
                })) ?? []);

            return result;
        },
    );

    private readonly onImageClick = (imageId : string) => {
        this.setState({
            imageOpen: true,
            selectedImageId: imageId,
        });
    };

    private readonly onImageCarouselClose = () => {
        this.setState({
            imageOpen: false,
            selectedImageId: null,
        });
    };

    public render = () => {
        const {
            trap,
            session,
        } = this.props;
        const {
            entries,
            isLoading,
            imageOpen,
            selectedImageId,
        } = this.state;

        const images = this.getImages(this.state);

        return (
            <div className='fdc flx1 pt20 bcg0'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !entries.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography>No Data</Typography>
                    </div>
                }
                <div className='ml15 mr15 pb80'>
                    {
                        entries.map(x => (
                            <TrapSurveyEntryView
                                key={x.guid}
                                value={x}
                                trap={trap}
                                onImageClick={this.onImageClick}
                                session={session}
                            />
                        ))
                    }
                </div>
                <ImageCarouselModal
                    images={images}
                    open={imageOpen}
                    selectedId={selectedImageId}
                    onClose={this.onImageCarouselClose}
                />
            </div>
        );
    };
}
