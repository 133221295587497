import { LngLat } from 'mapbox-gl';

export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const TIME_FULL_FORMAT_DEFAULT = 'HH:mm:ss';
export const WEEK_INPUT_FILTER_FORMAT_DEFAULT = 'DD MMM';
export const WEEK_FILTER_FORMAT_DEFAULT = 'DD MMM YYYY';
export const DATE_FORMAT_MMM_DD_HH_MM = ' MMM DD, HH:mm';

export type CROP = 'apples' | 'avocados' | 'almonds' | 'blueberries' | 'cherries' | 'dates' | 'tomatoes' | 'onions' | 'lemon' | 'mango' | 'lychee';
type CROP_ARRAY = Record<CROP, Array<string>>;
export const CROPS : Array<CROP> = ['apples', 'avocados', 'almonds', 'blueberries', 'cherries', 'dates', 'tomatoes', 'onions', 'lemon', 'mango', 'lychee'];

export const DEFAULT_MAP_CENTER = new LngLat(30.1148622, -23.5520414);
export const DEFAULT_MAP_ZOOM = 8;

export const DIVISION_BOUNDRIES = [19.324796118269436, -33.805521942862825, 30.558539648045727, -22.185720865840814];

export interface IKeyValue<T> { [key : string] : T }

export enum EnumShapefileType {
    block,
}

export enum EnumEntityValueType {
    damage,
    insects,
    disease,
}

export const ENTITY_VALUE_TYPE_TO_ENUM : {[key : string] : EnumEntityValueType} = {
    damage: EnumEntityValueType.damage,
    disease: EnumEntityValueType.disease,
    insects: EnumEntityValueType.insects,
};

export const CROP_INSECTS : CROP_ARRAY = {
    apples: ['Bollworm', 'Codling moth', 'Mealybug', 'Scale', 'Aphelinus mali', 'Wooly Apple Aphid', 'Weevil', 'Predatory Mites', 'Bryobia Mite', 'European Red Mite', 'Red Spider Mite'],
    avocados: ['Avo Bug', 'Bollworm', 'Red Spider Mite', 'Leaf Roller', 'Lappet Moth', 'Coconut Bug', 'Fruit Fly', 'False Codling Moth', 'Thrips', 'Mealybug'],
    almonds: [],
    blueberries: [
        'Aphids', 'Weevil', 'Leaf Footed Bugs', 'Bollworm', 'Cryptobug', 'Fruit Fly', 'Lacewing', 'Ladybug', 'Leaf Roller',
        'Leafminer', 'Praying Mantis', 'Red Spider Mite', 'Semi Looper', 'Stinkbug', 'Scale', 'Mealybug', 'Nephus',
        'Termites', 'Thrips', 'Whitefly',
    ],
    cherries: [
        'Thrips',
        'Christmas Beetle',
        'Bollworm',
        'Cherry slug',
        'Red Spider Mite',
        'Scale',
    ],
    dates: [],
    tomatoes: [
        'Aphids', 'Bollworm', 'Diglyphus', 'Liriomyza', 'Nesidiocoris', 'Orius', 'Persimillus', 'Potato Tuber Moth',
        'Red Spider Mite', 'Rust Mite', 'Semi Looper', 'Stinkbug', 'Mealybug', 'Whitefly',
    ],
    onions: ['Bollworm', 'Onion Fly', 'Thrips'],
    lemon: [],
    mango: [],
    lychee: [],
};

export const CROP_DISEASES : CROP_ARRAY = {
    apples: ['Fusi', 'Phytophthora', 'Powdery Mildew Primary', 'Powdery Mildew Secondary'],
    avocados: ['Black Spot', 'Verticillium', 'Rosellinia', 'Phytophthora'],
    almonds: [],
    blueberries: [
        'Botrytis',
        'Powdery Mildew',
        'Leaf rust',
        'Alternaria',
        'Septoria',
        'Chimera',
    ],
    cherries: [
        'Coryneum',
        'Botrytis',
        'Monilinia',
        'Canker / Gummosis',
        'Twig Blight',
    ],
    dates: [],
    tomatoes: [
        'Bacterial Diseases', 'Black Stem', 'Blight', 'Blossom End Rot', 'Botrytis',
        'Fungal Growth', 'Fusarium', 'Powdery Mildew', 'Splitting Stem', 'Verticillium', 'Virus', 'Wilt',
    ],
    onions: [
        'Alternaria',
        'Downy Mildew',
        'Soft Rot',
        'Fusarium',
        'White Rot',
    ],
    lemon: [],
    mango: [],
    lychee: [],
};