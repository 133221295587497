import React from 'react';
import { INematodeSurveyTypeLevelImage } from '../../../../../types/model/masterData/nematode/survey';
import { Button, FormControl, FormHelperText, Icon, TextField } from '@material-ui/core';

interface INematodeSurveyTypeLevelImageInputProps {
    index : number;
    value : INematodeSurveyTypeLevelImage;

    onChange ?: (index : number, value : INematodeSurveyTypeLevelImage) => void;

    disabled ?: boolean;
}

interface INematodeSurveyTypeLevelImageInputState {}

export default class NematodeSurveyTypeLevelImageInput extends React.PureComponent<INematodeSurveyTypeLevelImageInputProps, INematodeSurveyTypeLevelImageInputState> {
    constructor(props : INematodeSurveyTypeLevelImageInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onDescriptionChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange(
            this.props.index,
            {
                ...this.props.value,
                description: e.currentTarget.value,
            }
        );
    };

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.onChange) return;
        if (!event.currentTarget.files?.length) return;
        const file = event.currentTarget.files[0];

        const value = {
            ...this.props.value,
            file: {
                file,
                name: file.name,
                url: '',
                path: '',
                thumbnailUrl: null,
            },
        };

        this.props.onChange(
            this.props.index,
            {
                ...value,
            }
        );
    };

    public readonly render = () => {
        const {
            disabled,
        } = this.props;
        const {
            description,
            file,
        } = this.props.value;
        
        return (
            <div className={'fdr mt10 jcfs aifs'}>
                <div className={'fdc flx1 jcfs mr10'}>
                    <FormControl>
                        <TextField
                            required
                            error={!description}
                            id='description'
                            label='Description'
                            type='text'
                            fullWidth
                            value={description}
                            disabled={disabled}
                            onChange={this.onDescriptionChange}
                        />
                        {
                            !description &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className={'fdr flx1 aic ml10'}>
                    <div className='fdr flx1 jcfe'>
                        <Button component='label' color='primary' disabled={disabled}>
                            <Icon>upload</Icon>
                            <input
                                type='file'
                                accept='image/*'
                                hidden
                                onChange={this.onFileChange}
                            />
                            {
                                !file &&
                                <span className='ml15'>Upload Image</span>
                            }
                            {
                                !!file &&
                                <span className='ml15'>{file.name}</span>
                            }
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
}
