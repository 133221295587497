import firebaseApp from '../services/firebaseService';
import UserHelper from './model/user';
import firebase from 'firebase/app';

export interface IBase {
    id : string;

    /**
     * Created by document Id
     */
    createdBy : string;

    /**
     * Updated by document Id
     */
    updatedBy : string;
    createdByName : string;
    updatedByName : string;
    createdByEmployee : string;
    updatedByEmployee : string;
    createdOn : number;
    updatedOn : number;
}

export class BaseHelper {
    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IBase | null {
        const data = snapshot.data();

        if (!data) return null;

        return {
            id: snapshot.ref.id,
            createdBy: (data['createdBy'] as firebase.firestore.DocumentReference).id,
            updatedBy: (data['updatedBy'] as firebase.firestore.DocumentReference).id,
            createdByName: data['createdByName'],
            updatedByName: data['updatedByName'],
            createdByEmployee: data['createdByEmployee'],
            updatedByEmployee: data['updatedByEmployee'],
            createdOn: (data['createdOn'] as firebase.firestore.Timestamp).toMillis(),
            updatedOn: (data['updatedOn'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    protected static toFirestore(data : IBase) {
        return {
            createdBy: firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(data.createdBy),
            updatedBy: firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(data.updatedBy),
            createdByName: data.createdByName,
            updatedByName: data.updatedByName,
            createdByEmployee: data.createdByEmployee,
            updatedByEmployee: data.updatedByEmployee,
            createdOn: firebase.firestore.Timestamp.fromMillis(data.createdOn),
            updatedOn: firebase.firestore.Timestamp.fromMillis(data.updatedOn),
        };
    }

    public static getUserPath(uid : string) {
        return firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(uid).path;
    }

    public static getUserId(uid : string) {
        return firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(uid).path;
    }

}
