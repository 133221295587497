import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { INematodeAssignment } from '../../../../types/model/nematode/nematodeAssignment';
import AppFunctionsService from '../../../../services/appFunctionServices';
import TabPanel from '../../../customComponents/tab/TabPanel';
import { INematodeAssignmentEntry, NematodeAssignmentEntryHelper } from '../../../../types/model/nematode/nematodeAssignmentEntry';
import GeneralFunctions from '../../../../store/general/functions';
import NematodeAssignmentInfoView from '../view/AssignmentInfoView';
import NematodeAssignmentCompletedHistoryView from '../view/AssignmentCompletedHistoryView';

interface INematodeAssignmentInfoDialogProps extends RouteComponentProps {
    value : INematodeAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClosed ?: () => void;
    defaultOpen ?: boolean;
}

interface INematodeAssignmentInfoDialogState {
    open : boolean;

    tabIndex : number;

    isLoading : boolean;

    entries : Array<INematodeAssignmentEntry>;
}

class NematodeAssignmentInfoDialogComponent extends React.PureComponent<INematodeAssignmentInfoDialogProps, INematodeAssignmentInfoDialogState> {
    private listener ?: () => void;
    
    constructor(props : INematodeAssignmentInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
            isLoading: false,
            entries: [],
        };
    }

    public readonly componentDidMount = () => {
        const open = !!this.props.defaultOpen || this.inPath();
        this.setState({
            open,
        });

        if (open) {
            this.load();
        }
    };

    private readonly inPath = () : boolean => {
        const urlParams = new URLSearchParams(this.props.location.search);

        const assignmentId = urlParams.get('assignmentId');

        return this.props.value.id === assignmentId;
    };

    private readonly onIconButtonClick = () => {
        this.setState({
            open: true,
        });

        this.load();
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        const urlParams = new URLSearchParams(this.props.location.search);
        urlParams.delete('assignmentId');

        this.props.history.replace({
            pathname: this.props.location.pathname,
            search: urlParams.toString(),
        },);
        
        if (!this.listener) return;
        this.listener();

        this.setState({
            open: false,
        });

        if (this.props.onClosed) this.props.onClosed();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly load = () => {
        this.setState({
            isLoading: true,
        });

        this.listener = NematodeAssignmentEntryHelper.collection(this.props.value.id).orderBy('createdOn', 'asc').onSnapshot((snapshot) => {
            const entries = this.state.entries.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const entry = f.doc.data();

                switch (f.type) {
                    case 'added':
                        entries.splice(f.newIndex, 0, entry);
                        break;
                    case 'modified':
                        entries.splice(f.oldIndex, 1);
                        entries.splice(f.newIndex, 1, entry);
                        break;
                    case 'removed':
                        entries.splice(f.oldIndex, 1);
                        break;
                }
            });

            this.setState({
                entries,
                isLoading: false,
            });
        }, (ex) => {
            GeneralFunctions.generalShowErrorSnackbar('Error loading entries', ex);
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
            defaultOpen,
        } = this.props;
        const {
            open,
            tabIndex,
            isLoading,
            entries,
        } = this.state;

        return (
            <React.Fragment>
                {
                    !defaultOpen &&
                    <Tooltip title='Info'>
                        <div>
                            <IconButton color='primary' onClick={this.onIconButtonClick} aria-label='info' disabled={isLoading}>
                                <Icon>info</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='nematode-assignment-info-dialog-title'
                    aria-describedby='nematode-assignment-info-description'
                >
                    <AppBar elevation={0} className='fdc posr ais' position='static'>
                        <Toolbar variant='dense' className={'fdr'}>
                            <div className={'fdr flx1 aic'}>
                                <Typography variant='h5' color='inherit'>
                                    {
                                        `Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                        ${value.landName} -
                                        ${value.blockName}
                                        `
                                    }
                                </Typography>
                                <span className='flx1' />
                                <Tooltip title='Close'>
                                    <div>
                                        <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='AssignmentTabs'>
                            <Tab value={0} label='INFO' />
                            <Tab value={1} label='RESULTS' />
                        </Tabs>
                    </AppBar>
                    <DialogContent className='fdc flx1'>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            <>
                                <TabPanel value={tabIndex} index={0}>
                                    <NematodeAssignmentInfoView value={value} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    <NematodeAssignmentCompletedHistoryView value={value} entries={entries} />
                                </TabPanel>
                            </>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}

const NematodeAssignmentInfoDialog = withRouter(NematodeAssignmentInfoDialogComponent);

export default NematodeAssignmentInfoDialog;