import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import { IScoutingEntry, IScoutingEntrySectionEntity } from '../../../types/model/scouting/scoutingEntry';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { IAssignmentBlock } from '../../../types/model/masterData/block';

interface IHorizontalAssignmnetReportProps {
    entries : Array<IScoutingEntry>;
    value : IScoutingAssignment;
}

interface IHorizontalAssignmnetReportState {}

interface IHeader {
    [key : string] : Array<{
        name : string;
        subs : Array<string>;
        count : number;
    }>;
}

interface IData {
    [key : string] : Array<number | string>;
}
export default class HorizontalAssignmnetReport extends React.PureComponent<IHorizontalAssignmnetReportProps, IHorizontalAssignmnetReportState> {
    private readonly types = ['disease', 'insects'];
    constructor(props : IHorizontalAssignmnetReportProps) {
        super(props);
        this.state = {};
    }

    /**
     * Builds header object, so we can dynamically build
     * table header.
     */
    private getHeaders = (headers : IHeader, entry : IScoutingEntry) => {
        let sum = 0;

        // Get headers for each type.
        this.types.forEach((type) => {
            // Make sure the header for a type starts as an empty array.
            headers[type] = [];

            // Now loop through each section of an entry as all entries should
            // have the same sections.
            entry.sections.forEach((section) => {
                // Skip over sections that aren't of the header type.
                if (section.type !== type) return;

                // Check to see if header is not already present.
                if (!headers[type].find(header => header.name === section.name)) {
                    // Add header of type to headers.
                    headers[type].push({
                        name: section.name, // The header name
                        subs: typeof section.count === 'object' ? Object.keys(section.count).sort() : [], // Some headers have sub headers
                        count: typeof section.count === 'object' ? Object.keys(section.count).length : 1, // How many columns a header should use.
                    });

                    // This is used to get the total amount of headers,
                    // it makes it a bit easier to generate data object.
                    sum += typeof section.count === 'object' ? Object.keys(section.count).length : 1;
                }

            });

            // Sort headers of each type alphabetically to read easier.
            headers[type] = headers[type].sort((z, y) => {
                if (typeof z === 'object' && typeof y === 'object') {
                    return z.name.localeCompare(y.name);
                }

                if (typeof z === 'object' && typeof y === 'string') {
                    return z.name.localeCompare(y);
                }

                return z.toString().localeCompare(y.toString());
            });
        });

        return sum;
    };

    /**
     * Handles a check section entry.
     * Mutates data!
     */
    private checkEntryType = (data : IData, pointGuid : string, entryData : Array<IScoutingEntrySectionEntity>) => {
        // If count = 1, then just show a check.
        if (entryData.some(v => typeof v.count === 'number' && v.count > 0 && v.count < 2)) {
            data[pointGuid].push('✓');
        // If count > 1 show number, some checks can be counters...
        } else if (entryData.some(v => typeof v.count === 'number' && v.count > 2)) {
            data[pointGuid].push(lodash.sumBy(entryData, v => typeof v.count === 'number' ? v.count : 0));
        // If the check is a string show first comma separated list of first caharacter.
        } else if (entryData.some(v => typeof v.count === 'string')) {
            data[pointGuid].push(lodash.chain(entryData)
                .filter(v => typeof v.count === 'string')
                .map((v : { count : string }) => v.count[0].toLocaleUpperCase())
                .uniq()
                .join(', ').value());
        // If none of these show X.
        } else {
            data[pointGuid].push('X');
        }
    };

    /**
     * Handles a multi counter entry.
     * Mutates data!
     */
    private counterEntryType = (data : IData, pointGuid : string, entryData : Array<IScoutingEntrySectionEntity>) => {
        data[pointGuid].push(lodash.sumBy(entryData, v => typeof v.count === 'number' ? v.count : 0));
    };

    /**
     * Handles a check counter entry.
     * Mutates data!
     */
    private multiEntryType = (data : IData, pointGuid : string, header : {
        name : string;
        subs : Array<string>;
        count : number;
    }, entryData : Array<IScoutingEntrySectionEntity>) => {
        header.subs.forEach((sub) => {
            data[pointGuid].push(lodash.sumBy(entryData, v => typeof v.count === 'object' ? v.count[sub] : 0));
        });
    };

    /**
     * Builds the data object using headers and sum.
     */
    private getData = (points : Array<string>,
        headers : IHeader,
        sum : number,
        entries : Array<IScoutingEntry>) => {
        const data : IData = {};

        // Loop through all the point guids.
        points.forEach((pointGuid) => {
            data[pointGuid] = [];

            const entry = entries.find(x => x.pointGuid === pointGuid);
            // Fill points with no entries.
            if (!entry) {
                data[pointGuid].length = sum;
                data[pointGuid].fill('X');
                return;
            }

            // Loop through the headers types
            this.types.forEach((type) => {
                // Loop through headers
                headers[type].forEach((header) => {
                    // Get all entry data for specific header.
                    const entryData = entry.sections.filter(section => section.name === header.name && section.type === type);

                    // Handle check entry types.
                    if (entryData[0]?.counterType === 'check') {
                        this.checkEntryType(data, pointGuid, entryData);
                        return;
                    }

                    // If data type is counter, show number.
                    if (entryData[0]?.counterType === 'counter') {
                        this.counterEntryType(data, pointGuid, entryData);
                        return;
                    }

                    // If data is a multi counter, push counter values.
                    if (entryData[0]?.counterType === 'multi') {
                        this.multiEntryType(data, pointGuid, header, entryData);
                        return;
                    }

                    // If loops gets here the point is unknown and
                    // a X should be displayed.
                    data[pointGuid].push('X');
                });
            });
        });

        return data;
    };

    /**
     * Builds totals object with total percentages for stats.
     */
    private getTotals = (rows : IData, scoutingBlock : Omit<IAssignmentBlock, 'id'
    | 'createdBy'
    | 'createdByName'
    | 'createdByEmployee'
    | 'createdOn'
    | 'updatedBy'
    | 'updatedByName'
    | 'updatedByEmployee'
    | 'updatedOn'>) => {
        // Build total row.
        const totals : Array<number> = [];
        totals.length = rows[scoutingBlock.points.features[0].properties.guid].length;
        totals.fill(0);

        // Build total average
        const totalAverage : Array<number> = [];
        totalAverage.length = totals.length;
        totalAverage.fill(0);

        // Build percentage row.
        const totalPercentages : Array<number> = [];
        totalPercentages.length = totals.length;
        totalPercentages.fill(0);

        // Loop through data and add to totals row.
        // If there is a value, +1 to percentage array.
        lodash.forEach(rows, row => row.forEach((data, columnIndex) => {
            // If data type is number, and more than 0, add to totals.
            if (typeof data === 'number' && data > 0) {
                totals[columnIndex] += data;
                totalPercentages[columnIndex]++;
                return;
            }

            // If data type is string, and more not X, add +1 to totals.
            if (typeof data === 'string' && data !== 'X') {
                totals[columnIndex] += 1;
                totalPercentages[columnIndex]++;
                return;
            }
        }));

        // Calculate Percentages row.
        totalPercentages.forEach((total, index) => {
            totalPercentages[index] = total / scoutingBlock.points.features.length * 100;
        });

        // Calculate total average row.
        totals.forEach((total, index) => {
            totalAverage[index] = total / scoutingBlock.points.features.length;
        });

        return {
            totals,
            totalPercentages,
            totalAverage,
        };
    };

    private getEntries = (props : IHorizontalAssignmnetReportProps) => props.entries;
    private getValue = (props : IHorizontalAssignmnetReportProps) => props.value;
    private getReportData = createSelector(
        [this.getEntries, this.getValue],
        (entries, value) => {
            // Initialize header object.
            const headers : IHeader = {};

            // First lets make all the headers
            const sum = this.getHeaders(headers, entries[0]);

            // Now lets loop through all points and get the entry for that point.
            const data : IData = this.getData(value.scoutingBlock.pointsArray, headers, sum, entries);

            // Gets the totals and total percentages for each column of table.
            const totals = this.getTotals(data, value.scoutingBlock);

            return {
                headers,
                data,
                ...totals,
            };
        },
    );

    public render = () => {
        const { data, headers, totals, totalPercentages, totalAverage } = this.getReportData(this.props);
        return (
            <div className={'fdc ais flx1 oya'}>
                {
                    !!Object.keys(data).length &&
                    <Table size='small' padding='none'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>
                                </TableCell>
                                <TableCell colSpan={lodash.sumBy(headers['disease'], n => n.count)} align='center'>
                                    DISEASE
                                </TableCell>
                                <TableCell colSpan={lodash.sumBy(headers['insects'], n => n.count)} align='center'>
                                    INSECTS
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='center'>
                                    POINT
                                </TableCell>
                                {
                                    this.types.map(type => (
                                        headers[type].map(header => (
                                            <TableCell align='center' key={`${type}_${header.name}`} colSpan={header.subs.length}>
                                                {header.name}
                                            </TableCell>
                                        ))
                                    ))
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                {
                                    this.types.map(type => (
                                        headers[type].map(header => (typeof header === 'object' && header.subs.length ?
                                            header.subs.map(sub => (
                                                <TableCell align='center' key={`${type}_${header.name}_${sub}`}>
                                                    {
                                                        header.name === 'Mite' &&
                                                sub[0]

                                                    }
                                                    {
                                                        header.name !== 'Mite' &&
                                                (sub.split(' ')[1] ?? sub[0])
                                                    }
                                                </TableCell>)) :
                                            <TableCell key={`${type}_sub_${header.name}`}></TableCell>))
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(data).map((n, i) => (
                                    <TableRow key={`${i}_point`}>
                                        <TableCell align='center'>
                                            {i + 1}
                                        </TableCell>
                                        {
                                            data[n].map((x, ii) => (
                                                <TableCell align='center' key={`${i}_point_${ii}_data`}>
                                                    {x}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                            <TableRow key={'totals'}>
                                <TableCell align='center'>
                                    <b>Totals</b>
                                </TableCell>
                                {
                                    totals.map((n, i) => (
                                        <TableCell align='center' key={`${i}_total`}>
                                            <b>{n}</b>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow key={'averages'}>
                                <TableCell align='center'>
                                    <b>Average Count</b>
                                </TableCell>
                                {
                                    totalAverage.map((n, i) => (
                                        <TableCell align='center' key={`${i}_average`}>
                                            <b>{`${n.toFixed(2)}`}</b>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                            <TableRow key={'percentages'}>
                                <TableCell align='center'>
                                    <b>Percentage</b>
                                </TableCell>
                                {
                                    totalPercentages.map((n, i) => (
                                        <TableCell align='center' key={`${i}_percentage`}>
                                            <b className={`${n > 50 ? 'cpr' : n > 0 ? 'co' : ''}`}>{`${n.toFixed(2)} %`}</b>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                }
            </div>
        );
    };
}
