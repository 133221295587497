import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { CROP } from '../../../../appConstants';
import { IScouting } from '../../../../types/model/masterData/scouting';
import ButtonSelector from '../../../customComponents/selector/ButtonSelector';

interface IScoutingSectionSelectorProps extends RouteComponentProps {
    scoutings : Array<IScouting>;
    isLoading : boolean;

    value : Array<string> | null;
    onChange ?: (value ?: Array<string> | null) => void;
}

interface IScoutingSectionSelectorState {}

class ScoutingSectionSelectorComponent extends React.PureComponent<IScoutingSectionSelectorProps, IScoutingSectionSelectorState> {
    constructor(props : IScoutingSectionSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly getData = (props : IScoutingSectionSelectorProps) => props.scoutings;
    private getSearch = (props : IScoutingSectionSelectorProps) => props.location.search;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getSections = createSelector(
        [this.getData, this.getCrop],
        (scoutings, crop) => {
            return scoutings.find(x => x.crop === crop)?.sections.map(x => x.name) ?? [];
        },
    );

    private onChange = (selected : string) => {
        if (!this.props.onChange) return;

        const value = [...this.props.value ?? []];

        const index = value.indexOf(selected);

        if (index > -1) value.splice(index, 1);
        else value.push(selected);
        this.props.onChange(value);
    };

    public render = () => {
        const { value, isLoading } = this.props;
        const sections = this.getSections(this.props);
        return (
            <div className='fdc aic p10'>
                {
                    isLoading &&
                    <CircularProgress />
                }
                {
                    !isLoading &&
                    <ButtonSelector<string>
                        values={sections}
                        onChange={this.onChange}
                        value={value}
                    />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        scoutings: state.scouting.scoutings,
        isLoading: state.scouting.isLoading,
    };
};

const ScoutingSectionSelector = connect(
    mapStateToProps,
)(withRouter(ScoutingSectionSelectorComponent));

export default ScoutingSectionSelector;
