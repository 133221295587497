import { CROP } from '../../../appConstants';
import firebase from 'firebase/app';
import lodash from 'lodash';
import { IGeoServerBlock } from './geoserver/block';
import { IGeoServerPoint } from './geoserver/point';
import GeoHelper from '../../../services/helper/geoHelper';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import firebaseApp from '../../../services/firebaseService';

export interface IBlock extends IBase {
    landId ?: string;
    fatherId ?: number;
    name : string;
    area : number;
    division : string;
    description : string;
    landName : string;
    ha : number;
    age : number;
    polygons : Record<number, Array<IGeoPoint>>;
    points : Record<string, IGeoPoint>;
    pointsArray : Array<string>;
    phenologyPoints : Record<string, IGeoPoint>;
    phenologyPointsArray : Array<string>;
    center : IGeoPoint;
    crop : string | CROP;
}

export interface IAssignmentBlock {
    guid : string;
    landId ?: string;
    fatherId ?: number;
    name : string;
    area : number;
    division : string;
    description : string;
    landName : string;
    ha : number | null;
    age : number;
    polygons : GeoJSON.Feature<GeoJSON.MultiPolygon>;
    points : GeoJSON.FeatureCollection<GeoJSON.Point, {
        guid : string;
        pointNumber : number;
    }>;
    pointsArray : Array<string>;
    phenologyPoints : GeoJSON.FeatureCollection<GeoJSON.Point, {
        guid : string;
        pointNumber : number;
    }>;
    phenologyPointsArray : Array<string>;
    center : GeoJSON.Point;
    crop : CROP;
}

export default class AssignmentBlockHelper extends BaseHelper {
    public static readonly COLLECTION = 'scouting_blocks';

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static converter : firebase.firestore.FirestoreDataConverter<IBlock | null> = {
        fromFirestore: (snapshot) => {
            return AssignmentBlockHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IBlock) => {
            return AssignmentBlockHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IBlock | null {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) return null;

        const pointGuids = Object.keys(data['points'] ?? {});
        pointGuids.sort();

        const phenologyPointGuids = Object.keys(data['phenologyPoints'] ?? {});
        phenologyPointGuids.sort();
        return {
            ...result,
            landId: data['landId'],
            fatherId: data['fatherId'],
            name: data['name'],
            area: data['area'],
            division: data['division'],
            description: data['description'],
            landName: data['landName'],
            ha: data['ha'],
            age: data['age'],
            polygons: lodash.mapValues(data['polygons'], geos => lodash.map(geos, geo => (GeoPointHelper.fromFirestore(geo)))),
            points: lodash.mapValues(data['points'], geo => (GeoPointHelper.fromFirestore(geo))),
            pointsArray: pointGuids,
            phenologyPoints: lodash.mapValues(data['phenologyPoints'] ?? {}, geo => (GeoPointHelper.fromFirestore(geo))),
            phenologyPointsArray: phenologyPointGuids,
            center: GeoPointHelper.fromFirestore(data['center']),
            crop: data['crop'],
        };
    }

    protected static toFirestore(data : IBlock) {

        const result = super.toFirestore(data);
        return {
            ...result,
            landId: data.landId ?? '0',
            fatherId: data.fatherId ?? 0,
            name: data.name,
            area: data.area,
            division: data.division,
            description: data.description,
            landName: data.landName,
            ha: data.ha,
            age: data.age,
            crop: data.crop,
            center: GeoPointHelper.toFirestore(data.center),
            polygons: lodash.mapValues(data.polygons, geos => lodash.map(geos, geo => (GeoPointHelper.toFirestore(geo)))),
            points: lodash.mapValues(data.points, geo => (GeoPointHelper.toFirestore(geo))),
        };
    }

    static fromFirestoreData(data : firebase.firestore.DocumentData) : IAssignmentBlock {
        const pointGuids = Object.keys(data['points']);
        const phenologyPointGuids = Object.keys(data['phenologyPoints'] ?? {});
        
        return {
            ...data as IAssignmentBlock,
            polygons: {
                type: 'Feature',
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: [
                        ...lodash
                            .chain(data['polygons'] as Record<number, Array<firebase.firestore.GeoPoint>>)
                            .map(
                                geos => geos.map(x => [[x.longitude, x.latitude]])
                            )
                            .value(),
                    ],
                },
                properties: {},
            },
            points: {
                type: 'FeatureCollection',
                features: lodash
                    .chain(data['points'] as Record<string, firebase.firestore.GeoPoint>)
                    .map<GeoJSON.Feature<GeoJSON.Point, {guid : string; pointNumber : number}>>((point, guid) => ({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [point.longitude, point.latitude],
                        },
                        properties: {
                            guid,
                            pointNumber: pointGuids.indexOf(guid) + 1,
                        },
                    }))
                    .value(),
            },
            pointsArray: pointGuids,
            phenologyPoints: {
                type: 'FeatureCollection',
                features: lodash
                    .chain(data['points'] as Record<string, firebase.firestore.GeoPoint>)
                    .map<GeoJSON.Feature<GeoJSON.Point, {guid : string; pointNumber : number}>>((point, guid) => ({
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [point.longitude, point.latitude],
                        },
                        properties: {
                            guid,
                            pointNumber: phenologyPointGuids.indexOf(guid) + 1,
                        },
                    }))
                    .value(),
            },
            phenologyPointsArray: phenologyPointGuids,
            center: {
                type: 'Point',
                coordinates: [
                    (data['center'] as firebase.firestore.GeoPoint).longitude,
                    (data['center'] as firebase.firestore.GeoPoint).latitude,
                ],
            },
        };
    }

    static toFirestoreData(data : IAssignmentBlock) : firebase.firestore.DocumentData {
        return {
            ...data,
            polygons: data.polygons.geometry.coordinates.reduce<Record<number, Array<firebase.firestore.GeoPoint>>>((current, value, index) => {
                current[index] = value[0].map(x => new firebase.firestore.GeoPoint(x[1], x[0]));
                return current;
            }, {}),
            points: data.points.features.reduce<Record<string, firebase.firestore.GeoPoint>>((current, value) => {
                current[value.properties.guid] = new firebase.firestore.GeoPoint(value.geometry.coordinates[1], value.geometry.coordinates[0]);

                return current;
            }, {}),
            phenologyPoints: data.phenologyPoints.features.reduce<Record<string, firebase.firestore.GeoPoint>>((current, value) => {
                current[value.properties.guid] = new firebase.firestore.GeoPoint(value.geometry.coordinates[1], value.geometry.coordinates[0]);

                return current;
            }, {}),
            center: new firebase.firestore.GeoPoint(data.center.coordinates[1], data.center.coordinates[0]),
        };
    }

    static fromGeoServerBlock(block : IGeoServerBlock, crop : CROP, points : Array<IGeoServerPoint>) : IAssignmentBlock {
        return {
            guid: block.guid,
            age: 0,
            area: GeoHelper.getGeoServerBlockArea(block),
            center: GeoHelper.getGeoServerBlockCenter(block),
            crop: crop,
            description: `${block.departmentName} - ${block.fieldName} - ${block.code}`,
            division: block.departmentShortName.toLocaleLowerCase(),
            ha: GeoHelper.getGeoServerBlockArea(block, 'ha'),
            landName: block.fieldName,
            name: block.code,
            polygons: {
                type: 'Feature',
                geometry: block.geometry.type === 'MultiPolygon' ? block.geometry : {
                    type: 'MultiPolygon',
                    coordinates: [[
                        ...block.geometry.coordinates,
                    ]],
                },
                properties: {},
            },
            points: {
                features: points.filter(x => x.type === 'scouting').map((point, index) => ({
                    type: 'Feature',
                    geometry: point.geometry.type === 'Point' ? point.geometry : {
                        type: 'Point',
                        coordinates: [...point.geometry.coordinates[0]],
                    },
                    properties: {
                        guid: point.guid,
                        pointNumber: index + 1,
                    },
                })),
                type: 'FeatureCollection',
            },
            pointsArray: points.filter(x => x.type === 'scouting').map(x => x.guid),
            phenologyPoints: {
                features: points.filter(x => x.type === 'phenology').map((point, index) => ({
                    type: 'Feature',
                    geometry: point.geometry.type === 'Point' ? point.geometry : {
                        type: 'Point',
                        coordinates: [...point.geometry.coordinates[0]],
                    },
                    properties: {
                        guid: point.guid,
                        pointNumber: index + 1,
                    },
                })),
                type: 'FeatureCollection',
            },
            phenologyPointsArray: points.filter(x => x.type === 'phenology').map(x => x.guid),
        };
    }
}