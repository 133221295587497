import { createAction } from 'typesafe-actions';
import { INotification } from '../../@types/redux';

export default class GeneralActions {
    public static enqueueSnackbar = createAction('ENQUEUE_SNACKBAR', resolve => (notification : INotification) => resolve({
        key: new Date().getTime() + Math.random(),
        ...notification,
    }));
    public static removeSnackbar = createAction('REMOVE_SNACKBAR', resolve => (key : number) => resolve(key));

    public static setLoadingAreas = createAction('GENERAL_SET_LOADING_AREAS', resolve => (loading : boolean) => resolve(loading));
}
