import { Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createSelector } from 'reselect';
import { IRootState } from '../../../@types/redux';
import { CROP } from '../../../appConstants';
import ScoutingFunctions from '../../../store/scouting/functions';
import { IScouting, IScoutingSection } from '../../../types/model/masterData/scouting';
import StandardFab from '../../customComponents/button/StandardFab';
import MaterialTable from '../../customComponents/materialTable/Table';
import ScoutingEditDialog from './dialog/EditDialog';
import DeleteDialog from '../../customComponents/dialog/DeleteDialog';
import { Transitions } from '../../customComponents/animations/Transitions';

interface IScoutingListProps extends RouteComponentProps {
    scoutings : Array<IScouting>;
    isLoading : boolean;
}

interface IScoutingListState {
    selected ?: IScoutingSection;
}

class ScoutingListComponent extends React.PureComponent<IScoutingListProps, IScoutingListState> {
    constructor(props : IScoutingListProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    public readonly componentDidUpdate = (prevProps : IScoutingListProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.load();
        }
    };

    public readonly load = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop');

            if (crop) {
                ScoutingFunctions.getList(crop, true);
            }
        }
    };
    private readonly getData = (props : IScoutingListProps) => props.scoutings;
    private getSearch = (props : IScoutingListProps) => props.location.search;
    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getScouting = createSelector(
        [this.getData, this.getCrop],
        (scoutings, crop) => {
            return scoutings.find(x => x.crop === crop) ?? ({
                crop,
                sections: [],
            });
        },
    );

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                entries: [],
            },
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;

        this.setState({
            selected: scouting.sections.find(x => x.name === event.currentTarget.value),
        });
    };

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;
        
        this.changeOrder(scouting, event.currentTarget.value, true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;
        
        this.changeOrder(scouting, event.currentTarget.value, false);
    };

    private readonly changeOrder = (scouting : IScouting, name : string, up : boolean) => {
        const sections = [...scouting.sections];

        const index = sections.findIndex(x => x.name === name);

        if (up && index > 0) {
            const section = sections[index];
            const prevSection = sections[index - 1];

            sections.splice(index, 1, prevSection);
            sections.splice(index - 1, 1, section);

            ScoutingFunctions.save({
                ...scouting,
                sections,
            });
        } else if (!up && index < sections.length - 1) {
            const section = sections[index];
            const prevSection = sections[index + 1];

            sections.splice(index, 1, prevSection);
            sections.splice(index + 1, 1, section);

            ScoutingFunctions.save({
                ...scouting,
                sections,
            });
        }
    };

    private readonly onEditClose = (section ?: IScoutingSection) => {
        if (!section) {
            this.setState({
                selected: undefined,
            });
            return;
        }
        
        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;

        scouting.sections = [...scouting.sections];

        const index = scouting.sections.findIndex(x => x.name === section.name);

        if (index > -1) {
            scouting.sections.splice(index, 1, {
                ...section,
                entries: [...section.entries],
            });
        } else {
            scouting.sections.push({
                ...section,
                entries: [...section.entries],
            });
        }

        ScoutingFunctions.save(scouting).then(() => {
            this.setState({
                selected: undefined,
            });
        });
    };

    private readonly onCopyClick = (event : React.MouseEvent<HTMLButtonElement>) => {

        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;
        
        const current = scouting.sections.find(x => x.name === event.currentTarget.value);

        if (!current) return;

        this.setState({
            selected: {
                ...current,
                name: '',
                entries: [
                    ...current.entries,
                ],
            },
        });
    };

    private readonly onDeleteClick = async (event : React.MouseEvent<HTMLButtonElement>) => {

        const scouting = {
            ...this.getScouting(this.props),
        } as IScouting;
        
        const currentIndex = scouting.sections.findIndex(x => x.name === event.currentTarget.value);

        if (currentIndex < 0) return;

        scouting.sections.splice(currentIndex, 1);

        ScoutingFunctions.save(scouting);
    };

    public readonly render = () => {
        const { selected } = this.state;
        const { isLoading } = this.props;

        const scouting = this.getScouting(this.props);

        return (
            <div className={'fdc hfill mh0 mw0'}>
                <div className={'flx1 fdc ml15 mr15 mt20'}>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IScoutingSection>
                            id='scoutingTable'
                            data={scouting.sections}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 20,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Copy'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onCopyClick}>
                                                    <Icon>content_copy</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <DeleteDialog
                                            maxWidth='md'
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                            message={'Delete section?'}
                                            value={row.name}
                                            onConfirm={this.onDeleteClick}
                                            title={'Delete'}
                                            transition={Transitions.Down}
                                        >
                                            {
                                                onClick => (
                                                    <Tooltip title='Delete'>
                                                        <div>
                                                            <IconButton value={row.name} disabled={isLoading} onClick={onClick}>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }
                                        </DeleteDialog>
                                    </div>
                                ),
                            }, {
                                header: '',
                                paddingRight: 4,
                                width: 15,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Up'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onUpClick}>
                                                    <Icon>expand_less</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Down'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onDownClick}>
                                                    <Icon>expand_more</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Options',
                                field: 'entries',
                                width: 145,
                                enableSort: true,
                                renderCell: row => (row.entries.length),
                            }]}
                        />
                    </Card>
                    <ScoutingEditDialog value={selected} fullWidth fullScreen onClose={this.onEditClose} isLoading={isLoading} />
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <AddIcon />
                    </StandardFab>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        scoutings: state.scouting.scoutings,
        isLoading: state.scouting.isLoading,
    };
};

const ScoutingList = connect(
    mapStateToProps,
)(withRouter(ScoutingListComponent));

export default ScoutingList;