import ScoutingActions from './actions';
import { dispatch, getState } from '..';
import firebaseApp from '../../services/firebaseService';
import GeneralFunctions from '../general/functions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import { Moment } from 'moment';
import { ScoutingAssignmentHelper, IScoutingAssignment, ScoutingType } from '../../types/model/scouting/scoutingAssignment';
import { IUser } from '../../types/model/user';
import { IAssignmentBlock } from '../../types/model/masterData/block';
import { CROP } from '../../appConstants';
import ScoutingHelper, { IScouting } from '../../types/model/masterData/scouting';
import * as uuid from 'uuid';

export default class ScoutingFunctions {
    private static listener ?: () => void;
    private static assignmentListener ?: () => void;

    public static getAssignments = (crop : string, unfinishedOnly : boolean, refresh ?: boolean) => {
        if (!refresh && ScoutingFunctions.assignmentListener) return;

        if (ScoutingFunctions.assignmentListener) {
            ScoutingFunctions.assignmentListener();
        }

        dispatch(ScoutingActions.setLoadingAssignments(true));
        dispatch(ScoutingActions.setAssignments([]));
        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());

            let query = ScoutingAssignmentHelper
                .collection()
                .where('crop', '==', crop);

            if (unfinishedOnly) {
                query = query.where('finished', '==', false);
            }

            ScoutingFunctions.assignmentListener = query
                .orderBy('date', 'desc')
                .limit(1000)
                .onSnapshot((snapshot) => {
                    const scoutingState = getState().scouting;

                    const assignments = lodash.cloneDeep(scoutingState.assignments);

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const assignment = f.doc.data();

                        if (!assignment || !divisions.includes(assignment.division)) return;

                        const index = lodash.findIndex(assignments, n => n.id === assignment.id);

                        switch (f.type) {
                            case 'added':
                                assignments.push(assignment);
                                break;
                            case 'modified':
                                assignments.splice(index, 1, assignment);
                                break;
                            case 'removed':
                                assignments.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(ScoutingActions.setAssignments(assignments.sort((a, b) => b.date - a.date)));
                    dispatch(ScoutingActions.setLoadingAssignments(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', err);
                    dispatch(ScoutingActions.setLoadingAssignments(false));
                }, () => {
                    dispatch(ScoutingActions.setAssignments([]));
                    dispatch(ScoutingActions.setLoadingAssignments(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', ex);
        }
    };

    /**
     * Validates an assignment.
     * Throws `Error` if invalid.
     * @param assignment
     */
    private static validateAssignment(assignment : IScoutingAssignment) {

        if (!assignment.date) {
            throw new Error('Date required.');
        }

        if (!assignment.block) {
            throw new Error('Block required.');
        }

        if (!assignment.division) {
            throw new Error('Division required.');
        }

        if (!assignment.blockName) {
            throw new Error(`${assignment.scoutingBlock.landName} required a Block.`);
        }

        if (!Object.keys(assignment.scoutingBlock.points).length) {
            throw new Error(`Block ${assignment.scoutingBlock.name} has no points.`);
        }
    }

    /**
     * Creates/Saves assignment.
     * If `saveItem` is not undefined it makes changes to that assignment and
     * saves it, if it is undefined a new assignment is create using the list
     * of scouting blocks. If multiple blocks is provided and `saveItem` is not undefined
     * the last block will be used.
     * This uses a firestore transaction to create/save multiple assignments.
     * @param date
     * @param employee Employee document path
     * @param employeeNumber
     * @param employeeName
     * @param scoutingBlocks
     * @param saveItem
     */
    public static saveAssignment = async (
        date : Moment,
        employee : string,
        employeeNumber : string,
        employeeName : string,
        scoutingBlocks : Array<IAssignmentBlock>,
        saveItem ?: IScoutingAssignment,
    ) => {
        dispatch(ScoutingActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const block of scoutingBlocks) {
                    const assignment = {
                        id: saveItem?.id ?? '',
                        createdBy: saveItem?.createdBy ?? authState.session.user.ref,
                        createdByName: saveItem?.createdByName ?? authState.session.user.name,
                        createdByEmployee: saveItem?.createdByEmployee ?? authState.session.user.employeeNumber,
                        createdOn: saveItem?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),

                        date: date.valueOf(),
                        employee,
                        employeeNumber,
                        employeeName,

                        block: block.guid,
                        blockName: block.name,
                        landName: block.landName,
                        division: block.division,
                        crop: block.crop,
                        scoutingBlock: block,
                        finished: false,
                        finishedPoints: [],

                        updatedBy: authState.session.user.ref,
                        updatedByName: authState.session.user.name,
                        updatedByEmployee: authState.session.user.employeeNumber,
                        updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                        type: saveItem?.type ?? 'general',
                        sections: saveItem?.sections ?? [],
                        components: saveItem?.components ?? [],
                        insects: saveItem?.insects ?? [],
                        diseases: saveItem?.diseases ?? [],
                        damages: saveItem?.damages ?? [],
                    } as IScoutingAssignment;

                    ScoutingFunctions.validateAssignment(assignment);
                    ScoutingAssignmentHelper.saveTransaction(transaction, assignment);
                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${scoutingBlocks.length > 1 ? 's' : ''} saved.`);
            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving assignment.', ex);
        } finally {
            dispatch(ScoutingActions.setLoadingAssignments(false));
        }
        return false;
    };

    public static createAssignments = async (
        date : Moment,
        scoutingBlocks : Record<string, Array<IAssignmentBlock>>,
        employees : Record<string, IUser | null>,
        scoutType : ScoutingType,
        sections : Array<string>,
        components : Array<string>,
        insects : Array<string>,
        diseases : Array<string>,
        damages : Array<string>,
    ) => {
        dispatch(ScoutingActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const landName of Object.keys(scoutingBlocks)) {
                    for (const block of scoutingBlocks[landName]) {
                        const employee = employees[landName];

                        if (!employee) throw new Error(`Land ${landName} has no Scout.`);
                        const assignment = {
                            id: '',
                            createdBy: authState.session.user.ref,
                            createdByName: authState.session.user.name,
                            createdByEmployee: authState.session.user.employeeNumber,
                            createdOn: firebase.firestore.Timestamp.now().toMillis(),

                            date: date.valueOf(),
                            employee: employee.ref,
                            employeeNumber: employee.employeeNumber,
                            employeeName: employee.name,

                            block: block.guid,
                            blockName: block.name,
                            landName: block.landName,
                            division: block.division,
                            crop: block.crop,
                            scoutingBlock: block,
                            finished: false,
                            finishedPoints: [],

                            updatedBy: authState.session.user.ref,
                            updatedByName: authState.session.user.name,
                            updatedByEmployee: authState.session.user.employeeNumber,
                            updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                            type: scoutType,
                            sections,
                            components,
                            insects,
                            diseases,
                            damages,
                        } as IScoutingAssignment;

                        ScoutingFunctions.validateAssignment(assignment);
                        ScoutingAssignmentHelper.saveTransaction(transaction, assignment);
                    }

                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${Object.keys(scoutingBlocks).length > 1 ? 's' : ''} created.`);
            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while creating assignment.', ex);
        } finally {
            dispatch(ScoutingActions.setLoadingAssignments(false));
        }
        return false;
    };



    public static save = async (item : IScouting) => {
        const session = getState().auth.session;

        if (!session) return;
        dispatch(ScoutingActions.setLoading(true));

        try {
            const save : IScouting = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                sections: lodash.cloneDeep(item.sections),
                id: item.id ? item.id : uuid.v4(),
            };

            await ScoutingHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving scouting.', ex);
            throw ex;
        } finally {
            dispatch(ScoutingActions.setLoading(false));
        }
    };

    public static getList = (crop : CROP | string, refresh ?: boolean) => {
        if (!refresh && ScoutingFunctions.listener) return;

        if (ScoutingFunctions.listener) {
            ScoutingFunctions.listener();
        }

        dispatch(ScoutingActions.setLoading(true));

        try {
            
            ScoutingFunctions.listener = ScoutingHelper
                .collection()
                .where('crop', '==', crop)
                .onSnapshot((snapshot) => {
                    const scoutingState = getState().scouting;

                    const scoutings = scoutingState.scoutings.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();
                        const index = lodash.findIndex(scoutings, n => n.id === data.id);

                        switch (f.type) {
                            case 'added':
                                scoutings.push(data);
                                break;
                            case 'modified':
                                scoutings.splice(index, 1, data);
                                break;
                            case 'removed':
                                scoutings.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(ScoutingActions.setList(scoutings));
                    dispatch(ScoutingActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading scoutings.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading scoutings.', ex);
            dispatch(ScoutingActions.setLoading(false));
        }
    };
}
