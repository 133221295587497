import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import TabPanel from '../../../customComponents/tab/TabPanel';
import { CROP } from '../../../../appConstants';
import { ScoutingType } from '../../../../types/model/scouting/scoutingAssignment';
import { IAssignmentBlock } from '../../../../types/model/masterData/block';
import AssignmentWeekSelector from '../../../customComponents/selector/AssignmentWeekSelector';
import AssignmentDivisionSelector from '../../../customComponents/selector/AssignmentDivisionSelector';
import { IDivision } from '../../../../types/model/rights/division';
import ScoutingAssignmentTypeSelector from '../ScoutTypeSelector';
import ScoutingAssignmentFieldsView from '../FieldsView';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import ScoutingAssignmentBlockSelector from '../BlockSelector';
import { MapRef } from 'react-map-gl';
import GeoHelper from '../../../../services/helper/geoHelper';

interface IGeneralScoutingAssignmentFieldSelectorProps {
    crop : CROP;

    division ?: IDivision;
    
    scoutType ?: ScoutingType;
    date ?: moment.Moment;
    
    selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>;

    sections ?: Array<string>;
    components ?: Array<string>;
    insects ?: Array<string>;
    diseases ?: Array<string>;
    damages ?: Array<string>;

    onWeekChanged ?: (date ?: moment.Moment) => void;
    onDivisionChanged ?: (division ?: IDivision) => void;
    onScoutTypeChange ?: (scoutType ?: ScoutingType) => void;
    onSectionsChange ?: (sections ?: Array<string> | null) => void;
    onComponentsChange ?: (components ?: Array<string> | null) => void;
    onInsectsChange ?: (insects ?: Array<string> | null) => void;
    onDiseasesChange ?: (diseases ?: Array<string> | null) => void;
    onDamagesChange ?: (damages ?: Array<string> | null) => void;

    onChange ?: (selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>) => void;
    onAssignmentAddClick ?: () => void;

    mapRef : MapRef | null;
}

interface IGeneralScoutingAssignmentFieldSelectorState {
    tabIndex : number;

    defaultLandName ?: string;
}

export default class GeneralScoutingAssignmentFieldSelector extends React.PureComponent<IGeneralScoutingAssignmentFieldSelectorProps, IGeneralScoutingAssignmentFieldSelectorState> {
    constructor(props : IGeneralScoutingAssignmentFieldSelectorProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onAddFieldsClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBlockBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };

    private readonly onAddClick = (landName : string, blocks : Array<IAssignmentBlock>) => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
            defaultLandName: undefined,
        });

        const selectedBlocks = {
            ...this.props.selectedScoutingBlocks,
        };

        selectedBlocks[landName] = [...blocks];

        if (this.props.onChange) this.props.onChange(selectedBlocks);
    };

    private readonly onBlocksEditClick = (landName : string) => {
        this.setState({
            defaultLandName: landName,
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onDeleteLandClick = (landName : string) => {
        const selectedBlocks = {
            ...this.props.selectedScoutingBlocks,
        };
        delete selectedBlocks[landName];
        if (this.props.onChange) this.props.onChange(selectedBlocks);

    };

    private readonly onZoomClick = (landName : string) => {
        this.props.mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds([
            ...this.props.selectedScoutingBlocks[landName],
        ]), {
            minZoom: 15,
        });
    };

    public readonly render = () => {
        const {
            tabIndex,
            defaultLandName,
        } = this.state;

        const {
            crop,
            division,
            scoutType,
            date,
            selectedScoutingBlocks,
            sections,
            components,
            insects,
            diseases,
            damages,
            onDivisionChanged,
            onWeekChanged,
            onScoutTypeChange,
            onSectionsChange,
            onComponentsChange,
            onInsectsChange,
            onDiseasesChange,
            onDamagesChange,
            onAssignmentAddClick,
            mapRef,
        } = this.props;
        
        return (
            <Paper className={'flx1 fdc m5'}>
                <TabPanel value={tabIndex} index={0}>
                    <AppBar position='static' className='bcdg pbr' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography className='fs17'>
                                ASSIGNMENTS
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc hfill oya drawer'>
                        <AssignmentWeekSelector
                            date={date}
                            onChanged={onWeekChanged}
                        />
                        <AssignmentDivisionSelector
                            crop={crop}
                            value={division}
                            onChanged={onDivisionChanged}
                        />
                        <ScoutingAssignmentTypeSelector
                            value={scoutType}
                            sections={sections}
                            components={components}
                            insects={insects}
                            diseases={diseases}
                            damages={damages}
                            onChange={onScoutTypeChange}
                            onSectionsChange={onSectionsChange}
                            onComponentsChange={onComponentsChange}
                            onInsectsChange={onInsectsChange}
                            onDiseasesChange={onDiseasesChange}
                            onDamagesChange={onDamagesChange}
                        />
                        <ScoutingAssignmentFieldsView
                            division={division?.code}
                            date={date}
                            scoutType={scoutType}
                            scoutingBlocks={selectedScoutingBlocks}
                            onAddFieldsClick={this.onAddFieldsClick}
                            onDeleteClick={this.onDeleteLandClick}
                            onZoomClick={this.onZoomClick}
                            onEditClick={this.onBlocksEditClick}
                        />
                    </div>
                    <div className={'fdc aife jcc p10 mt5'}>
                        <StadiumButton
                            variant='contained'
                            className='bcy cpd dbcg dcg bsd'
                            disabled={!Object.keys(selectedScoutingBlocks).length}
                            onClick={onAssignmentAddClick}
                        >
                            ADD ASSIGNMENT
                        </StadiumButton>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <ScoutingAssignmentBlockSelector
                        crop={crop}
                        division={division?.code}
                        defaultLandName={defaultLandName}
                        scoutType={scoutType}
                        onBackClick={this.onBlockBackClick}
                        date={date}
                        selectedScoutingBlocks={selectedScoutingBlocks}
                        onAddClick={this.onAddClick}
                        mapRef={mapRef}
                    />
                </TabPanel>
            </Paper>
        );
    };
}
