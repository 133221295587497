import React from 'react';
import StadiumButton from '../button/StadiumButton';
import WeekGridSelector from './WeekGridSelector';
import { DatePicker } from '@material-ui/pickers';
import { WEEK_FILTER_FORMAT_DEFAULT } from '../../../appConstants';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

interface IWeekFilterSelectorProps {
    startDate ?: moment.Moment;
    endDate ?: moment.Moment;
    onSave ?: (startDate ?: moment.Moment, endDate ?: moment.Moment) => void;
    onCancel ?: () => void;
    clearable ?: boolean;
}

interface IWeekFilterSelectorState {
    week : number;
    startDate : moment.Moment;
    endDate : moment.Moment;

    weeks : Array<number>;

}

export default class WeekFilterSelector extends React.PureComponent<IWeekFilterSelectorProps, IWeekFilterSelectorState> {
    private maxWeek : number = moment.utc().isoWeek();
    constructor(props : IWeekFilterSelectorProps) {
        super(props);

        const week = props.startDate?.isoWeek() ?? this.maxWeek;

        const weeks = [];
        for (let i = 5; i >= 0; i--) {
            weeks[i] = moment.utc().day('Sunday').isoWeek(this.maxWeek).add(-5 + i, 'week').isoWeek();
        }
        this.state = {
            week,
            startDate: moment.utc().day('Sunday').isoWeek(week).startOf('isoWeek'),
            endDate: moment.utc().day('Sunday').isoWeek(week).endOf('isoWeek'),
            weeks,
        };
    }

    private onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.value || parseInt(event.currentTarget.value) > this.maxWeek) return;
        const week = parseInt(event.currentTarget.value);
        this.setWeek(week);
    };

    private onStartDateChange = (startDate : moment.Moment | null) => {
        if (!startDate) return;
        this.setState({
            startDate,
        });
    };

    private onEndDateChange = (endDate : moment.Moment | null) => {
        if (!endDate) return;
        this.setState({
            endDate,
        });
    };

    private setWeek = (week : number) => {
        const startDate = moment.utc().day('Sunday').isoWeek(week).startOf('isoWeek');
        const endDate = moment.utc().day('Sunday').isoWeek(week).endOf('isoWeek');
        this.setState({
            week,
            startDate,
            endDate,
        });
    };

    private onSaveClick = () => {
        if (!this.props.onSave) return;
        this.props.onSave(this.state.startDate, this.state.endDate);
    };

    private onClearClick = () => {
        if (!this.props.onSave) return;
        this.props.onSave(undefined, undefined);
    };

    public render = () => {
        const { onCancel, clearable } = this.props;
        const { week, startDate, endDate, weeks } = this.state;
        return (
            <div className='week-filter fdc ais p20'>
                <div className='fdr aic jcc'>
                    <div className='fdc flx1 aifs jcc'>
                        <Typography className='mb3'
                            style={{
                                font: '400 10px/13px Roboto',
                                color: '#757575',
                            }}>From</Typography>
                        <DatePicker
                            margin='dense'
                            maxDate={endDate}
                            id='week_filter_from'
                            variant='dialog'
                            inputVariant='outlined'
                            format={WEEK_FILTER_FORMAT_DEFAULT}
                            value={startDate}
                            onChange={this.onStartDateChange}
                            style={{
                                marginTop: 0,
                            }}
                            inputProps={{
                                style: {
                                    font: '400 12px/16px Roboto',
                                    color: '#898989',
                                },
                            }}
                        />
                    </div>
                    <div className='fdr aic jcc w11 ml3 mr3 h0'>
                        <div className='flx1 h0' style={{
                            marginTop: 13,
                            border: '1px solid #757575',
                        }}>
                        </div>
                    </div>
                    <div className='fdc flx1 aifs jcc'>
                        <Typography className='mb3'
                            style={{
                                font: '400 10px/13px Roboto',
                                color: '#757575',
                            }}>To</Typography>
                        <DatePicker
                            minDate={startDate}
                            margin='dense'
                            id='week_filter_to'
                            variant='dialog'
                            inputVariant='outlined'
                            format={WEEK_FILTER_FORMAT_DEFAULT}
                            value={endDate}
                            onChange={this.onEndDateChange}
                            style={{
                                marginTop: 0,
                            }}
                            inputProps={{
                                style: {
                                    font: '400 12px/16px Roboto',
                                    color: '#898989',
                                },
                            }}
                        />
                    </div>
                </div>
                <div className='fdr aic jcfs mt15'>
                    <Typography className='mr10'
                        style={{
                            font: '400 12px/16px Roboto',
                            color: '#424242',
                            textAlign: 'left',
                        }}>Week</Typography>
                    <TextField
                        id='week_numbe'
                        className='w80'
                        type='number'
                        value={week}
                        onChange={this.onChange}
                        inputProps={{
                            min: '1',
                            max: this.maxWeek.toString(),
                            style: {
                                textAlign: 'center',
                            },
                        }}
                    />
                </div>
                <div className='fdc flx1 ais'>
                    <WeekGridSelector onChange={this.setWeek} weeks={weeks} value={week} />
                </div>
                <div className='fdr aic jcfe'>
                    <StadiumButton style={{
                        height: 21,
                    }} className='fw500 fs9 cpd mr15 bw1 h21' variant='text' type='button' onClick={onCancel}>
                            CANCEL
                    </StadiumButton>
                    {
                        clearable &&
                        <StadiumButton style={{
                            height: 21,
                        }} className='fw500 fs9 cpd mr15 bw1 h21' variant='text' type='button' onClick={this.onClearClick}>
                                CLEAR
                        </StadiumButton>
                    }
                    <StadiumButton style={{
                        height: 21,
                    }} className='fw500 fs9 cpd bcy h21' type='button' onClick={this.onSaveClick}>
                            APPLY
                    </StadiumButton>
                </div>
            </div>
        );
    };
}
