import AvoBugActions from './actions';
import { dispatch, getState } from '..';
import firebaseApp from '../../services/firebaseService';
import GeneralFunctions from '../general/functions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import { Moment } from 'moment';
import { AvoBugAssignmentHelper, IAvoBugAssignment } from '../../types/model/avoBug/avoBugAssignment';
import { IAssignmentBlock } from '../../types/model/masterData/block';

export default class AvoBugFunctions {
    private static assignmentListener ?: () => void;

    public static getAssignments = (refresh ?: boolean) => {
        if (!refresh && AvoBugFunctions.assignmentListener) return;

        if (AvoBugFunctions.assignmentListener) {
            AvoBugFunctions.assignmentListener();
        }

        dispatch(AvoBugActions.setLoadingAssignments(true));
        dispatch(AvoBugActions.setAssignments([]));
        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());

            const query = AvoBugAssignmentHelper
                .collection();

            AvoBugFunctions.assignmentListener = query
                .orderBy('date', 'desc')
                .limit(1000)
                .onSnapshot((snapshot) => {
                    const avoBugState = getState().avoBug;

                    const assignments = [...avoBugState.assignments];

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const assignment = f.doc.data();

                        if (!divisions.includes(assignment.division)) return;

                        const index = lodash.findIndex(assignments, n => n.id === assignment.id);

                        switch (f.type) {
                            case 'added':
                                assignments.push(assignment);
                                break;
                            case 'modified':
                                assignments.splice(index, 1, assignment);
                                break;
                            case 'removed':
                                assignments.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(AvoBugActions.setAssignments(assignments.sort((a, b) => b.date - a.date)));
                    dispatch(AvoBugActions.setLoadingAssignments(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', err);
                    dispatch(AvoBugActions.setLoadingAssignments(false));
                }, () => {
                    dispatch(AvoBugActions.setAssignments([]));
                    dispatch(AvoBugActions.setLoadingAssignments(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', ex);
        }
    };

    /**
     * Validates an assignment.
     * Throws `Error` if invalid.
     * @param assignment
     */
    private static validateAssignment(assignment : IAvoBugAssignment) {

        if (!assignment.date) {
            throw new Error('Date required.');
        }

        if (!assignment.block) {
            throw new Error('Block required.');
        }

        if (!assignment.division) {
            throw new Error('Division required.');
        }
    }

    /**
     * Creates/Saves assignment.
     * If `saveItem` is not undefined it makes changes to that assignment and
     * saves it, if it is undefined a new assignment is create using the list
     * of avoBug blocks. If multiple blocks is provided and `saveItem` is not undefined
     * the last block will be used.
     * This uses a firestore transaction to create/save multiple assignments.
     * @param date
     * @param employee Employee document path
     * @param employeeNumber
     * @param employeeName
     * @param avoBugBlocks
     * @param saveItem
     */
    public static saveAssignment = async (
        date : Moment,
        employee : string,
        employeeNumber : string,
        employeeName : string,
        scoutingBlocks : Array<IAssignmentBlock>,
        saveItem ?: IAvoBugAssignment,
    ) => {
        dispatch(AvoBugActions.setLoadingAssignments(true));

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const authState = getState().auth;

                if (!authState.session) return;

                for (const block of scoutingBlocks) {
                    const assignment = {
                        id: saveItem?.id ?? '',
                        createdBy: saveItem?.createdBy ?? authState.session.user.ref,
                        createdByName: saveItem?.createdByName ?? authState.session.user.name,
                        createdByEmployee: saveItem?.createdByEmployee ?? authState.session.user.employeeNumber,
                        createdOn: saveItem?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),

                        updatedBy: authState.session.user.ref,
                        updatedByName: authState.session.user.name,
                        updatedByEmployee: authState.session.user.employeeNumber,
                        updatedOn: firebase.firestore.Timestamp.now().toMillis(),

                        date: date.valueOf(),
                        employee,
                        employeeNumber,
                        employeeName,

                        block: block.guid,
                        blockName: block.name,
                        landName: block.landName,
                        division: block.division,
                        crop: block.crop,

                        isSent: false,

                    } as IAvoBugAssignment;

                    AvoBugFunctions.validateAssignment(assignment);
                    AvoBugAssignmentHelper.saveTransaction(transaction, assignment);
                }
            });

            GeneralFunctions.generalShowSuccessSnackbar(`Assignment${scoutingBlocks.length > 1 ? 's' : ''} saved.`);
            return true;
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving assignment.', ex);
        } finally {
            dispatch(AvoBugActions.setLoadingAssignments(false));
        }
        return false;
    };
}
