import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { THRESHOLD_TYPE } from '../../../types/model/masterData/threshold';

const useStyles = makeStyles<Theme, IHeaderButtonProps>({
    root: {
        backgroundColor: (props) => {
            switch (props.type) {
                case 'insects':
                    return '#339FA2';
                case 'disease':
                    return '#CC2B29';
                default:
                    return '#C0DADB';
            }
        },
    },
});

interface IHeaderButtonProps {
    children : React.ReactNode;
    type : THRESHOLD_TYPE | string;
}

const ScoutingReportHeader = (props : IHeaderButtonProps) => {
    const classes = useStyles(props);

    return (
        <div className={`flx1 fw500 cw fs13-18 lsp02 aic jcc tac pl5 pr5 brw1 bocg4 ${classes.root}`}>
            {
                props.children
            }
        </div>
    );
};

export default ScoutingReportHeader;
