import * as React from 'react';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { IRootState, IAuthState } from '../../@types/redux';
import SideMenuItem from '../customComponents/nav/SideMenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NavFunctions from '../../store/nav/functions';
import AuthFunctions from '../../store/auth/functions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ListItemTextStyled from '../customComponents/list/ListItemTextStyled';
import NavDrawerMasterData from './nav/MasterData';
import { List as ListIcon, Map as MapIcon, Timeline } from '@material-ui/icons';

interface INavDrawerProps {
    openLeft : boolean;
    openRight : boolean;
    toggleDrawer : () => void;
    auth : IAuthState;
}

class NavDrawer extends React.Component<INavDrawerProps> {
    constructor(props : INavDrawerProps) {
        super(props);
    }

    private readonly onLogoutClick = () => {
        this.props.toggleDrawer();

        AuthFunctions.firebaseSignOut();
    };

    private readonly onDivisionNavClick = () => {
        NavFunctions.navPath('/rights/division');
        this.props.toggleDrawer();
    };

    private readonly onUserNavClick = () => {
        NavFunctions.navPath('/users');
        this.props.toggleDrawer();
    };

    private readonly onMapNavClick = () => {
        NavFunctions.navPath('/scouting/map');
        this.props.toggleDrawer();
    };

    private readonly onAssignmentNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/scouting/assignments', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onPhenologyNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/phenology/assignments', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onPhenologySpecificNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/phenology/specific_assignment', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onBlockNavClick = () => {
        NavFunctions.navPath('/block/list');
        this.props.toggleDrawer();
    };

    private readonly onScoutingReportingNavClick = () => {
        NavFunctions.navPath('/reporting/scouting');
        this.props.toggleDrawer();
    };

    private readonly onTrapMapNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/traps/map', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onTrapListNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/traps/list', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onNematodeListNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/nematode/assignments', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onTrapAnalyticsNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/traps/analytics', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onAvoBugMapNavClick = () => {
        NavFunctions.navPath('/avoBug/map');
        this.props.toggleDrawer();
    };

    private readonly onAvoBugListNavClick = () => {
        NavFunctions.navPath('/avoBug/list');
        this.props.toggleDrawer();
    };

    private readonly onAvoBugAnalyticsNavClick = () => {
        NavFunctions.navPath('/avoBug/analytics');
        this.props.toggleDrawer();
    };

    public render() {
        const { session } = this.props.auth;
        return (
            <div>
                <Drawer
                    variant='temporary'
                    anchor='left'
                    open={this.props.openLeft}
                    onClose={this.props.toggleDrawer}
                >
                    <AppBar position='static'>
                        <Toolbar variant='dense'>
                            <IconButton color={'inherit'} onClick={this.props.toggleDrawer}>
                                <Icon>menu</Icon>
                            </IconButton>
                            <img height={50} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10 mb5 mt5'} />
                            <Typography color='inherit' variant='h6'>
                                Scouting
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={'fdc flx1 w295 bcg0 oa drawer'}>
                        <SideMenuItem
                            key={'scouting_nav_header'}
                            icon={<img className={'h35 w35'}
                                src={`${ASSET_BASE}/assets/images/logo.png`} />}
                            title={'Scouting'}>
                            <ListItem button key={'map_nav'} onClick={this.onMapNavClick}>
                                <ListItemIcon>
                                    <img src={`${ASSET_BASE}/assets/images/icons/map.svg`} className={'h30 w30'} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Map'} />
                            </ListItem>
                            <ListItem button key={'block_nav'} onClick={this.onBlockNavClick}>
                                <ListItemIcon>
                                    <img src={`${ASSET_BASE}/assets/images/icons/blocks.svg`} className={'h30 w30'} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Blocks'} />
                            </ListItem>
                            {
                                session?.user.permissions.isScoutingAdmin &&
                                <SideMenuItem
                                    key={'scouting_nav_assign_header'}
                                    icon={<img className={'h30 w30'}
                                        src={`${ASSET_BASE}/assets/images/icons/assignments.svg`} />}
                                    title={'Assignments'}>
                                    {
                                        session.user.crops.map(n => (
                                            <ListItem button id={n} key={`assignments_nav_${n}`} onClick={this.onAssignmentNavClick}>
                                                <ListItemIcon>
                                                    <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                                </ListItemIcon>
                                                <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                            </ListItem>
                                        ))
                                    }
                                </SideMenuItem>
                            }
                        </SideMenuItem>
                        <Divider />
                        {
                            session?.user.permissions.isPhenologyAdmin &&
                            <>
                                <SideMenuItem
                                    key={'phenology_nav_header'}
                                    icon={<img className={'h35 w35'}
                                        src={`${ASSET_BASE}/assets/images/logo.png`} />}
                                    title={'Phenology'}>
                                    <SideMenuItem
                                        key={'general_assign_header'}
                                        icon={<img className={'h30 w30'}
                                            src={`${ASSET_BASE}/assets/images/icons/assignments.svg`} />}
                                        title={'General'}>
                                        {
                                            session.user.crops.map(n => (
                                                <ListItem button id={n} key={`assignments_specific_nav_${n}`} onClick={this.onPhenologyNavClick}>
                                                    <ListItemIcon>
                                                        <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                                    </ListItemIcon>
                                                    <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                                </ListItem>
                                            ))
                                        }
                                    </SideMenuItem>
                                    <SideMenuItem
                                        key={'specific_assign_header'}
                                        icon={<img className={'h30 w30'}
                                            src={`${ASSET_BASE}/assets/images/icons/assignments.svg`} />}
                                        title={'Specific'}>
                                        {
                                            session.user.crops.map(n => (
                                                <ListItem button id={n} key={`assignments_specific_nav_${n}`} onClick={this.onPhenologySpecificNavClick}>
                                                    <ListItemIcon>
                                                        <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                                    </ListItemIcon>
                                                    <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                                </ListItem>
                                            ))
                                        }
                                    </SideMenuItem>
                                </SideMenuItem>
                                <Divider />
                            </>
                        }
                        <SideMenuItem
                            key={'trap_nav_header'}
                            icon={<img className={'h35 w35'}
                                src={`${ASSET_BASE}/assets/images/traps.png`} />}
                            title={'Traps'}>
                            {
                                session?.user.crops.map(n => (
                                    <SideMenuItem
                                        key={`traps_nav_${n}`}
                                        icon={<img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />}
                                        title={n.toLocaleUpperCase()}>
                                        <ListItem button id={n} key={`trap_map_nav_${n}`} onClick={this.onTrapMapNavClick}>
                                            <ListItemIcon>
                                                <MapIcon color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'MAP'} />
                                        </ListItem>
                                        <ListItem button id={n} key={`trap_list_nav_${n}`} onClick={this.onTrapListNavClick}>
                                            <ListItemIcon>
                                                <ListIcon color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'LIST'} />
                                        </ListItem>
                                        <ListItem button id={n} key={`trap_analytics_nav_${n}`} onClick={this.onTrapAnalyticsNavClick}>
                                            <ListItemIcon>
                                                <Timeline color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'ANALYTICS'} />
                                        </ListItem>
                                    </SideMenuItem>
                                ))
                            }
                        </SideMenuItem>
                        <Divider />
                        {
                            session?.user.permissions.isNematodeAdmin &&
                                <>
                                    <SideMenuItem
                                        key={'nematode_nav_header'}
                                        icon={<img className={'h35 w35'}
                                            src={`${ASSET_BASE}/assets/images/nematodes.png`} />}
                                        title={'Nematodes'}
                                    >
                                        {
                                            session.user.crops.map(n => (
                                                <ListItem button id={n} key={`nematode_list_nav_${n}`} onClick={this.onNematodeListNavClick}>
                                                    <ListItemIcon>
                                                        <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                                    </ListItemIcon>
                                                    <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                                </ListItem>
                                            ))
                                        }
                                    </SideMenuItem>
                                    <Divider />
                                </>
                        }
                        {
                            session?.user.permissions.isAvoBugAdmin &&
                                <>
                                    <SideMenuItem
                                        key={'avobug_nav_header'}
                                        icon={<img className={'h35 w35'}
                                            src={`${ASSET_BASE}/assets/images/icons/avobug.svg`} />}
                                        title={'Avo Bug'}
                                    >
                                        <ListItem button key={'avobug_map_nav'} onClick={this.onAvoBugMapNavClick}>
                                            <ListItemIcon>
                                                <MapIcon color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'MAP'} />
                                        </ListItem>
                                        <ListItem button key={'avobug_list_nav'} onClick={this.onAvoBugListNavClick}>
                                            <ListItemIcon>
                                                <ListIcon color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'LIST'} />
                                        </ListItem>
                                        <ListItem button key={'avobug_analytics_nav'} onClick={this.onAvoBugAnalyticsNavClick}>
                                            <ListItemIcon>
                                                <Timeline color='primary' className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'ANALYTICS'} />
                                        </ListItem>
                                    </SideMenuItem>
                                    <Divider />
                                </>
                        }
                        
                        {
                            session &&
                            <NavDrawerMasterData
                                session={session}
                                toggleDrawer={this.props.toggleDrawer}
                            />
                        }
                        {
                            session?.user.permissions.isUserAdmin &&
                            <div>
                                <SideMenuItem key={'employee_nav_header'} icon={<img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/rights.svg`} />}
                                    title={'Rights'}>
                                    {

                                        session.user.permissions.isSuperAdmin &&
                                        <ListItem button key={'division_nav'} onClick={this.onDivisionNavClick}>
                                            <ListItemIcon>
                                                <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/users.svg`} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={'Divisions'} />
                                        </ListItem>
                                    }
                                    <ListItem button key={'employee_nav'} onClick={this.onUserNavClick}>
                                        <ListItemIcon>
                                            <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/users.svg`} />
                                        </ListItemIcon>
                                        <ListItemTextStyled primary={'Users'} />
                                    </ListItem>
                                </SideMenuItem>
                                <Divider />
                            </div>
                        }
                        {
                            session?.user.permissions.isScoutingAdmin &&
                            <div>
                                <ListItem button key={'report_nav'} onClick={this.onScoutingReportingNavClick}>
                                    <ListItemIcon>
                                        <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />
                                    </ListItemIcon>
                                    <ListItemTextStyled primary={'Reports'} />
                                </ListItem>
                                <Divider />
                            </div>
                        }
                        <span className='flx1' />
                    </div>
                    <div className={'bcg0'}>
                        <MenuItem onClick={this.onLogoutClick}>Logout<span className={'flx1'} /> <img className={'h20'} src={`${ASSET_BASE}/assets/images/icons/logout.svg`} /> </MenuItem>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(NavDrawer);
