import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import AppFunctionsService from '../../../services/appFunctionServices';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../customComponents/tab/TabPanel';
import { IPhenologyAssignment } from '../../../types/model/phenology/assignment';
import PhenologyAssignmentInfoView from '../view/AssignmentInfoView';
import PhenologyAssignmentCompletedHistoryView from '../view/AssignmentCompletedHistoryView';

interface IPhenologyAssignmentInfoDialogProps {
    value : IPhenologyAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    onClosed ?: () => void;
    defaultOpen ?: boolean;
}

interface IPhenologyAssignmentInfoDialogState {
    open : boolean;

    tabIndex : number;
}

export default class PhenologyAssignmentInfoDialog extends React.PureComponent<IPhenologyAssignmentInfoDialogProps, IPhenologyAssignmentInfoDialogState> {
    constructor(props : IPhenologyAssignmentInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
        };
    }

    public readonly componentDidMount = () => {
        this.setState({
            open: !!this.props.defaultOpen,
        });
    };

    private readonly onIconButtonClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });

        if (this.props.onClosed) this.props.onClosed();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading, defaultOpen } = this.props;
        const { open, tabIndex } = this.state;

        return (
            <React.Fragment>
                {
                    !defaultOpen &&
                    <Tooltip title='Info'>
                        <div>
                            <IconButton color='primary' onClick={this.onIconButtonClick} aria-label='info' disabled={isLoading}>
                                <Icon>info</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='phenology-assignment-info-dialog-title'
                    aria-describedby='phenology-assignment-info-description'>
                    <AppBar elevation={0} className='fdc posr ais' position='static'>
                        <Toolbar variant='dense' className={'fdr'}>
                            <div className={'fdr flx1 aic'}>
                                <Typography variant='h5' color='inherit'>
                                    {
                                        `Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                        ${value.landName} -
                                        ${value.blockName}
                                        `
                                    }
                                </Typography>
                                <span className='flx1' />
                                <Tooltip title='Close'>
                                    <div>
                                        <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Tabs value={tabIndex} variant='fullWidth' onChange={this.onTabChange} aria-label='AssignmentTabs'>
                            <Tab value={0} label='INFO' />
                            <Tab value={1} label='RESULTS' />
                        </Tabs>
                    </AppBar>
                    <DialogContent className='fdc flx1'>
                        <TabPanel value={tabIndex} index={0}>
                            <PhenologyAssignmentInfoView value={value} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <PhenologyAssignmentCompletedHistoryView value={value} />
                        </TabPanel>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}
