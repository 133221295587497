import { styled } from '@material-ui/core';
import React from 'react';

interface IRotatingDivProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    rotate : boolean;
}

const RotatingDiv = styled((props : IRotatingDivProps) => {
    const { rotate: _, ...rest } = props;
    
    return (
        <div {...rest} />
    );
})((props : IRotatingDivProps) => ({
    transition: '0.2s transform ease-out',
    transform: props.rotate ? 'rotate(0deg)' : 'rotate(180deg)',
}));

export default RotatingDiv;