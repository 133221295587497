import React from 'react';
import { Layer, MapMouseEvent, MapboxGeoJSONFeature, Popup, Source } from 'react-map-gl';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { MapboxContext, MapboxContextValue } from '../../MapBox';
import { EventData } from 'mapbox-gl';
import { IPhenologySpecific, PhenologySpecificHelper, PhenologySpecificTreeType } from '../../../../../types/model/phenology/specific';
import PhenologySpecificFunctions from '../../../../../store/phenology/specific/functions';

interface IMapboxCherriesSpecificLayerProps {
    markersLayerId : string;

    markersBeforeId ?: string;

    specifics : Array<IPhenologySpecific>;
    isLoading : boolean;

    minZoom ?: number;
    maxZoom ?: number;

    visible ?: boolean;
    hideUnselected ?: boolean;

    divisionCode ?: string | null;
    blockName ?: string | null;
    landName ?: string | null;
    selectedCode ?: string | null;

    onMarkerClick ?: (tree ?: PhenologySpecificTreeType) => void;
}

interface IMapboxCherriesSpecificLayerState {
    imagesLoaded : boolean;
}

class MapboxCherriesSpecificLayerComponent extends React.PureComponent<IMapboxCherriesSpecificLayerProps, IMapboxCherriesSpecificLayerState> {
    declare context : MapboxContextValue;

    constructor(props : IMapboxCherriesSpecificLayerProps) {
        super(props);
        this.state = {
            imagesLoaded: false,
        };
    }

    public componentDidMount() : void {
        this.load();
        this.setLayer();
    }

    public componentWillUnmount() : void {
        if (!this.props.onMarkerClick) return;
        if (!this.props.markersLayerId) return;

        this.context.map.off('click', this.props.markersLayerId, this.onMarkerClick);
        this.context.map.off('mouseenter', this.props.markersLayerId, this.onMarkerMouseOver);
        this.context.map.off('mouseleave', this.props.markersLayerId, this.onMarkerMouseOver);
    }

    private readonly load = () => {
        PhenologySpecificFunctions.listen('cherries');
    };

    private readonly getData = (props : IMapboxCherriesSpecificLayerProps) => props.specifics;
    private readonly getDivision = (props : IMapboxCherriesSpecificLayerProps) => props.divisionCode;
    private readonly getLandName = (props : IMapboxCherriesSpecificLayerProps) => props.landName;
    private readonly getBlockName = (props : IMapboxCherriesSpecificLayerProps) => props.blockName;
    private readonly getSelectedCode = (props : IMapboxCherriesSpecificLayerProps) => props.selectedCode;
    private readonly getHideUnselected = (props : IMapboxCherriesSpecificLayerProps) => props.hideUnselected;

    private readonly getTrees = createSelector([
        this.getData,
    ], (
        specifics,
    ) => {
        return specifics
            .filter(x => x.crop === 'cherries')
            .map(PhenologySpecificHelper.getTreeType)
            .flatMap(x => x)
            .filter(x => x.location);
    });

    private readonly getFeatureCollection = createSelector(
        [
            this.getTrees,
        ],
        (
            trees,
        ) => {
            const geoJson : GeoJSON.FeatureCollection<GeoJSON.Point, {
                crop : string;
                code : string;
                landName : string;
                blockName : string;
                center : Array<number>;
                division : string;
                image : string;
                size : number;
            }> = {
                type: 'FeatureCollection',
                features: lodash
                    .chain(trees)
                    .map(tree => ({
                        center: [tree.location?.longitude ?? 0, tree.location?.latitude ?? 0],
                        crop: tree.crop,
                        code: tree.code,
                        blockName: tree.blockName,
                        landName: tree.landName,
                        division: tree.division.toLocaleLowerCase(),
                        image: `image_${this.props.markersLayerId}`,
                        size: 0.5,
                    }))
                    .map(point => ({
                        geometry: {
                            type: 'Point',
                            coordinates: point.center,
                        },
                        properties: {
                            ...point,
                        },
                        type: 'Feature',
                    } as GeoJSON.Feature<GeoJSON.Point, {
                        crop : string;
                        code : string;
                        landName : string;
                        blockName : string;
                        center : Array<number>;
                        division : string;
                        image : string;
                        size : number;
                    }>))
                    .value(),
            };

            return geoJson;
        },
    );

    private readonly getSelected = createSelector(
        [
            this.getTrees,
            this.getSelectedCode,
        ],
        (
            trees,
            code,
        ) => {
            return trees.find(x => x.code === code);
        },
    );

    private getFilter = createSelector(
        [
            this.getDivision,
            this.getLandName,
            this.getBlockName,
            this.getHideUnselected,
            this.getSelectedCode,
        ],
        (
            division,
            landName,
            blockName,
            hideUnselected,
            selectedCode,
        ) => {
            const result : Array<unknown> = [];

            if (division) {
                result.push(['==', ['literal', division], ['get', 'division']]);
            }

            if (blockName) {
                result.push(['==', ['literal', blockName], ['get', 'blockName']]);
            }

            if (landName) {
                result.push(['==', ['literal', landName], ['get', 'landName']]);
            }

            if (hideUnselected && selectedCode) {
                result.push(['==', ['literal', selectedCode], ['get', 'code']]);
            }

            return [
                'all',
                ...result,
            ];
        },
    );

    private readonly loadImages = () => {
        const mapRef = this.context.map;

        return new Promise<undefined>((res, rej) => {
            const id = `image_${this.props.markersLayerId}`;

            if (mapRef.hasImage(id)) {
                res(undefined);
                return;
            }

            mapRef.loadImage('/assets/images/phenology/cherries.png', (error, result) => {
                if (error) {
                    rej();
                    return;
                }
                
                if (result) {
                    mapRef.addImage(id, result);
                    res(undefined);
                    return;
                }

                rej();
            });
        });
    };

    private readonly setLayer = async () => {
        await this.loadImages();

        this.setState({
            imagesLoaded: true,
        });

        if (!this.props.onMarkerClick) return;
        if (!this.props.markersLayerId) return;

        this.context.map.on('click', this.props.markersLayerId, this.onMarkerClick);
        this.context.map.on('mouseenter', this.props.markersLayerId, this.onMarkerMouseOver);
        this.context.map.on('mouseleave', this.props.markersLayerId, this.onMarkerMouseOver);
    };

    private readonly onMarkerClick = (ev : MapMouseEvent & { 
        features ?: Array<MapboxGeoJSONFeature> | undefined;
    } & EventData) => {
        if (!ev.features) return;

        const properties = ev.features[0].properties;

        if (!properties) return;

        if (this.props.onMarkerClick) {
            const code = properties.code;

            this.props.onMarkerClick(this.getTrees(this.props).find(x => x.code === code));
        } else {
            this.flyTo(JSON.parse(properties.center));
        }
    };

    private readonly onPopupClose = () => {
        if (!this.props.onMarkerClick) return;
        this.props.onMarkerClick();
    };

    private readonly flyTo = (center : mapboxgl.LngLatLike, zoom = 17) => {
        this.context.map.flyTo({
            zoom,
            center,
            essential: true, 
        });
    };

    private readonly onMarkerMouseOver = (ev : MapMouseEvent) => {
        if (ev.type === 'mouseenter') {
            ev.target.getCanvas().style.cursor = 'pointer';
        }
        if (ev.type === 'mouseleave') {
            ev.target.getCanvas().style.cursor = '';
        }
    };

    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 21,
            markersLayerId,
            markersBeforeId,
            visible = true,
        } = this.props;

        const {
            imagesLoaded,
        } = this.state;

        const trees = this.getFeatureCollection(this.props);
        const filter = this.getFilter(this.props);
        const selected = this.getSelected(this.props);

        return (
            <Source
                id={`${markersLayerId}_source`}
                type='geojson'
                data={trees}
                maxzoom={maxZoom}
            >
                {
                    imagesLoaded &&
                    <Layer
                        id={markersLayerId}
                        beforeId={markersBeforeId}
                        maxzoom={maxZoom}
                        minzoom={minZoom}
                        type='symbol'
                        {
                            ...{
                                paint: {
                                },
                                layout: {
                                    'icon-image': ['get', 'image'],
                                    'icon-size': ['get', 'size'],
                                    'icon-anchor': 'center',
                                    'icon-allow-overlap': true,
                                    visibility: visible ? 'visible' : 'none',
                                },
                            }
                        }
                        filter={filter}
                    />
                }
                {
                    selected?.location &&
                    <Popup
                        latitude={selected.location.latitude}
                        longitude={selected.location.longitude}
                        anchor='bottom'
                        offset={[0, -21] as [number, number]}

                        onClose={this.onPopupClose}
                    >
                        {
                            selected.code
                        }
                    </Popup>
                }
            </Source>
        );
    };
}

MapboxCherriesSpecificLayerComponent.contextType = MapboxContext;

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.phenology.specific.isLoading,
        specifics: state.phenology.specific.specifics,
    };
};

const MapboxCherriesSpecificLayer = connect(
    mapStateToProps,
)(MapboxCherriesSpecificLayerComponent);

export default MapboxCherriesSpecificLayer;