import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { IRootState } from '../../../../../@types/redux';
import { IGeoServerBlock } from '../../../../../types/model/masterData/geoserver/block';
import MasterDataGeoServerFunctions from '../../../../../store/masterData/geoserver/functions';
import { LngLatBoundsLike } from 'react-map-gl';
import GeoHelper from '../../../../../services/helper/geoHelper';
import { IGeoServerPoint, pointType } from '../../../../../types/model/masterData/geoserver/point';
import { CROP } from '../../../../../appConstants';

interface IGeoServerBlockMultiAutocompleteProps {
    id ?: string;
    value : Array<string>;

    onChange : (value : Array<string>, bounds ?: LngLatBoundsLike | null, event ?: React.ChangeEvent<unknown>) => void;

    blocks : Array<IGeoServerBlock>;
    points : Array<IGeoServerPoint>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;

    label ?: string;

    divisionGuid ?: string | null;
    subDivisionGuid ?: string | null;
    departmentGuid ?: string | null;
    fieldGuid ?: string | null;
    crop ?: CROP | null;

    type : pointType | null;
}

interface IGeoServerBlockMultiAutocompleteState {
}

class GeoServerBlockMultiAutocompleteComponent extends React.Component<IGeoServerBlockMultiAutocompleteProps, IGeoServerBlockMultiAutocompleteState> {
    constructor(props : IGeoServerBlockMultiAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        MasterDataGeoServerFunctions.getBlockList();
        MasterDataGeoServerFunctions.getPointList();
    };

    private readonly getData = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.blocks;
    private readonly getValue = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.value;
    private readonly getRequired = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.required;
    private readonly getDivisionGuid = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.divisionGuid;
    private readonly getSubDivisionGuid = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.subDivisionGuid;
    private readonly getDepartmentGuidGuid = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.departmentGuid;
    private readonly getFieldGuidGuid = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.fieldGuid;
    private readonly getPoints = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.points;
    private readonly getCrop = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.crop;
    private readonly getType = (state : IGeoServerBlockMultiAutocompleteState, props : IGeoServerBlockMultiAutocompleteProps) => props.type;
    
    private readonly getBlockMultiAutocomplete = createSelector([
        this.getData,
        this.getRequired,
        this.getDivisionGuid,
        this.getSubDivisionGuid,
        this.getDepartmentGuidGuid,
        this.getFieldGuidGuid,
        this.getValue,
        this.getPoints,
        this.getCrop,
        this.getType,
    ], (
        blocks,
        required,
        divisionGuid,
        subDivisionGuid,
        departmentGuid,
        fieldGuid,
        value,
        points,
        crop,
        type,
    ) => {
        const blockGuids = lodash.chain(points)
            .filter(n => !type || n.type === type)
            .filter(n => !crop || n.crop === crop)
            .map(n => n.blockGuid)
            .uniq()
            .value();

        const blocksDrop = lodash.chain(blocks)
            .filter(n => blockGuids.includes(n.guid))
            .filter(x => !divisionGuid || x.divisionGuid === divisionGuid)
            .filter(x => !subDivisionGuid || x.subDivisionGuid === subDivisionGuid)
            .filter(x => !departmentGuid || x.departmentGuid === departmentGuid)
            .filter(x => !fieldGuid || x.fieldGuid === fieldGuid)
            .uniqBy(n => n.guid)
            .map(n => ({
                label: n.code,
                value: n.guid,
            }))
            .sortBy(n => n.label)
            .value();


        if (!value.length && !required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
        this.getBlockMultiAutocomplete,
    ], (
        value,
        required,
        blocks,
    ) => {
        const selectedValues = blocks.filter(x => value.includes(x.value));
        if (selectedValues.length) {

            return selectedValues;
        }

        if (!value.length && !required) {
            return [{
                label: 'ALL',
                value: '',
            }];
        }

        return [];
    });



    private readonly onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }>) => {
        const result = value.map(x => x.value);

        const bounds = !value.length ? null : GeoHelper.getPolygonBounds(
            this.props.blocks
                .filter(x => !this.props.divisionGuid || x.divisionGuid === this.props.divisionGuid)
                .filter(x => !this.props.subDivisionGuid || x.subDivisionGuid === this.props.subDivisionGuid)
                .filter(x => !this.props.departmentGuid || x.departmentGuid === this.props.departmentGuid)
                .filter(x => !this.props.fieldGuid || x.fieldGuid === this.props.fieldGuid)
                .filter(x => result.includes(x.guid))
        );

        this.props.onChange(result, bounds, event);
    };

    public readonly render = () => {
        const {
            required,
            fullWidth,
            isLoading,
            disabled,
            label,
        } = this.props;

        const blocks = this.getBlockMultiAutocomplete(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='block_select'
                        options={blocks}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        multiple
                        disableCloseOnSelect
                        renderInput={params => (
                            <TextField
                                error={required && !value}
                                required={required}
                                {...params}
                                fullWidth={fullWidth}
                                label={label ?? 'Block'}
                            />)}
                    />
                    {
                        required && !value.length &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.masterData.geoserver.blocks,
        points: state.masterData.geoserver.points,
        isLoading: state.masterData.geoserver.isLoadingBlocks || state.masterData.geoserver.isLoadingPoints,
    };
};

const GeoServerBlockMultiAutocomplete = connect(
    mapStateToProps,
)(GeoServerBlockMultiAutocompleteComponent);

export default GeoServerBlockMultiAutocomplete;
