import React from 'react';
import { Layer, MapMouseEvent, MapboxGeoJSONFeature, Source } from 'react-map-gl';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { CROP } from '../../../../../appConstants';
import TrapFunctions from '../../../../../store/trap/functions';
import { ITrap } from '../../../../../types/model/trap/trap';
import { MapboxContext, MapboxContextValue } from '../../MapBox';
import { EventData } from 'mapbox-gl';

interface IMapboxTrapLayerProps {
    markersLayerId : string;

    markersBeforeId ?: string;

    traps : Array<ITrap>;
    isLoading : boolean;

    minZoom ?: number;
    maxZoom ?: number;

    visible ?: boolean;

    crop ?: CROP | null;

    divisionCode ?: string | null;
    blockName ?: string | null;
    landName ?: string | null;
    trapCode ?: string | null;

    onMarkerClick ?: (trap ?: ITrap) => void;
}

interface IMapboxTrapLayerState {
    imagesLoaded : boolean;
}

class MapboxTrapLayerComponent extends React.PureComponent<IMapboxTrapLayerProps, IMapboxTrapLayerState> {
    declare context : MapboxContextValue;

    constructor(props : IMapboxTrapLayerProps) {
        super(props);
        this.state = {
            imagesLoaded: false,
        };
    }

    public componentDidMount() : void {
        this.load();
        this.setLayer();
    }

    public componentWillUnmount() : void {
        if (!this.props.onMarkerClick) return;
        if (!this.props.markersLayerId) return;

        this.context.map.off('click', this.props.markersLayerId, this.onMarkerClick);
        this.context.map.off('mouseenter', this.props.markersLayerId, this.onMarkerMouseOver);
        this.context.map.off('mouseleave', this.props.markersLayerId, this.onMarkerMouseOver);
    }

    private readonly load = () => {
        TrapFunctions.getList();
    };

    private readonly getData = (props : IMapboxTrapLayerProps) => props.traps;
    private readonly getCrop = (props : IMapboxTrapLayerProps) => props.crop;
    private readonly getDivision = (props : IMapboxTrapLayerProps) => props.divisionCode;
    private readonly getLandName = (props : IMapboxTrapLayerProps) => props.landName;
    private readonly getBlockName = (props : IMapboxTrapLayerProps) => props.blockName;
    private readonly getTrapCode = (props : IMapboxTrapLayerProps) => props.trapCode;

    private readonly getTraps = createSelector([
        this.getData,
        this.getTrapCode,
    ], (
        traps,
        code,
    ) => {
        return traps
            .filter(x => !!x.location)
            .filter(x => !code || x.code === code);
    });

    private getFeatureCollection = createSelector(
        [
            this.getTraps,
        ],
        (
            traps,
        ) => {
            const geoJson : GeoJSON.FeatureCollection<GeoJSON.Point, {
                crop : string;
                code : string;
                landName : string;
                blockName : string;
                center : Array<number>;
                division : string;
                image : string;
                size : number;
            }> = {
                type: 'FeatureCollection',
                features: lodash
                    .chain(traps)
                    .filter(trap => !!trap.location)
                    .map(trap => ({
                        center: [trap.location?.longitude ?? 0, trap.location?.latitude ?? 0],
                        crop: trap.crop,
                        code: trap.code,
                        blockName: trap.blockName,
                        landName: trap.landName,
                        division: trap.divisionCode.toLocaleLowerCase(),
                        image: `image_${this.props.markersLayerId}`,
                        size: 0.1,
                    }))
                    .map(point => ({
                        geometry: {
                            type: 'Point',
                            coordinates: point.center,
                        },
                        properties: {
                            ...point,
                        },
                        type: 'Feature',
                    } as GeoJSON.Feature<GeoJSON.Point, {
                        crop : string;
                        code : string;
                        landName : string;
                        blockName : string;
                        center : Array<number>;
                        division : string;
                        image : string;
                        size : number;
                    }>))
                    .value(),
            };

            return geoJson;
        },
    );

    private getFilter = createSelector(
        [
            this.getDivision,
            this.getLandName,
            this.getBlockName,
            this.getCrop,
        ],
        (
            division,
            landName,
            blockName,
            crop,
        ) => {
            const result : Array<unknown> = [];

            if (division) {
                result.push(['==', ['literal', division], ['get', 'division']]);
            }

            if (blockName) {
                result.push(['==', ['literal', blockName], ['get', 'blockName']]);
            }

            if (landName) {
                result.push(['==', ['literal', landName], ['get', 'landName']]);
            }

            if (crop) {
                result.push(['==', ['literal', crop], ['get', 'crop']]);
            }

            return [
                'all',
                ...result,
            ];
        },
    );

    private readonly loadImages = () => {
        const mapRef = this.context.map;

        return new Promise<undefined>((res, rej) => {
            const id = `image_${this.props.markersLayerId}`;

            if (mapRef.hasImage(id)) {
                res(undefined);
                return;
            }

            mapRef.loadImage('/assets/images/traps.png', (error, result) => {
                if (error) {
                    rej();
                    return;
                }
                
                if (result) {
                    mapRef.addImage(id, result);
                    res(undefined);
                    return;
                }

                rej();
            });
        });
    };

    private readonly setLayer = async () => {
        await this.loadImages();

        this.setState({
            imagesLoaded: true,
        });

        if (!this.props.onMarkerClick) return;
        if (!this.props.markersLayerId) return;

        this.context.map.on('click', this.props.markersLayerId, this.onMarkerClick);
        this.context.map.on('mouseenter', this.props.markersLayerId, this.onMarkerMouseOver);
        this.context.map.on('mouseleave', this.props.markersLayerId, this.onMarkerMouseOver);
    };

    private readonly onMarkerClick = (ev : MapMouseEvent & { 
        features ?: Array<MapboxGeoJSONFeature> | undefined;
    } & EventData) => {
        if (!ev.features) return;

        const properties = ev.features[0].properties;

        if (!properties) return;

        if (this.props.onMarkerClick) {
            const code = properties.code;

            this.props.onMarkerClick(this.getTraps(this.props).find(x => x.code === code));
        } else {
            this.flyTo(JSON.parse(properties.center));
        }
    };

    private readonly flyTo = (center : mapboxgl.LngLatLike, zoom = 17) => {
        this.context.map.flyTo({
            zoom,
            center,
            essential: true, 
        });
    };

    private readonly onMarkerMouseOver = (ev : MapMouseEvent) => {
        if (ev.type === 'mouseenter') {
            ev.target.getCanvas().style.cursor = 'pointer';
        }
        if (ev.type === 'mouseleave') {
            ev.target.getCanvas().style.cursor = '';
        }
    };

    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 21,
            markersLayerId,
            markersBeforeId,
            visible = true,
        } = this.props;

        const {
            imagesLoaded,
        } = this.state;

        const blocks = this.getFeatureCollection(this.props);
        const filter = this.getFilter(this.props);

        return (
            <Source
                id={`${markersLayerId}_source`}
                type='geojson'
                data={blocks}
                maxzoom={maxZoom}
            >
                {
                    imagesLoaded &&
                    <Layer
                        id={markersLayerId}
                        beforeId={markersBeforeId}
                        maxzoom={maxZoom}
                        minzoom={minZoom}
                        type='symbol'
                        {
                            ...{
                                paint: {
                                },
                                layout: {
                                    'icon-image': ['get', 'image'],
                                    'icon-size': ['get', 'size'],
                                    'icon-anchor': 'center',
                                    'icon-allow-overlap': true,
                                    visibility: visible ? 'visible' : 'none',
                                },
                            }
                        }
                        filter={filter}
                    />
                }
            </Source>
        );
    };
}

MapboxTrapLayerComponent.contextType = MapboxContext;

const mapStateToProps = (state : IRootState) => {
    return {
        traps: state.trap.traps,
        isLoading: state.trap.isLoading,
    };
};

const MapboxTrapLayer = connect(
    mapStateToProps,
)(MapboxTrapLayerComponent);

export default MapboxTrapLayer;