import React from 'react';
import { INematodeSurveyTypeLevel, INematodeSurveyTypeLevelImage } from '../../../../../types/model/masterData/nematode/survey';
import { Button, FormControl, FormHelperText, TextField, Toolbar, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import NematodeSurveyTypeLevelImageInput from './SurveyLevelImageInput';

interface INematodeSurveyTypeLevelInputProps {
    index : number;
    value : INematodeSurveyTypeLevel;

    onChange ?: (index : number, value : INematodeSurveyTypeLevel) => void;

    disabled ?: boolean;
}

interface INematodeSurveyTypeLevelInputState {}

export default class NematodeSurveyTypeLevelInput extends React.PureComponent<INematodeSurveyTypeLevelInputProps, INematodeSurveyTypeLevelInputState> {
    constructor(props : INematodeSurveyTypeLevelInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange(
            this.props.index,
            {
                ...this.props.value,
                name: e.currentTarget.value,
            }
        );
    };

    private readonly onAddClick = () => {
        if (!this.props.onChange) return;

        this.props.onChange(
            this.props.index,
            {
                ...this.props.value,
                images: [
                    ...this.props.value.images,
                    {
                        description: '',
                        file: {
                            name: '',
                            url: '',
                            path: '',
                            thumbnailUrl: null,
                        },
                    },
                ],
            }
        );
    };

    private readonly onImageChange = (index : number, value : INematodeSurveyTypeLevelImage) => {
        if (!this.props.onChange) return;

        const images = this.props.value.images.slice();
        images.splice(index, 1, value);

        this.props.onChange(
            this.props.index,
            {
                ...this.props.value,
                images: [
                    ...images,
                ],
            }
        );
    };

    public readonly render = () => {
        const {
            disabled,
        } = this.props;
        const {
            name,
            images,
        } = this.props.value;
        
        return (
            <div className='fdc jcfs ais bcg1'>
                <div className={'fdr mt10 jcfs aifs'}>
                    <div className={'fdc flx1 jcfs mr10'}>
                        <FormControl>
                            <TextField
                                required
                                error={!name}
                                id='name'
                                label='Name'
                                type='text'
                                fullWidth
                                value={name}
                                onChange={this.onNameChange}
                                disabled={disabled}
                            />
                            {
                                !name &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <Toolbar className='ml15'>
                    <Typography variant='h5' color='inherit'>
                        IMAGES
                    </Typography>
                    <span className={'flx1'} />
                    <Button aria-label='add' onClick={this.onAddClick}>
                        <Add />
                    </Button>
                </Toolbar>
                <div className='fdc jcfs ais bcg0 ml15'>
                    {
                        images.map((image, index) => (
                            <NematodeSurveyTypeLevelImageInput
                                key={index}
                                value={image}
                                index={index}
                                onChange={this.onImageChange}
                                disabled={disabled}
                            />
                        ))
                    }
                </div>
            </div>
        );
    };
}
