import React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import PhenologyFunctions from '../../../store/phenology/general/functions';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../customComponents/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import AppFunctionsService from '../../../services/appFunctionServices';
import { Transitions } from '../../customComponents/animations/Transitions';
import { withRouter, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import DeleteDialog from '../../customComponents/dialog/DeleteDialog';
import { Dispatch, bindActionCreators } from 'redux';
import { IPhenologyAssignment, PhenologyAssignmentHelper } from '../../../types/model/phenology/assignment';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import StandardFab from '../../customComponents/button/StandardFab';
import NavFunctions from '../../../store/nav/functions';
import { IconButton } from '@material-ui/core';
import { IUser } from '../../../types/model/user';
import GeneralFunctions from '../../../store/general/functions';
import ScoutingAssignmentDateFilter from '../../customComponents/materialTable/ScoutingAssignmentDateFilter';
import ScoutingAssignmentEmployeeFilter from '../../customComponents/materialTable/ScoutingAssignmentEmployeeFilter';
import PhenologyAssignmentInfoDialog from '../dialog/AssignmentInfoDialog';
import PhenologyActions from '../../../store/phenology/general/actions';
import { CROP } from '../../../appConstants';

interface IPhenologyAssignmentListProps extends RouteComponentProps {
    isLoading : boolean;
    assignments : Array<IPhenologyAssignment>;

    setLoading : DispatchCall<boolean>;
}

interface IPhenologyAssignmentListState {
    date ?: moment.Moment;
    startedDate ?: moment.Moment;
    finishedDate ?: moment.Moment;
    employee ?: IUser;

    selectedAssignments : Array<IPhenologyAssignment>;
    onlyUnfinished : boolean;
}

class PhenologyAssignmentListComponent extends React.Component<IPhenologyAssignmentListProps, IPhenologyAssignmentListState> {
    constructor(props : IPhenologyAssignmentListProps) {
        super(props);
        this.state = {
            selectedAssignments: [],
            onlyUnfinished: false,
        };
    }

    public readonly componentDidMount = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        this.loadAssignments(!this.props.assignments.length || urlParams.get('crop') !== this.props.assignments[0].crop);
    };

    public readonly componentDidUpdate = (prevProps : IPhenologyAssignmentListProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.loadAssignments(true);
        }
    };

    public readonly loadAssignments = (refresh ?: boolean) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop');

            if (crop) {
                PhenologyFunctions.getAssignments(crop as CROP, refresh);
            }
        }
    };

    private readonly getAssignments = (props : IPhenologyAssignmentListProps) => props.assignments;
    private readonly getDate = (props : IPhenologyAssignmentListProps, state : IPhenologyAssignmentListState) => state.date;
    private readonly getStartedDate = (props : IPhenologyAssignmentListProps, state : IPhenologyAssignmentListState) => state.startedDate;
    private readonly getFinishedDate = (props : IPhenologyAssignmentListProps, state : IPhenologyAssignmentListState) => state.finishedDate;
    private readonly getEmployee = (props : IPhenologyAssignmentListProps, state : IPhenologyAssignmentListState) => state.employee;
    private readonly getOnlyUnfinished = (props : IPhenologyAssignmentListProps, state : IPhenologyAssignmentListState) => state.onlyUnfinished;
    private readonly getFilteredAssignments = createSelector([
        this.getAssignments,
        this.getDate,
        this.getStartedDate,
        this.getFinishedDate,
        this.getEmployee,
        this.getOnlyUnfinished,
    ], (assignments, date, startedDate, finishedDate, employee, onlyUnfinished) => {
        return assignments
            .filter(n => !date || n.date === date.valueOf())
            .filter(n => !startedDate || Object.values(n.startedOn).some(x => moment.utc(x).startOf('day').isSame(startedDate)))
            .filter(n => !finishedDate || Object.values(n.finishedOn).some(x => moment.utc(x).startOf('day').isSame(finishedDate)))
            .filter(n => !employee || employee.employeeNumber === n.employeeNumber || employee.name === n.employeeName)
            .filter(n => !onlyUnfinished || Object.keys(n.finishedPoints).length !== PhenologyAssignmentHelper.DIRECTIONS.length);
    });

    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            await PhenologyAssignmentHelper.bulkDelete([event.currentTarget.value]);
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignment', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    private readonly onAddClick = () => {
        NavFunctions.navPath('/phenology/assignments/create', this.props.location.search);
    };

    private readonly onDateChange = (date ?: moment.Moment) => {
        this.setState({
            date: date?.startOf('day'),
        });
    };

    private readonly onStartDateChange = (date ?: moment.Moment) => {
        this.setState({
            startedDate: date?.startOf('day'),
        });
    };

    private readonly onFinishedDateChange = (date ?: moment.Moment) => {
        this.setState({
            finishedDate: date?.startOf('day'),
        });
    };

    private readonly onEmployeeChange = (employee ?: IUser) => {
        this.setState({
            employee,
        });
    };

    private readonly onSelectionChange = (selectedAssignments : Array<IPhenologyAssignment>) => {
        this.setState({
            selectedAssignments: selectedAssignments.filter(x => Object.keys(x.finished).length !== PhenologyAssignmentHelper.DIRECTIONS.length),
        });
    };

    private readonly onShowOnlyCompletedClick = () => {
        this.setState({
            onlyUnfinished: !this.state.onlyUnfinished,
        });
    };

    private readonly onDeleteSelected = async () => {
        try {
            this.props.setLoading(true);
            await PhenologyAssignmentHelper.bulkDelete(this.state.selectedAssignments.map(x => x.id));
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
            this.onSelectionChange([]);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignments', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    public readonly render = () => {
        const { isLoading, assignments } = this.props;
        const { date, startedDate, finishedDate, employee, selectedAssignments,
            onlyUnfinished } = this.state;

        const filteredAssignments = this.getFilteredAssignments(this.props, this.state);
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc flx1 mh0 mw0'}>
                    <Toolbar>
                        <span className={'flx1'} />
                        <div className='mr15'>
                            <Tooltip title='Show Only Unfinshed'>
                                <IconButton onClick={this.onShowOnlyCompletedClick} className={onlyUnfinished ? 'bcp cw' : ''}>
                                    <Icon>
                                        block
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={'fdr jcfe aic'}>
                            <Typography className='fs16 fw700 cpd'>
                                Total: {assignments.length}
                            </Typography>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IPhenologyAssignment>
                            id='assignmentTable'
                            data={filteredAssignments}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedAssignments}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 145,
                                renderCell: row => (
                                    <div className='aic'>
                                        <DeleteDialog maxWidth='md' disabled={Object.keys(row.finished).length === PhenologyAssignmentHelper.DIRECTIONS.length} isLoading={isLoading}
                                            message={`Delete assignment for ${row.landName} - ${row.blockName} on ${AppFunctionsService.formatDateTimeToDateOnly(row.date)}?`}
                                            onConfirm={this.onDelete} title={'Delete'} value={row.id} transition={Transitions.Down}
                                        />
                                        <PhenologyAssignmentInfoDialog value={row} fullWidth transition={Transitions.Up} isLoading={isLoading} maxWidth='md' fullScreen />
                                    </div>
                                ),
                            }, {
                                header: 'Date',
                                field: 'date',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                                filtered: !!date,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={date} onChange={this.onDateChange} id='date_filter' />),
                            }, {
                                header: 'Started On',
                                field: 'startedOn',
                                enableSort: true,
                                enableFilter: true,
                                width: 140,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={startedDate} onChange={this.onStartDateChange} id='start_date_filter' />),
                                filtered: !!startedDate,
                                renderCell: row => (
                                    <span>
                                        {
                                            !!Object.keys(row.startedOn).length &&
                                            <Tooltip title={AppFunctionsService.formatDateTimeFromRecord(row.startedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnlyRecord(row.startedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            }, {
                                header: 'Finished On',
                                field: 'finishedOn',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filtered: !!finishedDate,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={finishedDate}
                                    onChange={this.onFinishedDateChange} id='finished_date_filter' />),
                                renderCell: row => (
                                    <span>
                                        {
                                            Object.keys(row.finishedOn).length === PhenologyAssignmentHelper.DIRECTIONS.length &&
                                            <Tooltip title={AppFunctionsService.formatDateTimeFromRecord(row.finishedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnlyRecord(row.finishedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            }, {
                                header: 'Scout',
                                field: 'employeeName',
                                enableSort: true,
                                enableFilter: true,
                                width: 150,
                                renderCell: row => (
                                    <Tooltip title={row.employeeNumber}>
                                        <div>
                                            {row.employeeName}
                                        </div>
                                    </Tooltip>
                                ),
                                filtered: !!employee,
                                filterRender: () => (<ScoutingAssignmentEmployeeFilter value={employee}
                                    onChange={this.onEmployeeChange} id='employee_filter' />),
                            }, {
                                header: 'Land Name',
                                field: 'landName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Block Name',
                                field: 'blockName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'KM Walked',
                                field: 'distance',
                                width: 100,
                            }, {
                                header: 'Ha',
                                field: 'scoutingBlock.ha',
                                width: 100,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (row.scoutingBlock.ha?.toFixed(2)),
                            }, {
                                header: 'No Points',
                                field: 'finished',
                                width: 100,
                                align: 'center',
                                renderCell: row => (row.scoutingBlock.phenologyPoints.features.length),
                            }, {
                                header: 'Total Time',
                                field: 'totalDuration',
                                width: 100,
                                align: 'center',
                                renderCell: row => (
                                    !!row.totalDuration &&
                                    Object.keys(row.finished).length === PhenologyAssignmentHelper.DIRECTIONS.length &&
                                    AppFunctionsService.millisecondsFormat(row.totalDuration)
                                ),
                            }, {
                                header: 'Finished',
                                field: 'finished',
                                width: 75,
                                enableSort: true,
                                align: 'center',
                                renderCell: row => (
                                    <Tooltip title={`${Object.values(row.finishedPoints).length} / ${row.scoutingBlock.phenologyPoints.features.length}`}>
                                        <Icon className={`${!row.finished ? 'cg1' : 'cp'}`}>
                                            check_circle
                                        </Icon>
                                    </Tooltip>
                                ),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            }, {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            }]}
                        />
                    </Card>
                    {
                        !!selectedAssignments.length &&
                        <DeleteDialog maxWidth='md' disabled={!selectedAssignments.length} isLoading={isLoading}
                            message={`Delete ${selectedAssignments.length} assignments?`}
                            onConfirm={this.onDeleteSelected} title={'Delete'} transition={Transitions.Down}
                        >
                            {
                                onClick => (
                                    <StandardFab aria-label='add' className='cw bcr mr80' disabled={isLoading} onClick={onClick}>
                                        <DeleteIcon />
                                    </StandardFab>
                                )
                            }
                        </DeleteDialog>
                    }
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <AddIcon />
                    </StandardFab>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.phenology.general.isLoadingAssignments,
        assignments: state.phenology.general.assignments,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(PhenologyActions.setLoadingAssignments(isLoading)),
}, dispatcher);

const PhenologyAssignmentList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PhenologyAssignmentListComponent));

export default PhenologyAssignmentList;
