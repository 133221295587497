import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';
import { styled } from '@material-ui/core/styles';
import ListItemTextStyled from '../list/ListItemTextStyled';

interface ISideMenuItemProps {
    icon : React.ReactElement<HTMLElement>;
    title : string;
}

interface ISideMenuItemState {
    open : boolean;
}

// tslint:disable-next-line: variable-name
const ExpandMoreStyled = styled(ExpandMore)({
    color: '#0F4323',
});

export default class SideMenuItem extends React.Component<ISideMenuItemProps, ISideMenuItemState> {

    constructor(props : ISideMenuItemProps) {
        super(props);

        this.state = {
            open: false,
        };

    }

    private handleClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    public render() {
        const { open } = this.state;
        return (
            <List>
                <ListItem button onClick={this.handleClick} key={this.props.title}>
                    <ListItemIcon>
                        {this.props.icon}
                    </ListItemIcon>
                    <ListItemTextStyled primary={this.props.title} title={this.props.title} />
                    <motion.div animate={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} className='mh0'>
                        <ExpandMoreStyled />
                    </motion.div>
                </ListItem>
                {!!open && <Divider />}
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <div className='pl5'>
                        <List disablePadding>
                            {this.props.children}
                        </List>
                    </div>
                </Collapse>
            </List>
        );
    }
}
