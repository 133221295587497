import { Accordion, AccordionDetails, AccordionSummary, ButtonBase, Tooltip, Typography } from '@material-ui/core';
import { ExpandMore, Warning } from '@material-ui/icons';
import React from 'react';
import AppFunctionsService from '../../../services/appFunctionServices';
import { ITrapEntry } from '../../../types/model/trap/trapEntry';
import StadiumDiv from '../../customComponents/div/Stadium';
import { ITrap } from '../../../types/model/trap/trap';
import getPreciseDistance from 'geolib/es/getPreciseDistance';
import { createSelector } from 'reselect';
import { IGeoPoint } from '../../../types/model/geoPoint';
import TrapFunctions from '../../../store/trap/functions';
import UseLocationDialog from '../dialog/UseLocationDialog';
import { IUserSession } from '../../../types/model/user';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import BasicMarker from '../../customComponents/mapBox/marker/BasicMarker';

interface ITrapSurveyEntryViewProps {
    trap : ITrap;
    value : ITrapEntry;
    
    session ?: IUserSession | null;

    onImageClick ?: (imageId : string) => void;
}

interface ITrapSurveyEntryViewState {
    expanded : boolean;
}

export default class TrapSurveyEntryView extends React.PureComponent<ITrapSurveyEntryViewProps, ITrapSurveyEntryViewState> {
    private readonly mapZoom = 16;
    private readonly accuracyLimitMeters = 15;

    constructor(props : ITrapSurveyEntryViewProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    private readonly saveLocation = async (location : IGeoPoint, elevation : number) => {
        const { trap } = this.props;
        
        try {
            await TrapFunctions.save({
                ...trap,
                location,
                elevation,
            });
        } catch (ex) {
            // Ignore
        }

    };

    private readonly onLocationEditClose = (location : IGeoPoint | null, elevation : number | null) => {
        if (!location) return;
        if (!elevation) return;

        this.saveLocation(location, elevation);
    };

    private readonly onExpansionChange = (event : React.ChangeEvent<unknown>, expanded : boolean) => {
        this.setState({
            expanded,
        });
    };

    private readonly getTrap = (state : ITrapSurveyEntryViewState, props : ITrapSurveyEntryViewProps) => props.trap;
    private readonly getValue = (state : ITrapSurveyEntryViewState, props : ITrapSurveyEntryViewProps) => props.value;

    private readonly getDistance = createSelector(
        [
            this.getTrap,
            this.getValue,
        ],
        (
            trap,
            value,
        ) => getPreciseDistance({
            latitude: trap.location?.latitude ?? 0,
            longitude: trap.location?.longitude ?? 0,
        }, {
            latitude: value.location.latitude,
            longitude: value.location.longitude,
        },
        ),
    );

    private readonly getComments = createSelector(
        [
            this.getValue,
        ],
        (
            value,
        ) => {
            const result = value.comments
                .filter(x => x !== 'Other' && x !== 'None');

            if (value.otherComment) {
                result.push(value.otherComment);
            }

            return result;
        },
    );

    private readonly getImage = createSelector(
        [
            this.getValue,
        ],
        (
            value,
        ) => {
            return value.files?.find(x => x.path === value.imagePath);
        },
    );

    private readonly onImageClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        if (!this.props.onImageClick) return;

        this.props.onImageClick(event.currentTarget.id);
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        mapRef.flyTo({
            center: [this.props.value.location.longitude, this.props.value.location.latitude],
            zoom: 15,
            animate: false,
        });
    };

    public readonly render = () => {
        const { value, trap, session } = this.props;
        const { expanded } = this.state;

        const distance = this.getDistance(this.state, this.props);
        const comments = this.getComments(this.state, this.props);
        const image = this.getImage(this.state, this.props);

        return (
            <Accordion expanded={expanded} onChange={this.onExpansionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    className={`${expanded ? 'bcp' : ''}`}
                    IconButtonProps={{
                        className: `${expanded ? 'cw' : 'cg3'}`,
                    }}
                >
                    <div className='fdr aic flx1'>
                        <Typography className={`fwm fs16 ${expanded ? 'cw' : 'cg3'}`}>
                            {
                                AppFunctionsService.formatDateTimeToDateOnly(value.date)
                            }
                        </Typography>
                        <span className='flx1'></span>
                        {
                            session?.user.permissions.isTrapAdmin &&
                            trap.location &&
                            distance > 0 &&
                            <div className='fdc mr10'>
                                <UseLocationDialog
                                    oldLocation={trap.location}
                                    oldElevation={trap.elevation ?? 0}
                                    newLocation={value.location}
                                    newElevation={value.elevation}
                                    onClose={this.onLocationEditClose}
                                    buttonClassName={expanded ? 'cp bcw' : 'cw bcp'}
                                />
                            </div>
                        }
                        <StadiumDiv className={expanded ? 'cg3 bcw' : 'cg3 bcg1'}>
                            <div className='fdr'>
                                <Typography className='fwr fs15'>
                                    Location difference:
                                </Typography>
                                <Typography className={`fwm fs15 ${expanded ? 'cpr' : ''}`}>
                                    &nbsp;&nbsp;
                                    {
                                        `${distance.toFixed(0)} m`
                                    }
                                </Typography>
                            </div>
                        </StadiumDiv>
                        {
                            value.locationAccuracy > this.accuracyLimitMeters &&
                            <div className='ml10'>
                                <Tooltip title={`Location accuracy low! ${value.locationAccuracy.toFixed(0)}m`}>
                                    <Warning className={`${expanded ? 'cy' : 'cpr'}`} />
                                </Tooltip>
                            </div>
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdr'}>
                    <div className='fdc mr17 flx1'>
                        <div className={'fdr'}>
                            <div className='fdc mr8 flx1'>
                                {
                                    value.results.map((result, i) => (
                                        <div key={i} className={'fdr'}>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    INSECT
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                                    {
                                                        result.insectName.toLocaleUpperCase()
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    COUNT
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                                    {
                                                        result.count
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    COMMENT
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                    {
                                        comments.join(', ')
                                    }
                                </Typography>
                            </div>
                        </div>
                        {
                            image &&
                            <div className='fdc mt8'>
                                <div className='fdr h280'>
                                    <ButtonBase
                                        focusRipple
                                        id={image.path}
                                        className='h280 wfill'
                                        onClick={this.onImageClick}
                                    >
                                        <img
                                            src={image.thumbnailUrl ?? image.url}
                                            className='h280 wfill'
                                        />
                                    </ButtonBase>
                                </div>
                                <div className='fdr aic jcc mt8'>
                                    {
                                        !!value.imageComment &&
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {
                                                value.imageComment
                                            }
                                        </Typography>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='fdc ml17 flx1'>
                        <div className={'fdc mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        LATITUDE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            value.location.latitude.toFixed(6)
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        LONGITUDE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            value.location.longitude.toFixed(6)
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        ELEVATION
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            value.elevation.toFixed(6)
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className='fdc h280'>

                            <BasicMap
                                id={'farmMap'}
                                onMap={this.setMapRef}
                            >
                                <BasicMarker
                                    markerId='trap_entry_marker'
                                    location={[
                                        value.location.longitude,
                                        value.location.latitude,
                                    ]}
                                />
                            </BasicMap>
                            
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };
}
