import React from 'react';
import { ITrap, TrapHelper } from '../../../types/model/trap/trap';
import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import { ITrapType } from '../../../types/model/masterData/trap/trapType';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import TrapTypeFunctions from '../../../store/masterData/trap/trapType/functions';
import { ITrapEntry, TrapEntryHelper } from '../../../types/model/trap/trapEntry';
import { IInsect } from '../../../types/model/masterData/insect';
import GeneralFunctions from '../../../store/general/functions';
import InsectFunctions from '../../../store/masterData/insect/functions';
import TrapDataGraphView, { ITrapDataGraphEntry } from './DataGraphView';

interface ITrapDataViewProps {
    trap : ITrap;
    types : Array<ITrapType>;
    insects : Array<IInsect>;
    isLoading : boolean;
}

interface ITrapDataViewState {
    entries : Array<ITrapEntry>;
    isLoading : boolean;
}

class TrapDataViewComponent extends React.PureComponent<ITrapDataViewProps, ITrapDataViewState> {
    private listener ?: () => void;

    constructor(props : ITrapDataViewProps) {
        super(props);
        this.state = {
            entries: [],
            isLoading: false,
        };
    }

    public readonly componentDidMount = () => {
        TrapTypeFunctions.getList();
        InsectFunctions.getList();
        this.load();
    };

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public async load() : Promise<void> {
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = TrapEntryHelper.collection()
            .where('trapRef', '==', TrapHelper.doc(this.props.trap.id))
            .orderBy('date', 'desc')
            .onSnapshot((event) => {
                const entries = this.state.entries.slice();

                event.docChanges().forEach((element) => {
                    switch (element.type) {
                        case 'added':
                            entries.splice(element.newIndex, 0, element.doc.data());
                            break;
                        case 'modified':
                            entries.splice(
                                element.newIndex,
                                1,
                                element.doc.data(),
                            );
                            break;
                        case 'removed':
                            entries.splice(element.oldIndex, 1);
                            break;
                    }
                });

                this.setState({
                    entries,
                    isLoading: false,
                });
            }, (ex) => {
                GeneralFunctions.generalShowErrorSnackbar('Error loading entries', ex);
            });
    }

    private readonly getEntries = (state : ITrapDataViewState) => state.entries;
    private readonly getInsectProps = (state : ITrapDataViewState, props : ITrapDataViewProps) => props.insects;
    private readonly getTrapTypes = (state : ITrapDataViewState, props : ITrapDataViewProps) => props.types;
    private readonly getTrap = (state : ITrapDataViewState, props : ITrapDataViewProps) => props.trap;
    private readonly getPropsIsLoading = (state : ITrapDataViewState, props : ITrapDataViewProps) => props.isLoading;
    private readonly getStatesIsLoading = (state : ITrapDataViewState) => state.isLoading;

    private readonly getInsectIds = createSelector([
        this.getTrapTypes,
        this.getTrap,
    ], (
        types,
        trap,
    ) => types.find(x => x.id === trap.type)?.insects ?? []);

    private readonly getInsects = createSelector([
        this.getInsectIds,
        this.getInsectProps,
    ], (
        insectIds,
        insects,
    ) => insectIds.map(x => insects.find(insect => insect.id === x)));

    private readonly getGraphEntries = createSelector([
        this.getInsects,
        this.getEntries,
    ], (
        insects,
        entries,
    ) => {
        const resultObject : Record<string, Array<ITrapDataGraphEntry> | undefined> = {};

        insects.forEach(x => {
            resultObject[x?.id ?? ''] = [];
        });

        entries.forEach(entry => {
            entry.results.forEach(result => {
                resultObject[result.insectRef]?.push({
                    count: result.count,
                    date: entry.date,
                    guid: entry.guid,
                });
            });
        });

        return resultObject;
    });

    private readonly getIsLoading = createSelector([
        this.getPropsIsLoading,
        this.getStatesIsLoading,
    ], (
        propsIsLoading,
        stateIsLoading,
    ) => propsIsLoading || stateIsLoading);

    public render = () => {
        const {
            trap,
        } = this.props;

        const graphEntries = this.getGraphEntries(this.state, this.props);
        const insects = this.getInsects(this.state, this.props);
        const isLoading = this.getIsLoading(this.state, this.props);

        return (
            <div className='fdc flx1 pt20 bcg0'>
                <Toolbar disableGutters className='ml15 mr15 '>
                    <Typography className='fs25 fwb cpd'>
                        {
                            trap.typeName
                        }
                    </Typography>
                </Toolbar>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !!insects.length &&
                    <div className='ml15 mr15 pb80'>
                        
                        {
                            insects.map((insect, i) => (
                                <div key={i} className={`fdc ${i > 0 ? 'mt15' : ''}`}>
                                    <div className='fdr'>
                                        <Typography className='fs22 fwm cp'>
                                            {
                                                insect?.name.toLocaleUpperCase() ?? 'UNKNOW INSECT'
                                            }
                                        </Typography>
                                    </div>
                                    <Paper className='fdc flx1 mt15 h350 p20' elevation={1}>
                                        <TrapDataGraphView values={graphEntries[insect?.id ?? ''] ?? []} />
                                    </Paper>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        types: state.masterData.trap.trapType.types,
        insects: state.masterData.insect.insects,
        isLoading: state.masterData.trap.trapType.isLoading
            || state.trap.isLoading
            || state.masterData.insect.isLoading,
    };
};


const TrapDataView = connect(
    mapStateToProps,
)(TrapDataViewComponent);

export default TrapDataView;