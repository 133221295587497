import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { IAvoBugAssignmentEntry } from '../../../types/model/avoBug/avoBugAssignment';
import MaterialTable from '../../customComponents/materialTable/Table';

interface ITableEntry {
    tree : number;
    [key : string] : number;
}

interface IAvoBugAssignmentResultViewProps {
    value : Record<string, IAvoBugAssignmentEntry>;

    bugs : Array<string>;
}

interface IAvoBugAssignmentResultViewState {
}

class AvoBugAssignmentResultViewComponent extends React.PureComponent<IAvoBugAssignmentResultViewProps, IAvoBugAssignmentResultViewState> {
    constructor(props : IAvoBugAssignmentResultViewProps) {
        super(props);
        this.state = {
        };
    }
    
    private readonly getValue = (props : IAvoBugAssignmentResultViewProps) => props.value;
    private readonly getBugs = (props : IAvoBugAssignmentResultViewProps) => props.bugs;

    private readonly getData = createSelector(
        [
            this.getValue,
            this.getBugs,
        ],
        (value, bugs) => {
            return lodash
                .chain(Object.keys(value))
                .map((treeNumber) => {
                    const result : ITableEntry = {
                        tree: Number(treeNumber),
                    };

                    lodash.forEach(bugs, (bug) => {
                        result[bug] = (value[treeNumber].result[bug] as number | null) ?? 0;
                    });

                    return result;
                })
                .value();
        },
    );

    public readonly render = () => {
        const { bugs } = this.props;
        const data = this.getData(this.props);

        return (
            <div className='fdc flx1 MuiDialogContent-root'>
                <MaterialTable<ITableEntry>
                    id='assignmentTable'
                    data={data}
                    rowsPerPage={50}
                    columns={[{
                        header: 'Tree',
                        field: 'tree',
                        width: 120,
                        enableSort: true,
                        enableFilter: true,
                    },
                    ...bugs.map(bug => ({
                        header: bug.toLocaleUpperCase(),
                        field: bug,
                        width: 160,
                        enableSort: true,
                        enableFilter: true,
                    }))]}
                />
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

const AvoBugAssignmentResultView = connect(
    mapStateToProps,
)(AvoBugAssignmentResultViewComponent);


export default AvoBugAssignmentResultView;