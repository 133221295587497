import React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import PhenologySpecificFunctions from '../../../store/phenology/specific/functions';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../customComponents/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import AppFunctionsService from '../../../services/appFunctionServices';
import { Transitions } from '../../customComponents/animations/Transitions';
import { withRouter, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import DeleteDialog from '../../customComponents/dialog/DeleteDialog';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralFunctions from '../../../store/general/functions';
import PhenologyActions from '../../../store/phenology/general/actions';
import { CROP } from '../../../appConstants';
import DivisionSelector from '../../customComponents/selector/rights/DivisionSelector';
import lodash from 'lodash';
import ToggleButton from '../../customComponents/button/ToggleButton';
import ScoutingAssignmentLandNameFilter from '../../customComponents/materialTable/ScoutingAssignmentLandNameFilter';
import ScoutingAssignmentBlockNameFilter from '../../customComponents/materialTable/ScoutingAssignmentBlockNameFilter';
import PhenologySpecificAssignmentCreateDialog from './dialog/CreateDialog';
import { IPhenologySpecific, PhenologySpecificTreeType } from '../../../types/model/phenology/specific';
import { IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Archive, Check, Close, InfoRounded, LocationOff, Unarchive, Warning } from '@material-ui/icons';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import NavFunctions from '../../../store/nav/functions';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { IUserSession } from '../../../types/model/user';
import IPhenologySpecificTreeInfoDialog from './dialog/Info';
import PhenologySpecificTreesPrintQrCodeButton from './dialog/PrintQrCode';
import CultivarFilter from '../../customComponents/materialTable/CultivarNameFilter';
import { ICultivar } from '../../../types/model/masterData/cultivars';

interface IPhenologySpecificListProps extends RouteComponentProps {
    isLoading : boolean;
    specifics : Array<IPhenologySpecific>;

    setLoading : DispatchCall<boolean>;

    session ?: IUserSession | null;
}

interface IPhenologySpecificListState {
    selectedTrees : Array<PhenologySpecificTreeType>;
    selectedDivisions : Array<string>;
    archived : boolean;
    active : boolean;
    landName ?: string;
    blockName ?: string;
    cultivar ?: string;

    selectedArchive ?: PhenologySpecificTreeType;
    selectedCode ?: string;
}

class PhenologySpecificListComponent extends React.Component<IPhenologySpecificListProps, IPhenologySpecificListState> {
    constructor(props : IPhenologySpecificListProps) {
        super(props);
        this.state = {
            selectedTrees: [],
            selectedDivisions: [],
            archived: false,
            active: false,
        };
    }

    public readonly componentDidMount = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        const trees = this.getTrees(this.props);
        this.loads(!trees.length || urlParams.get('crop') !== trees[0].crop);
    };

    public readonly componentDidUpdate = (prevProps : IPhenologySpecificListProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.loads(true);
        }
    };

    public readonly loads = (refresh ?: boolean) => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop');

            if (crop) {
                PhenologySpecificFunctions.listen(crop as CROP, refresh);
            }
        }
    };

    private readonly getTrees = (props : IPhenologySpecificListProps) => props.specifics.map(x => x.trees.map(z => ({
        ...x,
        ...z,
    }))).flatMap(x => x);
    private readonly getDivisions = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.selectedDivisions;
    private readonly getActive = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.active;
    private readonly getArchived = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.archived;
    private readonly getLandName = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.landName;
    private readonly getBlockName = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.blockName;
    private readonly getCultivar = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.cultivar;
    private readonly getSelectedCode = (props : IPhenologySpecificListProps, state : IPhenologySpecificListState) => state.selectedCode;
    private readonly getSearch = (props : IPhenologySpecificListProps) => props.location.search;

    private readonly getFiltereds = createSelector([
        this.getTrees,
        this.getDivisions,
        this.getActive,
        this.getArchived,
        this.getLandName,
        this.getBlockName,
        this.getCultivar,
    ], (
        trees,
        divisions,
        active,
        archived,
        landName,
        blockName,
        cultivar,
    ) => {
        return trees
            .filter(x => (active && archived) || (!active && !archived) || (active && !x.archived) || (archived && x.archived))
            .filter(x => !divisions.length || divisions.includes(x.division.toLocaleUpperCase()))
            .filter(n => !landName || landName === n.landName)
            .filter(n => !blockName || blockName === n.blockName)
            .filter(n => !cultivar || cultivar === n.cultivar)
            .sort((a, b) => Number(a.code.substring(2)) - Number(b.code.substring(2)));
    });

    private readonly getDivisionCounts = createSelector([
        this.getTrees,
    ], (trees) => {
        return lodash.chain(trees)
            .groupBy(x => x.division.toLocaleUpperCase())
            .reduce((prev, list, key) => {
                return {
                    ...prev,
                    [key]: list.length,
                };
            }, {} as Record<string, number>)
            .value();
    });

    private readonly getSelected = createSelector([
        this.getTrees,
        this.getSelectedCode,
    ], (trees, code) => {
        return trees.find(x => x.code === code);
    });

    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });

    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            
            const { specifics } = this.props;
            const currentTrees = this.getTrees(this.props);
            const tree = currentTrees.find(x => x.code === event.currentTarget.value);

            if (!tree) return;
            if (tree.location) return;

            const specific = specifics.find(x => x.id === tree.id);
            if (!specific) return;
            const trees = specific.trees.slice();
            const treeIndex = trees.findIndex(x => x.code === tree.code);

            trees.splice(treeIndex, 1);

            await PhenologySpecificFunctions.save({
                ...specific,
                trees,
            });

            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting entry', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    private readonly onSelectionChange = (selectedTrees : Array<PhenologySpecificTreeType>) => {
        this.setState({
            selectedTrees: selectedTrees.slice(),
        });
    };

    private readonly onDivisionClick = (value : string) => {
        const selectedDivisions = this.state.selectedDivisions.slice();
        const index = selectedDivisions.indexOf(value);
        if (index > -1) {
            selectedDivisions.splice(index, 1);
        } else {
            selectedDivisions.push(value);
        }

        this.setState({
            selectedDivisions,
        });
    };

    private readonly onArchivedClick = () => {
        this.setState({
            archived: !this.state.archived,
        });
    };

    private readonly onActiveClick = () => {
        this.setState({
            active: !this.state.active,
        });
    };

    private readonly onLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
    };

    private readonly onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockName: !blockNames.length ? undefined : blockNames[0],
        });
    };

    private readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        NavFunctions.navPath('/phenology/specific_assignment/map', `${this.props.location.search}&code=${event.currentTarget.id}`);
    };

    private readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selectedCode: event.currentTarget.value,
        });
    };

    private readonly onArchiveClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const trees = this.getTrees(this.props);
        this.setState({
            selectedArchive: trees.find(x => x.code === event.currentTarget.value),
        });
    };

    private readonly onInfoClose = () => {
        this.setState({
            selectedCode: undefined,
        });
    };

    private readonly archivePhenologySpecificTree = async () => {
        try {
            const { selectedArchive } = this.state;
            const { session, specifics } = this.props;

            if (!selectedArchive) return;
            if (!session) return;

            const specific = specifics.find(x => x.id === selectedArchive.id);

            if (!specific) return;
            const trees = specific.trees.slice();
            const treeIndex = trees.findIndex(x => x.code === selectedArchive.code);

            trees.splice(treeIndex, 1, {
                ...trees[treeIndex],
                archived: !trees[treeIndex].archived,
            });

            await PhenologySpecificFunctions.save({
                ...specific,
                trees,
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error archiving tree', ex);
        } finally {
            this.setState({
                selectedArchive: undefined,
            });
        }
    };

    private readonly onArchiveConfirmClose = (result : boolean) => {
        if (!result) {
            this.setState({
                selectedArchive: undefined,
            });
            return;
        }

        this.archivePhenologySpecificTree();
    };

    private readonly onSelectedClose = () => {
        this.setState({
            selectedTrees: [],
        });
    };

    private readonly onCultivarChange = (cultivar : Array<ICultivar>) => {
        this.setState({
            cultivar: !cultivar.length ? undefined : cultivar[0].id,
        });
    };

    public readonly render = () => {
        const { isLoading, session } = this.props;
        const {
            selectedTrees,
            selectedDivisions,
            archived,
            active,
            landName,
            blockName,
            selectedArchive,
            cultivar,
        } = this.state;

        const crop = this.getCrop(this.props);
        const filtereds = this.getFiltereds(this.props, this.state);
        const divisionCounts = this.getDivisionCounts(this.props);
        const selected = this.getSelected(this.props, this.state);
        return (
            <div className={'fdc flx1 mh0 mw0 bcg0'}>
                <div className='fdr mb15'>
                    <DivisionSelector
                        value={selectedDivisions}
                        counts={divisionCounts}
                        onClick={this.onDivisionClick}
                        crop={crop}
                    />
                </div>
                <div className={'fdc flx1 p10 mh0 mw0'}>
                    <div className='fdr mb15'>
                        <div className='mr15'>
                            <ToggleButton
                                selected={archived}
                                onClick={this.onArchivedClick}
                                disabled={isLoading}
                                stadium={'true'}
                            >
                                ARCHIVE
                            </ToggleButton>
                        </div>
                        <div className='mr15'>
                            <ToggleButton
                                selected={active}
                                onClick={this.onActiveClick}
                                disabled={isLoading}
                                stadium={'true'}
                            >
                                ACTIVE
                            </ToggleButton>
                        </div>
                    </div>
                    <Card className={'flx1 fdc mb40'}>
                        <MaterialTable<PhenologySpecificTreeType>
                            id='entryTable'
                            data={filtereds}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedTrees}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 145,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            !row.location &&
                                            <DeleteDialog
                                                maxWidth='md' isLoading={isLoading}
                                                message={`Delete ${row.code} - ${row.landName} - ${row.blockName}?`}
                                                onConfirm={this.onDelete}
                                                title={'Delete'}
                                                value={row.code}
                                                transition={Transitions.Down}
                                            />
                                        }
                                        {
                                            !row.location &&
                                            <Tooltip title='No Location'>
                                                <LocationOff className='co p12' />
                                            </Tooltip>
                                        }
                                        {
                                            !!row.location &&
                                            !!row.code &&
                                            <Tooltip title='View'>
                                                <div>
                                                    <IconButton
                                                        id={row.code}
                                                        color='primary'
                                                        onClick={this.onMapClick}
                                                    >
                                                        <FontAwesomeIcon icon={faMapLocationDot} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        }
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton
                                                    value={row.code}
                                                    color='primary'
                                                    onClick={this.onInfoClick}
                                                >
                                                    <InfoRounded />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Division',
                                field: 'divisionName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Field',
                                field: 'landName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentLandNameFilter value={landName}
                                    onChange={this.onLandNameChange} id='land_filter' cropType={crop} />),
                            }, {
                                header: 'Block',
                                field: 'blockName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentBlockNameFilter value={blockName}
                                    onChange={this.onBlockNameChange} id='block_filter' cropType={crop} landName={landName} />),
                            }, {
                                header: 'Cultivar',
                                field: 'cultivarName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (
                                    <CultivarFilter
                                        value={cultivar}
                                        onChange={this.onCultivarChange}
                                        id='cultivar_filter'
                                        cropType={crop}
                                    />
                                ),
                            }, {
                                header: 'Tree Number',
                                field: 'treeNumber',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            }, {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            }, {
                                header: 'Archive',
                                paddingRight: 4,
                                width: 55,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            !row.archived ?
                                                (<Close className='cpr' />) :
                                                (<Check className='cdg' />)
                                        }
                                        <Tooltip title={!row.archived ? 'Archive' : 'Unarchive'}>
                                            <div>
                                                <IconButton
                                                    color='primary'
                                                    onClick={this.onArchiveClick}
                                                    value={row.code}
                                                >
                                                    {
                                                        row.archived &&
                                                        <Unarchive />
                                                    }
                                                    {
                                                        !row.archived &&
                                                        <Archive />
                                                    }
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }]}
                        />
                    </Card>
                    <ConfirmDialog
                        open={!!selectedArchive}
                        title={
                            !selectedArchive ? '' : `${selectedArchive.archived ? 'Unarchive' : 'Archive'} TREE`.toLocaleUpperCase()
                        }
                        message={(
                            <div className='fdr aic jcc'>
                                <div className='fdc mr15'>
                                    <Warning className='co h36 w36' />
                                </div>
                                <div className='fdc'>
                                    <Typography
                                        className='co fs24 fwm'
                                        
                                    >
                                        {
                                            !selectedArchive ?
                                                '' :
                                                `Are you sure you want to ${selectedArchive.archived ? 'Unarchive' : 'Archive'} tree ${selectedArchive.code}?`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        )}
                        onClose={this.onArchiveConfirmClose}
                        transition={Transitions.Down}
                        disabled={isLoading || !selectedArchive}
                        acceptText={!selectedArchive?.archived ? 'ARCHIVE' : 'UNARCHIVE'}
                        denyText='BACK'
                    />
                    <IPhenologySpecificTreeInfoDialog
                        value={selected}
                        onClose={this.onInfoClose}
                    />
                    <div className='fdr h36'>
                        <div className='mr15'>
                            <PhenologySpecificTreesPrintQrCodeButton
                                selected={selectedTrees}
                                onClose={this.onSelectedClose}
                            />
                        </div>
                        <span className='flx1' />
                        {
                            session?.user.permissions.isPhenologyAdmin &&
                            <PhenologySpecificAssignmentCreateDialog
                                maxWidth='sm'
                                fullWidth
                                disabled={isLoading}
                                crop={crop}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        isLoading: state.phenology.specific.isLoading,
        specifics: state.phenology.specific.specifics,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(PhenologyActions.setLoadingAssignments(isLoading)),
}, dispatcher);

const PhenologySpecificList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PhenologySpecificListComponent));

export default PhenologySpecificList;
