import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import auth from './auth/reducer';
import general from './general/reducer';
import user from './userManagement/reducer';
import scouting from './scouting/reducer';
import nematode from './nematode/reducer';
import avoBug from './avobug/reducer';
import phenology from './phenology';
import masterDataReducer from './masterData/main';
import trap from './trap/reducer';
import rightsReducer from './rights/main';
import notificationReducer from './notifications/reducer';

export const hashHistory = createHashHistory();
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createRootReducer = (history : any) => combineReducers({
    router: connectRouter(history),
    auth,
    general,
    user,
    scouting,
    phenology,
    trap,
    masterData: masterDataReducer,
    rights: rightsReducer,
    notifications: notificationReducer,
    nematode,
    avoBug,
});

const configureStore = () => {
    return createStore(
        createRootReducer(hashHistory),
        composeEnhancer(
            applyMiddleware(routerMiddleware(hashHistory)),
        ),
    );
};

const store = configureStore();

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
