import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';
import { withStyles } from '@material-ui/core/styles';

interface IDivisionButtonProps extends ButtonProps {
    selected : boolean;
    selectedColor : string;
}

interface IDivisionButtonState {}

class DivisionButton extends React.PureComponent<IDivisionButtonProps, IDivisionButtonState> {
    constructor(props : IDivisionButtonProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { selected, children, classes, className, ...other } = this.props;
        return (
            <Button className={`${className} ${classes?.root}`} { ...other }>
                {
                    children
                }
                <motion.div initial={{
                    transform: 'rotate(0deg)',
                }} animate={{ transform: selected ? 'rotate(180deg)' : 'rotate(0deg)' }} className='mh0 aic jcc'>
                    <ExpandMore className='cg6' />
                </motion.div>
            </Button>
        );
    };
}

export default withStyles<'root', never, IDivisionButtonProps>({
    root: {
        background: props => !props.selected ? 'white' : `linear-gradient(45deg, ${props.selectedColor} 30%, ${props.selectedColor} 90%)`,
    },
})(DivisionButton);
