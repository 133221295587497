import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { createSelector } from 'reselect';
import { IPhenologySpecific, PhenologySpecificTreeType } from '../../../../types/model/phenology/specific';
import { IUserSession } from '../../../../types/model/user';
import { GeoPointHelper, IGeoPoint } from '../../../../types/model/geoPoint';
import LocationEditDialog from '../../../dialog/LocationEditDialog';
import PhenologySpecificTreeDownloadQrCodeButton from '../dialog/DownloadQrCodes';
import PhenologySpecificFunctions from '../../../../store/phenology/specific/functions';
import BasicMap from '../../../customComponents/mapBox/BasicMap';
import { MapBoxRef } from '../../../customComponents/mapBox/MapBox';
import MapboxCherriesSpecificLayer from '../../../customComponents/mapBox/layer/phenology/CherriesSpecificLayer';
interface IPhenologySpecificTreeSummaryViewProps {
    specifics : Array<IPhenologySpecific>;
    tree : PhenologySpecificTreeType;
    
    session ?: IUserSession | null;

    isLoading ?: boolean;
}

interface IPhenologySpecificTreeSummaryViewState {
    showMap : boolean;
}

export default class PhenologySpecificTreeSummaryView extends React.PureComponent<IPhenologySpecificTreeSummaryViewProps, IPhenologySpecificTreeSummaryViewState> {
    private readonly mapZoom = 16;

    constructor(props : IPhenologySpecificTreeSummaryViewProps) {
        super(props);
        this.state = {
            showMap: false,
        };

    }

    private getTree = (state : IPhenologySpecificTreeSummaryViewState, props : IPhenologySpecificTreeSummaryViewProps) => props.tree;
    private getCenter = createSelector(
        [this.getTree],
        (tree) => {
            if (!tree.location) return null;
            return {
                lat: tree.location.latitude,
                lng: tree.location.longitude,
            };
        },
    );

    private readonly saveLocation = async (location : IGeoPoint, elevation : number) => {        
        try {
            const specifics = this.props.specifics.slice();

            const specific = specifics.find(x => x.id === this.props.tree.id);
            const tree = specific?.trees.find(x => x.code === this.props.tree.code);
            if (!specific || !tree) return;

            const trees = specific.trees.slice();

            const index = trees.findIndex(x => x.code === tree.code);

            trees.splice(index, 1, {
                ...tree,
                location: GeoPointHelper.toFirestore(location),
                elevation: elevation,
                elevationAccuracy: 0,
            });
            specific.trees = trees;

            await PhenologySpecificFunctions.save(specific);
        } catch (ex) {
            // Ignore
        }

    };

    private readonly onLocationEditClose = (location : IGeoPoint | null, elevation : number | null) => {
        if (!location) return;
        if (!elevation) return;

        this.saveLocation(location, elevation);
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        if (!this.props.tree.location) return;
        mapRef.flyTo({
            zoom: this.mapZoom,
            center: [this.props.tree.location.longitude, this.props.tree.location.latitude],
            animate: false, 
        });
    };

    private readonly onMapDivRef = () => {
        setTimeout(() => {
            this.setState({
                showMap: true,
            });
        }, 250);
    };
    
    public readonly render = () => {
        const {
            tree,
            session,
            isLoading,
        } = this.props;

        const {
            showMap,
        } = this.state;

        const center = this.getCenter(this.state, this.props);

        return (
            <div className='fdc flx1 pt20 bcg0'>
                <div className='fdr aic jcc'>
                    <Card className={'fdc bcw mt30'}>
                        <div className={'fdr bcinfo cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                INFO
                            </Typography>
                            <div className='posr aic jcc'>
                                <div className='posa posr0'>
                                </div>
                            </div>
                        </div>
                        <div className={'fdc flx1 ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        CODE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            tree.code
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        DIVISION
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            tree.divisionName
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        BLOCK
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            tree.blockName
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        TREE NUMBER
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            tree.treeNumber
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        CREATED BY
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            tree.createdByName
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className='fdr aic jcc'>
                    <Card className={'fdc bcw mt30 w600 h350'}>
                        <div className={'fdr bcinfo cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                LOCATION
                            </Typography>
                            <div className='posr aic jcc'>
                                <div className='posa posr0'>
                                    {
                                        session?.user.permissions.isPhenologyAdmin &&
                                        tree.location &&
                                        <LocationEditDialog
                                            location={tree.location}
                                            disabled={isLoading}
                                            elevation={tree.elevation ?? 0}
                                            color='secondary'
                                            onClose={this.onLocationEditClose}
                                            iconUrl={'/assets/images/phenology/cherries.png'}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'fdc flx1'}>
                            {
                                !tree.location &&
                                <div className={'fdc  flx1 aic jcc'}>
                                    <div className='fdc mb20'>
                                        <Warning className='co h36 w36' />
                                    </div>
                                    <Typography
                                        className='fwb fs15 mb20'
                                    >
                                        NOT ACTIVATED
                                    </Typography>
                                    <Typography
                                        className='fs11 ml100 mr100 tac'
                                    >
                                        This tree{'\u2019'}s location is not yet activated. Please scan QR sticker in the field destination when doing survey to activate location.
                                    </Typography>
                                </div>
                            }
                            {
                                center &&
                                tree.location &&
                                <div className='fdc flx1'>
                                    <div className={'fdc ml17 mr17 mb13'}>
                                        <div className={'flx1 fdr'}>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    LATITUDE
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                                    {
                                                        tree.location.latitude.toFixed(6)
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    LONGITUDE
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                                    {
                                                        tree.location.longitude.toFixed(6)
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    ELEVATION
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                                    {
                                                        tree.elevation?.toFixed(6) ?? '-'
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flx1' ref={this.onMapDivRef}>
                                        {
                                            showMap &&
                                            <BasicMap
                                                id={'specificMap'}
                                                onMap={this.setMapRef}
                                                
                                            >
                                                {
                                                    tree.crop === 'cherries' &&
                                                    <MapboxCherriesSpecificLayer
                                                        markersLayerId='point_farm_layer'
                                                        minZoom={1}
                                                        markersBeforeId='polygon_division_layer'
                                                        selectedCode={tree.code}
                                                        hideUnselected
                                                    />
                                                }
                                            </BasicMap>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                <span className='flx1' />
                <div className='fdr aic mb15 mr15'>
                    <span className='flx1' />
                    <PhenologySpecificTreeDownloadQrCodeButton value={tree} />
                </div>
            </div>
        );
    };
}
