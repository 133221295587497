import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IDamage } from '../../../types/model/masterData/damage';
import DamageFunctions from '../../../store/masterData/damage/functions';

interface IDamageDropdownProps {
    id ?: string;
    value ?: IDamage | string;

    onChange : (name ?: string, value ?: IDamage) => void;

    damages : Array<IDamage>;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;
    disabled ?: boolean;
    name ?: string;
}

interface IDamageDropdownState {
}

class DamageDropdown extends React.Component<IDamageDropdownProps, IDamageDropdownState> {
    constructor(props : IDamageDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public loadData = async () => {
        DamageFunctions.getList();
    };

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = this.props.damages.find(n => n.id === value?.value);

        this.props.onChange(this.props.name, result);
    };

    private getData = (state : IDamageDropdownState, props : IDamageDropdownProps) => props.damages;
    private getValue = (state : IDamageDropdownState, props : IDamageDropdownProps) => props.value;
    private getRequired = (state : IDamageDropdownState, props : IDamageDropdownProps) => props.required;
    private getDamageDropdown = createSelector([
        this.getData, this.getRequired,
    ], (damages, required) => {
        const damagesDrop = damages
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ label: `${x.name}`, value: x.id }));

        if (!required) {
            damagesDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return damagesDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, damages) => {
        if (typeof(value) === 'object') {
            return {
                label: `${value.name}`,
                value: value.id,
            };
        } else if (value && typeof(value) === 'string') {
            const damagee = damages.slice().find(x => x.id === value);

            if (damagee) {
                return {
                    label: `${damagee.name}`,
                    value: damagee.id,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required, fullWidth, isLoading, disabled } = this.props;

        const damages = this.getDamageDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        disabled={isLoading || disabled}
                        id='damagee_select'
                        options={damages}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Damage' />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        damages: state.masterData.damage.damages.filter(x => x.isActive),
        isLoading: state.masterData.damage.isLoading,
    };
};

export default connect(
    mapStateToProps,
)(DamageDropdown);
