import firebase from 'firebase/app';
import { BaseHelper, IBase } from '../../base';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import UserHelper from '../user';
import { AvoBugAssignmentHelper } from './avoBugAssignment';
import FirestoreHelper from '../../../services/helper/firestoreHelper';

export interface IAvoBugAssignmentLocationEntry extends IBase {
    /** Assignment Path */
    assignmentRef : string;
    assignmentId : string;

    employeeName : string;
    employeeNumber : string;
    /** Employee Path */
    employee : string;

    date : number;

    latitude : number;
    longitude : number;
    geo : IGeoPoint;
    mocked : boolean;
    altitude : number;
    accuracy : number;
    heading : number;
    speed : number;
    speedAccuracy : number;
}

export class AvoBugAssignmentLocationEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'avobug_entries_locations';

    public static converter : firebase.firestore.FirestoreDataConverter<IAvoBugAssignmentLocationEntry> = {
        fromFirestore: (snapshot) => {
            return AvoBugAssignmentLocationEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IAvoBugAssignmentLocationEntry) => {
            return AvoBugAssignmentLocationEntryHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IAvoBugAssignmentLocationEntry {
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');

        return {
            ...data as IAvoBugAssignmentLocationEntry,
            geo: GeoPointHelper.fromFirestore(data['geo']),
            employee: (data['employee'] as firebase.firestore.DocumentReference | null)?.id ?? '',
            assignmentRef: (data['assignmentRef'] as firebase.firestore.DocumentReference | null)?.id ?? '',
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    protected static toFirestore(data : IAvoBugAssignmentLocationEntry) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;
        return {
            ...rest,
            ...result,
            geo: GeoPointHelper.toFirestore(data.geo),
            employee: UserHelper.get(data.employee),
            assignmentRef: AvoBugAssignmentHelper.doc(data.assignmentRef),
            date: FirestoreHelper.millisToTimestamp(data.date),
        };
    }

    public static collection(assignmentId ?: string) {
        if (!assignmentId) {
            return AvoBugAssignmentHelper.collection().withConverter(this.converter);
        }
        return AvoBugAssignmentHelper.doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(assignmentId : string, id ?: string) {
        if (!id) {
            return AvoBugAssignmentLocationEntryHelper.collection(assignmentId).doc();
        }

        return AvoBugAssignmentLocationEntryHelper.collection(assignmentId).doc(id);
    }

}