import { dispatch, getState } from '../index';
import AuthActions from './actions';
import AuthMapper from '../../services/mappers/authMapper';
import HttpService from '../../services/httpService';
import firebaseApp, { firebaseGoogleProvider, firebaseMessaging } from '../../services/firebaseService';
import GeneralFunctions from '../general/functions';
import UserHelper from '../../types/model/user';
import UserFcmHelper from '../../types/model/rights/userFcm';
import firebase from 'firebase/app';
import NotificationService from '../../services/notificationService';
import HttpGeoServerService from '../../services/geoserverHttpService';

export default class AuthFunctions {
    private static authUnsubscribe ?: firebase.Unsubscribe;
    private static userUnsubscribe ?: () => void;

    public static setAuthState = async () => {
        dispatch(AuthActions.authSetLoggingIn(true));
        HttpGeoServerService.initializeInterceptor();

        if (AuthFunctions.authUnsubscribe) AuthFunctions.authUnsubscribe();
        AuthFunctions.authUnsubscribe = firebaseApp.auth().onAuthStateChanged(AuthFunctions.authObserver);
    };

    private static logoutUser = () => {
        dispatch(AuthActions.authSetSession(null));
        dispatch(AuthActions.authSetLoggedIn(false));
        dispatch(AuthActions.authSetLoggingIn(false));
        HttpService.initializeInterceptor('');
        if (AuthFunctions.userUnsubscribe) AuthFunctions.userUnsubscribe();
    };

    private static authObserver = async (firebaseUser : firebase.User | null) => {
        if (AuthFunctions.userUnsubscribe) AuthFunctions.userUnsubscribe();

        if (firebaseUser) {
            try {
                AuthFunctions.userUnsubscribe = UserHelper.get(firebaseUser.uid).onSnapshot((snapshot) => {
                    const authState = getState().auth;
                    const user = !authState.session ? undefined : authState.session.user;
                    const data = snapshot.data();
                    if (snapshot.exists && data) {
                        setTimeout(() => {
                            firebaseUser.getIdTokenResult(true).then((token) => {
                                HttpService.initializeInterceptor(token.token);
                                dispatch(AuthActions.authSetSession(AuthMapper.mapFirebaseUserToSession(firebaseUser, data)));
                                dispatch(AuthActions.authSetLoggedIn(true));
                                dispatch(AuthActions.authSetLoggingIn(false));
                            });
                        }, user ? 2000 : 0); // Just wait 2sec before updating redux so custom claims can change.
                    } else if (user && !snapshot.exists) {
                        AuthFunctions.firebaseSignOut();
                    }
                }, (error) => {
                    GeneralFunctions.generalShowErrorSnackbar(error.message);
                    AuthFunctions.logoutUser();
                });

                try {
                    const permission = await Notification.requestPermission();

                    if (permission !== 'granted') return;

                    const registration = await NotificationService.subscribePush();
                    
                    if (registration) {
                        const token = await firebaseMessaging.getToken({
                            vapidKey: PUBLIC_PUSH_KEY,
                            serviceWorkerRegistration: registration,
                        });
    
                        await UserFcmHelper.updateWebFcm(token, firebaseUser.uid);
                    }

                } catch (ex) {
                    GeneralFunctions.generalShowErrorSnackbar('Error getting FCM token', ex);
                }
            } catch {
                AuthFunctions.logoutUser();
            }


        } else {
            AuthFunctions.logoutUser();
        }
    };

    public static hasRight = (url : string) => {
        const authState = getState().auth;

        if (!authState.session) return false;

        switch (url) {
            case '/scouting/assignments':
            case '/scouting/assignments/create':
            case '/reporting/scouting':
                return authState.session.user.permissions.isScoutingAdmin;
            case '/scouting/map':
            case '/block/list':
            case '/traps/list':
            case '/traps/map':
            case '/traps/analytics':
                return true;
            case '/nematode/assignments':
            case '/nematode/assignments/create':
                return authState.session.user.permissions.isNematodeAdmin;
            case '/avoBug/list':
            case '/avoBug/map':
                return authState.session.user.permissions.isAvoBugAdmin;
            case '/masterData/traps/farmDescription':
            case '/masterData/traps/entryComment':
            case '/masterData/traps/type':
                return authState.session.user.permissions.isSuperAdmin && authState.session.user.permissions.isTrapAdmin;
            case '/users':
                return authState.session.user.permissions.isUserAdmin;
            case '/masterData/threshold':
                return authState.session.user.permissions.isThresholdAdmin;
            case '/phenology/assignments':
            case '/phenology/assignments/create':
            case '/phenology/specific_assignment':
            case '/phenology/specific_assignment/map':
                return authState.session.user.permissions.isPhenologyAdmin;
            case '/masterData/insect':
            case '/masterData/damage':
            case '/masterData/disease':
            case '/masterData/scouting':
            case '/masterData/phenology':
            case '/rights/division':
            case '/masterData/cultivars':
            case '/masterData/nematode/surveyTypes':
                return authState.session.user.permissions.isSuperAdmin;
            case '/masterData/points':
                return authState.session.user.permissions.isBlockAdmin;
            default:
                return false;
        }
    };

    public static firebaseSignIn = async () => {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            const provider = firebaseGoogleProvider;
            await firebaseApp.auth().signInWithPopup(provider);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error occurred while trying to sign you in');
            dispatch(AuthActions.authSetLoggingIn(false));
        }
    };

    public static firebaseSignOut = async () => {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            await firebaseApp.auth().signOut();
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error occurred while trying to sign you out');
            dispatch(AuthActions.authSetLoggingIn(false));
        }
    };
}
