import React from 'react';
import { AppBar, Dialog, DialogActions, DialogContent, FormControl, IconButton, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { createSelector } from 'reselect';
import { IGeoPoint } from '../../../types/model/geoPoint';
import { Transitions } from '../../customComponents/animations/Transitions';
import StadiumButton from '../../customComponents/button/StadiumButton';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import BasicMarker from '../../customComponents/mapBox/marker/BasicMarker';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';

interface IUseLocationDialogProps {
    oldLocation : IGeoPoint;
    newLocation : IGeoPoint;
    oldElevation : number;
    newElevation : number;

    onClose ?: (location : IGeoPoint, elevation : number) => void;

    disabled ?: boolean;

    buttonClassName ?: string;
}

interface IUseLocationDialogState {
    open : boolean;
}

export default class UseLocationDialog extends React.PureComponent<IUseLocationDialogProps, IUseLocationDialogState> {
    private readonly mapZoom = 16;

    constructor(props : IUseLocationDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            open: true,
        });
    };

    private readonly onCloseClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            open: false,
        });
    };

    private readonly onDialogClick = (event : React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    private readonly onSaveClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            open: false,
        });

        if (!this.props.onClose) return;
        this.props.onClose(this.props.newLocation, this.props.newElevation);
    };

    private getOldLocation = (state : IUseLocationDialogState, props : IUseLocationDialogProps) => props.oldLocation;
    private getNewLocation = (state : IUseLocationDialogState, props : IUseLocationDialogProps) => props.newLocation;
    private getOldCenter = createSelector(
        [this.getOldLocation],
        (location) => {
            return [location.longitude, location.latitude] as [number, number];
        },
    );

    private getNewCenter = createSelector(
        [this.getNewLocation],
        (location) => {
            return [location.longitude, location.latitude] as [number, number];
        },
    );

    private readonly setOldMapRef = (mapRef : MapBoxRef) => {
        const oldPosition = this.getOldCenter(this.state, this.props);
        mapRef.flyTo({
            center: oldPosition,
            zoom: 15,
            animate: false,
        });
    };

    private readonly setNewMapRef = (mapRef : MapBoxRef) => {
        const oldPosition = this.getNewCenter(this.state, this.props);
        mapRef.flyTo({
            center: oldPosition,
            zoom: 15,
            animate: false,
        });
    };

    public readonly render = () => {
        const {
            disabled,
            oldElevation,
            newElevation,
            buttonClassName,
        } = this.props;

        const {
            open,
        } = this.state;

        const oldPosition = this.getOldCenter(this.state, this.props);
        const newPosition = this.getNewCenter(this.state, this.props);

        return (
            <>
                <div className='fdc aic jcc'>
                    <Tooltip title='Set Trap Location'>
                        <div>
                            <StadiumButton className={`fw500 fs14 cw bcp ${buttonClassName}`} onClick={this.onClick}>
                                SET TRAP LOCATION
                            </StadiumButton>
                        </div>
                    </Tooltip>
                </div>
                <Dialog
                    onClick={this.onDialogClick}
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    maxWidth='md'
                    fullWidth
                    disableBackdropClick
                    aria-labelledby='location-edit-title'
                    aria-describedby='location-edit-description'
                >
                    <AppBar className='fdr posr aic jcc' position='static' elevation={0}>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Update Location
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={disabled} onClick={this.onCloseClick} aria-label='Add'>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                        <div className='fdc flx1'>
                            <div className={'fdc ml17 mr17 mb13'}>
                                <div className={'fdc flx1 mt15'}>
                                    <div className='fdr'>
                                        <div className={'fdc flx1 jcfs mr10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    id='oldElevation'
                                                    label='Current Elevation'
                                                    type='number'
                                                    fullWidth
                                                    value={oldElevation}
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'fdc flx1 jcfs ml10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    id='newElevation'
                                                    label='Updated Elevation'
                                                    type='number'
                                                    fullWidth
                                                    value={newElevation}
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className={'fdr bcw mt30 h350'}>
                                    <div className='fdc flx1 mr10'>
                                        <BasicMap
                                            id={'oldPositionMap'}
                                            onMap={this.setOldMapRef}
                                        >
                                            <BasicMarker
                                                markerId='trap_entry_marker'
                                                location={oldPosition}
                                                iconUrl='/assets/images/traps.png'
                                                size={0.1}
                                            />
                                        </BasicMap>
                                    </div>
                                    <div className='fdc flx1 ml10'>
                                        <BasicMap
                                            id={'newPositionMap'}
                                            onMap={this.setNewMapRef}
                                        >
                                            <BasicMarker
                                                markerId='trap_entry_marker'
                                                location={newPosition}
                                                iconUrl='/assets/images/traps.png'
                                                size={0.1}
                                            />
                                        </BasicMap>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='fdr'>
                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onCloseClick}>
                                BACK
                            </StadiumButton>
                            <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                SAVE
                            </StadiumButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </>
        );
    };
}
