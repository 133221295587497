import React from 'react';
import { Layer, Source } from 'react-map-gl';
import MasterDataGeoServerFunctions from '../../../../../store/masterData/geoserver/functions';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { IGeoServerBlock } from '../../../../../types/model/masterData/geoserver/block';
import { createSelector } from 'reselect';

interface IMapboxBlockLayerProps {
    polygonId : string;
    strokeId : string;
    labelId : string;

    polygonBeforeId ?: string;
    strokeBeforeId ?: string;
    labelBeforeId ?: string;

    blocks : Array<IGeoServerBlock>;
    isLoading : boolean;

    minZoom ?: number;
    maxZoom ?: number;

    divisionGuid ?: string | null;
    subDivisionGuid ?: string | null;
    departmentGuid ?: string | null;
    fieldGuid ?: string | null;
    blockGuid ?: string | null;
    blockGuids ?: Array<string> | null;
    blockCodes ?: Array<string> | null;

    visible ?: boolean;
}

interface IMapboxBlockLayerState {}

class MapboxBlockLayerComponent extends React.PureComponent<IMapboxBlockLayerProps, IMapboxBlockLayerState> {
    constructor(props : IMapboxBlockLayerProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() : void {
        this.load();
    }

    private readonly load = () => {
        MasterDataGeoServerFunctions.getBlockList();
    };

    private readonly getBlocks = (props : IMapboxBlockLayerProps) => props.blocks;
    private readonly getDivisionGuid = (props : IMapboxBlockLayerProps) => props.divisionGuid;
    private readonly getSubDivisionGuid = (props : IMapboxBlockLayerProps) => props.subDivisionGuid;
    private readonly getDepartmentGuid = (props : IMapboxBlockLayerProps) => props.departmentGuid;
    private readonly getFieldGuid = (props : IMapboxBlockLayerProps) => props.fieldGuid;
    private readonly getBlockGuid = (props : IMapboxBlockLayerProps) => props.blockGuid;
    private readonly getBlockGuids = (props : IMapboxBlockLayerProps) => props.blockGuids;
    private readonly getBlockNames = (props : IMapboxBlockLayerProps) => props.blockCodes;

    private getFeatureCollection = createSelector(
        [
            this.getBlocks,
        ],
        (
            blocks,
        ) => {
            return {
                type: 'FeatureCollection',
                features: blocks
                    .map(x => ({
                        geometry: x.geometry,
                        properties: {
                            ...x,
                        },
                        type: 'Feature',
                    })),
            } as GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon, IGeoServerBlock>;
        },
    );

    private getFilter = createSelector(
        [
            this.getDivisionGuid,
            this.getSubDivisionGuid,
            this.getDepartmentGuid,
            this.getFieldGuid,
            this.getBlockGuid,
            this.getBlockGuids,
            this.getBlockNames,
        ],
        (
            divisionGuid,
            subDivisionGuid,
            departmentGuid,
            fieldGuid,
            blockGuid,
            blockGuids,
            blockCodes,
        ) => {
            const result : Array<unknown> = [];

            if (divisionGuid) {
                result.push(['==', ['literal', divisionGuid], ['get', 'divisionGuid']]);
            }

            if (subDivisionGuid) {
                result.push(['==', ['literal', subDivisionGuid], ['get', 'subDivisionGuid']]);
            }

            if (departmentGuid) {
                result.push(['==', ['literal', departmentGuid], ['get', 'departmentGuid']]);
            }

            if (fieldGuid) {
                result.push(['==', ['literal', fieldGuid], ['get', 'fieldGuid']]);
            }

            if (blockGuid) {
                result.push(['==', ['literal', blockGuid], ['get', 'guid']]);
            }

            if (blockGuids?.length) {
                result.push(['in', ['get', 'guid'], ['literal', blockGuids]]);
            }

            if (blockCodes?.length) {
                result.push(['in', ['get', 'code'], ['literal', blockCodes]]);
            }

            return [
                'all',
                ...result,
            ];
        },
    );

    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 21,
            labelId,
            strokeId,
            polygonId,
            labelBeforeId,
            polygonBeforeId,
            strokeBeforeId,
            visible = true,
        } = this.props;

        const blocks = this.getFeatureCollection(this.props);
        const filter = this.getFilter(this.props);
        return (
            <Source
                id='farm_blocks'
                type='geojson'
                data={blocks}
                maxzoom={maxZoom}
            >
                <Layer
                    {...{
                        id: polygonId,
                        type: 'fill',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'fill-color': ['get', 'color'],
                            'fill-opacity': 0.2,
                        },
                        filter,
                        beforeId: polygonBeforeId,
                    }}
                />
                <Layer
                    {...{
                        'id': strokeId,
                        'type': 'line',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'line-color': ['get', 'color'],
                            'line-width': 3,
                        },
                        filter,
                        beforeId: strokeBeforeId,
                    }}
                />
                <Layer
                    {...{
                        id: labelId,
                        type: 'symbol',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        layout: {
                            'text-field': [
                                'format',
                                [
                                    'get',
                                    'code',
                                ],
                                {
                                    'font-scale': 0.8,
                                },
                            ],
                            'text-justify': 'center',
                            'text-allow-overlap': true,
                            visibility: visible ? 'visible' : 'none',
                        },
                        paint: {
                            'text-opacity': 1,
                            'text-color': '#FFFFFF',
                            'text-halo-color': '#000000',
                            'text-halo-width': 2,
                            'text-halo-blur': 1,
                        },
                        filter,
                        beforeId: labelBeforeId,
                    }}
                />
            </Source>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.masterData.geoserver.blocks,
        isLoading: state.masterData.geoserver.isLoadingBlocks,
    };
};

const MapboxBlockLayer = connect(
    mapStateToProps,
)(MapboxBlockLayerComponent);

export default MapboxBlockLayer;