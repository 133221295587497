import { Tab, withStyles } from '@material-ui/core';

const SecondaryTab = withStyles({
    selected: {
        color: '#0D703F',
        fontWeight: 700,
        '&.enableBackground': {
            backgroundColor: '#B7C9BD',
        },
    },
    textColorInherit: {
        opacity: 1,
    },
})(Tab);

export default SecondaryTab;
