import React from 'react';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';
import lodash from 'lodash';
import firebaseApp from '../../../services/firebaseService';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import GeneralFunctions from '../../../store/general/functions';

interface IEmergencyPhotoView {
    imageUrl : string;
    title : string;
}

interface IScoutingEmergencyPhotosViewProps {
    value : IScoutingAssignment;
    
    entries : Array<IScoutingEntry>;
}

interface IScoutingEmergencyPhotosViewState {
    isLoading : boolean;

    photos : Array<IEmergencyPhotoView>;
}

export default class ScoutingEmergencyPhotosView extends React.PureComponent<IScoutingEmergencyPhotosViewProps, IScoutingEmergencyPhotosViewState> {
    constructor(props : IScoutingEmergencyPhotosViewProps) {
        super(props);
        this.state = {
            isLoading: true,
            photos: [],
        };
    }
    public componentDidMount = () => {
        this.loadEmergencyPhotos(this.props.entries);
    };

    public componentDidUpdate = (prevProps : Readonly<IScoutingEmergencyPhotosViewProps>) => {
        if (this.props.entries.length !== prevProps.entries.length) {
            this.loadEmergencyPhotos(this.props.entries);
        }
    };

    private readonly loadEmergencyPhotos = async (entries : Array<IScoutingEntry>) => {
        const result : Array<IEmergencyPhotoView> = [];
        const { value } = this.props;

        const sections = lodash.chain(entries)
            .flatMap(x => (x.sections.filter(z => !!z.photoPath).map(z => ({
                ...z,
                point: x.pointGuid,
            }))))
            .groupBy(x => `${x.point}_${x.section}_${x.entry}_${x.type}`)
            .map(x => x[0])
            .value();

        for (const section of sections) {
            try {
                let imageUrl = '';
                try {
                    imageUrl = await firebaseApp.storage().ref(section.photoPath ?? '').getDownloadURL();
                } catch {
                    // Lets wait 5sec and see if the image is uploaded now,
                    // If not, no need to display in notification!
                    await new Promise<void>((res) => {
                        setTimeout(() => {
                            firebaseApp.storage().ref(section.photoPath ?? '').getDownloadURL().then((url) => {
                                imageUrl = url;
                                res();
                            }).catch(() => {
                                res();
                            });
                        }, 5000);
                    });
                }

                result.push({
                    imageUrl,
                    title: `Point - ${value.scoutingBlock.pointsArray.indexOf(section.point) + 1} - ${section.type.toLocaleUpperCase()}`,
                });
            } catch (ex) {
                GeneralFunctions.generalShowErrorSnackbar('Error loading Emergency photos.', ex);
            }
        }

        this.setState({
            isLoading: false,
            photos: lodash.sortBy(result, x => x.title),
        });
    };

    public readonly onImageClick = (event : React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();

        window.open(event.currentTarget.id, '_blank');
    };

    public render = () => {
        const {
            photos,
        } = this.state;

        return (
            <>
                {
                    !!photos.length &&
                    <HorizontalExpander
                        title={'EMERGENCY PHOTOS'}
                        color={'primary'}
                        initiallyExpanded
                    >
                        <div className='fdr p20 fww jcse bcg0'>
                            {
                                photos.map((photo, i) => (
                                    <Card
                                        key={i}
                                        className='mb15'
                                    >
                                        <CardActionArea
                                            href={photo.imageUrl}
                                            target='_blank'
                                        >
                                            <CardMedia
                                                component='img'
                                                alt={photo.title}
                                                height='140'
                                                width={'230'}
                                                image={photo.imageUrl}
                                                title={photo.title}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant='h5' component='h2'>
                                                    {photo.title}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                    </Card>
                                ))
                            }
                        </div>
                    </HorizontalExpander>

                }
            </>
        );
    };
}
