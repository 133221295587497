import React from 'react';
import moment from 'moment';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createSelector } from 'reselect';
import Button from '@material-ui/core/Button';

interface IAssignmentWeekSelectorProps {
    date ?: moment.Moment;
    onChanged ?: (date ?: moment.Moment) => void;
}

interface IAssignmentWeekSelectorState {}

export default class AssignmentWeekSelector extends React.PureComponent<IAssignmentWeekSelectorProps, IAssignmentWeekSelectorState> {
    private readonly minWeek : number = moment.utc().isoWeek();
    private readonly minDate = moment.utc().startOf('day');
    constructor(props : IAssignmentWeekSelectorProps) {
        super(props);
        this.state = {};
    }

    private onWeekChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.value || parseInt(event.currentTarget.value) < this.minWeek) return;
        const week = parseInt(event.currentTarget.value);

        const date = week === this.minWeek ? moment.utc().startOf('day') : moment.utc().day('Sunday').isoWeek(week).startOf('isoWeek');

        if (this.props.onChanged) this.props.onChanged(date);
    };

    private onDateChange = (date : moment.Moment | null) => {
        if (this.props.onChanged && date) this.props.onChanged(date);
    };

    private onWeekClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!this.props.onChanged) return;
        const week = parseInt(event.currentTarget.value);
        const date = week === this.minWeek ? moment.utc().startOf('day') : moment.utc().day('Sunday').isoWeek(week).startOf('isoWeek');
        this.props.onChanged(date);
    };

    public getWeeks = createSelector(
        [],
        () => {

            const weeks = [];
            for (let i = 0; i <= 5; i++) {
                weeks[i] = moment.utc().day('Sunday').isoWeek(this.minWeek).add(i, 'week').isoWeek();
            }

            return weeks;
        },
    );

    public render = () => {
        const { date } = this.props;

        const weeks = this.getWeeks(this.props);

        return (
            <HorizontalExpander initiallyExpanded title={`WEEK - ${date?.format(DATEPICKER_FORMAT_DEFAULT)}`} color={date ? 'primary' : 'secondary'}>
                <div className='fdc p10 mb10'>
                    <div className='fdr'>
                        <div className='fdr flx1 aic jcfs mt5'>
                            <Typography className='mr10'
                                style={{
                                    font: '400 14px/19px Roboto',
                                    color: '#424242',
                                    textAlign: 'left',
                                }}>Week</Typography>
                            <TextField
                                id='week_number'
                                className='w80'
                                type='number'
                                value={date?.isoWeek() ?? ''}
                                onChange={this.onWeekChange}
                                inputProps={{
                                    min: this.minWeek.toString(),
                                    max: '52',
                                    style: {
                                        textAlign: 'center',
                                        font: '400 14px/37px Roboto',
                                        color: '#424242',
                                    },
                                }}
                            />
                        </div>
                        <div className='fdr flx1 aic jcfs'>
                            <KeyboardDatePicker
                                minDate={this.minDate}
                                margin='dense'
                                id='week_filter_date'
                                format={DATEPICKER_FORMAT_DEFAULT}
                                value={date}
                                onChange={this.onDateChange}
                                style={{
                                    marginTop: 0,
                                }}
                                label='Select Date'
                                inputProps={{
                                    style: {
                                        font: '400 14px/37px Roboto',
                                        color: '#424242',
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className='wfill aic jcfs mt10'>
                        <div style={{
                            width: '92%',
                            display: 'grid',
                            gridTemplateColumns: '33% 33% 33%',
                            gridTemplateRows: '29px 29px',
                            gridRowGap: 15,
                            gridColumnGap: 15,
                            alignItems: 'center',
                        }}>
                            {
                                weeks.map(n => (
                                    <Button key={`week_${n}`} value={n} onClick={this.onWeekClick} variant={date?.isoWeek() === n ? 'contained' : 'outlined'} color='primary'>
                                        {n}
                                    </Button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </HorizontalExpander>
        );
    };
}
