import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import { createSelector } from 'reselect';
import moment from 'moment';
import { Card, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AvoBugAssignmentHelper, IAvoBugAssignment } from '../../types/model/avoBug/avoBugAssignment';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { IUser } from '../../types/model/user';
import GeneralFunctions from '../../store/general/functions';
import DeleteDialog from '../customComponents/dialog/DeleteDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import AppFunctionsService from '../../services/appFunctionServices';
import MaterialTable from '../customComponents/materialTable/Table';
import ScoutingAssignmentDateFilter from '../customComponents/materialTable/ScoutingAssignmentDateFilter';
import ScoutingAssignmentEmployeeFilter from '../customComponents/materialTable/ScoutingAssignmentEmployeeFilter';
import ScoutingAssignmentLandNameFilter from '../customComponents/materialTable/ScoutingAssignmentLandNameFilter';
import ScoutingAssignmentBlockNameFilter from '../customComponents/materialTable/ScoutingAssignmentBlockNameFilter';
import StandardFab from '../customComponents/button/StandardFab';
import AvoBugActions from '../../store/avobug/actions';
import AvoBugFunctions from '../../store/avobug/functions';
import AvoBugAssignmentInfoDialog from './dialog/AsignmentInfo';

interface IAvoBugAssignmentListProps extends RouteComponentProps {
    isLoading : boolean;
    assignments : Array<IAvoBugAssignment>;

    setLoading : DispatchCall<boolean>;
}

interface IAvoBugAssignmentListState {
    date ?: moment.Moment;
    startedDate ?: moment.Moment;
    finishedDate ?: moment.Moment;
    employee ?: IUser;
    landName ?: string;
    blockName ?: string;

    selectedAssignments : Array<IAvoBugAssignment>;
}

class AvoBugAssignmentListComponent extends React.Component<IAvoBugAssignmentListProps, IAvoBugAssignmentListState> {
    constructor(props : IAvoBugAssignmentListProps) {
        super(props);
        this.state = {
            selectedAssignments: [],
        };
    }

    public readonly componentDidMount = () => {
        this.loadAssignments();
    };

    public readonly loadAssignments = (refresh ?: boolean) => {
        AvoBugFunctions.getAssignments(refresh);
    };

    private readonly getAssignments = (props : IAvoBugAssignmentListProps) => props.assignments;
    private readonly getDate = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.date;
    private readonly getStartedDate = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.startedDate;
    private readonly getFinishedDate = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.finishedDate;
    private readonly getEmployee = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.employee;
    private readonly getLandName = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.landName;
    private readonly getBlockName = (props : IAvoBugAssignmentListProps, state : IAvoBugAssignmentListState) => state.blockName;
    private readonly getFilteredAssignments = createSelector([
        this.getAssignments,
        this.getDate,
        this.getStartedDate,
        this.getFinishedDate,
        this.getEmployee,
        this.getLandName,
        this.getBlockName,
    ], (
        assignments,
        date,
        startedDate,
        finishedDate,
        employee,
        landName,
        blockName,
    ) => {
        return assignments
            .filter(n => !date || n.date === date.valueOf())
            .filter(n => !startedDate || (n.startedOn && moment.utc(n.startedOn).startOf('day').isSame(startedDate)))
            .filter(n => !finishedDate || moment.utc(n.finishedOn).startOf('day').isSame(finishedDate))
            .filter(n => !employee || employee.employeeNumber === n.employeeNumber || employee.name === n.employeeName)
            .filter(n => !landName || landName === n.landName)
            .filter(n => !blockName || blockName === n.blockName);
    });

    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            await AvoBugAssignmentHelper.bulkDelete([event.currentTarget.value]);
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignment', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    private readonly onDateChange = (date ?: moment.Moment) => {
        this.setState({
            date: date?.startOf('day'),
        });
    };

    private readonly onStartDateChange = (date ?: moment.Moment) => {
        this.setState({
            startedDate: date?.startOf('day'),
        });
    };

    private readonly onFinishedDateChange = (date ?: moment.Moment) => {
        this.setState({
            finishedDate: date?.startOf('day'),
        });
    };

    private readonly onEmployeeChange = (employee ?: IUser) => {
        this.setState({
            employee,
        });
    };

    private readonly onLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
    };

    private readonly onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockName: !blockNames.length ? undefined : blockNames[0],
        });
    };

    private readonly onSelectionChange = (selectedAssignments : Array<IAvoBugAssignment>) => {
        this.setState({
            selectedAssignments: selectedAssignments,
        });
    };

    private readonly onDeleteSelected = async () => {
        try {
            this.props.setLoading(true);
            await AvoBugAssignmentHelper.bulkDelete(this.state.selectedAssignments.map(x => x.id));
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
            this.onSelectionChange([]);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting assignments', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    public readonly render = () => {
        const { isLoading, assignments } = this.props;
        const {
            date,
            startedDate,
            finishedDate,
            employee,
            selectedAssignments,
            landName,
            blockName,
        } = this.state;

        const filteredAssignments = this.getFilteredAssignments(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc flx1 mh0 mw0'}>
                    <Toolbar>
                        <span className={'flx1'} />
                        <div className={'fdr jcfe aic'}>
                            <Typography className='fs16 fw700 cpd'>
                                Total: {assignments.length}
                            </Typography>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IAvoBugAssignment>
                            id='assignmentTable'
                            data={filteredAssignments}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedAssignments}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 145,
                                renderCell: row => (
                                    <div className='aic'>
                                        <AvoBugAssignmentInfoDialog value={row} fullWidth transition={Transitions.Up} maxWidth='md' fullScreen />
                                        <DeleteDialog
                                            maxWidth='md'
                                            isLoading={isLoading}
                                            message={`Delete assignment for ${row.landName} - ${row.blockName} on ${AppFunctionsService.formatDateTimeToDateOnly(row.date)}?`}
                                            onConfirm={this.onDelete} title={'Delete'} value={row.id} transition={Transitions.Down}
                                        />
                                    </div>
                                ),
                            }, {
                                header: 'Date',
                                field: 'date',
                                width: 120,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                                filtered: !!date,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={date} onChange={this.onDateChange} id='date_filter' />),
                            }, {
                                header: 'Started On',
                                field: 'startedOn',
                                enableSort: true,
                                enableFilter: true,
                                width: 140,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={startedDate} onChange={this.onStartDateChange} id='start_date_filter' />),
                                filtered: !!startedDate,
                                renderCell: row => (
                                    <span>
                                        {
                                            !!row.startedOn &&
                                            <Tooltip title={AppFunctionsService.formatDateTime(row.startedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.startedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            }, {
                                header: 'Finished On',
                                field: 'finishedOn',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filtered: !!finishedDate,
                                filterRender: () => (<ScoutingAssignmentDateFilter value={finishedDate}
                                    onChange={this.onFinishedDateChange} id='finished_date_filter' />),
                                renderCell: row => (
                                    <span>
                                        {
                                            !!row.finishedOn &&
                                            <Tooltip title={AppFunctionsService.formatDateTime(row.finishedOn)}>
                                                <div>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.finishedOn)
                                                    }
                                                </div>
                                            </Tooltip>
                                        }
                                    </span>
                                ),
                            }, {
                                header: 'Scout',
                                field: 'employeeName',
                                enableSort: true,
                                enableFilter: true,
                                width: 150,
                                renderCell: row => (
                                    <Tooltip title={row.employeeNumber}>
                                        <div>
                                            {row.employeeName}
                                        </div>
                                    </Tooltip>
                                ),
                                filtered: !!employee,
                                filterRender: () => (<ScoutingAssignmentEmployeeFilter value={employee}
                                    onChange={this.onEmployeeChange} id='employee_filter' />),
                            }, {
                                header: 'Land Name',
                                field: 'landName',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentLandNameFilter value={landName}
                                    onChange={this.onLandNameChange} id='land_filter' />),
                            }, {
                                header: 'Block Name',
                                field: 'blockName',
                                width: 160,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingAssignmentBlockNameFilter value={blockName}
                                    onChange={this.onBlockNameChange} id='block_filter' landName={landName} />),
                            }, {
                                header: 'KM Walked',
                                field: 'distance',
                                width: 100,
                                renderCell: row => (
                                    (row.distance / 1000).toPrecision(2)
                                ),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.createdOn)}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>),
                            }, {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={AppFunctionsService.formatDateTime(row.updatedOn)}>
                                        <span>{row.updatedByName}</span>
                                    </Tooltip>),
                            }]}
                        />
                    </Card>
                    {
                        !!selectedAssignments.length &&
                        <DeleteDialog maxWidth='md' disabled={!selectedAssignments.length} isLoading={isLoading}
                            message={`Delete ${selectedAssignments.length} assignments?`}
                            onConfirm={this.onDeleteSelected} title={'Delete'} transition={Transitions.Down}
                        >
                            {
                                onClick => (
                                    <StandardFab aria-label='add' className='cw bcr mr80' disabled={isLoading} onClick={onClick}>
                                        <Delete />
                                    </StandardFab>
                                )
                            }
                        </DeleteDialog>
                    }
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.avoBug.isLoadingAssignments,
        assignments: state.avoBug.assignments,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(AvoBugActions.setLoadingAssignments(isLoading)),
}, dispatcher);

const AvoBugAssignmentList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AvoBugAssignmentListComponent));

export default AvoBugAssignmentList;