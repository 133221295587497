import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IRootState } from '../../../../@types/redux';
import { createSelector } from 'reselect';
import { CROP } from '../../../../appConstants';
import { CircularProgress, Paper } from '@material-ui/core';
import ScoutingFunctions from '../../../../store/scouting/functions';
import BasicMap from '../../../customComponents/mapBox/BasicMap';
import GeneralScoutingAssignmentFieldSelector from './AssignmentFieldSelector';
import { IAssignmentBlock } from '../../../../types/model/masterData/block';
import moment from 'moment';
import { IDivision } from '../../../../types/model/rights/division';
import { ScoutingType } from '../../../../types/model/scouting/scoutingAssignment';
import SpecificScoutingAssignmentAlertDialog from '../../dialog/SpecificScoutingAlertDialog';
import ScoutingAssignmentCreateDialog from '../../dialog/AssignmentCreateDialog';
import NavFunctions from '../../../../store/nav/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';
import { MapBoxRef } from '../../../customComponents/mapBox/MapBox';
import MapboxPointLayer from '../../../customComponents/mapBox/layer/masterData/PointLayer';

interface IScoutingAssignmentCreateProps extends RouteComponentProps {
    isLoading : boolean;
}

interface IScoutingAssignmentCreateState {
    selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>;

    date : moment.Moment;
    division ?: IDivision;
    
    scoutType ?: ScoutingType;

    sections : Array<string>;
    components : Array<string>;
    insects : Array<string>;
    diseases : Array<string>;
    damages : Array<string>;

    showCreate : boolean;
}

class ScoutingAssignmentCreateComponent extends React.PureComponent<IScoutingAssignmentCreateProps, IScoutingAssignmentCreateState> {
    private mapRef : MapBoxRef | null = null;
    
    constructor(props : IScoutingAssignmentCreateProps) {
        super(props);
        this.state = {
            selectedScoutingBlocks: {},
            date: moment.utc().startOf('day'),
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
            showCreate: false,
        };
    }

    public readonly componentDidMount = () => {
        ScoutingFunctions.getAssignments(this.getCrop(this.props), false);
    };
    
    private readonly getSearch = (props : IScoutingAssignmentCreateProps) => props.location.search;
    private readonly getScoutingBlocks = (state : IScoutingAssignmentCreateState) => state.selectedScoutingBlocks;

    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });
    
    private readonly getGuids = createSelector([
        this.getScoutingBlocks,
    ], (
        blocks,
    ) => {
        const selectedGuids = Object
            .values(blocks)
            .flatMap(x => x)
            .filter(x => x.guid)
            .map(n => n.guid);


        return [...selectedGuids];
    });

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
    };

    private readonly onWeekChanged = (date ?: moment.Moment) => {
        if (!date) return;

        this.setState({
            date,
        });
    };

    private readonly onDivisionChanged = (division ?: IDivision) => {
        this.setState({
            division,
            selectedScoutingBlocks: {},
        });
    };

    private readonly onScoutTypeChange = (scoutType ?: ScoutingType) => {
        this.setState({
            scoutType,
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    };

    private readonly onSectionsChange = (sections ?: Array<string> | null) => {
        this.setState({
            sections: sections ?? [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    };

    private readonly onComponentsChange = (components ?: Array<string> | null) => {
        this.setState({
            components: components ?? [],
            insects: [],
            diseases: [],
            damages: [],
        });
    };

    private readonly onInsectsChange = (insects ?: Array<string> | null) => {
        this.setState({
            insects: insects ?? [],
        });
    };

    private readonly onDiseasesChange = (diseases ?: Array<string> | null) => {
        this.setState({
            diseases: diseases ?? [],
        });
    };

    private readonly onDamagesChange = (damages ?: Array<string> | null) => {
        this.setState({
            damages: damages ?? [],
        });
    };

    private readonly onFieldsChange = (selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>) => {
        this.setState({
            selectedScoutingBlocks,
        });
    };

    private readonly onContinueClick = () => {
        this.setState({
            scoutType: 'general',
            sections: [],
            components: [],
            insects: [],
            diseases: [],
            damages: [],
        });
    };

    private readonly onAssignmentCreateClose = (result : boolean) => {
        this.setState({
            showCreate: !this.state.showCreate,
        });

        if (result) {
            NavFunctions.navReplacePath('/scouting/assignments', this.props.location.search);
        }
    };

    private readonly onAlertChange = (
        sections : Array<string>,
        components : Array<string>,
        insects : Array<string>,
        diseases : Array<string>,
        damages : Array<string>
    ) => {
        this.setState({
            sections,
            components,
            insects,
            diseases,
            damages,
        });
    };

    private readonly onAssignmentAddClick = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };
    
    public readonly render = () => {
        const {
            isLoading,
        } = this.props;
        
        const {
            selectedScoutingBlocks,
            date,
            division,
            scoutType,
            sections,
            components,
            insects,
            diseases,
            damages,
            showCreate,
        } = this.state;

        const crop = this.getCrop(this.props);
        const blockGuids = this.getGuids(this.state);

        return (
            <div className={'fdc hfill p10 mh0 mw0 bcg0'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <div className='fdr hfill'>
                        <Paper className={'fdc flx3 p5 m5'}>
                            <BasicMap
                                id={'scoutingMap'}
                                onMap={this.setMapRef}
                                blockGuids={blockGuids}
                            >
                                <MapboxPointLayer
                                    pointsId='point_farm_layer'
                                    minZoom={15}
                                    type='scouting'
                                    crop={crop}
                                    blockGuids={blockGuids}
                                />
                            </BasicMap>
                        </Paper>
                        <GeneralScoutingAssignmentFieldSelector
                            mapRef={this.mapRef}
                            crop={crop}
                            selectedScoutingBlocks={selectedScoutingBlocks}
                            date={date}
                            onChange={this.onFieldsChange}
                            onWeekChanged={this.onWeekChanged}
                            division={division}
                            onDivisionChanged={this.onDivisionChanged}
                            scoutType={scoutType}
                            onScoutTypeChange={this.onScoutTypeChange}
                            sections={sections}
                            components={components}
                            insects={insects}
                            diseases={diseases}
                            damages={damages}
                            onSectionsChange={this.onSectionsChange}
                            onComponentsChange={this.onComponentsChange}
                            onInsectsChange={this.onInsectsChange}
                            onDiseasesChange={this.onDiseasesChange}
                            onDamagesChange={this.onDamagesChange}
                            onAssignmentAddClick={this.onAssignmentAddClick}
                        />
                    </div>
                }
                {
                    scoutType === 'specific' &&
                    <SpecificScoutingAssignmentAlertDialog
                        selectedSections={sections}
                        selectedComponents={components}
                        selectedInsects={insects}
                        selectedDiseases={diseases}
                        selectedDamages={damages}
                        onContinueClick={this.onContinueClick}
                        onChange={this.onAlertChange}
                    />
                }
                {
                    scoutType &&
                    <ScoutingAssignmentCreateDialog
                        open={showCreate}
                        isLoading={isLoading}
                        date={date}
                        division={division?.code}
                        transition={Transitions.Down}
                        maxWidth='md' fullWidth
                        onClose={this.onAssignmentCreateClose}
                        scoutingBlocks={selectedScoutingBlocks}
                        scoutType={scoutType}
                        sections={sections}
                        components={components}
                        insects={insects}
                        diseases={diseases}
                        damages={damages}
                        crop={crop}
                    />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.scouting.isLoadingAssignments,
    };
};

const ScoutingAssignmentCreate = connect(
    mapStateToProps,
)(withRouter(ScoutingAssignmentCreateComponent));

export default ScoutingAssignmentCreate;