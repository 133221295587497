import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IPhenologyAssignment } from '../../../types/model/phenology/assignment';
import { IPhenologyEntry, PhenologyEntryHelper } from '../../../types/model/phenology/entry';
import PhenologyEntryView from './PhenologyEntryView';
import GeneralFunctions from '../../../store/general/functions';

interface IPhenologyAssignmentCompletedHistoryViewProps {
    value : IPhenologyAssignment;
}

interface IPhenologyAssignmentCompletedHistoryViewState {
    isLoading : boolean;

    entries : Array<IPhenologyEntry | null>;
}

export default class PhenologyAssignmentCompletedHistoryView extends React.PureComponent<IPhenologyAssignmentCompletedHistoryViewProps, IPhenologyAssignmentCompletedHistoryViewState> {

    private listener ?: () => void;
    constructor(props : IPhenologyAssignmentCompletedHistoryViewProps) {
        super(props);
        this.state = {
            isLoading: true,
            entries: [],
        };
    }

    public componentDidMount = () => {
        this.loadLocations();
    };

    public componentWillUnmount = () => {
        if (this.listener) {
            this.listener();
        }
    };

    private loadLocations = () => {        
        this.listener = PhenologyEntryHelper.collection(this.props.value.id).orderBy('createdOn', 'asc').onSnapshot((snapshot) => {
            const entries : Array<IPhenologyEntry | null> = [];
            try {

                snapshot.docs.forEach(n => {
                    const data = n.data();
                    entries.push(data);
                });
    
                const notDonePoints = this.props.value.scoutingBlock.phenologyPoints.features
                    .filter(feature => !entries.some(x => x?.pointGuid === feature.properties.guid));
    
                notDonePoints.forEach(() => {
                    entries.push(null);
                });
            } catch (ex) {
                GeneralFunctions.generalShowErrorSnackbar('Could not load entries.', ex);
            } finally {
                this.setState({
                    isLoading: false,
                    entries: entries,
                });

            }
            

        }, (ex) => {
            GeneralFunctions.generalShowErrorSnackbar('Could not load entries.', ex);
            this.setState({
                isLoading: false,
                entries: [],
            });
        });
    };

    public render = () => {
        const { isLoading, entries } = this.state;
        const { value } = this.props;

        return (
            <div className={'fdc ais flx1'}>
                <div className={'fdc h5 posr'}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
                {

                    entries.map((entry, i) => (
                        <PhenologyEntryView
                            key={`${i}_phenology_point`}
                            index={i}
                            value={entry}
                            assignment={value}
                        />
                    ))
                }
            </div>);
    };
}
