import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, Switch, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Transitions } from '../../../../customComponents/animations/Transitions';
import { Close, Save } from '@material-ui/icons';
import GeneralFunctions from '../../../../../store/general/functions';
import { ITrapFarmDescription } from '../../../../../types/model/masterData/trap/farmDescription';
import TrapFarmDescriptionFunctions from '../../../../../store/masterData/trap/farmDescription/functions';

interface ITrapFarmDescriptionEditDialogProps {
    value : ITrapFarmDescription | null;

    isLoading ?: boolean;

    onClose ?: () => void;
}

interface ITrapFarmDescriptionEditDialogState {
    description : string;
    isActive : boolean;
}

export default class TrapFarmDescriptionEditDialog extends React.PureComponent<ITrapFarmDescriptionEditDialogProps, ITrapFarmDescriptionEditDialogState> {
    constructor(props : ITrapFarmDescriptionEditDialogProps) {
        super(props);
        this.state = {
            description: '',
            isActive: true,
        };
    }

    public componentDidUpdate(prevProps : Readonly<ITrapFarmDescriptionEditDialogProps>) : void {
        if (!prevProps.value && this.props.value) {
            this.setState({
                description: this.props.value.description,
                isActive: this.props.value.isActive,
            });
        }
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            description: e.target.value,
        });
    };

    private readonly save = async () => {
        try {
            const {
                value,
            } = this.props;
            if (!value) return;
    
            const {
                description,
                isActive,
            } = this.state;

            if (!description) return;
    
            await TrapFarmDescriptionFunctions.save({
                ...value,
                description,
                isActive,
            });

            if (this.props.onClose) this.props.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Saved');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving description', ex);
        }
    };

    private readonly onSaveClick = () => {
        this.save();
    };

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };

    public render = () => {
        const {
            value,
            isLoading,
            onClose,
        } = this.props;

        const {
            description,
            isActive,
        } = this.state;

        return (
            <Dialog
                open={!!value}
                TransitionComponent={Transitions.Down}
                transitionDuration={500}
                maxWidth={'md'}
                fullWidth
                aria-labelledby='edit-trap-farm-description-dialog-title'
                aria-describedby='edit-trap-farm-description-description'>
                <AppBar className='fdr posr aic jcc bcpd' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography>
                            {value?.id ? 'EDIT' : 'ADD'} FARM DESCRIPTION
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr ais jcfs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    required
                                    error={!description}
                                    autoFocus
                                    margin='dense'
                                    id='description'
                                    label='Description'
                                    type='text'
                                    fullWidth
                                    value={description}
                                    onChange={this.onNameChange}
                                    disabled={isLoading}
                                />
                                {
                                    !description &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <Save style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
