import React from 'react';
import { ITrapComment, TrapCommentHelper } from '../../../types/model/trap/trapComment';
import { Card, CircularProgress, Divider, Tooltip, Typography } from '@material-ui/core';
import AppFunctionsService from '../../../services/appFunctionServices';
import StadiumDiv from '../../customComponents/div/Stadium';
import { IUserSession } from '../../../types/model/user';
import StandardFab from '../../customComponents/button/StandardFab';
import AddTrapCommentDialog from '../dialog/TrapCommentDialog';
import ModeComment from '../../customComponents/icons/ModeComment';
import GeneralFunctions from '../../../store/general/functions';

interface ITrapCommentsViewProps {
    trapId : string;
    
    session ?: IUserSession | null;
}

interface ITrapCommentsViewState {
    isLoading : boolean;

    comments : Array<ITrapComment>;
    openAdd : boolean;
}

export default class TrapCommentsView extends React.PureComponent<ITrapCommentsViewProps, ITrapCommentsViewState> {
    private listener ?: () => void;

    constructor(props : ITrapCommentsViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            comments: [],
            openAdd: false,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    public readonly componentWillUnmount = () => {
        if (this.listener) this.listener();
    };

    private readonly load = () => {
        if (this.listener) return;

        this.setState({
            isLoading: true,
        });

        this.listener = TrapCommentHelper.collection(this.props.trapId).orderBy('date', 'desc').onSnapshot((snapshot) => {
            const comments = this.state.comments.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                switch (f.type) {
                    case 'added':
                        comments.splice(f.newIndex, 0, f.doc.data());
                        break;
                    case 'modified':
                        comments.splice(f.oldIndex, 1);
                        comments.splice(f.newIndex, 1, f.doc.data());
                        break;
                    case 'removed':
                        comments.splice(f.oldIndex, 1);
                        break;
                }
            });

            this.setState({
                comments,
                isLoading: false,
            });
        }, (ex) => {
            GeneralFunctions.generalShowErrorSnackbar('Error loading comments', ex);
        });
    };

    public readonly onAddClick = () => {
        this.setState({
            openAdd: true,
        });
    };

    public readonly onAddClose = () => {
        this.setState({
            openAdd: false,
        });
    };

    public render = () => {
        const {
            session,
            trapId,
        } = this.props;

        const {
            isLoading,
            comments,
            openAdd,
        } = this.state;

        return (
            <div className='fdc flx1 pt20 bcg0'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !comments.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography>No Data</Typography>
                    </div>
                }
                {
                    !isLoading &&
                    comments.length &&
                    <div className='ml15 mr15 pb80'>
                        {
                            comments.map(x => (
                                <Card key={x.id} className={'fdc bcw mt30'}>
                                    <div className={'fdr flx1 bcpd cw mb10 p10'}>
                                        <Typography className={'fdr aic jcfs flx1 fw600 fs16'}>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(x.date)
                                            }
                                        </Typography>
                                    </div>
                                    <div className={'fdc ml7 mr7'}>
                                        <div className='fdc'>
                                            <div className='fdr bcg0 pl10 mb10'>
                                                <div className='fdc flx1'>
                                                    <Typography className='fw700 cdg fs18 mt5 mb5'>
                                                        {
                                                            x.createdByName
                                                        }
                                                    </Typography>
                                                    <Typography className='cpd fs12 mb10'>
                                                        {
                                                            AppFunctionsService.formatDateTimeToMMMDDHHmm(x.date)
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className='fdc jcc aic mr10'>
                                                    
                                                    <StadiumDiv className=''>
                                                        {
                                                            x.type
                                                        }
                                                    </StadiumDiv>
                                                </div>
                                            </div>
                                            <div className='fdr ml10 mr10 mb10'>
                                                <Divider className='bcp flx1 h2' />
                                            </div>
                                            <div className='fdc ml10 mr10 mb10'>
                                                {
                                                    /** This is ugly but needed for displaying new lines... */
                                                    x.comment.split('\n').map(n => (
                                                        <Typography key={n} className='fs15 cg3' component='p'>
                                                            {
                                                                n
                                                            }
                                                        </Typography>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))
                        }
                    </div>
                }
                {
                    !isLoading &&
                    session &&
                    <div className='fdr aife jcfe'>
                        <Tooltip title='Add'>
                            <div>
                                <StandardFab onClick={this.onAddClick}>
                                    <ModeComment />
                                </StandardFab>
                            </div>
                        </Tooltip>
                    </div>
                }
                {
                    trapId &&
                    session &&
                    <AddTrapCommentDialog
                        open={openAdd}
                        maxWidth='md'
                        onClose={this.onAddClose}
                        fullWidth
                        session={session}
                        trapId={trapId}
                    />
                }
            </div>
        );
    };
}
