import GeneralActions from '../general/actions';
import { dispatch } from '../../store';

export default class GeneralFunctions {
    public static generalShowErrorSnackbar = (message : string, ex ?: Error | unknown) => {
        let errorMessage = message;

        if (typeof(ex) === 'object' && ex instanceof Error) {
            if (ex.message && typeof(ex.message) === 'string') {
                errorMessage = ex.message;
            }
        } else if (typeof(ex) === 'string') {
            errorMessage += ` ${ex}`;
        }

        dispatch(GeneralActions.enqueueSnackbar({
            message: errorMessage,
            options: {
                variant: 'error',
            },
        }));
    };

    public static generalShowSuccessSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'success',
            },
        }));
    };

    public static generalShowWarningSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'warning',
            },
        }));
    };

    public static generalShowInfoSnackbar = (message : string) => {
        dispatch(GeneralActions.enqueueSnackbar({
            message,
            options: {
                variant: 'info',
            },
        }));
    };

    public static generalRemoveSnackbar = (key : number) => {
        dispatch(GeneralActions.removeSnackbar(key));
    };

    public static showPrintError(errorLocation : string, error : Error) {
        GeneralFunctions.generalShowErrorSnackbar(error.message);
    }
}
