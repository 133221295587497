import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ScoutingFunctions from '../../../../../store/scouting/functions';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import ScoutingBlockLandNameDropdown from '../../../../customComponents/dropdowns/BlockLandNameDropdown';
import ScoutingBlockNameDropdown from '../../../../customComponents/dropdowns/BlockNameDropdown';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { IUser } from '../../../../../types/model/user';
import EmployeeDropdown from '../../../../customComponents/dropdowns/EmployeeDropdown';
import { IAssignmentBlock } from '../../../../../types/model/masterData/block';
import { CROP } from '../../../../../appConstants';
import DivisionAutocomplete from '../../../../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../../../../types/model/rights/division';
import BasicMap from '../../../../customComponents/mapBox/BasicMap';
import { MapBoxRef } from '../../../../customComponents/mapBox/MapBox';
import { createSelector } from 'reselect';
import MasterDataGeoServerFunctions from '../../../../../store/masterData/geoserver/functions';
import MapboxBlockPointLayer from '../../../../customComponents/mapBox/layer/masterData/PointLayer';

interface IScoutingGeneralAssignmentCreateDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    /**
     * Default division to use when creating assignment.
     */
    division ?: string;

    /**
     * Default Land name to use when creating assignment.
     */
    landName ?: string;

    /**
     * Default Block Names to use when creating assignment.
     */
    blockNames ?: Array<string>;

    crop : CROP;

    disabled ?: boolean;
}

interface IScoutingGeneralAssignmentCreateDialogState {
    open : boolean;

    division ?: string;
    landName ?: string;

    date : Moment;
    /**
     * Employee Id
     */
    employee ?: string;
    employeeName ?: string;
    employeeNumber ?: string;
    scoutingBlocks : Array<IAssignmentBlock>;
}

class ScoutingGeneralAssignmentCreateDialogComponent extends React.PureComponent<IScoutingGeneralAssignmentCreateDialogProps, IScoutingGeneralAssignmentCreateDialogState> {
    private mapRef ?: MapBoxRef;
    constructor(props : IScoutingGeneralAssignmentCreateDialogProps) {
        super(props);
        this.state = {
            open: false,
            scoutingBlocks: [],
            date: moment.utc().startOf('day'),
        };
    }

    public componentDidMount() : void {
        MasterDataGeoServerFunctions.getPointList();
    }

    private readonly onIconButtonClick = () => {        
        // const blocks = this.props.blocks
        //     .filter(n => n.landName === this.props.landName)
        //     .filter(n => !this.props.blockNames?.length || this.props.blockNames.includes(n.name));

        // this.setState({
        //     division: this.props.division,
        //     landName: this.props.landName,
        //     date: moment.utc().startOf('day'),
        //     employee: undefined,
        //     employeeNumber: undefined,
        //     employeeName: undefined,
        //     scoutingBlocks: blocks,
        // }, () => this.setState({
        //     open: true,
        // }, () => this.mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds(this.state.scoutingBlocks), {
        //     minZoom: 15,
        //     animate: false,
        // })));
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    };

    private readonly save = async () => {
        const { date, scoutingBlocks, employee, employeeName, employeeNumber } = this.state;

        if (!employee || !employeeName || !employeeNumber) return;
        if (!scoutingBlocks.length) return;

        const result = await ScoutingFunctions.saveAssignment(
            date,
            employee,
            employeeNumber,
            employeeName,
            scoutingBlocks,
        );

        if (result) {
            this.onClose();
        }
    };

    private readonly onDateChange = (momentDate : Moment | null) => {
        if (!momentDate) return;
        this.setState({
            date: momentDate.utc().startOf('day'),
        });
    };

    private readonly onEmployeeChange = (employee ?: IUser) => {
        if (!employee) return;
        this.setState({
            employee: employee.ref,
            employeeName: employee.name,
            employeeNumber: employee.employeeNumber,
        });
    };

    private readonly onBlockChange = (blocks : Array<IAssignmentBlock>) => {
        this.setState({
            scoutingBlocks: blocks.slice(),
        });
    };

    private readonly onBlockLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
        this.onBlockChange([]);
    };

    private readonly onBlockNameChange = (blocks : Array<IAssignmentBlock>) => {
        this.onBlockChange(blocks);
    };

    private readonly onDivisionChange = (division ?: IDivision) => {
        this.setState({
            division: division?.code,
            landName: undefined,
        });
        this.onBlockChange([]);
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
    };

    private readonly getScoutingBlocks = (state : IScoutingGeneralAssignmentCreateDialogState) => state.scoutingBlocks;
    private readonly getBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (
        blocks,
    ) => {
        return blocks.map(n => n.name);
    });


    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            crop,
            disabled,
        } = this.props;
        const {
            open,
            division,
            landName,
            date,
            employee,
            scoutingBlocks,
        } = this.state;

        const blockNames = this.getBlockNames(this.state);

        return (
            <React.Fragment>
                <Tooltip title={'Add'}>
                    <div>
                        <IconButton color='primary' onClick={this.onIconButtonClick}
                            aria-label={'Add'} disabled={disabled || isLoading}>
                            <Icon>{'add'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='scouting-assignment-dialog-title'
                    aria-describedby='scouting-assignment-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Create Assignment
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc ais flx1'>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        value={date}
                                        onChange={this.onDateChange}
                                        format='YYYY-MM-DD'
                                        label='Date'
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <DivisionAutocomplete
                                        required
                                        fullWidth
                                        value={division}
                                        onChange={this.onDivisionChange}
                                        crop={crop}
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <EmployeeDropdown
                                        value={employee}
                                        fullWidth
                                        required
                                        onChange={this.onEmployeeChange}
                                        division={division}
                                        disabled={!division}
                                    />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockLandNameDropdown
                                        crop={crop}
                                        value={landName}
                                        disabled={!division}
                                        division={division}
                                        fullWidth
                                        required
                                        onChange={this.onBlockLandNameChange}
                                        type='scouting'
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockNameDropdown
                                        value={blockNames}
                                        landName={landName}
                                        disabled={!division || !landName}
                                        division={division}
                                        fullWidth
                                        required
                                        onChange={this.onBlockNameChange}
                                        type='scouting'
                                    />
                                </div>
                            </div>
                            <div className={'h280 fdc'}>
                                <BasicMap
                                    id={'scoutingMap'}
                                    onMap={this.setMapRef}
                                >
                                    <MapboxBlockPointLayer
                                        pointsId='point_farm_layer'
                                        minZoom={15}
                                        type='scouting'
                                        blockGuids={blockNames}
                                    />
                                </BasicMap>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !scoutingBlocks.length || !employee} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.scouting.isLoadingAssignments,
    };
};

const ScoutingGeneralAssignmentCreateDialog = connect(
    mapStateToProps,
)(ScoutingGeneralAssignmentCreateDialogComponent);

export default ScoutingGeneralAssignmentCreateDialog;
