import { ActionType, getType } from 'typesafe-actions';
import MasterDataGeoServerActions from './actions';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint } from '../../../types/model/masterData/geoserver/point';

export interface IMasterDataGeoServerState {
    blocks : Array<IGeoServerBlock>;
    isLoadingBlocks : boolean;

    points : Array<IGeoServerPoint>;
    isLoadingPoints : boolean;

}

const initialState : IMasterDataGeoServerState = {
    blocks: [],
    isLoadingBlocks: false,
    points: [],
    isLoadingPoints: false,
};

export default function masterDataGeoServerReducer(state : IMasterDataGeoServerState = initialState, action : ActionType<typeof MasterDataGeoServerActions>) : IMasterDataGeoServerState {
    switch (action.type) {
        case getType(MasterDataGeoServerActions.setBlockList):
            return { ...state, blocks: action.payload };
        case getType(MasterDataGeoServerActions.setLoadingBlockList):
            return { ...state, isLoadingBlocks: action.payload };
        case getType(MasterDataGeoServerActions.setPointList):
            return { ...state, points: action.payload };
        case getType(MasterDataGeoServerActions.setLoadingPointList):
            return { ...state, isLoadingPoints: action.payload };
        default:
            return state;
    }
}