import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import FirestoreHelper from '../../../services/helper/firestoreHelper';
import { BaseHelper, IBase } from '../../base';
import BlockHelper, { IAssignmentBlock } from '../masterData/block';
import UserHelper from '../user';
import lodash from 'lodash';
import { PhenologyLocationEntryHelper } from './locationEntry';
import { PhenologyEntryHelper } from './entry';
import firebase from 'firebase/app';

export type PHENOLOGY_ASSIGNMENT_TYPE = 'Farmer Survey' | 'Specific';

export interface IPhenologyAssignment extends IBase {
    employeeName : string;
    employeeNumber : string;

    /**
     * Employee Id
     */
    employee : string;

    /**
     * Date in Millis
     */
    date : number;

    type : PHENOLOGY_ASSIGNMENT_TYPE;

    /**
     * Block Id
     */
    block : string;
    blockName : string;
    landName : string;
    division : string;
    crop : CROP;
    finishedPoints : Record<string, Array<string>>;
    finished : boolean;

    /**
     * Side is the key and DateTime in milliseconds is the value
     */
     startedOn : Record<string, number>;

    /**
     * Side is the key and DateTime in milliseconds is the value
     */
    finishedOn : Record<string, number>;
    
    scoutingBlock : IAssignmentBlock;
    distance : number | null;

    totalDuration : number | null;

    directions : Array<string>;
}

export class PhenologyAssignmentHelper extends BaseHelper {
    public static readonly DIRECTIONS = ['MORNING SUN', 'AFTERNOON SUN'];
    public static readonly COLLECTION = 'phenology_assignments';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenologyAssignment | null> = {
        fromFirestore: (snapshot) => {
            return PhenologyAssignmentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IPhenologyAssignment) => {
            return PhenologyAssignmentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenologyAssignment | null {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) return null;

        return {
            ...data as IPhenologyAssignment,
            ...result,
            employee: (data['employee'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
            block: typeof data['block'] === 'string' ? data['block'] : (data['block'] as firebase.firestore.DocumentReference).id,
            finishedOn: lodash.transform((data['finishedOn'] ?? {}) as Record<string, firebase.firestore.Timestamp>, (r, v, k) => {
                r[k] = v.toMillis();
            }),
            scoutingBlock: BlockHelper.fromFirestoreData(data['scoutingBlock']),
            type: data['type'] === 'General' ? 'Farmer Survey' : data['type'],
            startedOn: lodash.transform((data['startedOn'] ?? {}) as Record<string, firebase.firestore.Timestamp>, (r, v, k) => {
                r[k] = v.toMillis();
            }),
            totalDuration: data['totalDuration'] ?? null,
            directions: data['directions'] ?? PhenologyAssignmentHelper.DIRECTIONS,
        };
    }

    protected static toFirestore(data : IPhenologyAssignment) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;

        return {
            ...rest,
            ...result,
            date: FirestoreHelper.millisToTimestamp(data.date),
            employee: firebaseApp.firestore().collection(UserHelper.USER_COLLECTION).doc(data.employee),
            scoutingBlock: BlockHelper.toFirestoreData(data.scoutingBlock),
            distance: data.distance ?? null,
            totalDuration: data.totalDuration ?? null,
            finishedOn: lodash.transform<number, Record<string, firebase.firestore.Timestamp>>(data.finishedOn, (r, v, k) => {
                r[k] = FirestoreHelper.millisToTimestamp(v);
            }),
            startedOn: lodash.transform<number, Record<string, firebase.firestore.Timestamp>>(data.startedOn, (r, v, k) => {
                r[k] = FirestoreHelper.millisToTimestamp(v);
            }),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    private static async onBatchDelete(batch : firebase.firestore.WriteBatch, id : string) {
        const entries = await PhenologyEntryHelper.collection(id).get();
        const locations = await PhenologyLocationEntryHelper.collection(id).get();

        for (const entryDoc of entries.docs) {
            batch.delete(entryDoc.ref);
        }

        for (const locationDoc of locations.docs) {
            batch.delete(locationDoc.ref);
        }

        batch.delete(this.collection().doc(id));
    }

    public static async bulkDelete(ids : Array<string>) {
        const currentIds = ids.slice();

        while (currentIds.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteIds = currentIds.splice(0, 50);
            
            await Promise.all(deleteIds.map((id) => this.onBatchDelete(batch, id)));

            batch.commit();
        }
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : IPhenologyAssignment) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }
}