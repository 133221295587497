import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { IAvoBugAssignment } from '../../../types/model/avoBug/avoBugAssignment';
import SecondaryTabs from '../../customComponents/tab/SecondaryTabs';
import SecondaryTab from '../../customComponents/tab/SecondaryTab';
import TabViews from '../../customComponents/tab/TabViews';
import AvoBugAssignmentResultView from './AssignmentResultView';

interface IAvoBugAssignmentInfoResultViewProps {
    value : IAvoBugAssignment;
}

interface IAvoBugAssignmentInfoResultViewState {
    tabIndex : number;
}

class AvoBugAssignmentInfoResultViewComponent extends React.PureComponent<IAvoBugAssignmentInfoResultViewProps, IAvoBugAssignmentInfoResultViewState> {
    constructor(props : IAvoBugAssignmentInfoResultViewProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };
    
    private readonly getResults = (props : IAvoBugAssignmentInfoResultViewProps) => props.value.results;

    private readonly getThresholds = createSelector(
        [this.getResults],
        (results) => {
            return lodash
                .chain(Object.keys(results))
                .uniq()
                .value();
        },
    );

    private readonly getBugs = createSelector(
        [this.getResults],
        (results) => {
            return lodash
                .chain(Object.keys(results))
                .map((thresold) => results[thresold])
                .map((treesObject) => Object.values(treesObject))
                .flatMap()
                .map((result) => Object.keys(result.result))
                .flatMap()
                .uniq()
                .value();
        },
    );

    public readonly render = () => {
        const { tabIndex } = this.state;
        const { value } = this.props;

        const thresholds = this.getThresholds(this.props);
        const bugs = this.getBugs(this.props);

        return (
            <div className='fdc flx1'>
                <SecondaryTabs
                    value={tabIndex}
                    onChange={this.onTabChange}
                    aria-label='Thresholds Tabs'
                    variant='fullWidth'
                >
                    {
                        thresholds.map((threshold) => (
                            <SecondaryTab key={threshold} label={threshold} />
                        ))
                    }
                </SecondaryTabs>
                <TabViews index={tabIndex}>
                    {
                        thresholds.map((threshold) => (
                            <AvoBugAssignmentResultView key={threshold} value={value.results[threshold]} bugs={bugs} />
                        ))
                    }
                </TabViews>
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

const AvoBugAssignmentInfoResultView = connect(
    mapStateToProps,
)(AvoBugAssignmentInfoResultViewComponent);


export default AvoBugAssignmentInfoResultView;