import React from 'react';
import { createSelector } from 'reselect';
import ToggleButton from '../button/ToggleButton';

interface IStadiumToggleButtonSelectorProps<T> {
    value ?: T | Array<T> | null;
    values : Array<T>;
    onChange ?: (value : T) => void;

    className ?: string;

    disabled ?: boolean;
}

interface IStadiumToggleButtonSelectorState {}

export default class StadiumToggleButtonSelector<T> extends React.PureComponent<IStadiumToggleButtonSelectorProps<T>, IStadiumToggleButtonSelectorState> {
    constructor(props : IStadiumToggleButtonSelectorProps<T>) {
        super(props);
        this.state = {};
    }

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        if (!this.props.onChange) return;

        this.props.onChange(event.currentTarget.value as T);
    };

    private readonly getData = (props : IStadiumToggleButtonSelectorProps<T>) => props.value;
    private readonly getValue = createSelector(
        [this.getData],
        (value) => {
            if (typeof value !== 'object') return [value];
            
            if (!value) return [];

            if (Array.isArray(value)) return [...value];

            return [];
        },
    );

    public render = () => {
        const {
            values,
            className,
            disabled,
        } = this.props;
        const value = this.getValue(this.props);
        return (
            <div className='fdr ilf15 fww'>
                {
                    values.map((n, i) => (
                        <ToggleButton
                            key={`${n}_${i}`}
                            className={`${className}`}
                            value={`${n}`}
                            selected={value.includes(n)}
                            onClick={this.onClick}
                            disabled={disabled}
                            stadium={'true'}
                        >
                            {`${n}`.toLocaleUpperCase()}
                        </ToggleButton>
                    ))
                }
            </div>
        );
    };
}
