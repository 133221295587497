import React from 'react';
import { Layer, Source } from 'react-map-gl';
import MasterDataGeoServerFunctions from '../../../../../store/masterData/geoserver/functions';
import { IRootState } from '../../../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IGeoServerPoint, pointType } from '../../../../../types/model/masterData/geoserver/point';
import { CROP } from '../../../../../appConstants';

interface IMapboxBlockPointLayerProps {
    pointsId : string;

    pointsBeforeId ?: string;

    points : Array<IGeoServerPoint>;
    isLoading : boolean;

    minZoom ?: number;
    maxZoom ?: number;

    blockGuids ?: Array<string> | null;
    crop ?: CROP | null;
    type ?: pointType | null;

    visible ?: boolean;
}

interface IMapboxBlockPointLayerState {}

class MapboxBlockPointLayerComponent extends React.PureComponent<IMapboxBlockPointLayerProps, IMapboxBlockPointLayerState> {
    constructor(props : IMapboxBlockPointLayerProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() : void {
        this.load();
    }

    private readonly load = () => {
        MasterDataGeoServerFunctions.getPointList();
    };

    private readonly getPoints = (props : IMapboxBlockPointLayerProps) => props.points;
    private readonly getType = (props : IMapboxBlockPointLayerProps) => props.type;
    private readonly getCrop = (props : IMapboxBlockPointLayerProps) => props.crop;
    private readonly getBlockGuids = (props : IMapboxBlockPointLayerProps) => props.blockGuids;

    private readonly getFeatureCollection = createSelector(
        [
            this.getPoints,
        ],
        (
            points,
        ) => {
            return {
                type: 'FeatureCollection',
                features: points
                    .map(x => ({
                        geometry: x.geometry,
                        properties: {
                            ...x,
                        },
                        type: 'Feature',
                    })),
            } as GeoJSON.FeatureCollection<GeoJSON.MultiPoint | GeoJSON.Point, IGeoServerPoint>;
        },
    );

    private readonly getFilter = createSelector(
        [
            this.getType,
            this.getCrop,
            this.getBlockGuids,
        ],
        (
            type,
            crop,
            blockGuids,
        ) => {
            const result : Array<unknown> = [];

            if (blockGuids?.length) {
                result.push(['in', ['get', 'blockGuid'], ['literal', blockGuids]]);
            }

            if (type) {
                result.push(['==', ['literal', type], ['get', 'type']]);
            }

            if (crop) {
                result.push(['==', ['literal', crop], ['get', 'crop']]);
            }

            return [
                'all',
                ...result,
            ];
        },
    );

    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 21,
            pointsId,
            pointsBeforeId,
            visible = true,
        } = this.props;

        const blocks = this.getFeatureCollection(this.props);
        const filter = this.getFilter(this.props);
        return (
            <Source
                id={`${pointsId}_source`}
                type='geojson'
                data={blocks}
                maxzoom={maxZoom}
            >
                <Layer
                    {...{
                        id: pointsId,
                        type: 'circle',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'circle-color': 'red',
                            'circle-opacity': 1,
                        },
                        filter,
                        beforeId: pointsBeforeId,
                    }}
                />
            </Source>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        points: state.masterData.geoserver.points,
        isLoading: state.masterData.geoserver.isLoadingPoints,
    };
};

const MapboxBlockPointLayer = connect(
    mapStateToProps,
)(MapboxBlockPointLayerComponent);

export default MapboxBlockPointLayer;