import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import FirestoreHelper from '../../../services/helper/firestoreHelper';
import { BaseHelper, IBase } from '../../base';
import firebase from 'firebase/app';
import UserHelper from '../user';

export interface IAvoBugAssignmentEntry {
    location : firebase.firestore.GeoPoint;
    result : Record<string, number>;
}

export interface IAvoBugAssignment extends IBase {
    guid : string;

    /**
     * Employee Id
     */
    employee : string;
    employeeName : string;
    employeeNumber : string;

    /**
     * Date in Millis
     */
    date : number;

    /**
     * DateTime in milliseconds
     */
    startedOn : number;
    /**
     * DateTime in milliseconds
     */
    finishedOn ?: number;

    distance : number;

    /**
     * Block Id
     */
    block : string;
    blockName : string;
    landName : string;
    division : string;
    crop : CROP;

    weather : Array<string>;
    phenology : Array<string>;

    /// Threshold - Tree - Bug/Size - Count
    results : Record<string, Record<string, IAvoBugAssignmentEntry>>;
    isSent : boolean;
}

export class AvoBugAssignmentHelper extends BaseHelper {
    public static readonly COLLECTION = 'avobug_assignments';

    public static converter : firebase.firestore.FirestoreDataConverter<IAvoBugAssignment> = {
        fromFirestore: (snapshot) => {
            return AvoBugAssignmentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IAvoBugAssignment) => {
            return AvoBugAssignmentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IAvoBugAssignment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IAvoBugAssignment,
            ...result,
            employee: (data['employee'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
            block: data['block'],
            startedOn: (data['startedOn'] as firebase.firestore.Timestamp).toMillis(),
            finishedOn: (data['finishedOn'] as firebase.firestore.Timestamp | null)?.toMillis(),
        };
    }

    protected static toFirestore(data : IAvoBugAssignment) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;
        return {
            ...rest,
            ...result,
            date: FirestoreHelper.millisToTimestamp(data.date),
            employee: UserHelper.get(data.employee),
            startedOn: !data.startedOn ? null : FirestoreHelper.millisToTimestamp(data.startedOn),
            finishedOn: !data.finishedOn ? null : FirestoreHelper.millisToTimestamp(data.finishedOn),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return AvoBugAssignmentHelper.collection().doc();
        }

        return AvoBugAssignmentHelper.collection().doc(id);
    }
    
    public static async bulkDelete(ids : Array<string>) {
        const currentIds = ids.slice();

        while (currentIds.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteIds = currentIds.splice(0, 50);
            
            deleteIds.forEach((id) => {
                batch.delete(this.collection().doc(id));
            });

            await batch.commit();
        }
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : IAvoBugAssignment) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }

    public static save(avobug : IAvoBugAssignment) {
        if (avobug.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(avobug.id).set(avobug);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(avobug);
    }

}