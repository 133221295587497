import React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../@types/redux';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { ITrap, TrapHelper } from '../../types/model/trap/trap';
import GeneralFunctions from '../../store/general/functions';
import TrapActions from '../../store/phenology/general/actions';
import TrapFunctions from '../../store/trap/functions';
import TrapCreateDialog from './dialog/CreateDialog';
import { createSelector } from 'reselect';
import { CROP } from '../../appConstants';
import DeleteDialog from '../customComponents/dialog/DeleteDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Archive, Check, Close, InfoRounded, LocationOff, Unarchive, Warning } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import AppFunctionsService from '../../services/appFunctionServices';
import DivisionSelector from '../customComponents/selector/rights/DivisionSelector';
import lodash from 'lodash';
import ToggleButton from '../customComponents/button/ToggleButton';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { IUserSession } from '../../types/model/user';
import TrapInfoDialog from './dialog/ViewDialog';
import TrapsArchiveButton from './dialog/TrapsArchiveButton';
import TrapsPrintQrCodeButton from './dialog/TrapsPrintQrCode';
import NavFunctions from '../../store/nav/functions';
import ScoutingTrapTypeFilter from '../customComponents/materialTable/ScoutingTrapTypeFilter';
import { ITrapType } from '../../types/model/masterData/trap/trapType';
import ScoutingAssignmentLandNameFilter from '../customComponents/materialTable/ScoutingAssignmentLandNameFilter';
import ScoutingAssignmentBlockNameFilter from '../customComponents/materialTable/ScoutingAssignmentBlockNameFilter';

interface ITrapListProps extends RouteComponentProps {
    isLoading : boolean;
    traps : Array<ITrap>;

    setLoading : DispatchCall<boolean>;

    session ?: IUserSession | null;
}

interface ITrapListState {
    selectedTraps : Array<ITrap>;

    selectedDivisions : Array<string>;
    archived : boolean;
    active : boolean;

    selectedArchive ?: ITrap;
    selected ?: ITrap;

    trapType ?: ITrapType | null;
    landName ?: string;
    blockName ?: string;
}

class TrapListComponent extends React.Component<ITrapListProps, ITrapListState> {
    constructor(props : ITrapListProps) {
        super(props);
        this.state = {
            selectedTraps: [],
            selectedDivisions: [],
            archived: false,
            active: false,
        };
    }

    public readonly componentDidMount = () => {
        this.loadTraps();
    };

    public readonly componentWillUnmount = () => {
        TrapFunctions.unsubscribe();
    };

    public readonly componentDidUpdate = (prevProps : ITrapListProps) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.loadTraps();
        }
    };

    public readonly loadTraps = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop');

            if (crop) {
                TrapFunctions.getList(crop);
            }
        }
    };

    private readonly getSearch = (state : ITrapListState, props : ITrapListProps) => props.location.search;
    private readonly getData = (state : ITrapListState, props : ITrapListProps) => props.traps;
    private readonly getDivisions = (state : ITrapListState) => state.selectedDivisions;
    private readonly getActive = (state : ITrapListState) => state.active;
    private readonly getArchived = (state : ITrapListState) => state.archived;
    private readonly getTrapType = (state : ITrapListState) => state.trapType;
    private readonly getLandName = (state : ITrapListState) => state.landName;
    private readonly getBlockName = (state : ITrapListState) => state.blockName;

    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });

    private readonly getDivisionCounts = createSelector([
        this.getData,
    ], (traps) => {
        return lodash.chain(traps)
            .groupBy(x => x.divisionCode.toLocaleUpperCase())
            .reduce((prev, list, key) => {
                return {
                    ...prev,
                    [key]: list.length,
                };
            }, {} as Record<string, number>)
            .value();
    });

    private readonly getTraps = createSelector([
        this.getData,
        this.getDivisions,
        this.getActive,
        this.getArchived,
        this.getTrapType,
        this.getLandName,
        this.getBlockName,
    ], (
        traps,
        divisions,
        active,
        archived,
        trapType,
        landName,
        blockName,
    ) => {
        return traps
            .filter(x => (active && archived) || (!active && !archived) || (active && !x.archived) || (archived && x.archived))
            .filter(x => !divisions.length || divisions.includes(x.divisionCode))
            .filter(x => !trapType || x.type === trapType.id)
            .filter(n => !landName || landName === n.landName)
            .filter(n => !blockName || blockName === n.blockName)
            .sort((a, b) => Number(a.code?.substring(2)) - Number(b.code?.substring(2)));
    });
    
    private readonly onDelete = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            this.props.setLoading(true);
            const trap = this.props.traps.find(x => x.id === event.currentTarget.value);

            if (!trap) return;

            await TrapHelper.delete(trap.id);
            GeneralFunctions.generalShowSuccessSnackbar('Deleted');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error deleting trap', ex);
        } finally {
            this.props.setLoading(false);
        }
    };

    private readonly onSelectionChange = (selectedTraps : Array<ITrap>) => {
        this.setState({
            selectedTraps: selectedTraps.filter(x => !x.location),
        });
    };

    private readonly onDivisionClick = (value : string) => {
        const selectedDivisions = this.state.selectedDivisions.slice();
        const index = selectedDivisions.indexOf(value);
        if (index > -1) {
            selectedDivisions.splice(index, 1);
        } else {
            selectedDivisions.push(value);
        }

        this.setState({
            selectedDivisions,
        });
    };

    private readonly onArchivedClick = () => {
        this.setState({
            archived: !this.state.archived,
        });
    };

    private readonly onActiveClick = () => {
        this.setState({
            active: !this.state.active,
        });
    };

    private readonly onArchiveClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selectedArchive: this.props.traps.find(x => x.id === event.currentTarget.value),
        });
    };

    private readonly archiveTrap = async () => {
        try {
            const { selectedArchive } = this.state;
            const { session } = this.props;

            if (!selectedArchive) return;
            if (!session) return;

            await TrapFunctions.save({
                ...selectedArchive,
                archived: !selectedArchive.archived,
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error archiving trap', ex);
        } finally {
            this.setState({
                selectedArchive: undefined,
            });
        }
    };

    private readonly onArchiveConfirmClose = (result : boolean) => {
        if (!result) {
            this.setState({
                selectedArchive: undefined,
            });
            return;
        }

        this.archiveTrap();
    };

    private readonly onSelectedClose = () => {
        this.setState({
            selectedTraps: [],
        });
    };

    private readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selected: this.props.traps.find(x => x.id === event.currentTarget.value),
        });
    };

    private readonly onInfoClose = () => {
        this.setState({
            selected: undefined,
        });
    };

    private readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        NavFunctions.navPath('/traps/map', `${this.props.location.search}&code=${event.currentTarget.id}`);
    };


    private readonly onTrapTypeChange = (trapType ?: ITrapType | null) => {
        this.setState({
            trapType,
        });
    };

    private readonly onLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
    };

    private readonly onBlockNameChange = (blockNames : Array<string>) => {
        this.setState({
            blockName: !blockNames.length ? undefined : blockNames[0],
        });
    };

    public readonly render = () => {
        const { isLoading } = this.props;
        const { 
            selectedTraps,
            selectedDivisions,
            archived,
            active,
            selectedArchive,
            selected,
            trapType,
            landName,
            blockName,
        } = this.state;

        const crop = this.getCrop(this.state, this.props);
        const divisionCounts = this.getDivisionCounts(this.state, this.props);
        const filteredTraps = this.getTraps(this.state, this.props);

        return (
            <div className={'fdc flx1 mh0 mw0 bcg0'}>
                <div className='fdr mb15'>
                    <DivisionSelector
                        value={selectedDivisions}
                        counts={divisionCounts}
                        onClick={this.onDivisionClick}
                        crop={crop}
                    />
                </div>
                <div className={'fdc flx1 p10 mh0 mw0'}>
                    <div className='fdr mb15'>
                        <div className='mr15'>
                            <ToggleButton
                                selected={archived}
                                onClick={this.onArchivedClick}
                                disabled={isLoading}
                                stadium={'true'}
                            >
                                ARCHIVE
                            </ToggleButton>
                        </div>
                        <div className='mr15'>
                            <ToggleButton
                                selected={active}
                                onClick={this.onActiveClick}
                                disabled={isLoading}
                                stadium={'true'}
                            >
                                ACTIVE
                            </ToggleButton>
                        </div>
                    </div>
                    <Card className={'flx1 fdc mb40'}>
                        <MaterialTable<ITrap>
                            id='trapTable'
                            data={filteredTraps}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selectedTraps}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 55,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            !row.location &&
                                            <Tooltip title='No Location'>
                                                <LocationOff className='co p12' />
                                            </Tooltip>
                                        }
                                        {
                                            !!row.location &&
                                            !!row.code &&
                                            <Tooltip title='View'>
                                                <div>
                                                    <IconButton
                                                        id={row.code}
                                                        color='primary'
                                                        onClick={this.onMapClick}
                                                    >
                                                        <FontAwesomeIcon icon={faMapLocationDot} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        }
                                        {
                                            !row.location &&
                                            <DeleteDialog
                                                maxWidth='md'
                                                disabled={!!row.location}
                                                isLoading={isLoading}
                                                message={row.monitoring ?
                                                    `Delete trap from ${row.divisionName}?` :
                                                    `Delete trap from ${row.landName} - ${row.blockName}?`}
                                                onConfirm={this.onDelete}
                                                title={'Delete'}
                                                value={row.id}
                                                transition={Transitions.Down}
                                            />
                                        }
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton
                                                    value={row.id}
                                                    color='primary'
                                                    onClick={this.onInfoClick}
                                                >
                                                    <InfoRounded />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 50,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Division',
                                field: 'divisionName',
                                width: 75,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Field',
                                field: 'landName',
                                width: 50,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => !row.landName ? '-' : row.landName,
                                filterRender: () => (<ScoutingAssignmentLandNameFilter value={landName}
                                    onChange={this.onLandNameChange} id='land_filter' cropType={crop} />),
                            }, {
                                header: 'Block',
                                field: 'blockName',
                                width: 50,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => !row.blockName ? '-' : row.blockName,
                                filterRender: () => (<ScoutingAssignmentBlockNameFilter value={blockName}
                                    onChange={this.onBlockNameChange} id='block_filter' cropType={crop} landName={landName} />),
                            }, {
                                header: 'Type',
                                field: 'typeName',
                                width: 75,
                                enableSort: true,
                                enableFilter: true,
                                filterRender: () => (<ScoutingTrapTypeFilter value={trapType}
                                    onChange={this.onTrapTypeChange} id='trap_type_filter' cropType={crop} />),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 50,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => (
                                    <Tooltip title={row.createdByEmployee}>
                                        <span>{row.createdByName}</span>
                                    </Tooltip>
                                ),
                            }, {
                                header: 'Created On',
                                field: 'createdOn',
                                width: 50,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => AppFunctionsService.formatDateTime(row.createdOn),
                            }, {
                                header: 'Archive',
                                paddingRight: 4,
                                width: 55,
                                renderCell: row => (
                                    <div className='aic'>
                                        {
                                            !row.archived ?
                                                (<Close className='cpr' />) :
                                                (<Check className='cdg' />)
                                        }
                                        <Tooltip title={!row.archived ? 'Archive' : 'Unarchive'}>
                                            <div>
                                                <IconButton
                                                    color='primary'
                                                    onClick={this.onArchiveClick}
                                                    value={row.id}
                                                >
                                                    {
                                                        row.archived &&
                                                        <Unarchive />
                                                    }
                                                    {
                                                        !row.archived &&
                                                        <Archive />
                                                    }
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }]}
                        />
                    </Card>
                    <ConfirmDialog
                        open={!!selectedArchive}
                        title={
                            !selectedArchive ? '' : `${selectedArchive.archived ? 'Unarchive' : 'Archive'} TRAP`.toLocaleUpperCase()
                        }
                        message={(
                            <div className='fdr aic jcc'>
                                <div className='fdc mr15'>
                                    <Warning className='co h36 w36' />
                                </div>
                                <div className='fdc'>
                                    <Typography
                                        className='co fs24 fwm'
                                        
                                    >
                                        {
                                            !selectedArchive ?
                                                '' :
                                                `Are you sure you want to ${selectedArchive.archived ? 'Unarchive' : 'Archive'} trap ${selectedArchive.code}?`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        )}
                        onClose={this.onArchiveConfirmClose}
                        transition={Transitions.Down}
                        disabled={isLoading || !selectedArchive}
                        acceptText={!selectedArchive?.archived ? 'ARCHIVE' : 'UNARCHIVE'}
                        denyText='BACK'
                    />

                    <TrapInfoDialog
                        value={selected?.id}
                        onClose={this.onInfoClose}
                    />
                    <div className='fdr h36'>
                        <div className='mr15'>
                            <TrapsPrintQrCodeButton
                                selected={selectedTraps}
                                onClose={this.onSelectedClose}
                            />
                        </div>
                        <TrapsArchiveButton
                            selected={selectedTraps}
                            onClose={this.onSelectedClose}
                        />
                        <span className='flx1' />
                        <TrapCreateDialog
                            maxWidth='sm'
                            fullWidth
                            disabled={isLoading}
                            crop={crop}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.trap.isLoading,
        traps: state.trap.traps,
        session: state.auth.session,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators({
    setLoading: (isLoading : boolean) => dispatcher(TrapActions.setLoading(isLoading)),
}, dispatcher);

const TrapList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(TrapListComponent));

export default TrapList;
