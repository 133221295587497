import { getType } from 'typesafe-actions';
import { IPhynologySpecificState, PhenologySpecificAction } from '../../../@types/redux';
import PhenologySpecificActions from './actions';

const initialState : IPhynologySpecificState = {
    specifics: [],
    isLoading: false,
};

export default function phenologySpecificReducer(state : IPhynologySpecificState = initialState, action : PhenologySpecificAction) : IPhynologySpecificState {
    switch (action.type) {
        case getType(PhenologySpecificActions.setSpecifics):
            return { ...state, specifics: action.payload };
        case getType(PhenologySpecificActions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}