import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import { CROPS, CROP } from '../../../appConstants';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../types/model/user';

interface ICropTypeDropdownProps {
    value ?: CROP | null;

    onChange : (value ?: CROP | null) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;
    session ?: IUserSession | null;
}

interface ICropTypeDropdownState {}

class CropTypeDropdown extends React.PureComponent<ICropTypeDropdownProps, ICropTypeDropdownState> {
    constructor(props : ICropTypeDropdownProps) {
        super(props);
        this.state = {};
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : CROP;
    } | null) => {
        this.props.onChange(value?.value);
    };

    private getValue = (props : ICropTypeDropdownProps) => props.value;
    private getRequired = (props : ICropTypeDropdownProps) => props.required;
    private getSession = (props : ICropTypeDropdownProps) => props.session;

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired,
    ], (value, required) => {
        if (value) {
            const cropType = CROPS.find(x => x === value);

            if (cropType) {
                return { label: cropType.toLocaleUpperCase(), value: cropType };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '' as CROP,
            };
        }

        return null;
    });

    private getValues = createSelector([
        this.getSession, this.getValue, this.getRequired,
    ], (session, value, required) => {
        return (!value && !required ? [{
            label: 'ALL',
            value: '' as CROP,
        }] : []).concat(CROPS.filter(x => !session || session.user.crops.includes(x)).map(x => ({
            value: x,
            label: x.toLocaleUpperCase(),
        })));
    });

    public render = () => {
        const { required, fullWidth } = this.props;

        const value = this.getSelectedValue(this.props);
        const values = this.getValues(this.props);

        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id='croptype_select'
                    options={values}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Crops' />}
                />
                <FormHelperText style={{
                    height: 19.2,
                }} error>
                    {
                        required && !value &&
                            'Required'
                    }
                </FormHelperText>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(CropTypeDropdown);
