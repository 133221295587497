import { dispatch, getState } from '../..';
import { CROP } from '../../../appConstants';
import PhenologySpecificActions from './actions';
import lodash from 'lodash';
import GeneralFunctions from '../../general/functions';
import { IPhenologySpecific, PhenologySpecificHelper, PhenologySpecificTreeType } from '../../../types/model/phenology/specific';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IAssignmentBlock } from '../../../types/model/masterData/block';
import firebase from 'firebase/app';
import { GeoPointHelper, IGeoPoint } from '../../../types/model/geoPoint';

export default class PhenologySpecificFunctions {
    private static listener ?: () => void;

    public static unsubscribe() {
        if (!this.listener) return;

        dispatch(PhenologySpecificActions.setSpecifics([]));
        dispatch(PhenologySpecificActions.setLoading(false));

        this.listener();
    }

    public static listen = (crop ?: CROP, refresh ?: boolean) => {
        if (!refresh && PhenologySpecificFunctions.listener) return;

        if (PhenologySpecificFunctions.listener) {
            PhenologySpecificFunctions.listener();
        }

        dispatch(PhenologySpecificActions.setLoading(true));
        dispatch(PhenologySpecificActions.setSpecifics([]));
        try {
            const session = getState().auth.session;
            if (!session) return;
            const divisions = Object.keys(session.user.divisions).map(n => n.toLocaleLowerCase());

            let query : firebase.firestore.CollectionReference<IPhenologySpecific> | firebase.firestore.Query<IPhenologySpecific> =
            PhenologySpecificHelper.collection();

            if (crop) {
                query = query.where('crop', '==', crop);
            }
            
            PhenologySpecificFunctions.listener = query
                .onSnapshot((snapshot) => {
                    const phenologyState = getState().phenology;

                    const specifics = lodash.cloneDeep(phenologyState.specific.specifics);

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const specific = f.doc.data();

                        if (!divisions.includes(specific.division)) return;

                        const index = lodash.findIndex(specifics, n => n.id === specific.id);

                        switch (f.type) {
                            case 'added':
                                specifics.push(specific);
                                break;
                            case 'modified':
                                specifics.splice(index, 1, specific);
                                break;
                            case 'removed':
                                specifics.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(PhenologySpecificActions.setSpecifics(specifics));
                    dispatch(PhenologySpecificActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', err);
                    dispatch(PhenologySpecificActions.setLoading(false));
                }, () => {
                    dispatch(PhenologySpecificActions.setSpecifics([]));
                    dispatch(PhenologySpecificActions.setLoading(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading assignments.', ex);
        }
    };

    public static createTrees = async (
        divisionName : string,
        cultivar : string,
        cultivarName : string,
        selectedBlocks : Array<IAssignmentBlock>,
        trees : Record<string, number>,
    ) => {
        dispatch(PhenologySpecificActions.setLoading(true));

        try {
            await FirebaseService.createPhenologySpecificTrees(
                divisionName,
                cultivar,
                cultivarName,
                selectedBlocks,
                trees,
            );

            GeneralFunctions.generalShowSuccessSnackbar('Trees created.');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trees.', ex);
            throw ex;
        } finally {
            dispatch(PhenologySpecificActions.setLoading(false));
        }
    };

    /**
     * Validates an assignment.
     * Throws `Error` if invalid.
     * @param assignment
     */
    private static validateSpecific(assignment : IPhenologySpecific) {

        if (!assignment.block) {
            throw new Error('Block required.');
        }

        if (!assignment.division) {
            throw new Error('Division required.');
        }

        if (!assignment.blockName) {
            throw new Error(`${assignment.scoutingBlock.landName} required a Block.`);
        }

        if (!assignment.trees.length) {
            throw new Error('No trees.');
        }
    }

    public static save = async (item : IPhenologySpecific) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(PhenologySpecificActions.setLoading(true));

        try {
            const save : IPhenologySpecific = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : '',
                isSent: false,
            };

            await PhenologySpecificHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap.', ex);
            throw ex;
        } finally {
            dispatch(PhenologySpecificActions.setLoading(false));
        }
    };

    public static updateLocation = async (treeType : PhenologySpecificTreeType, location : IGeoPoint, elevation : number) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(PhenologySpecificActions.setLoading(true));

        try {

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const _tSnapshot = await transaction.get(PhenologySpecificHelper.doc(treeType.id));
                
                const treeIndex = treeType.trees.findIndex(tree => tree.code === treeType.code);
                if (treeIndex < 0) return;

                treeType.trees.splice(treeIndex, 1, {
                    ...treeType.trees[treeIndex],
                    location: GeoPointHelper.toFirestore(location),
                    locationAccuracy: 0,
                    elevation,
                });

                transaction.set(PhenologySpecificHelper.doc(treeType.id), {
                    trees: treeType.trees,
                }, {
                    mergeFields: [
                        'trees',
                    ],
                });
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving trap.', ex);
            throw ex;
        } finally {
            dispatch(PhenologySpecificActions.setLoading(false));
        }
    };
}