import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Paper } from '@material-ui/core';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import { CROP } from '../../appConstants';
import { IUserSession } from '../../types/model/user';
import TrapInfoDialog from './dialog/ViewDialog';
import { ITrap } from '../../types/model/trap/trap';
import BasicMap from '../customComponents/mapBox/BasicMap';
import { LngLatBoundsLike } from 'react-map-gl';
import { createSelector } from 'reselect';
import { IGeoServerBlock } from '../../types/model/masterData/geoserver/block';
import CropTypeDropdown from '../customComponents/dropdowns/CropTypeDropdown';
import GeoServerDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DivisionAutocomplete';
import GeoServerSubDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/SubDivisionAutocomplete';
import GeoServerDepartmentAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DepartmentAutocomplete';
import GeoServerFieldAutocomplete from '../customComponents/autocomplete/masterData/geoserver/FieldAutocomplete';
import GeoServerBlockMultiAutocomplete from '../customComponents/autocomplete/masterData/geoserver/BlockMultiAutocomplete';
import MapboxTrapLayer from '../customComponents/mapBox/layer/trap/TrapLayer';
import { MapBoxRef } from '../customComponents/mapBox/MapBox';

interface ITrapsMapProps extends RouteComponentProps {
    session ?: IUserSession | null;
    
    traps : Array<ITrap>;
    geoserverblocks : Array<IGeoServerBlock>;
}

interface ITrapsMapState {
    selected ?: ITrap | null;

    divisionGuid : string | null;
    subDivisionGuid : string | null;
    departmentGuid : string | null;
    fieldGuid : string | null;
    blockGuids : Array<string>;
    cropType : CROP | null;
}

class TrapsMapComponent extends React.PureComponent<ITrapsMapProps, ITrapsMapState> {
    private mapRef : MapBoxRef | null = null;

    private readonly divisionZoom = 10;
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : ITrapsMapProps) {
        super(props);
        this.state = {
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
            cropType: null,
        };
    }

    public readonly componentDidMount = () => {
        this.setCropType();
        this.setCode();
    };

    public componentDidUpdate(prevProps : Readonly<ITrapsMapProps>) : void {
        if (prevProps.location !== this.props.location) {
            this.setCropType();
            this.setCode();
        }

        if (!prevProps.traps.length && this.props.traps.length) {
            this.setCode();
        }
    }


    public readonly setCropType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop') as CROP | null;

            if (crop) {
                this.setState({
                    cropType: crop,
                });
            }
        } else {
            this.setState({
                cropType: null,
            });
        }
    };

    public readonly setCode = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('code')) {
            const code = urlParams.get('code') as string | null;

            if (code) {
                const trap = this.props.traps.find(x => x.code === code);

                if (!trap?.location || !this.mapRef) return;

                this.mapRef.flyTo({
                    center: [trap.location.longitude, trap.location.latitude],
                    zoom: this.blockZoom,
                });
            }
        }
    };
    
    private readonly flyToBounds = (bounds : LngLatBoundsLike, zoom ?: number) => {
        this.mapRef?.fitBounds(bounds, {
            minZoom: zoom,
        });
    };

    private readonly onDivisionChange = (value ?: string | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            divisionGuid: value ?? null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.divisionZoom);
        }
    };

    private readonly onSubDivisionChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            subDivisionGuid: value ?? null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.subDivisionZoom);
        }
    };

    private readonly onDepartmentChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            departmentGuid: value ?? null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.departmentZoom);
        }
    };

    private readonly onFieldChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            fieldGuid: value ?? null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.fieldZoom);
        }
    };

    private readonly onBlockChange = (value : Array<string>, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            blockGuids: value,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.blockZoom);
        }
    };

    private readonly onCropTypeChange = (cropType ?: CROP | null) => {
        this.setState({
            cropType: cropType ?? null,
        });
    };

    private readonly onTrapClick = (trap ?: ITrap) => {
        this.setState({
            selected: trap,
        });
    };

    private readonly onInfoClose = () => {
        this.setState({
            selected: undefined,
        });
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
    };

    private readonly getGeoserverBlocks = (props : ITrapsMapProps) => props.geoserverblocks;
    private readonly getBlockGuids = (props : ITrapsMapProps, state : ITrapsMapState) => state.blockGuids;
    private readonly getDepartmentGuid = (props : ITrapsMapProps, state : ITrapsMapState) => state.departmentGuid;

    private readonly getBlockCodes = createSelector([
        this.getGeoserverBlocks,
        this.getBlockGuids,
    ], (
        blocks,
        blockGuids,
    ) => {
        return blocks.filter(x => blockGuids.includes(x.guid)).map(x => x.code);
    });

    private readonly getDivision = createSelector([
        this.getGeoserverBlocks,
        this.getDepartmentGuid,
    ], (
        geoserverblocks,
        departmentGuid,
    ) => {
        const geoserverblock = geoserverblocks.find(x => x.departmentGuid === departmentGuid);

        if (!geoserverblock) return;
        
        
        return geoserverblock.departmentShortName;
    });

    public render = () => {
        const {
            divisionGuid,
            subDivisionGuid,
            blockGuids,
            departmentGuid,
            fieldGuid,
            selected,
            cropType,
        } = this.state;

        const blockNames = this.getBlockCodes(this.props, this.state);
        const division = this.getDivision(this.props, this.state);

        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <BasicMap
                        id={'farmMap'}
                        onMap={this.setMapRef}
                        divisionGuid={divisionGuid}
                        subDivisionGuid={subDivisionGuid}
                        departmentGuid={departmentGuid}
                        fieldGuid={fieldGuid}
                        blockCodes={blockNames}
                    >
                        <MapboxTrapLayer
                            markersLayerId='point_farm_layer'
                            minZoom={1}
                            crop={cropType}
                            divisionCode={division}
                            markersBeforeId='polygon_division_layer'
                            onMarkerClick={this.onTrapClick}
                        />
                    </BasicMap>
                    
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <CropTypeDropdown fullWidth value={cropType} onChange={this.onCropTypeChange} />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDivisionAutocomplete
                                value={divisionGuid}
                                fullWidth
                                onChange={this.onDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerSubDivisionAutocomplete
                                value={subDivisionGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                onChange={this.onSubDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDepartmentAutocomplete
                                value={departmentGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                onChange={this.onDepartmentChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerFieldAutocomplete
                                value={fieldGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                onChange={this.onFieldChange}
                                type={null}
                                crop={cropType}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerBlockMultiAutocomplete
                                value={blockGuids}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                fieldGuid={fieldGuid}
                                onChange={this.onBlockChange}
                                type={null}
                                crop={cropType}
                            />
                        </div>
                    </VerticalExpander>
                </div>
                <TrapInfoDialog
                    value={selected?.id}
                    onClose={this.onInfoClose}
                />
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        isLoading: state.masterData.geoserver.isLoadingBlocks || state.trap.isLoading,
        traps: state.trap.traps,
        geoserverblocks: state.masterData.geoserver.blocks,
    };
};

const TrapsMap = connect(
    mapStateToProps,
)(withRouter(TrapsMapComponent));

export default TrapsMap;