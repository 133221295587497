import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../customComponents/table/MaterialTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import NavFunctions from '../../../store/nav/functions';
import AssignmentBlockHelper, { IBlock } from '../../../types/model/masterData/block';
import { IUserSession } from '../../../types/model/user';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import MasterDataGeoServerFunctions from '../../../store/masterData/geoserver/functions';
import lodash from 'lodash';
import { v4, validate as isValidUUID } from 'uuid';
import moment from 'moment';

interface IScoutingBlockListProps {
    session ?: IUserSession | null;

    blocks : Array<IGeoServerBlock>;
    isLoadingBlocks : boolean;
}

interface IScoutingBlockListState {
    isLoading : boolean;
    blocks : Array<IBlock>;
}

class BlockList extends React.Component<IScoutingBlockListProps, IScoutingBlockListState> {
    constructor(props : IScoutingBlockListProps) {
        super(props);
        this.state = {
            isLoading: false,
            blocks: [],
        };
    }

    public componentDidMount = () => {
        this.load();
    };

    public onClick = () => {
        const result : Array<Array<string | number>> = [[
            'poiGuid',
            'poiBlockGuid',
            'poiPointNumber',
            'poiLat',
            'poiLng',
            'poiType',
            'poiCrop',
            'poiCreatedBy',
            'poiCreatedByEmployee',
            'poiCreatedByName',
            'poiCreatedOn',
            'poiUpdatedBy',
            'poiUpdatedByEmployee',
            'poiUpdatedByName',
            'poiUpdatedOn',
        ]];

        const map : Record<string, string | null> = {
            'es4': 'es',
        };
        const { blocks: scoutingBlocks } = this.state;
        const { blocks: geoServerBlocks, session } = this.props;

        scoutingBlocks.forEach((scoutingBlock) => {
            const geoServerBlockFilter = geoServerBlocks.filter(x =>
                x.code.toLocaleLowerCase() === scoutingBlock.name.toLocaleLowerCase() &&
                x.fieldName.toLocaleLowerCase() === scoutingBlock.landName.toLocaleLowerCase() &&
                x.departmentShortName.toLocaleLowerCase() === (map[scoutingBlock.division.toLocaleLowerCase()] ?? scoutingBlock.division.toLocaleLowerCase()));

            if (!geoServerBlockFilter.length) return;

            const geoServerBlock : IGeoServerBlock = geoServerBlockFilter[0];

            let i = 1;
            result.push(...lodash.map(scoutingBlock.points, (geo, pointGuid) => ([
                !isValidUUID(pointGuid) ? v4() : pointGuid.toLocaleLowerCase(),
                geoServerBlock.guid,
                i++,
                geo.latitude,
                geo.longitude,
                'scouting',
                scoutingBlock.crop,
                session?.user.employeeNumber ?? '',
                session?.user.employeeNumber ?? '',
                session?.user.name ?? '',
                moment.utc(scoutingBlock.createdOn).toISOString(),
                session?.user.employeeNumber ?? '',
                session?.user.employeeNumber ?? '',
                session?.user.name ?? '',
                moment.utc(scoutingBlock.updatedOn).toISOString(),
            ])));

            if (scoutingBlock.crop === 'avocados') {
                i = 1;
                result.push(...lodash.map(scoutingBlock.points, (geo, pointGuid) => ([
                    !isValidUUID(pointGuid) ? v4() : pointGuid.toLocaleLowerCase(),
                    geoServerBlock.guid,
                    i++,
                    geo.latitude,
                    geo.longitude,
                    'phenology',
                    scoutingBlock.crop,
                    session?.user.employeeNumber ?? '',
                    session?.user.employeeNumber ?? '',
                    session?.user.name ?? '',
                    moment.utc(scoutingBlock.createdOn).toISOString(),
                    session?.user.employeeNumber ?? '',
                    session?.user.employeeNumber ?? '',
                    session?.user.name ?? '',
                    moment.utc(scoutingBlock.updatedOn).toISOString(),
                ])));
            }
        });

        result.forEach((row) => {
            const duplicates = result.filter(x => x[0] === row[0]);

            if (duplicates.length > 1) {
                row[0] = v4();
            }
        });

        const csvContent = 'data:text/csv;charset=utf-8,' + result.map(e => e.join(',')).join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'points.csv');
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    };

    private async load() {
        this.setState({
            blocks: [],
            isLoading: true,
        });
        MasterDataGeoServerFunctions.getBlockList();

        AssignmentBlockHelper.collection().onSnapshot((snapshot) => {

            this.setState({
                blocks: snapshot.docs.map(x => x.data()!),
                isLoading: false,
            });
        });
    }

    public onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        NavFunctions.navScoutingMap(event.currentTarget.value);
    };

    public render = () => {
        const { session, isLoadingBlocks } = this.props;
        const { blocks, isLoading } = this.state;

        const loading = isLoadingBlocks || isLoading;

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            SCOUTING BLOCKS
                        </Typography>
                        {
                            session?.user.permissions.isBlockAdmin &&
                            <Tooltip title='Download'>
                                <div>
                                    <IconButton color='primary' disabled={loading} onClick={this.onClick} aria-label='Download'>
                                        <Icon>download</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{blocks.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IBlock>
                            id='blocklsTable'
                            data={blocks}
                            isLoading={loading}
                            rowsPerPage={50}
                            columns={[{
                                header: '',
                                minWidth: 50,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.id} onClick={this.onMapClick}>
                                                    <Icon>
                                                        map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Division',
                                field: 'division',
                                minWidth: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Land Name',
                                field: 'landName',
                                minWidth: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                minWidth: 70,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Crop',
                                field: 'crop',
                                minWidth: 70,
                                enableSort: true,
                                enableFilter: true,
                            }]}
                        />
                    </Card>
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        blocks: state.masterData.geoserver.blocks,
        isLoading: state.masterData.geoserver.isLoadingBlocks,
    };
};

export default connect(
    mapStateToProps,
)(BlockList);
