import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface IDeleteDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
    disabled ?: boolean;

    onConfirm : (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;

    title : string;
    message : string;

    value ?: string | number | Array<string>;

    children ?: (onClick : () => void) => React.ReactNode;
}

interface IDeleteDialogState {
    open : boolean;
}

export default class DeleteDialog extends React.Component<IDeleteDialogProps, IDeleteDialogState> {
    constructor(props : IDeleteDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private onClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        this.setState({
            open: false,
        });
    };

    private onOkClose = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.onConfirm(event);
        this.setState({
            open: false,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, message, title,
            value, disabled } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                {
                    !this.props.children &&
                    <Tooltip title='Delete'>
                        <div>
                            <IconButton className={isLoading || disabled ? '' : 'cpr'} onClick={this.onClick} disabled={isLoading || disabled} aria-label='Delete'>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                {
                    this.props.children?.(this.onClick)
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='delete-dialog-title'
                    aria-describedby='delete-description'>
                    <AppBar className='fdr posr aic jcc bcr' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {title}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        <Typography variant='h5' component='h2'>
                            {message}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' className='bcr' onClick={this.onOkClose} value={value} disabled={isLoading || disabled} color='primary'>
                            YES
                        </Button>
                        <Button variant='outlined' onClick={this.onClose} disabled={isLoading || disabled} color='primary' autoFocus>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}
