import React from 'react';
import { INematodeAssignment } from '../../../../types/model/nematode/nematodeAssignment';
import { INematodeAssignmentEntry } from '../../../../types/model/nematode/nematodeAssignmentEntry';
import NematodeAssignmentEntryView from './AssignmentEntryView';

interface INematodeAssignmentCompletedHistoryViewProps {
    value : INematodeAssignment;
    entries : Array<INematodeAssignmentEntry>;
}

interface INematodeAssignmentCompletedHistoryViewState {

    entries : {
        [key : string] : INematodeAssignmentEntry;
    };
}

export default class NematodeAssignmentCompletedHistoryView extends React.PureComponent<INematodeAssignmentCompletedHistoryViewProps, INematodeAssignmentCompletedHistoryViewState> {
    constructor(props : INematodeAssignmentCompletedHistoryViewProps) {
        super(props);
        this.state = {
            entries: {},
        };
    }

    public componentDidMount = () => {
        this.loadLocations();
    };

    private loadLocations = () => {
        const entries = {
            ...this.state.entries,
        };

        // "added" | "removed" | "modified"
        this.props.entries.forEach((entry) => {
            entries[entry.pointGuid] = entry;
        });

        this.setState({
            entries,
        });
    };

    public render = () => {
        const { entries } = this.state;
        const { value } = this.props;

        return (
            <div className={'fdc ais flx1 bcg0'}>
                {
                    value.scoutingBlock.pointsArray.map((n, i) => (
                        <NematodeAssignmentEntryView key={`${n}_scout_point`} pointNumber={i + 1} value={entries[n]} />
                    ))
                }
            </div>);
    };
}
