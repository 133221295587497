import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppFunctionsService from '../../../services/appFunctionServices';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ScoutingFunctions from '../../../store/scouting/functions';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import ScoutingBlockLandNameDropdown from '../../customComponents/dropdowns/BlockLandNameDropdown';
import ScoutingBlockNameDropdown from '../../customComponents/dropdowns/BlockNameDropdown';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralActions from '../../../store/general/actions';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { IUser } from '../../../types/model/user';
import EmployeeDropdown from '../../customComponents/dropdowns/EmployeeDropdown';
import AssignmentBlockHelper, { IAssignmentBlock } from '../../../types/model/masterData/block';
import { CROP } from '../../../appConstants';
import DivisionAutocomplete from '../../customComponents/autocomplete/rights/DivisionAutocomplete';
import { IDivision } from '../../../types/model/rights/division';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import GeoHelper from '../../../services/helper/geoHelper';
import MapboxBlockPointLayer from '../../customComponents/mapBox/layer/masterData/PointLayer';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint } from '../../../types/model/masterData/geoserver/point';
import { createSelector } from 'reselect';

interface IScoutingAssignmentDialogProps {
    value ?: IScoutingAssignment;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    /**
     * Default division to use when creating assignment.
     */
    division ?: string;

    /**
     * Default Land name to use when creating assignment.
     */
    landName ?: string;

    /**
     * Default Block Names to use when creating assignment.
     */
    blockNames ?: Array<string>;

    crop : CROP;

    generalShowErrorSnackbar : DispatchCall<string>;

    disabled ?: boolean;

    geoserverblocks : Array<IGeoServerBlock>;
    points : Array<IGeoServerPoint>;
}

interface IScoutingAssignmentDialogState {
    open : boolean;

    division ?: string;
    landName ?: string;

    date : Moment;
    /**
     * Employee Id
     */
    employee ?: string;
    employeeName ?: string;
    employeeNumber ?: string;
    scoutingBlocks : Array<IAssignmentBlock>;
}

class ScoutingAssignmentDialogComponent extends React.PureComponent<IScoutingAssignmentDialogProps, IScoutingAssignmentDialogState> {
    private mapRef : MapBoxRef | null = null;

    constructor(props : IScoutingAssignmentDialogProps) {
        super(props);
        this.state = {
            open: false,
            scoutingBlocks: [],
            date: moment.utc().startOf('day'),
        };
    }

    public readonly componentDidUpdate = (prevProps : IScoutingAssignmentDialogProps, prevState : IScoutingAssignmentDialogState) => {
        if (!prevState.open && this.state.open) {
            const blocks : Array<IAssignmentBlock> = [];

            if (this.props.value) {
                blocks.push({ ...this.props.value.scoutingBlock });
            }

            if (!this.props.value && !!this.props.blockNames && !!this.props.landName) {
                this.props.geoserverblocks
                    .filter(n => !this.props.division || n.departmentShortName.toLocaleLowerCase() === this.props.division.toLocaleLowerCase())
                    .filter(n => n.fieldName.toLocaleLowerCase() === this.props.landName?.toLocaleLowerCase())
                    .filter(n => !this.props.blockNames?.length || this.props.blockNames.includes(n.name))
                    .forEach(n => blocks.push(AssignmentBlockHelper.fromGeoServerBlock(n, this.props.crop, this.props.points.filter(x => x.type === 'scouting'))));
            }

            this.setState({
                division: !this.props.value ? this.props.division : this.props.value.division.toLocaleUpperCase(),
                landName: !this.props.value ? this.props.landName : this.props.value.landName,
                date: !this.props.value ? moment.utc().startOf('day') : moment.utc(this.props.value.date),
                employee: !this.props.value ? undefined : this.props.value.employee,
                employeeNumber: !this.props.value ? undefined : this.props.value.employeeNumber,
                employeeName: !this.props.value ? undefined : this.props.value.employeeName,
                scoutingBlocks: blocks,
            });
        }

    };

    private readonly onIconButtonClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    };

    private readonly save = async () => {
        const { date, scoutingBlocks, employee, employeeName, employeeNumber } = this.state;

        if (!employee || !employeeName || !employeeNumber) return;
        if (!scoutingBlocks.length) return;

        const result = await ScoutingFunctions.saveAssignment(
            date,
            employee,
            employeeNumber,
            employeeName,
            scoutingBlocks,
            this.props.value,
        );

        if (result) {
            this.onClose();
        }
    };

    private readonly onDateChange = (momentDate : Moment | null) => {
        if (!momentDate) return;
        this.setState({
            date: momentDate.utc().startOf('day'),
        });
    };

    private readonly onEmployeeChange = (employee ?: IUser) => {
        if (!employee) return;
        this.setState({
            employee: employee.ref,
            employeeName: employee.name,
            employeeNumber: employee.employeeNumber,
        });
    };

    private readonly onBlockChange = (blocks : Array<IAssignmentBlock>) => {
        this.setState({
            scoutingBlocks: blocks.slice(),
        });

        if (blocks.length) {
            this.mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds(blocks), {
                minZoom: 15,
                animate: false,
            });
        }
    };

    private readonly onBlockLandNameChange = (landName ?: string) => {
        this.setState({
            landName,
        });
        this.onBlockChange([]);
    };

    private readonly onBlockNameChange = (blocks : Array<IAssignmentBlock>) => {
        this.onBlockChange(blocks);
    };

    private readonly onDivisionChange = (division ?: IDivision) => {
        this.setState({
            division: division?.code,
            landName: undefined,
        });
        this.onBlockChange([]);
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        const { value } = this.props;
        if (!value) return;

        this.mapRef = mapRef;
        mapRef.fitBounds(GeoHelper.getAssignmentBlockBounds([value.scoutingBlock]), {
            minZoom: 15,
            animate: false,
        });
    };

    private readonly getScoutingBlocks = (state : IScoutingAssignmentDialogState) => state.scoutingBlocks;
    private readonly getGeoserverblocks = (state : IScoutingAssignmentDialogState, props : IScoutingAssignmentDialogProps) => props.geoserverblocks;
    
    private readonly getBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (
        blocks,
    ) => {
        return blocks.map(n => n.name);
    });
    private readonly getGuids = createSelector([
        this.getScoutingBlocks,
        this.getGeoserverblocks,
    ], (
        blocks,
        geoserverblocks,
    ) => {
        const selectedGuids = blocks.filter(x => x.guid).map(n => n.guid);
        const oldGuids = blocks
            .filter(x => !x.guid)
            .map(block => geoserverblocks
                .find(geoserverblock =>
                    block.division.toLocaleLowerCase() === geoserverblock.departmentShortName.toLocaleLowerCase()
                    && block.landName.toLocaleLowerCase() === geoserverblock.fieldName.toLocaleLowerCase()
                    && block.name === geoserverblock.code)?.guid).filter(x => x);


        return [...selectedGuids, ...oldGuids.map(x => x!)];
    });

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
            isLoading,
            crop,
            disabled,
        } = this.props;
        const {
            open,
            division,
            landName,
            date,
            employee,
            scoutingBlocks,
        } = this.state;

        const blockNames = this.getBlockNames(this.state);
        const blockGuids = this.getGuids(this.state, this.props);

        return (
            <React.Fragment>
                <Tooltip title={!value ? 'Add' : 'Edit'}>
                    <div>
                        <IconButton color='primary' onClick={this.onIconButtonClick}
                            aria-label={!value ? 'Add' : 'Edit'} disabled={disabled || isLoading || !!value?.finished || !!value?.finishedPoints.length }>
                            <Icon>{!value ? 'add' : 'edit'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='scouting-assignment-dialog-title'
                    aria-describedby='scouting-assignment-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    value &&
                                    `Edit Assignment - ${AppFunctionsService.formatDateTimeToDateOnly(value.date)} -
                                     ${value.landName} -
                                     ${value.blockName}
                                    `
                                }
                                {
                                    !value &&
                                    'Create Assignment'
                                }
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc ais flx1'>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        value={date}
                                        onChange={this.onDateChange}
                                        format='YYYY-MM-DD'
                                        label='Date'
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <DivisionAutocomplete
                                        required
                                        fullWidth
                                        value={division}
                                        onChange={this.onDivisionChange}
                                        crop={crop}
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <EmployeeDropdown
                                        value={employee}
                                        fullWidth
                                        required
                                        onChange={this.onEmployeeChange}
                                        division={division}
                                        disabled={!division}
                                    />
                                </div>
                            </div>
                            <div className={'fdr aifs'}>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockLandNameDropdown
                                        crop={crop}
                                        value={landName}
                                        disabled={!division}
                                        division={division}
                                        fullWidth
                                        required
                                        onChange={this.onBlockLandNameChange}
                                        type='scouting'
                                    />
                                </div>
                                <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                                    <ScoutingBlockNameDropdown
                                        value={blockNames}
                                        landName={landName}
                                        disabled={!division || !landName}
                                        division={division}
                                        fullWidth
                                        required
                                        single={!!this.props.value}
                                        onChange={this.onBlockNameChange}
                                        type='scouting'
                                    />
                                </div>
                            </div>
                            <div className={'h280 fdc'}>
                                <BasicMap
                                    id={'scoutingMap'}
                                    onMap={this.setMapRef}
                                    blockCodes={blockNames}
                                >
                                    <MapboxBlockPointLayer
                                        pointsId='point_farm_layer'
                                        minZoom={15}
                                        type='scouting'
                                        blockGuids={blockGuids}
                                    />
                                </BasicMap>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || !scoutingBlocks.length || !employee} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        geoserverblocks: state.masterData.geoserver.blocks,
        points: state.masterData.geoserver.points,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatch(GeneralActions.enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
}, dispatch);

const ScoutingAssignmentDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScoutingAssignmentDialogComponent);

export default ScoutingAssignmentDialog;
