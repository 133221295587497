import React from 'react';
import { IGeoPoint } from '../../types/model/geoPoint';
import { AppBar, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, PropTypes, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { Transitions } from '../customComponents/animations/Transitions';
import StadiumButton from '../customComponents/button/StadiumButton';
import { createSelector } from 'reselect';
import { MapBoxRef } from '../customComponents/mapBox/MapBox';
import BasicMap from '../customComponents/mapBox/BasicMap';
import { Layer, MapMouseEvent, Source } from 'react-map-gl';
import { EventData } from 'mapbox-gl';

interface ILocationEditDialogProps {
    location : IGeoPoint | null;
    elevation : number | null;

    onClose ?: (location : IGeoPoint | null, elevation : number | null) => void;

    disabled ?: boolean;
    color ?: PropTypes.Color;

    iconUrl ?: string | null;
}

interface ILocationEditDialogState {
    open : boolean;
    location : IGeoPoint | null;
    elevation : number | null;

    showMap : boolean;
    imageLoaded : boolean;
}

export default class LocationEditDialog extends React.PureComponent<ILocationEditDialogProps, ILocationEditDialogState> {
    private mapRef : MapBoxRef | null = null;
    private readonly mapZoom = 16;
    private readonly layerId = 'selected_location';

    constructor(props : ILocationEditDialogProps) {
        super(props);
        this.state = {
            location: null,
            elevation: null,
            open: false,
            showMap: false,
            imageLoaded: false,
        };
    }

    private readonly loadImages = () => {
        return new Promise<undefined>((res, rej) => {
            const id = `image_${this.layerId}`;

            if (this.mapRef?.hasImage(id)) {
                res(undefined);
                return;
            }

            this.mapRef?.loadImage(this.props.iconUrl ?? '/assets/images/green_location.png', (error, result) => {
                if (error) {
                    rej();
                    return;
                }
                
                if (result) {
                    this.mapRef?.addImage(id, result);
                    res(undefined);
                    return;
                }

                rej();
            });
        });
    };

    private readonly onClick = () => {
        this.setState({
            open: true,
            location: this.props.location,
            elevation: this.props.elevation,
        });
    };

    private readonly onClose = (event : React.MouseEventHandler<HTMLButtonElement>, reason : 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick') return;
        this.onCloseClick();
    };

    private readonly onCloseClick = () => {
        this.setState({
            open: false,
        });

        this.mapRef?.off('click', this.onMapClick);
    };


    private readonly onSaveClick = () => {
        this.onCloseClick();
        if (!this.props.onClose) return;
        this.props.onClose(this.state.location, this.state.elevation);
    };

    private readonly onLongitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                longitude: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onLatitudeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                latitude: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onElevationChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            elevation: Number(event.currentTarget.value),
        });
    };

    private readonly onMapClick = (event : MapMouseEvent & EventData) => {
        this.setState({
            location: {
                ...this.state.location ?? {
                    latitude: 0,
                    longitude: 0,
                },
                latitude: event.lngLat.lat,
                longitude: event.lngLat.lng,
            },
        });

        event.target.flyTo({
            center: event.lngLat,
            zoom: this.mapZoom,
        });
    };

    private getLocation = (state : ILocationEditDialogState) => state.location;
    private readonly getFeatureCollection = createSelector(
        [
            this.getLocation,
        ],
        (
            location,
        ) => {
            if (!location) return null;
            const geoJson : GeoJSON.Feature<GeoJSON.Point> = {
                type: 'Feature',
                geometry: {
                    coordinates: [location.longitude, location.latitude],
                    type: 'Point',
                },
                properties: {},
            };

            return geoJson;
        },
    );

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;

        this.mapRef.on('click', this.onMapClick);

        this.loadImages().then(() => {
            this.setState({
                imageLoaded: true,
            });
        });

        if (!this.props.location) return;

        mapRef.flyTo({
            center: [this.props.location.longitude, this.props.location.latitude],
            zoom: this.mapZoom,
            animate: false,
        });
    };

    private readonly onAnimationEnd = () => {

        setTimeout(() => {
            this.setState({
                showMap: this.state.open,
            });
        }, 250);
    };

    private readonly onAnimationStart = () => {
        this.setState({
            showMap: false,
            imageLoaded: false,
        });
    };

    public readonly render = () => {
        const {
            color,
            disabled,
        } = this.props;

        const {
            open,
            location,
            elevation,
            showMap,
            imageLoaded,
        } = this.state;

        const data = this.getFeatureCollection(this.state);

        return (
            <>
                <div className='fdc aic jcc'>
                    <Tooltip title='Edit'>
                        <div>
                            <IconButton
                                disabled={disabled}
                                color={color}
                                onClick={this.onClick}
                            >
                                <Edit />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth='md'
                    fullWidth
                    aria-labelledby='location-edit-title'
                    aria-describedby='location-edit-description'
                    onAnimationStart={this.onAnimationStart}
                    onAnimationEnd={this.onAnimationEnd}
                >
                    <AppBar className='fdr posr aic jcc' position='static' elevation={0}>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Location
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={disabled} onClick={this.onCloseClick} aria-label='Add'>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                        <div className='fdc flx1'>
                            <div className={'fdc ml17 mr17 mb13'}>
                                <div className={'fdc flx1 mt15'}>
                                    <div className='fdr'>
                                        <div className={'fdc flx1 jcfs mr10'}>
                                            <FormControl fullWidth error={!location?.longitude}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!location?.longitude}
                                                    id='longitude'
                                                    label='Longitude'
                                                    type='number'
                                                    fullWidth
                                                    value={location?.longitude ?? ''}
                                                    onChange={this.onLongitudeChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !location?.longitude &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'fdc flx1 jcfs ml10'}>
                                            <FormControl fullWidth error={!location?.latitude}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!location?.latitude}
                                                    id='latitude'
                                                    label='Latitude'
                                                    type='number'
                                                    fullWidth
                                                    value={location?.latitude ?? ''}
                                                    onChange={this.onLatitudeChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !location?.latitude &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='fdr'>
                                        <div className={'fdc flx1 jcfs mr10'}>
                                            <FormControl fullWidth error={!elevation}>
                                                <TextField
                                                    autoComplete='off'
                                                    required
                                                    error={!elevation}
                                                    id='elevation'
                                                    label='Elevation'
                                                    type='number'
                                                    fullWidth
                                                    value={elevation ?? ''}
                                                    onChange={this.onElevationChange}
                                                    disabled={disabled}
                                                />
                                                {
                                                    !elevation &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'fdc flx1 jcfs ml10'}>
                                        </div>
                                    </div>
                                </div>
                                <div className={'fdc bcw mt30 h350'}>
                                    {
                                        showMap &&
                                        <BasicMap
                                            id={'locationMap'}
                                            onMap={this.setMapRef}
                                        >
                                            {
                                                data &&
                                                <Source
                                                    type='geojson'
                                                    data={data}
                                                >
                                                    {
                                                        imageLoaded &&
                                                        <Layer
                                                            id={this.layerId}
                                                            type='symbol'
                                                            layout={{
                                                                'icon-image': `image_${this.layerId}`,
                                                                'icon-size': 1,
                                                                'icon-anchor': 'center',
                                                                'icon-allow-overlap': true,
                                                            }}
        
                                                        />
                                                    }
                                                </Source>
                                            }
                                        </BasicMap>
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='fdr'>
                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onCloseClick}>
                                    BACK
                            </StadiumButton>
                            <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                    SAVE
                            </StadiumButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </>
        );
    };
}
