import { FormControl, IconButton, TextField } from '@material-ui/core';
import { AddCircleRounded, RemoveCircleRounded } from '@material-ui/icons';
import React from 'react';

interface ICounterTextFieldProps {
    value : number | null;
    id ?: string;
    min ?: number;
    max ?: number;
    label ?: string;
    disabled ?: boolean;

    onChange ?: (value : number, id ?: string) => void;
}

interface ICounterTextFieldState {}

export default class CounterTextField extends React.PureComponent<ICounterTextFieldProps, ICounterTextFieldState> {
    constructor(props : ICounterTextFieldProps) {
        super(props);
        this.state = {};
    }

    private readonly onAddClick = () => {
        const {
            value,
            onChange,
            max,
            id,
        } = this.props;

        if (!onChange) return;
        if (value !== null && max && max >= value) return;

        onChange(Number(value ?? 0) + 1, id);
    };

    private readonly onRemoveClick = () => {
        const {
            value,
            onChange,
            min,
            id,
        } = this.props;

        if (!onChange) return;
        if (value !== null && min && min >= value) return;

        onChange(Number(value ?? 0) - 1, id);
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            onChange,
            id,
        } = this.props;

        if (!onChange) return;

        onChange(Number(event.currentTarget.value), id);
    };

    public readonly render = () => {
        const {
            value,
            id,
            min,
            max,
            label,
        } = this.props;

        return (
            <div className='fdr aic'>
                <IconButton color='primary' onClick={this.onAddClick}>
                    <AddCircleRounded />
                </IconButton>
                <FormControl className=''>
                    <TextField
                        autoComplete='off'
                        id={id}
                        type='number'
                        fullWidth
                        className=''
                        label={label}
                        value={value === null ? '' : value}
                        onChange={this.onChange}
                        InputProps={{
                            inputProps: {
                                className: 'tac',
                                min,
                                max,
                            },
                        }}
                    />
                </FormControl>
                <IconButton color='primary' onClick={this.onRemoveClick}>
                    <RemoveCircleRounded />
                </IconButton>
            </div>
        );
    };
}
