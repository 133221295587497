import React from 'react';
import MapBox, { MapBoxInitialState } from '../../customComponents/mapBox/MapBox';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../../../appConstants';
import MapboxDivisionLayer from '../../customComponents/mapBox/layer/masterData/DivisionLayer';
import MapboxSubDivisionLayer from '../../customComponents/mapBox/layer/masterData/SubDivisionLayer';
import MapboxDepartmentLayer from '../../customComponents/mapBox/layer/masterData/DepartmentLayer';
import MapboxBlockLayer from '../../customComponents/mapBox/layer/masterData/BlockLayer';
import MapboxFieldLayer from '../../customComponents/mapBox/layer/masterData/FieldLayer';
import { GeolocateControl, MapRef, NavigationControl, ScaleControl } from 'react-map-gl';
import GeoServerLayerControl from '../../customComponents/mapBox/control/GeoServerLayerControl';

interface IBasicMapProps {
    id : string;

    onMap ?: (mapRef : MapRef) => void;

    divisionGuid ?: string | null;
    subDivisionGuid ?: string | null;
    departmentGuid ?: string | null;
    fieldGuid ?: string | null;
    blockGuid ?: string | null;
    blockGuids ?: Array<string> | null;
    blockCodes ?: Array<string> | null;

    blockMaxZoom ?: number;
}

interface IBasicMapState {
    initialViewState : MapBoxInitialState | null;

    divisionVisible : boolean;
    subDivisionVisible : boolean;
    departmentVisible : boolean;
    fieldVisible : boolean;
    blockVisible : boolean;
}

export default class BasicMap extends React.PureComponent<IBasicMapProps, IBasicMapState> {
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : IBasicMapProps) {
        super(props);
        this.state = {
            initialViewState: null,
            divisionVisible: true,
            subDivisionVisible: true,
            departmentVisible: true,
            fieldVisible: true,
            blockVisible: true,
        };
    }

    public componentDidMount() : void {
        this.setState({
            initialViewState: {
                latitude: DEFAULT_MAP_CENTER.lat,
                longitude: DEFAULT_MAP_CENTER.lng,
                zoom: DEFAULT_MAP_ZOOM,
            },
        });
    }

    private readonly setMapRef = (mapRef ?: MapRef | null) => {
        if (!mapRef) return;
        if (this.props.onMap) this.props.onMap(mapRef);
    };

    private readonly onDivisionLayerControlClick = (checked ?: boolean) => {
        this.setState({
            divisionVisible: checked ?? true,
        });
    };

    private readonly onSubDivisionLayerControlClick = (checked ?: boolean) => {
        this.setState({
            subDivisionVisible: checked ?? true,
        });
    };

    private readonly onDepartmentLayerControlClick = (checked ?: boolean) => {
        this.setState({
            departmentVisible: checked ?? true,
        });
    };

    private readonly onFieldLayerControlClick = (checked ?: boolean) => {
        this.setState({
            fieldVisible: checked ?? true,
        });
    };

    private readonly onBlockLayerControlClick = (checked ?: boolean) => {
        this.setState({
            blockVisible: checked ?? true,
        });
    };

    public readonly render = () => {
        const {
            initialViewState,
            divisionVisible,
            subDivisionVisible,
            departmentVisible,
            fieldVisible,
            blockVisible,
        } = this.state;

        const { 
            id,
            divisionGuid,
            subDivisionGuid,
            blockGuid,
            departmentGuid,
            fieldGuid,
            children,
            blockCodes,
            blockGuids,
        } = this.props;

        if (!initialViewState) return null;
        
        return (
            <MapBox
                id={id}
                mapRef={this.setMapRef}
                reuseMaps
                initialViewState={initialViewState}
                mapStyle='mapbox://styles/mapbox/satellite-streets-v12'
                projection={{
                    name: 'globe',
                }}
            >
                <GeolocateControl
                    positionOptions={{
                        enableHighAccuracy: true,
                    }}
                    trackUserLocation
                    showUserHeading
                />
                <NavigationControl />
                <ScaleControl />
                <GeoServerLayerControl
                    onDivisionClick={this.onDivisionLayerControlClick}
                    divisionChecked={divisionVisible}
                    onSubDivisionClick={this.onSubDivisionLayerControlClick}
                    subDivisionChecked={subDivisionVisible}
                    onDepartmentClick={this.onDepartmentLayerControlClick}
                    departmentChecked={departmentVisible}
                    onFieldClick={this.onFieldLayerControlClick}
                    fieldChecked={fieldVisible}
                    onBlockClick={this.onBlockLayerControlClick}
                    blockChecked={blockVisible}
                />
                <MapboxBlockLayer
                    polygonId='polygon_block_layer'
                    labelId='label_block_layer'
                    strokeId='stroke_block_layer'
                    minZoom={this.blockZoom}
                    maxZoom={this.props.blockMaxZoom}
                    divisionGuid={divisionGuid}
                    subDivisionGuid={subDivisionGuid}
                    departmentGuid={departmentGuid}
                    fieldGuid={fieldGuid}
                    blockGuid={blockGuid}
                    visible={blockVisible}
                    blockCodes={blockCodes}
                    blockGuids={blockGuids}
                />
                <MapboxFieldLayer
                    polygonId='polygon_field_layer'
                    labelId='label_field_layer'
                    strokeId='stroke_field_layer'
                    minZoom={this.fieldZoom}
                    divisionGuid={divisionGuid}
                    subDivisionGuid={subDivisionGuid}
                    departmentGuid={departmentGuid}
                    fieldGuid={fieldGuid}
                    labelBeforeId='stroke_block_layer'
                    visible={fieldVisible}
                />
                <MapboxDepartmentLayer
                    polygonId='polygon_department_layer'
                    labelId='label_department_layer'
                    strokeId='stroke_department_layer'
                    minZoom={this.departmentZoom}
                    maxZoom={this.fieldZoom}
                    divisionGuid={divisionGuid}
                    subDivisionGuid={subDivisionGuid}
                    departmentGuid={departmentGuid}
                    visible={departmentVisible}
                />
                <MapboxSubDivisionLayer
                    polygonId='polygon_sub_division_layer'
                    labelId='label_sub_division_layer'
                    strokeId='stroke_sub_division_layer'
                    maxZoom={this.departmentZoom}
                    minZoom={this.subDivisionZoom}
                    divisionGuid={divisionGuid}
                    subDivisionGuid={subDivisionGuid}
                    visible={subDivisionVisible}
                />
                <MapboxDivisionLayer
                    polygonId='polygon_division_layer'
                    labelId='label_division_layer'
                    strokeId='stroke_division_layer'
                    maxZoom={this.subDivisionZoom}
                    divisionGuid={divisionGuid}
                    visible={divisionVisible}
                />
                {
                    children
                }
            </MapBox>
        );
    };
}
