import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { createSelector } from 'reselect';
import { CROP } from '../../../appConstants';
import moment from 'moment';
import NematodeFunctions from '../../../store/nematode/functions';
import NavFunctions from '../../../store/nav/functions';
import { IAssignmentBlock } from '../../../types/model/masterData/block';
import { IDivision } from '../../../types/model/rights/division';
import { INematodeAssignment } from '../../../types/model/nematode/nematodeAssignment';
import NematodeAssignmentCreateDialog from './dialog/AssignmentCreateDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import { CircularProgress } from '@material-ui/core';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import GeneralNematodeAssignmentFieldSelector from './general/AssignmentFieldSelector';
import MapboxPointLayer from '../../customComponents/mapBox/layer/masterData/PointLayer';

interface INematodeNematodeAssignmentCreateProps extends RouteComponentProps {
    isLoading : boolean;

    assignments : Array<INematodeAssignment>;
}

interface INematodeNematodeAssignmentCreateState {

    selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>;

    date : moment.Moment;
    division ?: IDivision;

    showCreate : boolean;

    showScoutHistory : boolean;
    nematodeHistory ?: number;
}

class NematodeAssignmentCreateComponent extends React.Component<INematodeNematodeAssignmentCreateProps, INematodeNematodeAssignmentCreateState> {
    private mapRef : MapBoxRef | null = null;

    constructor(props : INematodeNematodeAssignmentCreateProps) {
        super(props);
        this.state = {
            selectedScoutingBlocks: {},
            date: moment.utc().startOf('day'),
            showCreate: false,
            showScoutHistory: false,
        };
    }

    public readonly componentDidMount = () => {
        NematodeFunctions.getAssignments(this.getCrop(this.props), false);
    };

    private readonly getSearch = (props : INematodeNematodeAssignmentCreateProps) => props.location.search;
    private readonly getCrop = createSelector([
        this.getSearch,
    ], (search) => {
        const urlParams = new URLSearchParams(search);
        const crop = urlParams.get('crop');
        return crop as CROP;
    });

    private readonly onWeekChanged = (date ?: moment.Moment) => {
        if (!date) return;

        this.setState({
            date,
        });
    };

    private readonly onDivisionChanged = (division ?: IDivision) => {
        this.setState({
            division,
            selectedScoutingBlocks: {},
        });
    };

    private readonly onFieldsChange = (selectedScoutingBlocks : Record<string, Array<IAssignmentBlock>>) => {
        this.setState({
            selectedScoutingBlocks,
        });
    };

    private readonly onAssignmentAddClick = () => {
        this.setState({
            showCreate: !this.state.showCreate,
        });
    };

    private readonly onAssignmentCreateClose = (result : boolean) => {
        this.setState({
            showCreate: !this.state.showCreate,
        });

        if (result) {
            NavFunctions.navReplacePath('/nematode/assignments', this.props.location.search);
        }
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
    };

    public render = () => {
        const { isLoading } = this.props;
        const {
            division,
            date,
            selectedScoutingBlocks,
            showCreate,
        } = this.state;

        const crop = this.getCrop(this.props);

        return (
            <div className={'fdc hfill p10 mh0 mw0 bcg0'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <div className='fdr hfill'>
                        <Paper className={'fdc flx3 p5 m5'}>
                            <BasicMap
                                id={'scoutingMap'}
                                onMap={this.setMapRef}
                            >
                                <MapboxPointLayer
                                    pointsId='point_farm_layer'
                                    minZoom={15}
                                    type='scouting'
                                    crop={crop}
                                />
                            </BasicMap>
                        </Paper>
                        <GeneralNematodeAssignmentFieldSelector
                            mapRef={this.mapRef}
                            crop={crop}
                            selectedNematodeBlocks={selectedScoutingBlocks}
                            date={date}
                            onChange={this.onFieldsChange}
                            onWeekChanged={this.onWeekChanged}
                            division={division}
                            onDivisionChanged={this.onDivisionChanged}
                            onAssignmentAddClick={this.onAssignmentAddClick}
                        />
                    </div>
                }
                <NematodeAssignmentCreateDialog
                    open={showCreate}
                    isLoading={isLoading}
                    date={date}
                    division={division?.code}
                    transition={Transitions.Down}
                    maxWidth='md'
                    fullWidth
                    onClose={this.onAssignmentCreateClose}
                    scoutingBlocks={selectedScoutingBlocks}
                    assignmentType={'general'}
                    crop={crop}
                />
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.nematode.isLoadingAssignments,
        assignments: state.nematode.assignments,
    };
};

const NematodeAssignmentCreate = connect(
    mapStateToProps,
)(withRouter(NematodeAssignmentCreateComponent));

export default NematodeAssignmentCreate;