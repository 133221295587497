import { Icon, IconButton, List, ListItem, ListItemText, Switch } from '@material-ui/core';
import React from 'react';
import InsectFunctions from '../../../../store/masterData/insect/functions';
import ToggleButton from '../../../customComponents/button/ToggleButton';
import ScoutingInsectMultiselect from '../../../customComponents/dropdowns/scouting/InsectMultiselect';

interface IScoutingInsectSelectorProps {
    sections ?: Array<string>;
    components ?: Array<string>;
    insects ?: Array<string>;
    onInsectsChange ?: (insects ?: Array<string> | null) => void;
}

interface IScoutingInsectSelectorState {
    edit : boolean;
    toggle : boolean;
}

class ScoutingInsectSelector extends React.PureComponent<IScoutingInsectSelectorProps, IScoutingInsectSelectorState> {
    constructor(props : IScoutingInsectSelectorProps) {
        super(props);
        this.state = {
            edit: true,
            toggle: false,
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public loadData = async () => {
        InsectFunctions.getList();
    };

    private readonly onClose = () => {
        if (!this.props.insects?.length) return;
        this.setState({
            edit: false,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            edit: !this.state.edit,
        });
    };

    private readonly onToggleClick = () => {
        this.setState({
            toggle: !this.state.toggle,
            edit: !this.state.toggle,
        });

        if (!this.props.onInsectsChange) return;

        this.props.onInsectsChange([]);
    };

    public render = () => {
        const { sections, insects, components, onInsectsChange } = this.props;
        const { edit, toggle } = this.state;
        return (
            <>
                <List className='mt15' disablePadding dense>
                    <ListItem className={'bcig cw'} key={'SPECIFIC - Insect'}>
                        <ListItemText primary={'SPECIFIC - Insect'} title={'SPECIFIC - Insect'} />
                        {
                            toggle &&
                            !edit &&
                            <IconButton onClick={this.onEditClick} color='secondary' size='small'>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        <Switch
                            id='toggle'
                            size='small'
                            checked={toggle}
                            onClick={this.onToggleClick}
                            color='primary'
                            disabled={!components?.length}
                        />
                    </ListItem>
                </List>
                {
                    toggle &&
                    !edit &&
                    insects &&
                    <div className='fdc aic p10'>
                        <div className='fdr jcsb fww'>
                            {
                                insects.map((n, i) => (
                                    <ToggleButton
                                        key={n}
                                        className={`mt15 ${i !== (insects.length - 1) ? 'mr15' : ''}`}
                                        value={`${n}`}
                                        selected
                                        disabled
                                    >
                                        {`${n}`.toLocaleUpperCase()}
                                    </ToggleButton>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    toggle &&
                    edit &&
                    <div className='fdc aifs p10'>
                        <ScoutingInsectMultiselect
                            value={insects ?? []}
                            onChange={onInsectsChange}
                            fullWidth
                            sections={sections}
                            components={components}
                            onClose={this.onClose}
                        />
                    </div>
                }
            </>
        );
    };
}

export default ScoutingInsectSelector;
