import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { IUserSession } from '../../../types/model/user';
import { ITrap } from '../../../types/model/trap/trap';
import { Check, Close, Warning } from '@material-ui/icons';
import EditTrapInfo from '../dialog/EditInfoDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import TrapsDownloadQrCodeButton from '../dialog/DownloadQrCodes';
import { createSelector } from 'reselect';
import LocationEditDialog from '../../dialog/LocationEditDialog';
import { IGeoPoint } from '../../../types/model/geoPoint';
import TrapFunctions from '../../../store/trap/functions';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import MapboxTrapLayer from '../../customComponents/mapBox/layer/trap/TrapLayer';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';

interface ITrapSummaryViewProps {
    trap : ITrap;
    
    session ?: IUserSession | null;

    isLoading ?: boolean;
}

interface ITrapSummaryViewState {
    showMap : boolean;
}

export default class TrapSummaryView extends React.PureComponent<ITrapSummaryViewProps, ITrapSummaryViewState> {
    private readonly mapZoom = 16;

    constructor(props : ITrapSummaryViewProps) {
        super(props);
        this.state = {
            showMap: false,
        };

    }

    private getTrap = (state : ITrapSummaryViewState, props : ITrapSummaryViewProps) => props.trap;
    private getCenter = createSelector(
        [this.getTrap],
        (trap) => {
            if (!trap.location) return null;
            return {
                lat: trap.location.latitude,
                lng: trap.location.longitude,
            };
        },
    );

    private readonly saveLocation = async (location : IGeoPoint, elevation : number) => {
        const { trap } = this.props;
        
        try {
            await TrapFunctions.save({
                ...trap,
                location,
                elevation,
            });
        } catch (ex) {
            // Ignore
        }

    };

    private readonly onLocationEditClose = (location : IGeoPoint | null, elevation : number | null) => {
        if (!location) return;
        if (!elevation) return;

        this.saveLocation(location, elevation);
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        if (!this.props.trap.location) return;
        mapRef.flyTo({
            zoom: this.mapZoom,
            center: [this.props.trap.location.longitude, this.props.trap.location.latitude],
            animate: false, 
        });
    };

    private readonly onMapDivRef = () => {
        setTimeout(() => {
            this.setState({
                showMap: true,
            });
        }, 250);
    };

    public readonly render = () => {
        const {
            trap,
            session,
            isLoading,
        } = this.props;

        const {
            showMap,
        } = this.state;

        const center = this.getCenter(this.state, this.props);

        return (
            <div className='fdc flx1 pt20 bcg0'>
                <div className='fdr aic jcc'>
                    <Card className={'fdc bcw mt30'}>
                        <div className={'fdr bcinfo cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                TRAP INFO
                            </Typography>
                            <div className='posr aic jcc'>
                                <div className='posa posr0'>
                                    {
                                        session?.user.permissions.isTrapAdmin &&
                                        <EditTrapInfo
                                            trap={trap}
                                            isLoading={isLoading}
                                            fullWidth
                                            maxWidth='sm'
                                            transition={Transitions.Down}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'fdc flx1 ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        CODE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            trap.code
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        DIVISION
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            trap.divisionName
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        TYPE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            trap.typeName
                                        }
                                    </Typography>
                                </div>
                                {
                                    trap.landName &&
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            FIELD
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                            {
                                                trap.landName
                                            }
                                        </Typography>
                                    </div>
                                }
                                {
                                    trap.blockName &&
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            BLOCK
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                            {
                                                trap.blockName
                                            }
                                        </Typography>
                                    </div>
                                }
                                {
                                    trap.description &&
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            DESCRIPTION
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                            {
                                                trap.description
                                            }
                                        </Typography>
                                    </div>
                                }
                                {
                                    trap.otherDescription &&
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            OTHER DESCRIPTION
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                            {
                                                trap.otherDescription
                                            }
                                        </Typography>
                                    </div>
                                }
                                <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        FARM MONITOR
                                    </Typography>
                                    {
                                        trap.monitoring &&
                                        <Check className={'cp mt10'} />
                                    }
                                    {
                                        !trap.monitoring &&
                                        <Close className={'cpr mt10'} />
                                    }
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className='fdr aic jcc'>
                    <Card className={'fdc bcw mt30 w600 h350'}>
                        <div className={'fdr bcinfo cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                LOCATION
                            </Typography>
                            <div className='posr aic jcc'>
                                <div className='posa posr0'>
                                    {
                                        session?.user.permissions.isTrapAdmin &&
                                        trap.location &&
                                        <LocationEditDialog
                                            location={trap.location}
                                            disabled={isLoading}
                                            elevation={trap.elevation ?? 0}
                                            color='secondary'
                                            onClose={this.onLocationEditClose}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'fdc flx1'}>
                            {
                                !trap.location &&
                                <div className={'fdc  flx1 aic jcc'}>
                                    <div className='fdc mb20'>
                                        <Warning className='co h36 w36' />
                                    </div>
                                    <Typography
                                        className='fwb fs15 mb20'
                                    >
                                        NOT ACTIVATED
                                    </Typography>
                                    <Typography
                                        className='fs11 ml100 mr100 tac'
                                    >
                                        This trap{'\u2019'}s location is not yet activated. Please scan QR sticker in the field destination when doing survey to activate location.
                                    </Typography>
                                </div>
                            }
                            {
                                center &&
                                trap.location &&
                                <div className='fdc flx1'>
                                    <div className={'fdc ml17 mr17 mb13'}>
                                        <div className={'flx1 fdr'}>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    LATITUDE
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                                    {
                                                        trap.location.latitude.toFixed(6)
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    LONGITUDE
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                                    {
                                                        trap.location.longitude.toFixed(6)
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                                <Typography className={'fs11 cdg'}>
                                                    ELEVATION
                                                </Typography>
                                                <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                                    {
                                                        trap.elevation?.toFixed(6) ?? '-'
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flx1' ref={this.onMapDivRef}>
                                        {
                                            showMap &&
                                            <BasicMap
                                                id={'trapMap'}
                                                onMap={this.setMapRef}
                                                
                                            >
                                                <MapboxTrapLayer
                                                    markersLayerId='point_farm_layer'
                                                    minZoom={1}
                                                    markersBeforeId='polygon_division_layer'
                                                    trapCode={trap.code}
                                                />
                                            </BasicMap>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                <span className='flx1' />
                <div className='fdr aic mb15 mr15'>
                    <span className='flx1' />
                    <TrapsDownloadQrCodeButton value={trap} />
                </div>
            </div>
        );
    };
}
