import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { BaseHelper, IBase } from '../../base';
import { IScoutingFile } from '../file';
import firebase from 'firebase/app';

export interface IPhenology extends IBase {
    type : 'general' | 'specific';
    crop : CROP;
    sections : Array<IPhenologySection>;
}

export interface IPhenologySection {
    name : string;
    options : Array<IPhenologySectionOption>;
    optional : boolean;
    validMonths : Array<number>;
}

export interface IPhenologySectionOption {
    enforceMax : boolean;
    name : string;
    group : string;
    subGroup : string;
    images : Array<IScoutingFile>;
    options : Array<IPhenologySectionOptionsOption>;
}

export interface IPhenologySectionOptionsOption {
    name : string;
    value : number;
}

export default class PhenologyHelper extends BaseHelper {
    public static readonly COLLECTION = 'phenology';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenology> = {
        fromFirestore: (snapshot) => {
            return PhenologyHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : IPhenology) => {
            return PhenologyHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenology {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as IPhenology,
            ...result,
        };
    }
    protected static toFirestore(data : IPhenology) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;
        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static save(phenology : IPhenology) {
        if (phenology.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(phenology.id).set(phenology);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(phenology);
    }
}