import { Typography } from '@material-ui/core';
import React from 'react';

interface IScoutingListboxProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLElement>> {}

interface IScoutingListboxState {}

class ScoutingListboxComponent extends React.PureComponent<IScoutingListboxProps, IScoutingListboxState> {
    constructor(props : IScoutingListboxProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { title, ...rest } = this.props;
        return (
            <>
                {
                    title &&
                    <Typography className='fs14 ml24 mt10'>
                        {
                            title
                        }
                    </Typography>
                }
                <ul {...rest} />
            </>
        );
    };
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const ScoutingListbox = React.forwardRef<unknown, IScoutingListboxProps>((props, ref) => <ScoutingListboxComponent {...props} />);

export default ScoutingListbox;