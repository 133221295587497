import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import DivisionHelper, { IDivision } from '../../../types/model/rights/division';
import { CircularProgress } from '@material-ui/core';
import GeneralFunctions from '../../../store/general/functions';

interface IDivisionSelectCardProps {
    value : Array<string>;

    onDivisionCheckChange ?: (division : IDivision) => void;
}

interface IDivisionSelectCardState {
    divisions : Array<IDivision>;
    isLoading : boolean;
}

class DivisionSelectCardComponent extends React.PureComponent<IDivisionSelectCardProps, IDivisionSelectCardState> {
    private listener ?: () => void;

    constructor(props : IDivisionSelectCardProps) {
        super(props);
        this.state = {
            divisions: [],
            isLoading: false,
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public componentWillUnmount = () => {
        if (!this.listener) return;
        this.listener();
    };

    public loadData = () => {
        if (this.listener) return;

        this.setState({
            isLoading: true,
        });

        this.listener = DivisionHelper
            .collection()
            .onSnapshot((snapshot) => {
                this.setState({
                    divisions: snapshot.docs.map(x => x.data()),
                    isLoading: false,
                });
            }, (err) => {
                GeneralFunctions.generalShowErrorSnackbar('An error while loading divisions.', err);
            });
    };

    private getData = (state : IDivisionSelectCardState) => state.divisions;

    private getDivisions = createSelector([
        this.getData,
    ], (divisions) => {
        return divisions
            .filter(x => !!x.isActive)
            .sort((a, b) => a.name.localeCompare(b.name));
    });

    private onDivisionCheck = (event : React.ChangeEvent<HTMLInputElement>) => {
        const division = this.state.divisions.find(x => x.id === event.target.value);

        if (division && this.props.onDivisionCheckChange) {
            this.props.onDivisionCheckChange(division);
        }
    };

    public render = () => {
        const { value } = this.props;
        const { isLoading } = this.state;
        const divisions = this.getDivisions(this.state);
        return (
            <div className='fdc ais flx1'>
                {
                    !isLoading &&
                    <div className='fdr fww m10'>
                        {
                            lodash.map(divisions, n => (
                                <div style={{
                                    width: 350,
                                }} key={`div_${n.id}`} >
                                    <FormControlLabel control={
                                        <Checkbox
                                            color='primary'
                                            value={n.id}
                                            checked={!!value.includes(n.code)}
                                            onChange={this.onDivisionCheck}
                                        />
                                    }
                                    label={n.name}
                                    />
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    isLoading &&
                    <div className='fdc aic jcc flx1'>
                        <CircularProgress />
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};


const DivisionSelectCard = connect(
    mapStateToProps,
)(DivisionSelectCardComponent);

export default DivisionSelectCard;
