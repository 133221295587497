import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import { INematodeAssignment } from '../../../../types/model/nematode/nematodeAssignment';
import AppFunctionsService from '../../../../services/appFunctionServices';
import BasicMap from '../../../customComponents/mapBox/BasicMap';
import ScoutingAssignmentFinishedMarkers from '../../../customComponents/mapBox/layer/scouting/FinishedPointsLayer';
import { MapBoxRef } from '../../../customComponents/mapBox/MapBox';
import GeoHelper from '../../../../services/helper/geoHelper';
import NematodeAssignmentLocationHistoryMarkers from '../../../customComponents/mapBox/layer/nematode/LocationHistoryLayer';
import lodash from 'lodash';
import MapboxBlockPointLayer from '../../../customComponents/mapBox/layer/masterData/PointLayer';

interface INematodeAssignmentInfoViewProps {
    value : INematodeAssignment;
}

interface INematodeAssignmentInfoViewState {
}

export default class NematodeAssignmentInfoView extends React.PureComponent<INematodeAssignmentInfoViewProps, INematodeAssignmentInfoViewState> {
    constructor(props : INematodeAssignmentInfoViewProps) {
        super(props);
        this.state = {
        };
    }
    
    private readonly getScoutingBlock = (props : INematodeAssignmentInfoViewProps) => props.value.scoutingBlock;
    private readonly getFinishedGuids = (props : INematodeAssignmentInfoViewProps) => props.value.finishedPoints;
    private readonly getFinishedPoints = createSelector(
        [this.getFinishedGuids, this.getScoutingBlock],
        (guids, block) => {
            return lodash
                .chain(guids)
                .map(n => block.points.features.filter(x => x.properties.guid === n))
                .flatMap()
                .map(x => x.geometry.coordinates)
                .value();
        },
    );

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        const { value } = this.props;

        mapRef.fitBounds(GeoHelper.getAssignmentBlockBounds([value.scoutingBlock]), {
            minZoom: 15,
            animate: false,
        });
    };

    public render = () => {
        const {
            value,
        } = this.props;

        const finishedPoints = this.getFinishedPoints(this.props);
        return (
            <div className='fdr flx1 ais'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BasicMap
                            id={'nematodeAssignmentMap'}
                            onMap={this.setMapRef}
                        >
                            <MapboxBlockPointLayer
                                pointsId='point_farm_layer'
                                minZoom={15}
                                type='scouting'
                                blockGuids={[value.block]}
                            />
                            <NematodeAssignmentLocationHistoryMarkers
                                polygonId={'point_history_layer'}
                                minZoom={15}
                                assignmentId={value.id}
                            />
                            <ScoutingAssignmentFinishedMarkers
                                polygonId={'point_finished_layer'}
                                minZoom={15}
                                finishedPoints={finishedPoints}
                            />
                        </BasicMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                ASSIGNMENT
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                DATE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {AppFunctionsService.formatDateTimeToDateOnly(value.date)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                COMPLETED POINTS
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.finishedPoints.length} / ${value.scoutingBlock.points.features.length}`}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                SCOUT
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.employeeNumber} - ${value.employeeName}`}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                DIVISION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.division.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                LAND NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.landName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                BLOCK NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.blockName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                LAND DESCRIPTION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.description.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                CROP
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.crop.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                AGE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.age.toFixed(0)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                AREA
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    !value.scoutingBlock.ha &&
                                    '-'
                                }
                                {
                                    !!value.scoutingBlock.ha &&
                                    `${value.scoutingBlock.ha.toFixed(2)} Ha`
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
