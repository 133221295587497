import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import MaterialTable from '../../../customComponents/materialTable/Table';
import { Add, Check, Close, Edit } from '@material-ui/icons';
import StandardFab from '../../../customComponents/button/StandardFab';
import TrapFarmDescriptionEditDialog from './dialog/Edit';
import moment from 'moment';
import { ITrapFarmDescription } from '../../../../types/model/masterData/trap/farmDescription';
import TrapFarmDescriptionFunctions from '../../../../store/masterData/trap/farmDescription/functions';

interface ITrapFarmDescriptionListProps extends RouteComponentProps {
    farmDescriptions : Array<ITrapFarmDescription>;
    isLoading : boolean;
}

interface ITrapFarmDescriptionListState {
    selected : ITrapFarmDescription | null;
}

class TrapFarmDescriptionListComponent extends React.PureComponent<ITrapFarmDescriptionListProps, ITrapFarmDescriptionListState> {
    constructor(props : ITrapFarmDescriptionListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    public readonly load = () => {
        TrapFarmDescriptionFunctions.getList();
    };

    public onAddClick = () => {
        this.setState({
            selected: {
                id: '',
                description: '',
                isActive: true,
                createdBy: '',
                createdByEmployee: '',
                createdByName: '',
                createdOn: moment.utc().valueOf(),
                updatedBy: '',
                updatedByEmployee: '',
                updatedByName: '',
                updatedOn: moment.utc().valueOf(),
            },
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const {
            farmDescriptions,
        } = this.props;

        const value = farmDescriptions.find(x => x.id === event.currentTarget.value);
        if (!value) return;

        this.setState({
            selected: {
                ...value,
            },
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selected: null,
        });
    };

    public render = () => {
        const {
            isLoading,
            farmDescriptions,
        } = this.props;
        const {
            selected,
        } = this.state;
        
        return (
            <div className={'fdc hfill mh0 mw0 bcg0'}>
                <div className='fdc flx1 p20'>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ITrapFarmDescription>
                            id='farmDescriptionsTable'
                            data={farmDescriptions}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton color='primary' value={row.id} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Description',
                                field: 'description',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Close className='cpr' />) : (<Check className='cdg' />),
                            }]}
                        />
                    </Card>
                    <TrapFarmDescriptionEditDialog
                        value={selected}
                        onClose={this.onEditClose}
                        isLoading={isLoading}
                    />
                </div>
                <div className='fdr aife jcfe'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                                <Add />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        farmDescriptions: state.masterData.trap.farmDescription.farmDescriptions,
        isLoading: state.masterData.trap.farmDescription.isLoading,
    };
};


const TrapFarmDescriptionList = withRouter(connect(
    mapStateToProps,
)(TrapFarmDescriptionListComponent));

export default TrapFarmDescriptionList;