import firebase from 'firebase/app';
import { BaseHelper, IBase } from '../../base';
import { NematodeAssignmentHelper } from './nematodeAssignment';
import { GeoPointHelper, IGeoPoint } from '../geoPoint';
import UserHelper from '../user';
import FirestoreHelper from '../../../services/helper/firestoreHelper';

export interface INematodeAssignmentLocationEntry extends IBase {
    /** Assignment Path */
    assignmentRef : string;
    assignmentId : string;

    employeeName : string;
    employeeNumber : string;
    /** Employee Path */
    employee : string;

    date : number;

    latitude : number;
    longitude : number;
    geo : IGeoPoint;
    mocked : boolean;
    altitude : number;
    accuracy : number;
    heading : number;
    speed : number;
    speedAccuracy : number;
}

export class NematodeAssignmentLocationEntryHelper extends BaseHelper {
    public static readonly COLLECTION = 'nematode_entries_locations';

    public static converter : firebase.firestore.FirestoreDataConverter<INematodeAssignmentLocationEntry> = {
        fromFirestore: (snapshot) => {
            return NematodeAssignmentLocationEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : INematodeAssignmentLocationEntry) => {
            return NematodeAssignmentLocationEntryHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : INematodeAssignmentLocationEntry {
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');

        return {
            ...data as INematodeAssignmentLocationEntry,
            geo: GeoPointHelper.fromFirestore(data['geo']),
            employee: (data['employee'] as firebase.firestore.DocumentReference | null)?.id ?? '',
            assignmentRef: (data['assignmentRef'] as firebase.firestore.DocumentReference | null)?.id ?? '',
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
        };
    }

    protected static toFirestore(data : INematodeAssignmentLocationEntry) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;
        return {
            ...rest,
            ...result,
            geo: GeoPointHelper.toFirestore(data.geo),
            employee: UserHelper.get(data.employee),
            assignmentRef: NematodeAssignmentHelper.doc(data.assignmentRef),
            date: FirestoreHelper.millisToTimestamp(data.date),
        };
    }

    public static collection(assignmentId ?: string) {
        if (!assignmentId) {
            return NematodeAssignmentHelper.collection().withConverter(this.converter);
        }
        return NematodeAssignmentHelper.doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(assignmentId : string, id ?: string) {
        if (!id) {
            return NematodeAssignmentLocationEntryHelper.collection(assignmentId).doc();
        }

        return NematodeAssignmentLocationEntryHelper.collection(assignmentId).doc(id);
    }

}