import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { connect } from 'react-redux';
import { CROP, CROPS } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionServices';

interface ICropAutocompleteProps {
    id ?: string;
    value ?: CROP | string | null;

    onChange : (value ?: CROP, event ?: React.ChangeEvent<unknown>) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    disabled ?: boolean;
    name ?: string;

    label ?: string;
}

interface ICropAutocompleteState {
}

class CropAutocompleteComponent extends React.Component<ICropAutocompleteProps, ICropAutocompleteState> {
    constructor(props : ICropAutocompleteProps) {
        super(props);

        this.state = {
        };
    }

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        const result = CROPS.find(n => n === value?.value);

        this.props.onChange(result, event);
    };

    private getValue = (state : ICropAutocompleteState, props : ICropAutocompleteProps) => props.value;
    private getRequired = (state : ICropAutocompleteState, props : ICropAutocompleteProps) => props.required;

    private getCrops = createSelector([
        this.getRequired,
    ], (
        required,
    ) => {
        const cropsDrop : Array<{
            label : string;
            value : string | CROP;
        }> = CROPS
            .sort((a, b) => a.localeCompare(b))
            .map(x => ({ label: `${AppFunctionsService.toTitleCase(x)}`, value: x }));

        if (!required) {
            cropsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return cropsDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired,
    ], (value, required,) => {
        if (value && typeof(value) === 'string') {
            const crop = CROPS.slice().find(x => x === value);

            if (crop) {
                return {
                    label: `${AppFunctionsService.toTitleCase(crop)}`,
                    value: crop,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const {
            required,
            fullWidth,
            disabled,
            label,
        } = this.props;

        const crops = this.getCrops(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    disabled={disabled}
                    id='crop_select'
                    options={crops}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => (
                        <TextField
                            error={required && !value}
                            required={required}
                            {...params}
                            fullWidth={fullWidth}
                            label={label ?? 'Crop'}
                        />)}
                />
                {
                    required && !value &&
                        <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const CropAutocomplete = connect(
)(CropAutocompleteComponent);

export default CropAutocomplete;
