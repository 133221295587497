import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface IPointButtonProps extends ButtonProps {
    target ?: string;
}

interface IPointButtonState {}

export default class PointButton extends React.PureComponent<IPointButtonProps, IPointButtonState> {
    constructor(props : IPointButtonProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { children, classes: _, className, ...other } = this.props;
        return (
            <Button className={`${className} bcw`} { ...other }>
                {
                    children
                }
            </Button>
        );
    };
}
