import firebase from 'firebase/app';
import { dispatch, getState } from '../../..';
import GeneralFunctions from '../../../general/functions';
import TrapEntryCommentActions from './actions';
import * as uuid from 'uuid';
import TrapEntryCommentHelper, { ITrapEntryComment } from '../../../../types/model/masterData/trap/trapEntryComment';

export default class TrapEntryCommentFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && TrapEntryCommentFunctions.listener) {
            return;
        }

        if (TrapEntryCommentFunctions.listener) {
            TrapEntryCommentFunctions.listener();
        }

        dispatch(TrapEntryCommentActions.setLoading(true));
        dispatch(TrapEntryCommentActions.setList([]));

        try {
            
            TrapEntryCommentFunctions.listener = TrapEntryCommentHelper
                .collection()
                .orderBy('comment')
                .onSnapshot((snapshot) => {
                    const trapEntryCommentState = getState().masterData.trap.trapEntryComment;

                    const trapEntryComments = trapEntryCommentState.trapEntryComments.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();

                        switch (f.type) {
                        case 'added':
                            trapEntryComments.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            trapEntryComments.splice(f.newIndex, 1, data);
                            break;
                        case 'removed':
                            trapEntryComments.splice(f.oldIndex, 1);
                            break;
                        }
                    });

                    dispatch(TrapEntryCommentActions.setList(trapEntryComments));
                    dispatch(TrapEntryCommentActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading comments.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading comments.', ex);
            dispatch(TrapEntryCommentActions.setLoading(false));
        }
    }

    public static save = async (item : ITrapEntryComment) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const trapEntryComments = getState().masterData.trap.trapEntryComment.trapEntryComments;

        if (trapEntryComments.some(x =>
            x.comment === item.comment &&
            x.crop === item.crop &&
            x.trapTypeId === item.trapTypeId &&
            x.id !== item.id
        )) {
            throw new Error('Comment already exists.');
        }

        dispatch(TrapEntryCommentActions.setLoading(true));

        try {
            const save : ITrapEntryComment = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : uuid.v4(),
            };

            await TrapEntryCommentHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving comment.', ex);
            throw ex;
        } finally {
            dispatch(TrapEntryCommentActions.setLoading(false));
        }
    }
}