import React, { ErrorInfo, ReactNode } from 'react';
import { IAuthState } from '../../@types/redux';
import { VERSION } from '../../version';
import ErrorScreen from './ErrorScreen';
import ErrorInformationHelper, { IErrorInformation } from '../../types/model/errorInformation';

interface IErrorBoundaryProps {
    auth : IAuthState;
}
interface IErrorBoundaryState {
    hasError : boolean;
    errorInformation ?: IErrorInformation;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps & Readonly<{
    children ?: React.ReactNode; }>, IErrorBoundaryState> {
    constructor(props : IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() : IErrorBoundaryState {
        return { hasError: true };
    }

    public componentDidCatch(error : Error, errorInfo : ErrorInfo) : void {
        const info : IErrorInformation = {
            id: null,
            version: VERSION.version,
            commitHash: VERSION.hash,
            environment: process.env.NODE_ENV ?? null,
            path: window.location.href,
            name: error.name,
            message: error.message,
            stack: error.stack ?? null,
            componentStack: errorInfo.componentStack,
            userName: this.props.auth.session?.user.name ?? null,
            userEmail: this.props.auth.session?.user.email ?? null,
            date: new Date().valueOf(),
        };

        if (process.env.NODE_ENV === 'production') ErrorInformationHelper.save(info);

        this.setState({
            errorInformation: info,
        });

    }

    render() : ReactNode {
        if (this.state.hasError) {
            return <ErrorScreen errorInformation={this.state.errorInformation} />;
        }

        return this.props.children;
    }
}
