import firebaseApp from '../../../services/firebaseService';
import { PhenologyAssignmentHelper } from './assignment';
import firebase from 'firebase/app';

export interface IPhenologyLocationEntry {
    id : string;
    employeeName : string;
    employeeNumber : string;

    /**
     * Employee Path
     */
    employee : firebase.firestore.DocumentReference;

    assignmentId : string;

    /**
     * Assignment Path
     */
    assignment : string;

    date : number;

    latitude : number;
    longitude : number;
    mocked : boolean;
    altitude : number;
    accuracy : number;
    heading : number;
    speed : number;
    speedAccuracy : number;
}

export class PhenologyLocationEntryHelper {
    public static readonly COLLECTION = 'phenology_entries_locations';

    public static converter : firebase.firestore.FirestoreDataConverter<IPhenologyLocationEntry> = {
        fromFirestore: (snapshot) => {
            return PhenologyLocationEntryHelper.fromFirestore(snapshot);
        },
        toFirestore: () => {
            throw new Error('Not Implemented');
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IPhenologyLocationEntry {
        const data = snapshot.data();

        if (!data) throw new Error('Document not found!');

        return {
            id: snapshot.id,
            date: data['date'] ?? 0,
            employeeName: data['employeeName'],
            employeeNumber: data['employeeNumber'],
            employee: (data['employee'] as firebase.firestore.DocumentReference),
            assignmentId: data['assignmentId'] ?? '',
            assignment: data['assignment'],
            latitude: data['latitude'] ?? 0,
            longitude: data['longitude'] ?? 0,
            accuracy: data['accuracy'] ?? 0,
            mocked: data['mocked'] ?? false,
            altitude: data['altitude'] ?? 0,
            heading: data['heading'] ?? 0,
            speed: data['speed'] ?? 0,
            speedAccuracy: data['speedAccuracy'] ?? 0,
        };
    }

    public static collection(assignmentId ?: string) {
        if (!assignmentId) {
            return PhenologyAssignmentHelper.collection().withConverter(this.converter);
        }
        return PhenologyAssignmentHelper.collection().doc(assignmentId).collection(this.COLLECTION).withConverter(this.converter);
    }

    public static async batchDelete(assignmentId : string) {
        const snapshot = await PhenologyLocationEntryHelper.collection(assignmentId).get();
        const docs = [...snapshot.docs];

        while (docs.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteDocs = docs.splice(0, 100);

            deleteDocs.forEach(doc => batch.delete(doc.ref));

            batch.commit();
        }
        return;
    }
}
