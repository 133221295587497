import { Tabs, withStyles } from '@material-ui/core';

const SecondaryTabs = withStyles({
    root: {
        borderBottom: '1px solid #707070',
        color: '#0D703F',
        opacity: 1,
    },
    indicator: {
        backgroundColor: '#0D703F',
    },
})(Tabs);

export default SecondaryTabs;
