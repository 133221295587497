import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint, pointType } from '../../../types/model/masterData/geoserver/point';
import MasterDataGeoServerFunctions from '../../../store/masterData/geoserver/functions';

interface IScoutingBlockLandNameDropdownProps {
    value ?: string | null;

    onChange : (value ?: string) => void;

    blocks : Array<IGeoServerBlock>;
    points : Array<IGeoServerPoint>;

    required ?: boolean;
    autoFocus ?: boolean;
    disabled ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;

    division ?: string | null;

    crop ?: string;
    label ?: string;

    getOptionDisabled ?: (option : {
        label : string;
        value : string;
    }) => boolean;

    type ?: pointType;
}

interface IScoutingBlockLandNameDropdownState {
}


class ScoutingBlockLandNameDropdownComponent extends React.Component<IScoutingBlockLandNameDropdownProps, IScoutingBlockLandNameDropdownState> {
    constructor(props : IScoutingBlockLandNameDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public loadData = async () => {
        MasterDataGeoServerFunctions.getBlockList();
        MasterDataGeoServerFunctions.getPointList();
    };

    private onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        this.props.onChange(value?.value);
    };

    private getDivision = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.division;
    private getCrop = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.crop;
    private getBlocks = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.blocks;
    private getPoints = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.points;
    private getValue = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.value;
    private getRequired = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.required;
    private getType = (state : IScoutingBlockLandNameDropdownState, props : IScoutingBlockLandNameDropdownProps) => props.type;

    private getData = createSelector([
        this.getBlocks,
        this.getDivision,
        this.getCrop,
        this.getPoints,
        this.getType,
    ], (
        blocks,
        division,
        crop,
        points,
        type,
    ) => {
        const blockGuids = lodash.chain(points)
            .filter(n => !type || n.type === type)
            .filter(n => !crop || n.crop === crop)
            .map(n => n.blockGuid)
            .uniq()
            .value();

        return lodash.chain(blocks)
            .filter(n => blockGuids.includes(n.guid))
            .filter(n => !division || n.departmentShortName.toLocaleUpperCase() === division.toLocaleUpperCase())
            .uniqBy(n => n.fieldName)
            .map(n => ({
                label: `${n.fieldName.toLocaleUpperCase()}`,
                value: n.fieldName,
            }))
            .sortBy(n => n.label)
            .value();
    });

    private getScoutingBlockLandNameDropdown = createSelector([
        this.getData, this.getRequired,
    ], (blocks, required) => {
        const blocksDrop = blocks.map(x => ({ label: x.label, value: x.value }));

        if (!required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getData,
    ], (value, required, blocks) => {
        if (value && typeof(value) === 'string') {
            const block = blocks.slice().find(x => x.value === value);

            if (block) {
                return {
                    label: block.label,
                    value: block.value,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required, fullWidth, isLoading, disabled, label, getOptionDisabled } = this.props;

        const blocks = this.getScoutingBlockLandNameDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        id='blockLandName_select'
                        options={blocks}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        disabled={disabled || isLoading}
                        getOptionDisabled={getOptionDisabled}
                        renderInput={params => <TextField error={required && !value} required={required} {...params} fullWidth={fullWidth} label={label ?? 'Land Name'} />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.masterData.geoserver.blocks,
        points: state.masterData.geoserver.points,
        isLoading: state.masterData.geoserver.isLoadingBlocks || state.masterData.geoserver.isLoadingPoints,
    };
};

const ScoutingBlockLandNameDropdown = connect(
    mapStateToProps,
)(ScoutingBlockLandNameDropdownComponent);

export default ScoutingBlockLandNameDropdown;
