import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import RotatingDiv from '../div/RotatingDiv';

interface IHorizontalExpanderProps {
    initiallyExpanded ?: boolean;
    disabled ?: boolean;
    title : string;

    color ?: 'primary' | 'secondary';

    children ?: React.ReactNode;

    actionComponent ?: React.ReactNode;
}

interface IHorizontalExpanderState {
    open : boolean;
}

export default class HorizontalExpander extends React.PureComponent<IHorizontalExpanderProps, IHorizontalExpanderState> {
    constructor(props : IHorizontalExpanderProps) {
        super(props);
        this.state = {
            open: !!props.initiallyExpanded,
        };
    }

    private readonly onClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    public readonly render = () => {
        const { title, children, disabled, color, actionComponent } = this.props;
        const { open } = this.state;
        return (
            <List disablePadding dense>
                <ListItem className={`${color === 'primary' ? 'bcp cw' : ''}`} button disabled={disabled} onClick={this.onClick} key={title}>
                    <ListItemText primary={title} title={title} />
                    {
                        actionComponent &&
                        <div className='fdr mr15'>
                            {
                                actionComponent
                            }
                        </div>
                    }
                    <RotatingDiv className={'mh0'} rotate={(open && !disabled)}>
                        <ExpandMoreIcon />
                    </RotatingDiv>
                </ListItem>
                {open && !disabled && <Divider />}
                <Collapse in={open && !disabled} timeout='auto' unmountOnExit>
                    {children}
                </Collapse>
                <Divider />
            </List>
        );
    };
}
