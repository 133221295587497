import React from 'react';
import Button from '@material-ui/core/Button';

interface IWeekGridSelectorProps {
    weeks : Array<number>;

    value : number;

    onChange ?: (week : number) => void;
}

interface IWeekGridSelectorState {}

export default class WeekGridSelector extends React.PureComponent<IWeekGridSelectorProps, IWeekGridSelectorState> {
    constructor(props : IWeekGridSelectorProps) {
        super(props);
        this.state = {};
    }

    private onClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!this.props.onChange) return;

        const week = parseInt(event.currentTarget.value);
        this.props.onChange(week);
    };

    public render = () => {
        const { weeks, value } = this.props;
        return (
            <div className='flx1 wfill aic jcc'>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '54px 54px 54px',
                    gridTemplateRows: '29px 29px',
                    gridRowGap: 7,
                    gridColumnGap: 15,
                }}>
                    {
                        weeks.map(n => (
                            <Button value={n} onClick={this.onClick} key={`week_${n}`} variant={value === n ? 'contained' : 'outlined'} color='primary'>
                                {n}
                            </Button>
                        ))
                    }
                </div>
            </div>
        );
    };
}
