import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { CROP } from '../../appConstants';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import BasicMap from '../customComponents/mapBox/BasicMap';
import { LngLatBoundsLike, MapRef } from 'react-map-gl';
import { CircularProgress } from '@material-ui/core';
import GeoServerDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DivisionAutocomplete';
import GeoServerSubDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/SubDivisionAutocomplete';
import GeoServerDepartmentAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DepartmentAutocomplete';
import GeoServerFieldAutocomplete from '../customComponents/autocomplete/masterData/geoserver/FieldAutocomplete';
import CropTypeDropdown from '../customComponents/dropdowns/CropTypeDropdown';
import GeoServerBlockMultiAutocomplete from '../customComponents/autocomplete/masterData/geoserver/BlockMultiAutocomplete';
import { createSelector } from 'reselect';
import { IGeoServerBlock } from '../../types/model/masterData/geoserver/block';
import { Transitions } from '../customComponents/animations/Transitions';
import ScoutingGeneralAssignmentCreateDialog from './assignments/general/dialog/AssignmentCreateDialog';

interface IScoutingMapProps {
    isLoading : boolean;
    geoserverblocks : Array<IGeoServerBlock>;
}

interface IScoutingMapState {
    divisionGuid : string | null;
    subDivisionGuid : string | null;
    departmentGuid : string | null;
    fieldGuid : string | null;
    blockGuids : Array<string>;
    cropType ?: CROP | null;
}

class ScoutingMapComponent extends React.Component<IScoutingMapProps, IScoutingMapState> {
    private mapRef : MapRef | null = null;

    private readonly divisionZoom = 10;
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : IScoutingMapProps) {
        super(props);
        this.state = {
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        };
    }
    
    private readonly flyToBounds = (bounds : LngLatBoundsLike, zoom ?: number) => {
        this.mapRef?.fitBounds(bounds, {
            minZoom: zoom,
        });
    };

    private readonly onDivisionChange = (value ?: string | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            divisionGuid: value ?? null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.divisionZoom);
        }
    };

    private readonly onSubDivisionChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            subDivisionGuid: value ?? null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.subDivisionZoom);
        }
    };

    private readonly onDepartmentChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            departmentGuid: value ?? null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.departmentZoom);
        }
    };

    private readonly onFieldChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            fieldGuid: value ?? null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.fieldZoom);
        }
    };

    private readonly onBlockChange = (value : Array<string>, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            blockGuids: value,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.blockZoom);
        }
    };

    private readonly onCropTypeChange = (cropType ?: CROP | null) => {
        this.setState({
            cropType,
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });
    };

    private readonly setMapRef = (mapRef : MapRef) => {
        this.mapRef = mapRef;
    };

    private readonly getGeoserverBlocks = (props : IScoutingMapProps) => props.geoserverblocks;
    private readonly getBlockGuids = (props : IScoutingMapProps, state : IScoutingMapState) => state.blockGuids;
    private readonly getDepartmentGuid = (props : IScoutingMapProps, state : IScoutingMapState) => state.departmentGuid;
    private readonly getFieldGuid = (props : IScoutingMapProps, state : IScoutingMapState) => state.fieldGuid;

    private readonly getBlockCodes = createSelector([
        this.getGeoserverBlocks,
        this.getBlockGuids,
    ], (
        blocks,
        blockGuids,
    ) => {
        return blocks.filter(x => blockGuids.includes(x.guid)).map(x => x.code);
    });

    private readonly getDivision = createSelector([
        this.getGeoserverBlocks,
        this.getDepartmentGuid,
    ], (
        geoserverblocks,
        departmentGuid,
    ) => {
        const geoserverblock = geoserverblocks.find(x => x.departmentGuid === departmentGuid);

        if (!geoserverblock) return;
        
        
        return geoserverblock.departmentShortName;
    });

    private readonly getLandName = createSelector([
        this.getGeoserverBlocks,
        this.getFieldGuid,
    ], (
        geoserverblocks,
        fieldGuid,
    ) => {
        const geoserverblock = geoserverblocks.find(x => x.fieldGuid === fieldGuid);

        if (!geoserverblock) return;
        
        
        return geoserverblock.fieldName.toLocaleUpperCase();
    });
    
    public readonly render = () => {
        const {
            divisionGuid,
            subDivisionGuid,
            blockGuids,
            departmentGuid,
            fieldGuid,
            cropType,
        } = this.state;

        const { isLoading } = this.props;
        const blockNames = this.getBlockCodes(this.props, this.state);
        const division = this.getDivision(this.props, this.state);
        const landName = this.getLandName(this.props, this.state);

        return (
            <div className={'fdr hfill mh0'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <>
                        <Paper className={'flx1 fdr oxh p5 m5'}>
                            <BasicMap
                                id={'farmMap'}
                                onMap={this.setMapRef}
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                fieldGuid={fieldGuid}
                                blockCodes={blockNames}
                            />
                        </Paper>
                        <div className={'fdc mh0'}>
                            <VerticalExpander title='filter' location='right' initiallyExpanded>
                                <div className={'fdc aic pr20 mt7'}>
                                    <CropTypeDropdown fullWidth value={cropType} onChange={this.onCropTypeChange} />
                                </div>
                                <div className={'fdc aic pr20 mt7'}>
                                    <GeoServerDivisionAutocomplete
                                        value={divisionGuid}
                                        fullWidth
                                        onChange={this.onDivisionChange}
                                    />
                                </div>
                                <div className={'fdc aic pr20 mt7'}>
                                    <GeoServerSubDivisionAutocomplete
                                        value={subDivisionGuid}
                                        fullWidth
                                        divisionGuid={divisionGuid}
                                        onChange={this.onSubDivisionChange}
                                    />
                                </div>
                                <div className={'fdc aic pr20 mt7'}>
                                    <GeoServerDepartmentAutocomplete
                                        value={departmentGuid}
                                        fullWidth
                                        divisionGuid={divisionGuid}
                                        subDivisionGuid={subDivisionGuid}
                                        onChange={this.onDepartmentChange}
                                    />
                                </div>
                                <div className={'fdc aic pr20 mt7'}>
                                    <GeoServerFieldAutocomplete
                                        value={fieldGuid}
                                        fullWidth
                                        divisionGuid={divisionGuid}
                                        subDivisionGuid={subDivisionGuid}
                                        departmentGuid={departmentGuid}
                                        onChange={this.onFieldChange}
                                        type={'scouting'}
                                        crop={cropType}
                                    />
                                </div>
                                <div className={'fdc aic pr20 mt7'}>
                                    <GeoServerBlockMultiAutocomplete
                                        value={blockGuids}
                                        fullWidth
                                        divisionGuid={divisionGuid}
                                        subDivisionGuid={subDivisionGuid}
                                        departmentGuid={departmentGuid}
                                        fieldGuid={fieldGuid}
                                        onChange={this.onBlockChange}
                                        type={'scouting'}
                                        crop={cropType}
                                    />
                                </div>
                                <div className={'fdc aife pr20 mt7'}>
                                    <ScoutingGeneralAssignmentCreateDialog
                                        division={division}
                                        landName={landName}
                                        crop={!cropType ? '' as CROP : cropType}
                                        blockNames={blockNames}
                                        fullWidth
                                        transition={Transitions.Down}
                                        maxWidth='md'
                                        disabled={!landName || !division || !cropType} />
                                </div>
                            </VerticalExpander>
                        </div>
                    </>
                }
            </div>
        );
    };
}
const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.masterData.geoserver.isLoadingBlocks,
        geoserverblocks: state.masterData.geoserver.blocks,
    };
};

const ScoutingMap = connect(
    mapStateToProps,
)(ScoutingMapComponent);


export default ScoutingMap;