import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { MapRef } from 'react-map-gl';
import { IDivision } from '../../../types/model/rights/division';
import { CROP } from '../../../appConstants';
import { IAssignmentBlock } from '../../../types/model/masterData/block';
import { PHENOLOGY_ASSIGNMENT_TYPE } from '../../../types/model/phenology/assignment';
import GeoHelper from '../../../services/helper/geoHelper';
import TabPanel from '../../customComponents/tab/TabPanel';
import AssignmentWeekSelector from '../../customComponents/selector/AssignmentWeekSelector';
import AssignmentDivisionSelector from '../../customComponents/selector/AssignmentDivisionSelector';
import PhenologyAssignmentFieldsView from './FieldsView';
import PhenologyAssignmentTypeSelector from './TypeSelector';
import StadiumButton from '../../customComponents/button/StadiumButton';
import PhenologyAssignmentBlockSelector from './BlockSelector';

interface IPhenologyAssignmentFieldSelectorProps {
    crop : CROP;

    division ?: IDivision;
    
    phenologyType ?: PHENOLOGY_ASSIGNMENT_TYPE | null;
    date ?: moment.Moment;
    
    selectedPhenologyBlocks : Record<string, Array<IAssignmentBlock>>;

    onWeekChanged ?: (date ?: moment.Moment) => void;
    onDivisionChanged ?: (division ?: IDivision) => void;
    onPhenologyTypeChange ?: (scoutType ?: PHENOLOGY_ASSIGNMENT_TYPE) => void;

    onChange ?: (selectedPhenologyBlocks : Record<string, Array<IAssignmentBlock>>) => void;
    onAssignmentAddClick ?: () => void;

    mapRef : MapRef | null;
}

interface IPhenologyAssignmentFieldSelectorState {
    tabIndex : number;

    defaultLandName ?: string;
}

export default class PhenologyAssignmentFieldSelector extends React.PureComponent<IPhenologyAssignmentFieldSelectorProps, IPhenologyAssignmentFieldSelectorState> {
    constructor(props : IPhenologyAssignmentFieldSelectorProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onAddFieldsClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBlockBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };

    private readonly onAddClick = (landName : string, blocks : Array<IAssignmentBlock>) => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
            defaultLandName: undefined,
        });

        const selectedBlocks = {
            ...this.props.selectedPhenologyBlocks,
        };

        selectedBlocks[landName] = [...blocks];

        if (this.props.onChange) this.props.onChange(selectedBlocks);
    };

    private readonly onBlocksEditClick = (landName : string) => {
        this.setState({
            defaultLandName: landName,
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onDeleteLandClick = (landName : string) => {
        const selectedBlocks = {
            ...this.props.selectedPhenologyBlocks,
        };
        delete selectedBlocks[landName];
        if (this.props.onChange) this.props.onChange(selectedBlocks);

    };

    private readonly onZoomClick = (landName : string) => {
        this.props.mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds([
            ...this.props.selectedPhenologyBlocks[landName],
        ]), {
            minZoom: 15,
        });
    };

    public readonly render = () => {
        const {
            tabIndex,
            defaultLandName,
        } = this.state;

        const {
            crop,
            division,
            date,
            selectedPhenologyBlocks,
            onDivisionChanged,
            onWeekChanged,
            onPhenologyTypeChange,
            onAssignmentAddClick,
            mapRef,
            phenologyType,
        } = this.props;
        
        return (
            <Paper className={'flx1 fdc m5'}>
                <TabPanel value={tabIndex} index={0}>
                    <AppBar position='static' className='bcdg pbr' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography className='fs17'>
                                ASSIGNMENTS
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc hfill oya drawer'>
                        <AssignmentWeekSelector
                            date={date}
                            onChanged={onWeekChanged}
                        />
                        <AssignmentDivisionSelector
                            crop={crop}
                            value={division}
                            onChanged={onDivisionChanged}
                        />
                        <PhenologyAssignmentTypeSelector
                            value={phenologyType}
                            onChanged={onPhenologyTypeChange}
                        />
                        <PhenologyAssignmentFieldsView
                            type={phenologyType}
                            division={division?.code}
                            date={date}
                            assignmentBlocks={selectedPhenologyBlocks}
                            onAddFieldsClick={this.onAddFieldsClick}
                            onDeleteClick={this.onDeleteLandClick}
                            onZoomClick={this.onZoomClick}
                            onEditClick={this.onBlocksEditClick}
                        />
                    </div>
                    <div className={'fdc aife jcc p10 mt5'}>
                        <StadiumButton
                            variant='contained'
                            className='bcy cpd dbcg dcg bsd'
                            disabled={!Object.keys(selectedPhenologyBlocks).length}
                            onClick={onAssignmentAddClick}
                        >
                            ADD ASSIGNMENT
                        </StadiumButton>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <PhenologyAssignmentBlockSelector
                        crop={crop}
                        division={division?.code}
                        defaultLandName={defaultLandName}
                        phenologyType={phenologyType}
                        onBackClick={this.onBlockBackClick}
                        date={date}
                        selectedPhenologyBlocks={selectedPhenologyBlocks}
                        onAddClick={this.onAddClick}
                        mapRef={mapRef}
                    />
                </TabPanel>
            </Paper>
        );
    };
}
