import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IScoutingSectionEntry, IScoutingSectionEntryCheck, ScoutingEntryChecks, ScoutingEntryCheckType } from '../../../../types/model/masterData/scouting';
import { Button, Checkbox, DialogActions, FormControl, FormControlLabel, FormHelperText, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import ScoutingEditOptionDialog from './EditOption';
import InsectDropdown from '../../../customComponents/dropdowns/InsectDropdown';
import InsectHelper, { IInsect } from '../../../../types/model/masterData/insect';
import DiseaseDropdown from '../../../customComponents/dropdowns/DiseaseDropdown';
import DiseaseHelper, { IDisease } from '../../../../types/model/masterData/disease';
import DamageDropdown from '../../../customComponents/dropdowns/DamageDropdown';
import DamageHelper, { IDamage } from '../../../../types/model/masterData/damage';
import moment from 'moment';

interface IScoutingEditCheckDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value ?: IScoutingSectionEntry;

    onClose : (value ?: IScoutingSectionEntry) => void;

    isLoading : boolean;
}

interface IScoutingEditCheckDialogState {
    open : boolean;

    name : string;
    checks : Record<ScoutingEntryCheckType, Array<IScoutingSectionEntryCheck>>;
    months : Array<number>;

    selectedTab : ScoutingEntryCheckType;
    selectedCheck : IScoutingSectionEntryCheck | null;
}

export default class ScoutingEditCheckDialog extends React.PureComponent<IScoutingEditCheckDialogProps, IScoutingEditCheckDialogState> {
    private readonly monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    constructor(props : IScoutingEditCheckDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            checks: ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: []}), {}) as Record<ScoutingEntryCheckType, Array<IScoutingSectionEntryCheck>>,
            selectedTab: ScoutingEntryChecks[0],
            selectedCheck: null,
            months: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IScoutingEditCheckDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    };

    public readonly show = () => {
        this.setState({
            open: true,
        });
    };

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    };

    public readonly onClose = () => {
        this.props.onClose();
    };

    private readonly setInitialValues = (value : IScoutingSectionEntry) => {
        this.setState({
            name: value.name,
            checks: ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), value.checks),
            months: [...value.months],
        });
    };

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    };

    private readonly onSaveClick = () => {
        this.props.onClose({
            name: this.state.name,
            checks: this.state.checks,
            months: this.state.months,
        });
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : ScoutingEntryCheckType) => {
        this.setState({
            selectedTab: value,
        });
    };

    private readonly onAddClick = () => {
        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = [
            ...checks[selectedTab],
            {
                ref: null,
                options: [],
                showOptions: false,
                singleRow: true,
                photoRequired: false,
                subName: null,
            },
        ];

        this.setState({
            checks,
        });
    };

    private readonly onInsectChange = (name ?: string, value ?: IInsect) => {
        if (!name) return;
        const [ index ] = name.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        if (!value) {
            checks[selectedTab][Number(index)].ref = null;
        } else {
            checks[selectedTab][Number(index)].ref = InsectHelper.collection().doc(value.id);
        }

        this.setState({
            checks,
        });
    };

    private readonly onDamageChange = (name ?: string, value ?: IDamage) => {
        if (!name) return;
        const [ index ] = name.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        if (!value) {
            checks[selectedTab][Number(index)].ref = null;
        } else {
            checks[selectedTab][Number(index)].ref = DamageHelper.collection().doc(value.id);
        }

        this.setState({
            checks,
        });
    };

    private readonly onDiseaseChange = (name ?: string, value ?: IDisease) => {
        if (!name) return;
        const [ index ] = name.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        if (!value) {
            checks[selectedTab][Number(index)].ref = null;
        } else {
            checks[selectedTab][Number(index)].ref = DiseaseHelper.collection().doc(value.id);
        }

        this.setState({
            checks,
        });
    };

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(Number(event.currentTarget.value), true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(Number(event.currentTarget.value), false);
    };

    private readonly changeOrder = (index : number, up : boolean) => {
        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        if (up && index > 0) {
            const option = checks[selectedTab][index];
            const prevOption = checks[selectedTab][index - 1];

            checks[selectedTab].splice(index, 1, prevOption);
            checks[selectedTab].splice(index - 1, 1, option);
        } else if (!up && index < checks[selectedTab].length - 1) {
            const option = checks[selectedTab][index];
            const prevOption = checks[selectedTab][index + 1];

            checks[selectedTab].splice(index, 1, prevOption);
            checks[selectedTab].splice(index + 1, 1, option);
        }

        this.setState({
            checks,
        });
    };

    private readonly onDeleteClick = (e : React.MouseEvent<HTMLButtonElement>) => {
        const index = Number(e.currentTarget.value);

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        checks[selectedTab].splice(index, 1);


        this.setState({
            checks,
        });
    };

    private readonly onShowOptionsChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const [ index ] = e.target.id.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        checks[selectedTab][Number(index)].showOptions = !checks[selectedTab][Number(index)].showOptions;

        this.setState({
            checks,
        });
    };

    private readonly onSingleRowChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const [ index ] = e.target.id.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        checks[selectedTab][Number(index)].singleRow = !checks[selectedTab][Number(index)].singleRow;

        this.setState({
            checks,
        });
    };

    private readonly onPhotoRequiredChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const [ index ] = e.target.id.split('.');

        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        checks[selectedTab] = checks[selectedTab].slice();

        checks[selectedTab][Number(index)].photoRequired = !checks[selectedTab][Number(index)].photoRequired;

        this.setState({
            checks,
        });
    };

    private readonly onEditCheckClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const index = Number(event.currentTarget.value);
        const { selectedTab } = this.state;
        const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);

        this.setState({
            selectedCheck: {
                ...checks[selectedTab][index],
                options: [...checks[selectedTab][index].options],
            },
        });
    };

    private readonly onEditClose = (check : IScoutingSectionEntryCheck | null) => {
        if (check) {
            const { selectedTab } = this.state;
            const checks = ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), this.state.checks);
    
            const index = checks[selectedTab].findIndex(e => check.ref?.id === e.ref?.id);
    
            checks[selectedTab] = checks[selectedTab].slice();
    
            checks[selectedTab].splice(index, 1, check);
    
    
            this.setState({
                checks,
            });
        }
    
        this.setState({
            selectedCheck: null,
        });
    };

    private readonly onAllMonthChange = () => {
        this.setState({
            months: [],
        });
    };

    private readonly onMonthChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.currentTarget.value);
        
        const months = this.state.months.slice();
        const index = months.indexOf(value);

        if (index > -1) {
            months.splice(index, 1);
        } else {
            months.push(value);
        }
        
        this.setState({
            months,
        });
    };



    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading } = this.props;
        const { open, name, checks, selectedTab, selectedCheck, months } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='scouting-general-option-dialog-title'
                aria-describedby='scouting-general-option-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                    disabled={!!value?.name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </div>
                    <div className='fdr fww'>
                        <FormControlLabel control={
                            <Checkbox
                                color='primary'
                                value={0}
                                checked={!months.length}
                                onChange={this.onAllMonthChange}
                            />
                        }
                        label={'All'}
                        />
                        {
                            this.monthNumbers.map(n => (
                                <FormControlLabel key={n} control={
                                    <Checkbox
                                        color='primary'
                                        value={n}
                                        checked={!!months.includes(n)}
                                        onChange={this.onMonthChange}
                                    />
                                }
                                label={moment().month(n - 1).format('MMMM')}
                                />
                            ))
                        }
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            OPTIONS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </Toolbar>
                    <AppBar elevation={0} className='fdc posr ais' position='static'>
                        <Tabs value={selectedTab} variant='fullWidth' onChange={this.onTabChange} aria-label='UploadTabs'>
                            {
                                ScoutingEntryChecks.map((n) => (
                                    <Tab key={n} value={n} label={n.toLocaleUpperCase()} />
                                ))
                            }
                        </Tabs>
                    </AppBar>
                    <div className='fdc jcfs ais'>
                        {
                            checks[selectedTab].map((n, i) => (
                                <div key={i} className='fdr ais jcfs mt7'>
                                    <div className='fdr aic mr15'>
                                        <IconButton className='cp' size='small' value={i} onClick={this.onEditCheckClick}>
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </div>
                                    <div className='fdr aic mr15'>
                                        <Tooltip title='Up'>
                                            <div>
                                                <IconButton
                                                    value={i}
                                                    size='small'
                                                    disabled={isLoading || i === 0}
                                                    onClick={this.onUpClick}
                                                >
                                                    <Icon>expand_less</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Down'>
                                            <div>
                                                <IconButton
                                                    value={i}
                                                    size='small'
                                                    disabled={isLoading || i === checks[selectedTab].length - 1}
                                                    onClick={this.onDownClick}
                                                >
                                                    <Icon>expand_more</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        
                                    </div>
                                    <div className='fdc flx1 mr15'>
                                        {
                                            selectedTab === 'insects' &&
                                            <InsectDropdown
                                                required
                                                id={`${i}.name`}
                                                name={`${i}.name`}
                                                fullWidth
                                                value={n.ref?.id}
                                                onChange={this.onInsectChange}
                                            />
                                        }
                                        {
                                            selectedTab === 'disease' &&
                                            <DiseaseDropdown
                                                required
                                                id={`${i}.name`}
                                                name={`${i}.name`}
                                                fullWidth
                                                value={n.ref?.id}
                                                onChange={this.onDiseaseChange}
                                            />
                                        }
                                        {
                                            selectedTab === 'damage' &&
                                            <DamageDropdown
                                                required
                                                id={`${i}.name`}
                                                name={`${i}.name`}
                                                fullWidth
                                                value={n.ref?.id}
                                                onChange={this.onDamageChange}
                                            />
                                        }
                                    </div>
                                    <div className='fdr flx1 mr15 aic'>
                                        <Typography className='pr6' >Show Options?</Typography>
                                        <Switch
                                            id={`${i}.showOptions`}
                                            name={`${i}.showOptions`}
                                            checked={n.showOptions}
                                            color='primary'
                                            onChange={this.onShowOptionsChange}
                                            inputProps={{
                                                'aria-label': 'Show Options',
                                                id: `${i}.showOptions`,
                                                
                                            }}
                                        />
                                        
                                    </div>
                                    <div className='fdc flx1 mr15'>
                                        <div className='fdr flx1 mr15 aic'>
                                            <Typography className='pr6' >Single Row?</Typography>
                                            <Switch
                                                id={`${i}.singleRow`}
                                                name={`${i}.singleRow`}
                                                checked={n.singleRow}
                                                color='primary'
                                                onChange={this.onSingleRowChange}
                                                inputProps={{
                                                    'aria-label': 'Show Options',
                                                    id: `${i}.singleRow`,
                                                    
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        selectedTab === 'disease' &&
                                        <div className='fdc flx1 mr15'>
                                            <div className='fdr flx1 mr15 aic'>
                                                <Typography className='pr6' >Photo Required?</Typography>
                                                <Switch
                                                    id={`${i}.photoRequired`}
                                                    name={`${i}.photoRequired`}
                                                    checked={!!n.photoRequired}
                                                    color='primary'
                                                    onChange={this.onPhotoRequiredChange}
                                                    inputProps={{
                                                        'aria-label': 'Show Options',
                                                        id: `${i}.photoRequired`,
                                                        
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className='fdr aic'>
                                        <IconButton className='cpr' size='small' value={i} onClick={this.onDeleteClick}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <ScoutingEditOptionDialog
                        value={selectedCheck}
                        type={selectedTab}
                        fullWidth maxWidth='md'
                        onClose={this.onEditClose}
                        isLoading={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading || !name} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
