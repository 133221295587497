import { CROP } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import FirestoreHelper from '../../../services/helper/firestoreHelper';
import { BaseHelper, IBase } from '../../base';
import AssignmentBlockHelper, { IAssignmentBlock } from '../masterData/block';
import firebase from 'firebase/app';
import UserHelper from '../user';


export const nematodeAssignmentTypes : ['general', 'specific'] = ['general', 'specific'];
export type NematodeAssignmentType = typeof nematodeAssignmentTypes[number];

export interface INematodeAssignment extends IBase {
    employeeName : string;
    employeeNumber : string;

    /**
     * Employee Id
     */
    employee : string;

    /**
     * Date in Millis
     */
    date : number;

    /**
     * Block Id
     */
    block : string;
    blockName : string;
    landName : string;
    division : string;
    crop : CROP;
    finishedPoints : Array<string>;
    finished : boolean;
    /**
     * DateTime in milliseconds
     */
    startedOn ?: number;
    /**
     * DateTime in milliseconds
     */
    finishedOn ?: number;

    scoutingBlock : IAssignmentBlock;
    totalTime : number;
    distance ?: number;

    assignmentType : NematodeAssignmentType;

    isSent : boolean;
}

export class NematodeAssignmentHelper extends BaseHelper {
    public static readonly COLLECTION = 'nematode_assignments';

    public static converter : firebase.firestore.FirestoreDataConverter<INematodeAssignment> = {
        fromFirestore: (snapshot) => {
            return NematodeAssignmentHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : INematodeAssignment) => {
            return NematodeAssignmentHelper.toFirestore(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : INematodeAssignment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data) throw new Error('Does not exist!');
        if (!result) throw new Error('Does not exist!');

        return {
            ...data as INematodeAssignment,
            ...result,
            employee: (data['employee'] as firebase.firestore.DocumentReference).id,
            date: (data['date'] as firebase.firestore.Timestamp).toMillis(),
            block: typeof data['block'] === 'string' ? data['block'] : (data['block'] as firebase.firestore.DocumentReference).id,
            startedOn: (data['startedOn'] as firebase.firestore.Timestamp | null)?.toMillis(),
            finishedOn: (data['finishedOn'] as firebase.firestore.Timestamp | null)?.toMillis(),
            scoutingBlock: AssignmentBlockHelper.fromFirestoreData(data['scoutingBlock']),
        };
    }

    protected static toFirestore(data : INematodeAssignment) {
        const result = super.toFirestore(data);

        const { id: _, ...rest } = data;
        return {
            ...rest,
            ...result,
            date: FirestoreHelper.millisToTimestamp(data.date),
            startedOn: !data.startedOn ? null : FirestoreHelper.millisToTimestamp(data.startedOn),
            finishedOn: !data.finishedOn ? null : FirestoreHelper.millisToTimestamp(data.finishedOn),
            employee: UserHelper.get(data.employee),
            scoutingBlock: AssignmentBlockHelper.toFirestoreData(data.scoutingBlock),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return NematodeAssignmentHelper.collection().doc();
        }

        return NematodeAssignmentHelper.collection().doc(id);
    }
    
    public static async bulkDelete(ids : Array<string>) {
        const currentIds = ids.slice();

        while (currentIds.length) {
            const batch = firebaseApp.firestore().batch();
            const deleteIds = currentIds.splice(0, 50);
            
            deleteIds.forEach((id) => {
                batch.delete(this.collection().doc(id));
            });

            await batch.commit();
        }
    }

    /**
     * Saves object to firestore using a transaction.
     * @param transaction
     */
    public static saveTransaction(transaction : firebase.firestore.Transaction, data : INematodeAssignment) {
        if (data.id) {
            transaction.set(this.collection().doc(data.id), data);
        } else {
            transaction.set(this.collection().doc(), data);
        }
    }

    public static save(nematode : INematodeAssignment) {
        if (nematode.id) {
            return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(nematode.id).set(nematode);
        }

        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc().set(nematode);
    }

}