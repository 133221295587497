import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ITrap } from '../../../types/model/trap/trap';
import TrapFunctions from '../../../store/trap/functions';
import DivisionAutocomplete from '../../customComponents/autocomplete/rights/DivisionAutocomplete';
import { ITrapType } from '../../../types/model/masterData/trap/trapType';
import { IDivision } from '../../../types/model/rights/division';
import TrapTypeDropdown from '../../customComponents/dropdowns/trap/TrapTypeDropdown';
import { DialogActions, Switch } from '@material-ui/core';
import TrapFarmDescriptionSelector from '../../customComponents/selector/trap/FarmDescriptionSelector';
import StadiumButton from '../../customComponents/button/StadiumButton';
import ScoutingBlockLandNameDropdown from '../../customComponents/dropdowns/BlockLandNameDropdown';
import ScoutingBlockNameDropdown from '../../customComponents/dropdowns/BlockNameDropdown';
import { IAssignmentBlock } from '../../../types/model/masterData/block';

interface IEditTrapInfoProps {
    trap : ITrap;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading ?: boolean;
}

interface IEditTrapInfoState {
    open : boolean;

    divisionCode : string | null;
    divisionName : string | null;
    trapTypeId : string | null;
    trapTypeName : string | null;
    monitor : boolean;

    block : string | null;
    blockName : string | null;
    landName : string | null;

    description : string | null;
    otherDescription : string | null;
}

export default class EditTrapInfo extends React.Component<IEditTrapInfoProps, IEditTrapInfoState> {
    private readonly initState : IEditTrapInfoState = {
        open: false,
        divisionCode: null,
        divisionName: null,
        trapTypeId: null,
        trapTypeName: null,
        monitor: false,
        block: null,
        blockName: null,
        landName: null,
        description: null,
        otherDescription: null,
    };

    constructor(props : IEditTrapInfoProps) {
        super(props);
        this.state = {
            ...this.initState,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTrapInfoProps, prevState : IEditTrapInfoState) => {
        if (!prevState.open && this.state.open) {
            const {
                divisionCode,
                type,
                monitoring,
                landName,
                block,
                blockName,
                divisionName,
                typeName,
                description,
                otherDescription,
            } = this.props.trap;

            this.setState({
                block,
                blockName,
                divisionName,
                description,
                divisionCode,
                landName,
                monitor: monitoring,
                otherDescription,
                trapTypeId: type,
                trapTypeName: typeName,
            });
        }
    };

    private readonly onDivisionChange = (division ?: IDivision | null) => {
        this.setState({
            divisionCode: division?.id ?? null,
            divisionName: division?.name ?? null,
            landName: null,
            block: null,
            blockName: null,
        });
    };
    
    private readonly onTrapTypeChange = (value ?: ITrapType | null) => {
        this.setState({
            trapTypeId: value?.id ?? null,
            trapTypeName: value?.name ?? null,
        });
    };

    private readonly onMonitorChange = () => {
        this.setState({
            monitor: !this.state.monitor,
            block: null,
            blockName: null,
            landName: null,
            description: null,
            otherDescription: null,
        });
    };

    private readonly onLandNameChange = (landName ?: string | null) => {
        this.setState({
            landName: landName ?? null,
            block: null,
            blockName: null,
        });
    };

    private readonly onBlockChange = (blocks : Array<IAssignmentBlock>) => {
        const block = blocks[0] as IAssignmentBlock | null;
        this.setState({
            block: block?.guid ?? null,
            blockName: block?.name ?? null,
            landName: block?.landName ?? null,
        });
    };

    private readonly onDescriptionChange = (value ?: string | null) => {
        this.setState({
            description: value ?? null,
            otherDescription: null,
        });
    };

    private readonly onOtherDescriptionChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            otherDescription: event.target.value,
        });
    };

    private readonly save = async () => {
        const { trap } = this.props;
        
        try {
            const {
                divisionCode,
                trapTypeId,
                monitor,
                landName,
                block,
                blockName,
                divisionName,
                trapTypeName,
                description,
                otherDescription,
            } = this.state;

            await TrapFunctions.save({
                ...trap,
                type: trapTypeId ?? '',
                typeName: trapTypeName ?? '',
                block,
                divisionCode: divisionCode ?? '',
                divisionName: divisionName ?? '',
                blockName,
                description,
                otherDescription: otherDescription ?? null,
                landName,
                monitoring: monitor,
            });
            this.onClose();
        } catch (ex) {
            // Ignore
        }

    };

    private readonly onSaveClick = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            trap,
        } = this.props;

        const {
            open,
            divisionCode,
            trapTypeId,
            monitor,
            landName,
            block,
            description,
            otherDescription,
        } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='trap-info-title'
                    aria-describedby='trap-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static' elevation={0}>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Trap  Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill p0'>
                        <div className='fdr bcp pt15 pb15 pl25'>
                            <Typography
                                className='fwn cw fs15'
                            >
                                DIVISION
                            </Typography>
                        </div>
                        <div className='fdc p10'>
                            <div className={'fdc flx1 aic'}>
                                <DivisionAutocomplete
                                    required
                                    fullWidth
                                    value={divisionCode}
                                    onChange={this.onDivisionChange}
                                    crop={trap.crop}
                                    label={'Select Division'}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className='fdr bcp pt15 pb15 pl25'>
                            <Typography
                                className='fwn cw fs15'
                            >
                                TYPE
                            </Typography>
                        </div>
                        <div className='fdc p10'>
                            <div className={'fdc flx1 aic'}>
                                <TrapTypeDropdown
                                    required
                                    fullWidth
                                    value={trapTypeId}
                                    onChange={this.onTrapTypeChange}
                                    crop={trap.crop}
                                    label='Select Type'
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className='fdr aic bcgg pl25'>
                            <Typography
                                className='fwn flx1 cw fs15'
                            >
                                FARM MONITORING
                            </Typography>
                            <div className={'fdr'}>
                                <Switch
                                    id={'monitor'}
                                    name={'monitor'}
                                    checked={monitor}
                                    color='secondary'
                                    onChange={this.onMonitorChange}
                                    inputProps={{
                                        'aria-label': 'Is FARM MONITORING',
                                        id: 'isActive',
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        {
                            monitor &&
                            <>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        DESCRIPTION
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <TrapFarmDescriptionSelector
                                        value={description}
                                        onChange={this.onDescriptionChange}
                                        disabled={isLoading}
                                    />
                                </div>
                                {
                                    description?.toLocaleLowerCase() === 'other' &&
                                    <div className='fdc p10'>
                                        <FormControl fullWidth required error={!otherDescription}>
                                            <TextField
                                                id={'otherDescription'}
                                                autoComplete='off'
                                                value={otherDescription ?? ''}
                                                onChange={this.onOtherDescriptionChanged}
                                                required
                                                error={!otherDescription}
                                                label='Add short description'
                                                disabled={isLoading}
                                            />
                                            {
                                                !otherDescription &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                }
                            </>
                        }
                        {
                            !monitor &&
                            <>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        FIELD
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <div className={'fdc flx1 aic'}>
                                        <ScoutingBlockLandNameDropdown
                                            type='scouting'
                                            value={landName}
                                            division={divisionCode}
                                            disabled={!divisionCode}
                                            crop={trap.crop}
                                            required
                                            fullWidth
                                            label='Select Field'
                                            onChange={this.onLandNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='fdr bcp pt15 pb15 pl25'>
                                    <Typography
                                        className='fwn cw fs15'
                                    >
                                        BLOCK
                                    </Typography>
                                </div>
                                <div className='fdc p10'>
                                    <div className={'fdc flx1 aic'}>
                                        <ScoutingBlockNameDropdown
                                            value={!block ? [] : [block]}
                                            landName={landName}
                                            division={divisionCode}
                                            fullWidth
                                            required
                                            single
                                            onChange={this.onBlockChange}
                                            type='scouting'
                                            disabled={!divisionCode}
                                            crop={trap.crop}
                                            label='Select Field'
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <div className='fdr'>
                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' onClick={this.onClose}>
                                    BACK
                            </StadiumButton>
                            <StadiumButton className='fw500 fs14 cw bcp' onClick={this.onSaveClick}>
                                    SAVE
                            </StadiumButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </React.Fragment>);
    };
}
