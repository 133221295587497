import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, IconButton, Switch, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Close, Save } from '@material-ui/icons';
import { ICultivar } from '../../../../types/model/masterData/cultivars';
import CultivarFunctions from '../../../../store/masterData/cultivar/functions';
import GeneralFunctions from '../../../../store/general/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';

interface ICultivarEditDialogProps {
    value : ICultivar | null;

    isLoading ?: boolean;

    onClose ?: () => void;
}

interface ICultivarEditDialogState {
    name : string;
    isActive : boolean;
    insects : Array<string>;
}

export default class CultivarEditDialog extends React.PureComponent<ICultivarEditDialogProps, ICultivarEditDialogState> {
    constructor(props : ICultivarEditDialogProps) {
        super(props);
        this.state = {
            name: '',
            isActive: true,
            insects: [],
        };
    }

    public componentDidUpdate(prevProps : Readonly<ICultivarEditDialogProps>) : void {
        if (!prevProps.value && this.props.value) {
            this.setState({
                name: this.props.value.name,
                isActive: this.props.value.isActive,
            });
        }
    }

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    };

    private readonly save = async () => {
        try {
            const {
                value,
            } = this.props;
            if (!value) return;
    
            const {
                name,
                isActive,
                insects,
            } = this.state;

            if (!name) return;
            if (insects.some(x => !x)) return;
    
            await CultivarFunctions.save({
                ...value,
                name,
                isActive,
            });

            if (this.props.onClose) this.props.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Saved');
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('Error saving cultivar', ex);
        }
    };

    private readonly onSaveClick = () => {
        this.save();
    };

    private readonly onIsActiveChange = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };
    
    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const index = Number(event.currentTarget.value);
        const insects = this.state.insects.slice();
        insects.splice(index, 1);

        this.setState({
            insects,
        });
    };

    public render = () => {
        const {
            value,
            isLoading,
            onClose,
        } = this.props;

        const {
            name,
            isActive,
        } = this.state;

        return (
            <Dialog
                open={!!value}
                TransitionComponent={Transitions.Down}
                transitionDuration={500}
                maxWidth={'md'}
                fullWidth
                aria-labelledby='edit-trap-type-dialog-title'
                aria-describedby='edit-trap-type-description'>
                <AppBar className='fdr posr aic jcc bcpd' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography>
                            {value?.id ? 'EDIT' : 'ADD'} CULTIVAR
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr ais jcfs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete='off'
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                    disabled={isLoading}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <Typography className='pr6' >Is Active?</Typography>
                            <Switch
                                id={'isActive'}
                                name={'isActive'}
                                checked={isActive}
                                color='primary'
                                onChange={this.onIsActiveChange}
                                inputProps={{
                                    'aria-label': 'Is Active',
                                    id: 'isActive',
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={this.onSaveClick}>
                        <Save style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
