import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import StadiumButton from '../../customComponents/button/StadiumButton';
import { Transitions } from '../../customComponents/animations/Transitions';
import { Close } from '@material-ui/icons';
import { ITrap, TrapHelper } from '../../../types/model/trap/trap';
import QrCodeService from '../../../services/qrCodeService';
import { Ecc } from '../../../types/qrcodegen';
import AppFunctionsService from '../../../services/appFunctionServices';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TrapQrCodeDocument from '../pdf/QrCodeDocument';

interface ITrapsDownloadQrCodeButtonProps {
    value : ITrap;

    onClose ?: () => void;
}

interface ITrapsDownloadQrCodeButtonState {
    open : boolean;
}

export default class TrapsDownloadQrCodeButton extends React.PureComponent<ITrapsDownloadQrCodeButtonProps, ITrapsDownloadQrCodeButtonState> {
    constructor(props : ITrapsDownloadQrCodeButtonProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onCanvisRef = (canvasRef : HTMLCanvasElement | null) => {
        if (!canvasRef) return;
        QrCodeService.drawOnCanvas(canvasRef, {
            ecc: Ecc.HIGH,
            text: TrapHelper.getQrCodeText(this.props.value),
            scale: 4,
        });
    };
    
    public readonly render = () => {
        const {
            value,
        } = this.props;

        const {
            open,
        } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='DownloadQrCode'>
                    <div>
                        <StadiumButton className='fw500 fs14 cp bcy' variant='contained' onClick={this.onClick}>
                            DOWNLOAD QR CODE
                        </StadiumButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    maxWidth={'sm'}
                    fullWidth
                    aria-labelledby='archive-trap-dialog-title'
                    aria-describedby='archive-trap-description'
                >
                    <AppBar className='fdr posr aic jcc bcpd' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography>
                                DOWNLOAD QR CODE
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div className='fdc'>
                            <div className='fdc aic jcc'>
                                <canvas
                                    ref={this.onCanvisRef}
                                    width={808}
                                    height={800}
                                />
                            </div>
                            <div className='fdc aic jcc mt15'>
                                <Typography
                                    className='fs15 fwm cg3'
                                >
                                    {value.code} - {value.typeName}
                                </Typography>
                                {
                                    value.monitoring &&
                                    <Typography
                                        className='fs15 fwm cg3'
                                    >
                                        ({AppFunctionsService.toTitleCase(value.otherDescription ?? value.description ?? '')} - FM)
                                    </Typography>
                                }
                                {
                                    !value.monitoring &&
                                    <Typography
                                        className='fs15 fwm cg3'
                                    >
                                        ({AppFunctionsService.toTitleCase(value.landName ?? '')} - {value.blockName})
                                    </Typography>
                                }
                            </div>
                            <div className='fdc aife jcc mt15'>
                                <PDFDownloadLink
                                    document={
                                        <TrapQrCodeDocument
                                            traps={[value]}
                                        />
                                    }
                                    fileName={`${value.code}.pdf`}
                                    className={'tdn'}
                                >
                                    {
                                        ({
                                            loading,
                                        }) => (
                                            <Tooltip title='Download'>
                                                <div>
                                                    <StadiumButton
                                                        disabled={loading}
                                                        variant='contained'
                                                        className='fw500 fs14 cw bcp'
                                                        onClick={this.onClick}
                                                    >
                                                        DOWNLOAD
                                                    </StadiumButton>
                                                </div>
                                            </Tooltip>
                                        )
                                    }
                                </PDFDownloadLink>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}
