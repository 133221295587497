import InsectActions from './actions';
import lodash from 'lodash';
import firebase from 'firebase/app';
import * as uuid from 'uuid';
import { dispatch, getState } from '../..';
import InsectHelper, { IInsect } from '../../../types/model/masterData/insect';
import GeneralFunctions from '../../general/functions';
import firebaseApp from '../../../services/firebaseService';
import moment from 'moment';

export default class InsectFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && InsectFunctions.listener) {
            return;
        }

        if (InsectFunctions.listener) {
            InsectFunctions.listener();
        }

        dispatch(InsectActions.setLoading(true));
        dispatch(InsectActions.setList([]));

        try {
            
            InsectFunctions.listener = InsectHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const insectState = getState().masterData.insect;

                    const insects = insectState.insects.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();
                        const index = lodash.findIndex(insects, n => n.id === data.id);

                        switch (f.type) {
                        case 'added':
                            insects.push(data);
                            break;
                        case 'modified':
                            insects.splice(index, 1, data);
                            break;
                        case 'removed':
                            insects.splice(index, 1);
                            break;
                        }
                    });

                    dispatch(InsectActions.setList(insects));
                    dispatch(InsectActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading insects.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading insects.', ex);
            dispatch(InsectActions.setLoading(false));
        }
    }

    private static uploadFiles = async (save : IInsect) => {
        for (const image of save.images ?? []) {
            if (image.file) {
                image.path = `insects/${save.id}/${moment.utc().valueOf()}_${image.name}`;

                const fileRef = firebaseApp.storage().ref(image.path);

                try {
                    image.url = await fileRef.getDownloadURL();
                } catch (ex) {
                    const result = await fileRef.put(image.file);
                    image.url = await result.ref.getDownloadURL();
                } finally {
                    delete image.file;
                }

            }
        }
    }

    public static save = async (item : IInsect) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const insects = getState().masterData.insect.insects;

        if (insects.some(x => x.name === item.name && x.id !== item.id)) {
            throw new Error('Insect already exists.');
        }

        dispatch(InsectActions.setLoading(true));

        try {

            await firebaseApp.firestore().runTransaction(async (db) => {
                const snapshot = await db.get(InsectHelper.doc(item.id));
                const save : IInsect = {
                    ...item,
                    createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                    createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                    createdByName: item.createdBy ? item.createdByName : session.user.name,
                    createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByEmployee: session.user.employeeNumber,
                    updatedByName: session.user.name,
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    id: item.id ? item.id : uuid.v4(),
                };
                
                await InsectFunctions.uploadFiles(save);

                db.set(snapshot.ref, save);
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving insect.', ex);
            throw ex;
        } finally {
            dispatch(InsectActions.setLoading(false));
        }
    }
}