import { IAvoBugState, AvoBugAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import Actions from './actions';

const initialState : IAvoBugState = {
    assignments: [],
    isLoadingAssignments: false,
};

export default function avoBugReducer(state : IAvoBugState = initialState, action : AvoBugAction) : IAvoBugState {
    switch (action.type) {
        case getType(Actions.setAssignments):
            return { ...state, assignments: action.payload };
        case getType(Actions.setLoadingAssignments):
            return { ...state, isLoadingAssignments: action.payload };
        default:
            return state;
    }
}
