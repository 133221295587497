import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import TabPanel from '../../../customComponents/tab/TabPanel';
import { CROP } from '../../../../appConstants';
import { IAssignmentBlock } from '../../../../types/model/masterData/block';
import AssignmentWeekSelector from '../../../customComponents/selector/AssignmentWeekSelector';
import AssignmentDivisionSelector from '../../../customComponents/selector/AssignmentDivisionSelector';
import { IDivision } from '../../../../types/model/rights/division';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { MapRef } from 'react-map-gl';
import GeoHelper from '../../../../services/helper/geoHelper';
import NematodeAssignmentFieldsView from '../view/FieldsView';
import NematodeAssignmentBlockSelector from './BlockSelector';

interface IGeneralNematodeAssignmentFieldSelectorProps {
    crop : CROP;

    division ?: IDivision;
    
    date ?: moment.Moment;
    
    selectedNematodeBlocks : Record<string, Array<IAssignmentBlock>>;

    onWeekChanged ?: (date ?: moment.Moment) => void;
    onDivisionChanged ?: (division ?: IDivision) => void;

    onChange ?: (selectedNematodeBlocks : Record<string, Array<IAssignmentBlock>>) => void;
    onAssignmentAddClick ?: () => void;

    mapRef : MapRef | null;
}

interface IGeneralNematodeAssignmentFieldSelectorState {
    tabIndex : number;

    defaultLandName ?: string;
}

export default class GeneralNematodeAssignmentFieldSelector extends React.PureComponent<IGeneralNematodeAssignmentFieldSelectorProps, IGeneralNematodeAssignmentFieldSelectorState> {
    constructor(props : IGeneralNematodeAssignmentFieldSelectorProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onAddFieldsClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBlockBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };

    private readonly onAddClick = (landName : string, blocks : Array<IAssignmentBlock>) => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
            defaultLandName: undefined,
        });

        const selectedBlocks = {
            ...this.props.selectedNematodeBlocks,
        };

        selectedBlocks[landName] = [...blocks];

        if (this.props.onChange) this.props.onChange(selectedBlocks);
    };

    private readonly onBlocksEditClick = (landName : string) => {
        this.setState({
            defaultLandName: landName,
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onDeleteLandClick = (landName : string) => {
        const selectedBlocks = {
            ...this.props.selectedNematodeBlocks,
        };
        delete selectedBlocks[landName];
        if (this.props.onChange) this.props.onChange(selectedBlocks);

    };

    private readonly onZoomClick = (landName : string) => {
        this.props.mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds([
            ...this.props.selectedNematodeBlocks[landName],
        ]), {
            minZoom: 15,
        });
    };

    public readonly render = () => {
        const {
            tabIndex,
            defaultLandName,
        } = this.state;

        const {
            crop,
            division,
            date,
            selectedNematodeBlocks,
            onDivisionChanged,
            onWeekChanged,
            onAssignmentAddClick,
            mapRef,
        } = this.props;
        
        return (
            <Paper className={'flx1 fdc m5'}>
                <TabPanel value={tabIndex} index={0}>
                    <AppBar position='static' className='bcdg pbr' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography className='fs17'>
                                ASSIGNMENTS
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc hfill oya drawer'>
                        <AssignmentWeekSelector
                            date={date}
                            onChanged={onWeekChanged}
                        />
                        <AssignmentDivisionSelector
                            crop={crop}
                            value={division}
                            onChanged={onDivisionChanged}
                        />
                        <NematodeAssignmentFieldsView
                            division={division?.code}
                            date={date}
                            scoutingBlocks={selectedNematodeBlocks}
                            onAddFieldsClick={this.onAddFieldsClick}
                            onDeleteClick={this.onDeleteLandClick}
                            onZoomClick={this.onZoomClick}
                            onEditClick={this.onBlocksEditClick}
                            assignmentType='general'
                        />
                    </div>
                    <div className={'fdc aife jcc p10 mt5'}>
                        <StadiumButton
                            variant='contained'
                            className='bcy cpd dbcg dcg bsd'
                            disabled={!Object.keys(selectedNematodeBlocks).length}
                            onClick={onAssignmentAddClick}
                        >
                            ADD ASSIGNMENT
                        </StadiumButton>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <NematodeAssignmentBlockSelector
                        crop={crop}
                        division={division?.code}
                        defaultLandName={defaultLandName}
                        onBackClick={this.onBlockBackClick}
                        date={date}
                        selectedNematodeBlocks={selectedNematodeBlocks}
                        onAddClick={this.onAddClick}
                        mapRef={mapRef}
                    />
                </TabPanel>
            </Paper>
        );
    };
}
