import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../../../services/appFunctionServices';
import { INematodeAssignmentLocationEntry, NematodeAssignmentLocationEntryHelper } from '../../../../../types/model/nematode/nematodeAssignmentLocationEntry';

interface INematodeAssignmentLocationHistoryMarkersProps {
    polygonId : string;

    polygonBeforeId ?: string;

    minZoom ?: number;
    maxZoom ?: number;

    visible ?: boolean;

    assignmentId ?: string;
}

interface INematodeAssignmentLocationHistoryMarkersState {
    locations : Array<INematodeAssignmentLocationEntry>;
}

export default class NematodeAssignmentLocationHistoryMarkers extends React.PureComponent<INematodeAssignmentLocationHistoryMarkersProps, INematodeAssignmentLocationHistoryMarkersState> {
    private listener ?: () => void;

    constructor(props : INematodeAssignmentLocationHistoryMarkersProps) {
        super(props);
        this.state = {
            locations: [],
        };
    }

    public componentDidMount() : void {
        this.loadLocations();
    }

    public componentWillUnmount() : void {
        if (this.listener) {
            this.listener();
        }
    }

    private readonly loadLocations = () => {
        this.listener = NematodeAssignmentLocationEntryHelper.collection(this.props.assignmentId).orderBy('date', 'desc').onSnapshot((snapshot) => {
            const locations = this.state.locations.slice();

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const location = f.doc.data();

                const index = f.newIndex;

                switch (f.type) {
                    case 'added':
                        locations.splice(index, 1, location);
                        break;
                    case 'modified':
                        locations.splice(f.oldIndex, 1);
                        locations.splice(index, 1, location);
                        break;
                    case 'removed':
                        locations.splice(f.oldIndex, 1);
                        break;
                }
            });

            this.setState({
                locations,
            });
        });
    };

    private readonly getLocations = (props : INematodeAssignmentLocationHistoryMarkersProps, state : INematodeAssignmentLocationHistoryMarkersState) => state.locations;

    private readonly getFeatureCollection = createSelector(
        [
            this.getLocations,
        ],
        (
            locations,
        ) => {
            const geoJson : GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, {
                id : string;
                color : string;
                radius : number;
                description : string;
            }> = {
                type: 'FeatureCollection',
                features: locations.map((n, key) => ({
                    id: `point_${key}`,
                    geometry: {
                        coordinates: [n.longitude, n.latitude],
                        type: 'Point',
                    },
                    type: 'Feature',
                    properties: {
                        id: `location_${n.id}`,
                        color: key === 0 || key === locations.length - 1 ? '#FF7F00' : 'blue',
                        radius: 3,
                        description: AppFunctionsService.formatDateTime(n.date),
                    },
                })),
            };

            geoJson.features = [
                ...geoJson.features,
                {
                    geometry: {
                        coordinates: [
                            ...locations.map((n) => [n.longitude, n.latitude]),
                        ],
                        type: 'LineString',
                    },
                    type: 'Feature',
                    properties: {
                        id: '',
                        color: 'blue',
                        radius: 3,
                        description: '',
                    },
                },
            ];

            return geoJson;
        },
    );
    
    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 21,
            polygonId,
            polygonBeforeId,
            visible = true,
        } = this.props;

        const data = this.getFeatureCollection(this.props, this.state);

        return (

            <Source
                id={`${polygonId}_source`}
                type='geojson'
                data={data}
                maxzoom={maxZoom}
            >
                <Layer
                    {...{
                        id: polygonId,
                        type: 'circle',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'circle-color': ['get', 'color'],
                            'circle-opacity': 1,
                            'circle-radius': ['get', 'radius'],
                            'circle-stroke-width': 1,
                        },
                        beforeId: polygonBeforeId,
                        'filter': ['==', '$type', 'Point'],
                    }}
                />
                <Layer
                    {...{
                        id: `${polygonId}_lines`,
                        type: 'line',
                        beforeId: polygonId,
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'line-width': 1,
                            'line-color': ['get', 'color'],
                        },
                        'filter': ['==', '$type', 'LineString'],
                    }}
                />
            </Source>
        );
    };
}
