import Typography from '@material-ui/core/Typography';
import React from 'react';
import Box from '@material-ui/core/Box';

interface ITabPanelProps {
    value ?: number;
    index ?: number;
}

export default class TabPanel extends React.PureComponent<React.PropsWithChildren<ITabPanelProps>> {
    constructor(props : ITabPanelProps) {
        super(props);
    }

    public readonly render = () => {
        const { children, value, index, ...other } = this.props;

        return (
            <Typography
                className='hfill'
                component='div'
                role='tabpanel'
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                <Box className='fdc flx1 hfill'>{children}</Box>
            </Typography>
        );
    };
}
