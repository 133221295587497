import axiosDefault, { CancelTokenStatic, Canceler } from 'axios';
import GeneralFunctions from '../store/general/functions';
import { IGeoServerGeoJsonBlock } from '../types/model/masterData/geoserver/block';
import { IGeoServerGeoJsonPoint } from '../types/model/masterData/geoserver/point';

export default class HttpGeoServerService {
    private static readonly axios = axiosDefault.create();
    private static readonly CancelToken : CancelTokenStatic = axiosDefault.CancelToken;

    private static requestInterceptorNum = 0;
    private static responseInterceptorNum = 0;

    /**
     * Allows you to cancel current latest inbound request
     */
    public static cancelBlocks ?: Canceler;
    public static cancelPoints ?: Canceler;

    public static readonly initializeInterceptor = () => {
        this.axios.interceptors.request.eject(HttpGeoServerService.requestInterceptorNum);
        this.axios.interceptors.response.eject(HttpGeoServerService.responseInterceptorNum);

        const token = process.env.GeoServerAccessToken;

        if (token) {
            HttpGeoServerService.requestInterceptorNum = this.axios.interceptors.request.use((config) => {
                //config.headers['Authorization'] = token;
                return config;
            });

            HttpGeoServerService.responseInterceptorNum = this.axios.interceptors.response.use((response) => {
                return response;
            }, (error) => {
                if (axiosDefault.isCancel(error)) {
                    return Promise.reject();
                }

                if (!!error.response && error.response.status === 401) {
                    HttpGeoServerService.initializeInterceptor();
                    GeneralFunctions.generalShowErrorSnackbar('Unauthorised', error.response?.data);
                    return Promise.reject(error.response);
                } else if (!!error.response && error.response.status === 403) {
                    GeneralFunctions.generalShowErrorSnackbar('Insufficient rights', error.response.data);
                    return Promise.reject(error.response);
                }

                if (!error?.response) {
                    GeneralFunctions.generalShowErrorSnackbar('Connection Error.');
                    return Promise.reject();
                }

                return Promise.reject(error.response);
            });
        }
    };

    public static getBlocks = () => {
        if (HttpGeoServerService.cancelBlocks) {
            HttpGeoServerService.cancelBlocks('Cancel');
        }
        return this.axios.get<GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon, IGeoServerGeoJsonBlock>>(`${GEO_SERVER_URL}/geoserver/${GEO_SERVER_WORKSPACE}/ows`, {
            cancelToken: new HttpGeoServerService.CancelToken((c) => {
                HttpGeoServerService.cancelBlocks = c;
            }),
            params: {
                authkey: process.env.GeoServerAccessToken,
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: 'farm_blocks',
                outputFormat: 'json',
                EPSG: 4326,
            },
        });
    };

    public static getPoints = () => {
        if (HttpGeoServerService.cancelPoints) {
            HttpGeoServerService.cancelPoints('Cancel');
        }
        return this.axios.get<GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.MultiPoint, IGeoServerGeoJsonPoint>>(`${GEO_SERVER_URL}/geoserver/${GEO_SERVER_POINT_WORKSPACE}/ows`, {
            cancelToken: new HttpGeoServerService.CancelToken((c) => {
                HttpGeoServerService.cancelPoints = c;
            }),
            params: {
                authkey: process.env.GeoServerAccessToken,
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                typeName: 'scouting_points',
                outputFormat: 'json',
            },
        });
    };
}