import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Paper } from '@material-ui/core';
import { CROP } from '../../../appConstants';
import { IPhenologySpecific, PhenologySpecificHelper, PhenologySpecificTreeType } from '../../../types/model/phenology/specific';
import VerticalExpander from '../../customComponents/expander/VerticalExpander';
import CropAutocomplete from '../../customComponents/autocomplete/masterData/CropAutocomplete';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import { createSelector } from 'reselect';
import { LngLatBoundsLike } from 'react-map-gl';
import GeoServerDivisionAutocomplete from '../../customComponents/autocomplete/masterData/geoserver/DivisionAutocomplete';
import GeoServerSubDivisionAutocomplete from '../../customComponents/autocomplete/masterData/geoserver/SubDivisionAutocomplete';
import GeoServerDepartmentAutocomplete from '../../customComponents/autocomplete/masterData/geoserver/DepartmentAutocomplete';
import GeoServerFieldAutocomplete from '../../customComponents/autocomplete/masterData/geoserver/FieldAutocomplete';
import GeoServerBlockAutocomplete from '../../customComponents/autocomplete/masterData/geoserver/BlockAutocomplete';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import MapboxCherriesSpecificLayer from '../../customComponents/mapBox/layer/phenology/CherriesSpecificLayer';

interface IPhenologySpecificsMapProps extends RouteComponentProps {
    isLoading : boolean;
    specifics : Array<IPhenologySpecific>;
    geoserverblocks : Array<IGeoServerBlock>;
}

interface IPhenologySpecificsMapState {
    selectedCode ?: string | null;

    divisionGuid : string | null;
    subDivisionGuid : string | null;
    departmentGuid : string | null;
    fieldGuid : string | null;
    blockGuid : string | null;
    cropType : CROP | null;
}

class PhenologySpecificsMapComponent extends React.PureComponent<IPhenologySpecificsMapProps, IPhenologySpecificsMapState> {
    private mapRef : MapBoxRef | null = null;

    private readonly divisionZoom = 10;
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : IPhenologySpecificsMapProps) {
        super(props);
        this.state = {
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
            cropType: null,
        };
    }

    public readonly componentDidMount = () => {
        this.setCropType();
    };

    public componentDidUpdate(prevProps : Readonly<IPhenologySpecificsMapProps>) : void {
        if (prevProps.location !== this.props.location) {
            this.setCropType();
            this.setCode();
        }

        if (!prevProps.specifics.length && this.props.specifics.length) {
            this.setCode();
        }
    }
    private readonly getData = (state : IPhenologySpecificsMapState, props : IPhenologySpecificsMapProps) => props.specifics;
    private readonly getGeoserverBlocks = (state : IPhenologySpecificsMapState, props : IPhenologySpecificsMapProps) => props.geoserverblocks;
    private readonly getBlockGuid = (state : IPhenologySpecificsMapState) => state.blockGuid;
    private readonly getDepartmentGuid = (state : IPhenologySpecificsMapState) => state.departmentGuid;
    private readonly getFieldGuid = (state : IPhenologySpecificsMapState) => state.fieldGuid;

    private readonly getTrees = createSelector([
        this.getData,
    ], (
        specifics,
    ) => {
        return specifics.map(PhenologySpecificHelper.getTreeType).flatMap(x => x);
    });
    
    public readonly setCropType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('crop')) {
            const crop = urlParams.get('crop') as CROP | null;

            if (crop) {
                this.setState({
                    cropType: crop,
                });
            }
        } else {
            this.setState({
                cropType: null,
            });
        }
    };

    public readonly setCode = () => {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('code')) {
            const code = urlParams.get('code') as string | null;

            if (code) {
                const tree = this.getTrees(this.state, this.props).find(x => x.code === code);

                if (!tree?.location) return;

                this.setState({
                    selectedCode: tree.code,
                });

                if (!this.mapRef) return;

                this.mapRef.flyTo({
                    center: [tree.location.longitude, tree.location.latitude],
                    zoom: this.blockZoom,
                });
            }
        }
    };
    
    private readonly flyToBounds = (bounds : LngLatBoundsLike, zoom ?: number) => {
        this.mapRef?.fitBounds(bounds, {
            minZoom: zoom,
        });
    };

    private readonly onDivisionChange = (value ?: string | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            divisionGuid: value ?? null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.divisionZoom);
        }
    };

    private readonly onSubDivisionChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            subDivisionGuid: value ?? null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.subDivisionZoom);
        }
    };

    private readonly onDepartmentChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            departmentGuid: value ?? null,
            fieldGuid: null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.departmentZoom);
        }
    };

    private readonly onFieldChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            fieldGuid: value ?? null,
            blockGuid: null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.fieldZoom);
        }
    };

    private readonly onBlockChange = (value ?: IGeoServerBlock | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            blockGuid: value?.guid ?? null,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.blockZoom);
        }
    };

    private readonly onCropTypeChange = (cropType ?: CROP | null) => {
        this.setState({
            cropType: cropType ?? null,
        });
    };

    private readonly onPhenologySpecificClick = (tree ?: PhenologySpecificTreeType) => {
        this.setState({
            selectedCode: tree?.code,
        });

        if (!tree?.location || !this.mapRef) {
            return;
        }

        this.mapRef.flyTo({
            center: [tree.location.longitude, tree.location.latitude],
            zoom: this.blockZoom,
        });
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;

        this.setCode();
    };

    private readonly getLandName = createSelector([
        this.getGeoserverBlocks,
        this.getFieldGuid,
    ], (
        blocks,
        fieldGuid,
    ) => {
        return blocks.find(x => fieldGuid === x.fieldGuid)?.fieldName;
    });

    private readonly getBlockCode = createSelector([
        this.getGeoserverBlocks,
        this.getBlockGuid,
    ], (
        blocks,
        blockGuid,
    ) => {
        return blocks.find(x => blockGuid === x.guid)?.code;
    });

    private readonly getDivision = createSelector([
        this.getGeoserverBlocks,
        this.getDepartmentGuid,
    ], (
        geoserverblocks,
        departmentGuid,
    ) => {
        const geoserverblock = geoserverblocks.find(x => x.departmentGuid === departmentGuid);

        if (!geoserverblock) return;
        
        
        return geoserverblock.departmentShortName;
    });

    public render = () => {
        const {
            divisionGuid,
            subDivisionGuid,
            blockGuid,
            departmentGuid,
            fieldGuid,
            cropType,
            selectedCode,
        } = this.state;

        const blockName = this.getBlockCode(this.state, this.props);
        const division = this.getDivision(this.state, this.props);
        const landName = this.getLandName(this.state, this.props);
        
        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <BasicMap
                        id={'phenologySpecificMap'}
                        onMap={this.setMapRef}
                        blockGuid={blockGuid}
                        divisionGuid={divisionGuid}
                        subDivisionGuid={subDivisionGuid}
                        departmentGuid={departmentGuid}
                        fieldGuid={fieldGuid}
                    >
                        {
                            cropType === 'cherries' &&
                            <MapboxCherriesSpecificLayer
                                markersLayerId='point_farm_layer'
                                minZoom={1}
                                divisionCode={division}
                                blockName={blockName}
                                landName={landName}
                                markersBeforeId='polygon_division_layer'
                                onMarkerClick={this.onPhenologySpecificClick}
                                selectedCode={selectedCode}
                            />
                        }
                    </BasicMap>
                    
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <CropAutocomplete
                                fullWidth
                                value={cropType}
                                onChange={this.onCropTypeChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDivisionAutocomplete
                                value={divisionGuid}
                                fullWidth
                                onChange={this.onDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerSubDivisionAutocomplete
                                value={subDivisionGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                onChange={this.onSubDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDepartmentAutocomplete
                                value={departmentGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                onChange={this.onDepartmentChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerFieldAutocomplete
                                value={fieldGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                onChange={this.onFieldChange}
                                type={'phenology'}
                                crop={cropType}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerBlockAutocomplete
                                value={blockGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                fieldGuid={fieldGuid}
                                onChange={this.onBlockChange}
                                type={'phenology'}
                                crop={cropType}
                            />
                        </div>
                    </VerticalExpander>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.masterData.geoserver.isLoadingBlocks,
        geoserverblocks: state.masterData.geoserver.blocks,
        specifics: state.phenology.specific.specifics,
    };
};

const PhenologySpecificsMap = connect(
    mapStateToProps,
)(withRouter(PhenologySpecificsMapComponent));

export default PhenologySpecificsMap;