import React from 'react';

interface ITabViewsProps {
    index : number;
    children : Array<boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined>;
}

interface ITabViewsState {}

export default class TabViews extends React.PureComponent<React.PropsWithChildren<ITabViewsProps>, ITabViewsState> {
    constructor(props : ITabViewsProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { index } = this.props;

        const children = this.props.children.filter(m => !!m);
        return children[index] ?? (<div></div>);
    };
}
