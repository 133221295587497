import { createAction } from 'typesafe-actions';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint } from '../../../types/model/masterData/geoserver/point';

export default class MasterDataGeoServerActions {
    public static setLoadingBlockList = createAction('MASTER_DATA_GEOSERVER_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
    public static setBlockList = createAction('MASTER_DATA_GEOSERVER_BLOCKS_SET', resolve => (blocks : Array<IGeoServerBlock>) => resolve(blocks));

    public static setLoadingPointList = createAction('MASTER_DATA_GEOSERVER_POINT_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
    public static setPointList = createAction('MASTER_DATA_GEOSERVER_POINTS_SET', resolve => (points : Array<IGeoServerPoint>) => resolve(points));
}