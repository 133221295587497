import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import MaterialTable from '../../../customComponents/materialTable/Table';
import { Add, Check, Close, Edit } from '@material-ui/icons';
import StandardFab from '../../../customComponents/button/StandardFab';
import TrapEntryCommentEditDialog from './dialog/Edit';
import moment from 'moment';
import { ITrapEntryComment } from '../../../../types/model/masterData/trap/trapEntryComment';
import TrapEntryCommentFunctions from '../../../../store/masterData/trap/trapEntryComment/functions';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { createSelector } from 'reselect';
import { CROP } from '../../../../appConstants';

interface ITrapEntryCommentListProps extends RouteComponentProps {
    trapEntryComments : Array<ITrapEntryComment>;
    isLoading : boolean;
}

interface ITrapEntryCommentListState {
    selected : ITrapEntryComment | null;
}

class TrapEntryCommentListComponent extends React.PureComponent<ITrapEntryCommentListProps, ITrapEntryCommentListState> {
    constructor(props : ITrapEntryCommentListProps) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    public readonly load = () => {
        TrapEntryCommentFunctions.getList();
    };

    private readonly getSearch = (props : ITrapEntryCommentListProps) => props.location.search;
    private readonly getData = (props : ITrapEntryCommentListProps) => props.trapEntryComments;

    private getCrop = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
            if (urlParams.has('crop')) {
                const crop = urlParams.get('crop');
    
                if (crop) {
                    return crop as CROP;
                }
            }

            return null;
        },
    );

    private readonly getComments = createSelector(
        [
            this.getData,
            this.getCrop,
        ],
        (trapEntryComments, crop) => {
            return trapEntryComments.filter(x => !crop || x.crop === crop);
        },
    );

    public onAddClick = () => {
        this.setState({
            selected: {
                id: '',
                trapTypeId: '',
                trapTypeName: '',
                comment: '',
                crop: this.getCrop(this.props) ?? 'almonds',
                isActive: true,
                createdBy: '',
                createdByEmployee: '',
                createdByName: '',
                createdOn: moment.utc().valueOf(),
                updatedBy: '',
                updatedByEmployee: '',
                updatedByName: '',
                updatedOn: moment.utc().valueOf(),
            },
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const {
            trapEntryComments,
        } = this.props;

        const value = trapEntryComments.find(x => x.id === event.currentTarget.value);
        if (!value) return;

        this.setState({
            selected: {
                ...value,
            },
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selected: null,
        });
    };

    public render = () => {
        const {
            isLoading,
        } = this.props;
        const {
            selected,
        } = this.state;

        const trapEntryComments = this.getComments(this.props);
        
        return (
            <div className={'fdc hfill mh0 mw0 bcg0'}>
                <div className='fdc flx1 p20'>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ITrapEntryComment>
                            id='trapEntryCommentsTable'
                            data={trapEntryComments}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton color='primary' value={row.id} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Crop',
                                field: 'crop',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: (row) => AppFunctionsService.toTitleCase(row.crop),
                            }, {
                                header: 'Comment',
                                field: 'comment',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'TrapType',
                                field: 'trapTypeName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Close className='cpr' />) : (<Check className='cdg' />),
                            }]}
                        />
                    </Card>
                    <TrapEntryCommentEditDialog
                        value={selected}
                        onClose={this.onEditClose}
                        isLoading={isLoading}
                    />
                </div>
                <div className='fdr aife jcfe'>
                    <Tooltip title='Create'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                                <Add />
                            </StandardFab>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        trapEntryComments: state.masterData.trap.trapEntryComment.trapEntryComments,
        isLoading: state.masterData.trap.trapEntryComment.isLoading,
    };
};


const TrapEntryCommentList = withRouter(connect(
    mapStateToProps,
)(TrapEntryCommentListComponent));

export default TrapEntryCommentList;