import React from 'react';
import ScoutingEntryView from './ScoutingEntryView';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';

interface IAssignmentCompletedHistoryViewProps {
    value : IScoutingAssignment;
    entries : Array<IScoutingEntry>;
}

interface IAssignmentCompletedHistoryViewState {

    entries : {
        [key : string] : IScoutingEntry;
    };
}

export default class AssignmentCompletedHistoryView extends React.PureComponent<IAssignmentCompletedHistoryViewProps, IAssignmentCompletedHistoryViewState> {
    constructor(props : IAssignmentCompletedHistoryViewProps) {
        super(props);
        this.state = {
            entries: {},
        };
    }

    public componentDidMount = () => {
        this.loadLocations();
    };

    private loadLocations = () => {
        const entries = {
            ...this.state.entries,
        };

        // "added" | "removed" | "modified"
        this.props.entries.forEach((entry) => {
            entries[entry.pointGuid] = entry;
        });

        this.setState({
            entries,
        });
    };

    public render = () => {
        const { entries } = this.state;
        const { value } = this.props;

        return (
            <div className={'fdc ais flx1'}>
                {
                    value.scoutingBlock.pointsArray.map((n, i) => (
                        <ScoutingEntryView key={`${n}_scout_point`} pointNumber={i + 1} value={entries[n]} />
                    ))
                }
            </div>);
    };
}
