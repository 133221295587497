import { AppBar, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import StadiumButton from '../../customComponents/button/StadiumButton';
import { Transitions } from '../../customComponents/animations/Transitions';
import { Close, Warning } from '@material-ui/icons';
import { ITrap } from '../../../types/model/trap/trap';
import TrapFunctions from '../../../store/trap/functions';

interface ITrapsArchiveButtonProps {
    selected : Array<ITrap>;

    onClose ?: () => void;
}

interface ITrapsArchiveButtonState {
    open : boolean;
    isLoading : boolean;
}

export default class TrapsArchiveButton extends React.PureComponent<ITrapsArchiveButtonProps, ITrapsArchiveButtonState> {
    constructor(props : ITrapsArchiveButtonProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
        };
    }
    public readonly onClick = () => {
        this.setState({
            open: true,
            isLoading: false,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    };
    
    private readonly archive = async () => {
        const {
            selected,
            onClose,
        } = this.props;

        try {
            this.setState({
                isLoading: true,
            });
            await TrapFunctions.archive(selected);

            this.onClose();
            if (onClose) onClose();
        } catch (ex) {
            // Ignore
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onArchiveClick = () => {
        this.archive();
    };

    public render = () => {
        const {
            selected,
        } = this.props;

        const {
            open,
            isLoading,
        } = this.state;

        return (
            <React.Fragment>
                {
                    !!selected.length &&
                    <div className='fdr'>
                        <Tooltip title='Archive'>
                            <div>
                                <StadiumButton className='fw500 fs14 cw bcpd' onClick={this.onClick}>
                                    ARCHIVE
                                </StadiumButton>
                            </div>
                        </Tooltip>
                    </div>
                }
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.ZoomIn}
                    transitionDuration={500}
                    maxWidth={'sm'}
                    fullWidth
                    aria-labelledby='archive-trap-dialog-title'
                    aria-describedby='archive-trap-description'>
                    <AppBar className='fdr posr aic jcc bcpd' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography>
                                ARCHIVE SELECTED TRAPS
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close' disabled={isLoading}>
                                        <Close />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div className='fdr aic jcc'>
                            <div className='fdc mr15'>
                                <Warning className='co h36 w36' />
                            </div>
                            <div className='fdc'>
                                <Typography
                                    className='co fs24 fwm'
                                >
                                    Are you sure you want to archive {selected.length} trap{selected.length > 1 ? 's' : ''}?
                                </Typography>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='fdr'>
                            <StadiumButton className='fw500 fs14 cpd mr15 bw1' variant='text' disabled={isLoading} onClick={this.onClose}>
                                    BACK
                            </StadiumButton>
                            <StadiumButton className='fw500 fs14 cw bcp' disabled={isLoading} onClick={this.onArchiveClick}>
                                    ARCHIVE
                            </StadiumButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}
