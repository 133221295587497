import firebase from 'firebase/app';
import CultivarActions from './actions';
import * as uuid from 'uuid';
import { dispatch, getState } from '../..';
import CultivarHelper, { ICultivar } from '../../../types/model/masterData/cultivars';
import GeneralFunctions from '../../general/functions';

export default class CultivarFunctions {
    private static listener ?: () => void;

    public static getList = (refresh ?: boolean) => {

        if (!refresh && CultivarFunctions.listener) {
            return;
        }

        if (CultivarFunctions.listener) {
            CultivarFunctions.listener();
        }

        dispatch(CultivarActions.setLoading(true));
        dispatch(CultivarActions.setList([]));

        try {
            
            CultivarFunctions.listener = CultivarHelper
                .collection()
                .onSnapshot((snapshot) => {
                    const cultivarState = getState().masterData.cultivar;

                    const cultivars = cultivarState.cultivars.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const data = f.doc.data();

                        switch (f.type) {
                        case 'added':
                            cultivars.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            cultivars.splice(f.oldIndex, 1);
                            cultivars.splice(f.newIndex, 1, data);
                            break;
                        case 'removed':
                            cultivars.splice(f.oldIndex, 1);
                            break;
                        }
                    });

                    dispatch(CultivarActions.setList(cultivars));
                    dispatch(CultivarActions.setLoading(false));
                }, (err) => {
                    GeneralFunctions.generalShowErrorSnackbar('An error while loading cultivars.', err);
                });

        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while loading cultivars.', ex);
            dispatch(CultivarActions.setLoading(false));
        }
    }

    public static save = async (item : ICultivar) => {
        const session = getState().auth.session;

        if (!session) return;
        
        const cultivars = getState().masterData.cultivar.cultivars;

        if (cultivars.some(x => x.name === item.name && x.crop === item.crop && x.id !== item.id)) {
            throw new Error('Cultivar  already exists.');
        }

        dispatch(CultivarActions.setLoading(true));

        try {
            const save : ICultivar = {
                ...item,
                createdBy: item.createdBy ? item.createdBy : session.firebaseUser.uid,
                createdByEmployee: item.createdByEmployee ? item.createdByEmployee : session.user.employeeNumber,
                createdByName: item.createdBy ? item.createdByName : session.user.name,
                createdOn: item.createdOn ? item.createdOn : firebase.firestore.Timestamp.now().toMillis(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.user.employeeNumber,
                updatedByName: session.user.name,
                updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                id: item.id ? item.id : uuid.v4(),
            };

            await CultivarHelper.save(save);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar('An error while saving cultivar.', ex);
            throw ex;
        } finally {
            dispatch(CultivarActions.setLoading(false));
        }
    }
}