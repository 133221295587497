import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import StadiumButton from '../button/StadiumButton';

interface IConfirmDialogProps {
    open : boolean;
    onClose : (result : boolean) => void;
    title : string;
    message : React.ReactNode;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    acceptText ?: string;
    denyText ?: string;

    disabled ?: boolean;
}

interface IConfirmDialogState {}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {
    constructor(props : IConfirmDialogProps) {
        super(props);
        this.state = {};
    }

    private onClose = () => {
        this.props.onClose(false);
    };

    private onAccept = () => {
        this.props.onClose(true);
    };

    private onDeny = () => {
        this.props.onClose(false);
    };

    public render = () => {
        const {
            open,
            transition,
            title,
            message,
            acceptText,
            denyText,
            fullWidth,
            maxWidth,
            fullScreen,
            disabled,
        } = this.props;
        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={disabled ? undefined : this.onClose}
                aria-labelledby='confirm-dialog-title'
                aria-describedby='confirm-dialog-description'
            >
                <AppBar className='posr p10' position='static'>
                    <Typography variant='h5' color='inherit' className='flx1'>
                        {title}
                    </Typography>
                </AppBar>
                <DialogContent>
                    {
                        typeof message === 'string' &&
                        <Typography variant='subtitle1'>
                            {message}
                        </Typography>
                    }
                    {
                        typeof message !== 'string' &&
                        message
                    }
                </DialogContent>
                <DialogActions>
                    <StadiumButton disabled={disabled} variant='text' onClick={this.onDeny} color='primary' autoFocus>
                        {denyText ? denyText : 'NO'}
                    </StadiumButton>
                    <StadiumButton disabled={disabled} variant='contained' onClick={this.onAccept} color='primary'>
                        {acceptText ? acceptText : 'YES'}
                    </StadiumButton>
                </DialogActions>
            </Dialog>
        );
    };
}
