import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import lodash from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint, pointType } from '../../../types/model/masterData/geoserver/point';
import AssignmentBlockHelper, { IAssignmentBlock } from '../../../types/model/masterData/block';
import { CROP } from '../../../appConstants';
import MasterDataGeoServerFunctions from '../../../store/masterData/geoserver/functions';

interface IScoutingBlockNameDropdownProps {
    value : Array<string>;

    onChange : (value : Array<IAssignmentBlock>) => void;

    blocks : Array<IGeoServerBlock>;
    points : Array<IGeoServerPoint>;

    label ?: string;

    required ?: boolean;
    autoFocus ?: boolean;
    disabled ?: boolean;

    fullWidth ?: boolean;

    isLoading : boolean;

    division ?: string | null;
    landName ?: string | null;

    single ?: boolean;
    crop ?: CROP;

    type : pointType;
}

interface IScoutingBlockNameDropdownState {
}

class ScoutingBlockNameDropdownComponent extends React.Component<IScoutingBlockNameDropdownProps, IScoutingBlockNameDropdownState> {
    constructor(props : IScoutingBlockNameDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount = () => {
        this.loadData();
    };

    public loadData = async () => {
        MasterDataGeoServerFunctions.getBlockList();
        MasterDataGeoServerFunctions.getPointList();
    };

    private onChange = (event : React.ChangeEvent<unknown>, value : Array<{
        label : string;
        value : string;
    }> | null) => {
        const blocks : Array<IAssignmentBlock> = this
            .props
            .blocks
            .filter(x => value?.some(val => x.guid === val.value))
            .map(block => {
                const points = this.props.points
                    .filter(x => x.type === this.props.type)
                    .filter(x => x.blockGuid === block.guid);

                return AssignmentBlockHelper.fromGeoServerBlock(block, this.props.crop ?? 'almonds', points);
            });
        if (this.props.single && value?.length) {
            this.props.onChange([blocks[0]]);
        } else {
            this.props.onChange(blocks);
        }
    };

    private onSingleChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        if (value) {
            const blocks : Array<IAssignmentBlock> = this.props.blocks
                .filter(x => x.guid === value.value)
                .map(block => {
                    const points = this.props.points
                        .filter(x => x.type === this.props.type)
                        .filter(x => x.blockGuid === block.guid);
    
                    return AssignmentBlockHelper.fromGeoServerBlock(block, this.props.crop ?? 'almonds', points);
                });
            this.props.onChange(blocks);
        } else {
            this.props.onChange([]);
        }
    };

    private getDivision = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.division;
    private getLandName = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.landName;
    private getBlocks = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.blocks;
    private getValue = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.value;
    private getCrop = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.crop;
    private getRequired = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.required;
    private getPoints = (state : IScoutingBlockNameDropdownState, props : IScoutingBlockNameDropdownProps) => props.points;

    private getData = createSelector([
        this.getBlocks,
        this.getDivision,
        this.getLandName,
        this.getCrop,
        this.getPoints,
    ], (
        blocks,
        division,
        landName,
        crop,
        points,
    ) => {
        const blockGuids = lodash.chain(points)
            .filter(n => !crop || n.crop === crop)
            .map(n => n.blockGuid)
            .uniq()
            .value();

        return lodash.chain(blocks)
            .filter(n => blockGuids.includes(n.guid))
            .filter(n => !division || n.departmentShortName.toLocaleUpperCase() === division.toLocaleUpperCase())
            .filter(n => !landName || n.fieldName.toLocaleUpperCase() === landName.toLocaleUpperCase())
            .sortBy(n => n.code)
            .value();
    });

    private getScoutingBlockNameDropdown = createSelector([
        this.getData, this.getRequired,
    ], (blocks, required) => {
        const blocksDrop = blocks.map(x => ({ label: x.code.toLocaleUpperCase(), value: x.guid }));

        if (!required) {
            blocksDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return blocksDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
        this.getData,
    ], (value, required, blocks) => {
        const values = [];

        blocks
            .slice()
            .filter(x => value.includes(x.code))
            .forEach(n => values.push({
                label: n.code.toLocaleUpperCase(),
                value: n.guid,
            }));

        if (!value.length && !required) {
            values.push({
                label: 'ALL',
                value: '',
            });
        }

        return values;
    });

    public render = () => {
        const { required, fullWidth, isLoading, disabled, single, label } = this.props;

        const blocks = this.getScoutingBlockNameDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value.length} required={required}>
                    {
                        single &&
                        <Autocomplete
                            id='blockName_select'
                            options={blocks}
                            value={!value.length ? null : value[0]}
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onSingleChange}
                            disableClearable={required}
                            openOnFocus
                            disabled={disabled || isLoading}
                            renderInput={params =>
                                <TextField error={required && !value.length} required={required} {...params} fullWidth={fullWidth} label={label ?? 'Block Name'} />}
                        />
                    }
                    {
                        !single &&
                        <Autocomplete
                            id='blockName_select'
                            options={blocks}
                            value={value}
                            disabled={disabled}
                            multiple
                            getOptionSelected={(option, val) => option.value === val.value}
                            getOptionLabel={option => option.label}
                            onChange={this.onChange}
                            disableClearable={required}
                            openOnFocus
                            disableCloseOnSelect
                            limitTags={3}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon color='primary' fontSize='small' />}
                                        checkedIcon={<CheckBoxIcon color='primary' fontSize='small' />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={params => <TextField maxRows={1} error={required && !value.length} {...params} fullWidth={fullWidth} label='Block Name' />}
                        />
                    }
                    
                    {
                        required && !value.length &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        blocks: state.masterData.geoserver.blocks,
        points: state.masterData.geoserver.points,
        isLoading: state.masterData.geoserver.isLoadingBlocks || state.masterData.geoserver.isLoadingPoints,
    };
};

const ScoutingBlockNameDropdown = connect(
    mapStateToProps,
)(ScoutingBlockNameDropdownComponent);

export default ScoutingBlockNameDropdown;
