import { Divider, ListItem, ListItemIcon } from '@material-ui/core';
import React from 'react';
import NavFunctions from '../../../store/nav/functions';
import { IUserSession } from '../../../types/model/user';
import ListItemTextStyled from '../../customComponents/list/ListItemTextStyled';
import SideMenuItem from '../../customComponents/nav/SideMenuItem';

interface INavDrawerMasterDataProps {
    session : IUserSession;
    toggleDrawer : () => void;
}

interface INavDrawerMasterDataState {}

export default class NavDrawerMasterData extends React.PureComponent<INavDrawerMasterDataProps, INavDrawerMasterDataState> {
    constructor(props : INavDrawerMasterDataProps) {
        super(props);
        this.state = {};
    }

    private readonly onThresholdInsectNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/threshold', `?type=insects&crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onThresholdDiseaseNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/threshold', `?type=disease&crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onPhenologyNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/phenology', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onScoutingNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/scouting', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onInsectNavClick = () => {
        NavFunctions.navPath('/masterData/insect');
        this.props.toggleDrawer();
    };

    private readonly onDamageNavClick = () => {
        NavFunctions.navPath('/masterData/damage');
        this.props.toggleDrawer();
    };

    private readonly onDiseaseNavClick = () => {
        NavFunctions.navPath('/masterData/disease');
        this.props.toggleDrawer();
    };

    private readonly onTrapTypeNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/traps/type', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onTrapFarmDescriptionNavClick = () => {
        NavFunctions.navPath('/masterData/traps/farmDescription');
        this.props.toggleDrawer();
    };

    private readonly onTrapEntryCommentNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/traps/entryComment', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onCultivarsNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/cultivars', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onNematodeSurveyNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/nematode/surveyTypes', `?crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onPointScoutingNavClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        NavFunctions.navPath('/masterData/points', `?type=scouting&crop=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    public render = () => {
        const {
            session,
        } = this.props;

        const {
            isThresholdAdmin,
            isPhenologyAdmin,
            isSuperAdmin,
            isTrapAdmin,
        } = session.user.permissions;

        if (
            !isThresholdAdmin
            && !isPhenologyAdmin) {
            return <div />;
        }
        
        return (
            <>
                <SideMenuItem key={'master_nav_header'} icon={<img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/masterData.svg`} />}
                    title={'Master Data'}>
                    {
                        (isSuperAdmin) &&
                        <div>
                            <ListItem button key='master_nav_insects_nav' onClick={this.onInsectNavClick}>
                                <ListItemIcon>
                                    <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Insects'} />
                            </ListItem>
                            <Divider />
                        </div>

                    }
                    {
                        (isSuperAdmin) &&
                        <div>
                            <ListItem button key='master_nav_damages_nav' onClick={this.onDamageNavClick}>
                                <ListItemIcon>
                                    <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Damage'} />
                            </ListItem>
                            <Divider />
                        </div>

                    }
                    {
                        (isSuperAdmin) &&
                        <div>
                            <ListItem button key='master_nav_diseases_nav' onClick={this.onDiseaseNavClick}>
                                <ListItemIcon>
                                    <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Disease'} />
                            </ListItem>
                            <Divider />
                        </div>

                    }
                    {
                        isThresholdAdmin &&
                        <SideMenuItem key={'master_nav_threshold'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                            title={'Reporting Threshold'}>
                            <SideMenuItem key={'master_nav_threshold_insects_nav'} icon={<div className={'h30 w30'} />}
                                title={'Insects'}>
                                {
                                    session.user.crops.map(n => (
                                        <ListItem button id={n} key={`master_nav_threshold_insects_nav_${n}`} onClick={this.onThresholdInsectNavClick}>
                                            <ListItemIcon>
                                                <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                        </ListItem>
                                    ))
                                }
                            </SideMenuItem>
                            <SideMenuItem key={'master_nav_threshold_diseases_nav'} icon={<div className={'h35 w35'} />}
                                title={'Diseases'}>
                                {
                                    session.user.crops.map(n => (
                                        <ListItem button id={n} key={`master_nav_threshold_diseases_nav_${n}`} onClick={this.onThresholdDiseaseNavClick}>
                                            <ListItemIcon>
                                                <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                        </ListItem>
                                    ))
                                }
                            </SideMenuItem>
                        </SideMenuItem>
                    }
                    {
                        isSuperAdmin &&
                        <SideMenuItem
                            key={'master_nav_phenology'}
                            icon={<img className={'h30 w30'}
                                src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                            title={'Phenology'}
                        >
                            {
                                session.user.crops.map(n => (
                                    <ListItem button id={n} key={`phenology_nav_${n}`} onClick={this.onPhenologyNavClick}>
                                        <ListItemIcon>
                                            <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                        </ListItemIcon>
                                        <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                    </ListItem>
                                ))
                            }
                        </SideMenuItem>
                    }
                    {
                        isSuperAdmin &&
                        <SideMenuItem
                            key={'master_nav_scouting'}
                            icon={<img className={'h30 w30'}
                                src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                            title={'Scouting'}
                        >
                            {
                                session.user.crops.map(n => (
                                    <ListItem button id={n} key={`scouting_nav_${n}`} onClick={this.onScoutingNavClick}>
                                        <ListItemIcon>
                                            <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                        </ListItemIcon>
                                        <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                    </ListItem>
                                ))
                            }
                        </SideMenuItem>
                    }
                    {
                        isTrapAdmin &&
                        isSuperAdmin &&
                        <SideMenuItem
                            key={'master_nav_traps'}
                            icon={<img className={'h30 w30'}
                                src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                            title={'Traps'}
                        >
                            <ListItem button key={'trap_farm_description_nav'} onClick={this.onTrapFarmDescriptionNavClick}>
                                <ListItemIcon>
                                    <img className={'h35 w35'} src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />
                                </ListItemIcon>
                                <ListItemTextStyled primary={'Farm Description'} />
                            </ListItem>
                            <SideMenuItem
                                key={'master_nav_trap_entry_comment_nav'}
                                icon={<img className={'h30 w30'}
                                    src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />}
                                title={'Trap Entry Comment'}
                            >
                                {
                                    session.user.crops.map(n => (
                                        <ListItem button id={n} key={`trap_entry_comment_nav_${n}`} onClick={this.onTrapEntryCommentNavClick}>
                                            <ListItemIcon>
                                                <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                        </ListItem>
                                    ))
                                }
                            </SideMenuItem>
                            <SideMenuItem
                                key={'master_nav_trap_type'}
                                icon={<img className={'h30 w30'}
                                    src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                                title={'Trap Type'}
                            >
                                {
                                    session.user.crops.map(n => (
                                        <ListItem button id={n} key={`trap_type_nav_${n}`} onClick={this.onTrapTypeNavClick}>
                                            <ListItemIcon>
                                                <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                        </ListItem>
                                    ))
                                }
                            </SideMenuItem>
                        </SideMenuItem>
                    }
                    {
                        isSuperAdmin &&
                        <SideMenuItem
                            key={'master_nav_cultivar_nav'}
                            icon={<img className={'h30 w30'}
                                src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />}
                            title={'Cultivar'}
                        >
                            {
                                session.user.crops.map(n => (
                                    <ListItem button id={n} key={`master_nav_cultivar_nav_${n}`} onClick={this.onCultivarsNavClick}>
                                        <ListItemIcon>
                                            <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                        </ListItemIcon>
                                        <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                    </ListItem>
                                ))
                            }
                        </SideMenuItem>
                    }
                    {
                        (isSuperAdmin) &&
                        <SideMenuItem
                            key={'master_nav_nematode'}
                            icon={<img className={'h30 w30'}
                                src={`${ASSET_BASE}/assets/images/icons/thresholds.svg`} />}
                            title={'Nematode'}
                        >
                            <SideMenuItem
                                key={'master_nav_nematode_survey_nav'}
                                icon={<img className={'h30 w30'}
                                    src={`${ASSET_BASE}/assets/images/icons/reports.svg`} />}
                                title={'Survey Type'}
                            >
                                {
                                    session.user.crops.map(n => (
                                        <ListItem button id={n} key={`master_nav_nematode_survey_nav_${n}`} onClick={this.onNematodeSurveyNavClick}>
                                            <ListItemIcon>
                                                <img src={`${ASSET_BASE}/assets/images/icons/cultivars/${n}.svg`} className={'h24 h24'} />
                                            </ListItemIcon>
                                            <ListItemTextStyled primary={n.toLocaleUpperCase()} />
                                        </ListItem>
                                    ))
                                }
                            </SideMenuItem>
                        </SideMenuItem>
                        

                    }
                </SideMenuItem>
                <Divider />
            </>
        );
    };
}
