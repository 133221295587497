import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../services/appFunctionServices';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import ToggleButton from '../../customComponents/button/ToggleButton';
import HorizontalExpander from '../../customComponents/expander/HorizontalExpander';
import ScoutingEmergencyPhotosView from './ScoutingEmergencyPhotosView';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import GeoHelper from '../../../services/helper/geoHelper';
import ScoutingAssignmentLocationHistoryMarkers from '../../customComponents/mapBox/layer/scouting/LocationHistoryLayer';
import ScoutingAssignmentFinishedMarkers from '../../customComponents/mapBox/layer/scouting/FinishedPointsLayer';
import lodash from 'lodash';
import MapboxBlockPointLayer from '../../customComponents/mapBox/layer/masterData/PointLayer';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';

interface IAssignmentInfoViewProps {
    value : IScoutingAssignment;
    entries : Array<IScoutingEntry>;
    geoserverblocks : Array<IGeoServerBlock>;
}

interface IAssignmentInfoViewState {
}

class AssignmentInfoViewComponent extends React.PureComponent<IAssignmentInfoViewProps, IAssignmentInfoViewState> {

    constructor(props : IAssignmentInfoViewProps) {
        super(props);
        this.state = {
        };
    }
    
    private readonly getBlocks = (props : IAssignmentInfoViewProps) => props.geoserverblocks;
    private readonly getScoutingBlock = (props : IAssignmentInfoViewProps) => props.value.scoutingBlock;
    private readonly getFinishedGuids = (props : IAssignmentInfoViewProps) => props.value.finishedPoints;
    private readonly getFinishedPoints = createSelector(
        [this.getFinishedGuids, this.getScoutingBlock],
        (guids, block) => {
            return lodash
                .chain(guids)
                .map(n => block.points.features.filter(x => x.properties.guid === n))
                .flatMap()
                .map(x => x.geometry.coordinates)
                .value();
        },
    );
    private readonly getBlock : (props : IAssignmentInfoViewProps) => IGeoServerBlock | null = createSelector(
        [this.getBlocks, this.getScoutingBlock],
        (blocks, block) => {
            return lodash
                .filter(blocks, (geoserverBlock) => (block.division.toLocaleLowerCase() === geoserverBlock.departmentShortName.toLocaleLowerCase()))
                .filter((geoserverBlock) => (block.landName.toLocaleLowerCase() === geoserverBlock.fieldName.toLocaleLowerCase()))
                .filter((geoserverBlock) => (block.name.toLocaleLowerCase() === geoserverBlock.code.toLocaleLowerCase()))[0];
        },
    );

    private readonly getBlockGuid = createSelector(
        [this.getBlock],
        (block) => {
            return block?.guid;
        },
    );

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        const { value } = this.props;

        mapRef.fitBounds(GeoHelper.getAssignmentBlockBounds([value.scoutingBlock]), {
            minZoom: 12,
            animate: false,
        });
    };

    public readonly render = () => {
        const { value, entries } = this.props;

        const blockGuid = this.getBlockGuid(this.props);
        const finishedPoints = this.getFinishedPoints(this.props);

        return (
            <div className='fdr flx1 ais'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BasicMap
                            id={'scoutingMap'}
                            onMap={this.setMapRef}
                            blockGuid={blockGuid}
                        >
                            <MapboxBlockPointLayer
                                pointsId='point_farm_layer'
                                minZoom={15}
                                type='scouting'
                                blockGuids={!blockGuid ? [] : [blockGuid]}
                            />
                            <ScoutingAssignmentLocationHistoryMarkers
                                polygonId={'point_history_layer'}
                                minZoom={15}
                                assignmentId={value.id}
                            />
                            <ScoutingAssignmentFinishedMarkers
                                polygonId={'point_finished_layer'}
                                minZoom={15}
                                finishedPoints={finishedPoints}
                            />
                        </BasicMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                            ASSIGNMENT
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DATE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {AppFunctionsService.formatDateTimeToDateOnly(value.date)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            COMPLETED POINTS
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.finishedPoints.length} / ${value.scoutingBlock.points.features.length}`}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                                SCOUT
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {`${value.employeeNumber} - ${value.employeeName}`}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            DIVISION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.division.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.landName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            BLOCK NAME
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.blockName.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            LAND DESCRIPTION
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.description.toLocaleUpperCase()}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            CROP
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.crop.toLocaleUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className='fdr mt5'>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AGE
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {value.scoutingBlock.age.toFixed(0)}
                            </Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className={'flx1 fw600 bbw1 bocg pl10 pr10'} variant='subtitle1'>
                            AREA
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    !value.scoutingBlock.ha &&
                                    '-'
                                }
                                {
                                    !!value.scoutingBlock.ha &&
                                    `${value.scoutingBlock.ha.toFixed(2)} Ha`
                                }
                            </Typography>
                        </div>
                    </div>
                    {
                        value.type === 'specific' &&
                        <>
                            <AppBar className='fdc ais bcig' variant='outlined' position='static'>
                                <Toolbar variant='dense' className={'fdr aic jcc'}>
                                    <Typography className={'cw'} variant='h6'>
                                        SPECIFIC
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <HorizontalExpander
                                title={'SECTIONS'}
                                color={'primary'}
                            >
                                <div className='fdc aic mb15'>
                                    <div className='fdr jcsb fww'>
                                        {
                                            value.sections?.map((n, i) => (
                                                <ToggleButton
                                                    key={n}
                                                    className={`mt15 ${i !== ((value.sections?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                    value={`${n}`}
                                                    selected
                                                    disabled
                                                >
                                                    {`${n}`.toLocaleUpperCase()}
                                                </ToggleButton>
                                            ))
                                        }
                                    </div>
                                </div>
                            </HorizontalExpander>
                            <HorizontalExpander
                                title={'COMPONENTS'}
                                color={'primary'}
                            >
                                <div className='fdc aic mb15'>
                                    <div className='fdr jcsb fww'>
                                        {
                                            value.components?.map((n, i) => (
                                                <ToggleButton
                                                    key={n}
                                                    className={`mt15 ${i !== ((value.components?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                    value={`${n}`}
                                                    selected
                                                    disabled
                                                >
                                                    {`${n}`.toLocaleUpperCase()}
                                                </ToggleButton>
                                            ))
                                        }
                                    </div>
                                </div>
                            </HorizontalExpander>
                            {
                                !!value.insects?.length &&
                                <HorizontalExpander
                                    title={'INSECTS'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.insects.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.insects?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                            {
                                !!value.diseases?.length &&
                                <HorizontalExpander
                                    title={'DISEASES'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.diseases.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.diseases?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                            {
                                !!value.damages?.length &&
                                <HorizontalExpander
                                    title={'DAMAGES'}
                                    color={'primary'}
                                >
                                    <div className='fdc aic mb15'>
                                        <div className='fdr jcsb fww'>
                                            {
                                                value.damages.map((n, i) => (
                                                    <ToggleButton
                                                        key={n}
                                                        className={`mt15 ${i !== ((value.damages?.length ?? 0) - 1) ? 'mr15' : ''}`}
                                                        value={`${n}`}
                                                        selected
                                                        disabled
                                                    >
                                                        {`${n}`.toLocaleUpperCase()}
                                                    </ToggleButton>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </HorizontalExpander>
                            }
                        </>
                    }
                    <ScoutingEmergencyPhotosView
                        value={value}
                        entries={entries}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        geoserverblocks: state.masterData.geoserver.blocks,
    };
};

const AssignmentInfoView = connect(
    mapStateToProps,
)(AssignmentInfoViewComponent);


export default AssignmentInfoView;