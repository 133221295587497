import React from 'react';
import { IRootState } from '../../../../@types/redux';
import { IDivision } from '../../../../types/model/rights/division';
import { connect } from 'react-redux';
import DivisionFunctions from '../../../../store/rights/division/functions';
import { Button, Chip, ChipProps, CircularProgress, Paper, styled } from '@material-ui/core';
import { CROP } from '../../../../appConstants';
import { createSelector } from 'reselect';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

interface IDivisionSelectorProps {
    divisions : Array<IDivision>;
    isLoading : boolean;

    crop ?: CROP;

    value : Array<string>;
    onClick ?: (value : string) => void;

    counts ?: Record<string, number | null>;
}

interface IDivisionSelectorState {}

class DivisionSelectorComponent extends React.PureComponent<IDivisionSelectorProps, IDivisionSelectorState> {
    private readonly scrollAreaRef : React.RefObject<HTMLDivElement>;
    private readonly scrollAmount = 200;

    constructor(props : IDivisionSelectorProps) {
        super(props);
        this.state = {};

        this.scrollAreaRef = React.createRef();
    }

    public componentDidMount = () => {
        DivisionFunctions.getList();
    };

    private getData = (state : IDivisionSelectorState, props : IDivisionSelectorProps) => props.divisions;
    private getCrop = (state : IDivisionSelectorState, props : IDivisionSelectorProps) => props.crop;
    private getDivisions = createSelector([
        this.getData,
        this.getCrop,
    ], (
        divisions,
        crop,
    ) => {
        const divisionsDrop = divisions
            .filter(x => !crop || x.crops.includes(crop))
            .sort((a, b) => a.code.localeCompare(b.code));

        return divisionsDrop;
    });

    private readonly onScrollBackClick = () => {
        this.scrollAreaRef.current?.scrollBy({
            behavior: 'smooth',
            left: -this.scrollAmount,
        });
    };

    private readonly onScrollForwardClick = () => {
        this.scrollAreaRef.current?.scrollBy({
            behavior: 'smooth',
            left: this.scrollAmount,
        });
    };

    private readonly onClick = (event : React.MouseEvent<HTMLDivElement>) => {
        if (!this.props.onClick) return;
        this.props.onClick(event.currentTarget.id);
    };

    private readonly StyledChip = styled((props) => {
        const { selected: _, ...rest } = props;
    
        return (
            <Chip {...rest} />
        );
    })((props : ChipProps & {
        selected ?: boolean;
    }) => ({
        height: 39,
        borderRadius: 39,
        borderColor: props.selected ? '#326E44' : '#6A6A6A',
        borderWidth: 2,
        borderStyle: 'solid',
        transition: 'background-color 0.5s ease, color 0.5s ease',
        backgroundColor: props.selected ? '#326E44' : 'white',
        '&.MuiChip-clickableColorPrimary:hover,&.MuiChip-clickableColorPrimary:focus': {
            backgroundColor: props.selected ? '#326E44' : 'white',
        },
        '& .MuiChip-label': {
            transition: 'background-color 0.5s ease, color 0.5s ease',
            color: props.selected ? 'white' : '#6A6A6A',
            fontSize: 15,
            lineHeight: '20px',
            fontWeight: 500,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
            '& div.count': {
                transition: 'background-color 0.5s ease, color 0.5s ease',
                height: 31,
                width: 31,
                borderRadius: 31,
                color: !props.selected ? 'white' : '#0D703F',
                backgroundColor: !props.selected ? '#6A6A6A' : 'white',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                    position: 'absolute',
                },
            },
        },

    }));

    public render = () => {
        const {
            isLoading,
            value,
            counts,
        } = this.props;

        const divisions = this.getDivisions(this.state, this.props);

        return (
            <Paper className='fdr flx1 bbs'>
                <div className='fdc'>
                    <div className='flx1'>
                        <Button className='br0' color='primary' size='small' onClick={this.onScrollBackClick}>
                            <ArrowBackIos />
                        </Button>
                    </div>
                </div>
                <div ref={this.scrollAreaRef} className='fdr flx1 aic mt15 mb15 mr15o oxs hidescroll'>
                    {
                        !isLoading &&
                        divisions.map((x) => (
                            <this.StyledChip
                                key={x.id}
                                id={x.id}
                                color='primary'
                                selected={value.includes(x.id)}
                                clickable
                                onClick={this.onClick}
                                label={(
                                    <div className='fdr aic'>
                                        <span className='mr15'>{x.code}</span>
                                        {
                                            !!counts &&
                                            <div className='count'>
                                                <span>
                                                    {
                                                        (counts[x.code] ?? 0)
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                )}
                                aria-label={x.name}
                            />
                        ))
                    }
                    {
                        isLoading &&
                        <div className='fdr flx1 aic jcc'>
                            <CircularProgress />
                        </div>
                    }
                </div>
                <div className='fdc'>
                    <div className='flx1'>
                        <Button className='br0' color='primary' size='small' onClick={this.onScrollForwardClick}>
                            <ArrowForwardIos />
                        </Button>
                    </div>
                </div>
                
            </Paper>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.rights.division.divisions.filter(x => x.isActive),
        isLoading: state.rights.division.isLoading,
    };
};

const DivisionSelector = connect(
    mapStateToProps,
)(DivisionSelectorComponent);

export default DivisionSelector;