import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Paper } from '@material-ui/core';
import VerticalExpander from '../customComponents/expander/VerticalExpander';
import { IUserSession } from '../../types/model/user';
import BasicMap from '../customComponents/mapBox/BasicMap';
import { LngLatBoundsLike } from 'react-map-gl';
import { createSelector } from 'reselect';
import { IGeoServerBlock } from '../../types/model/masterData/geoserver/block';
import GeoServerDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DivisionAutocomplete';
import GeoServerSubDivisionAutocomplete from '../customComponents/autocomplete/masterData/geoserver/SubDivisionAutocomplete';
import GeoServerDepartmentAutocomplete from '../customComponents/autocomplete/masterData/geoserver/DepartmentAutocomplete';
import GeoServerFieldAutocomplete from '../customComponents/autocomplete/masterData/geoserver/FieldAutocomplete';
import GeoServerBlockMultiAutocomplete from '../customComponents/autocomplete/masterData/geoserver/BlockMultiAutocomplete';
import { MapBoxRef } from '../customComponents/mapBox/MapBox';
import { IAvoBugAssignment } from '../../types/model/avoBug/avoBugAssignment';

interface IAvoBugAssignmentMapProps extends RouteComponentProps {
    session ?: IUserSession | null;
    
    avoBugs : Array<IAvoBugAssignment>;
    geoserverblocks : Array<IGeoServerBlock>;
}

interface IAvoBugAssignmentMapState {
    selected ?: IAvoBugAssignment | null;

    divisionGuid : string | null;
    subDivisionGuid : string | null;
    departmentGuid : string | null;
    fieldGuid : string | null;
    blockGuids : Array<string>;
}

class AvoBugMapComponent extends React.PureComponent<IAvoBugAssignmentMapProps, IAvoBugAssignmentMapState> {
    private mapRef : MapBoxRef | null = null;

    private readonly divisionZoom = 10;
    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;
    private readonly fieldZoom = 15;
    private readonly blockZoom = 15;

    constructor(props : IAvoBugAssignmentMapProps) {
        super(props);
        this.state = {
            divisionGuid: null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        };
    }
    
    private readonly flyToBounds = (bounds : LngLatBoundsLike, zoom ?: number) => {
        this.mapRef?.fitBounds(bounds, {
            minZoom: zoom,
        });
    };

    private readonly onDivisionChange = (value ?: string | null, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            divisionGuid: value ?? null,
            subDivisionGuid: null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.divisionZoom);
        }
    };

    private readonly onSubDivisionChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            subDivisionGuid: value ?? null,
            departmentGuid: null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.subDivisionZoom);
        }
    };

    private readonly onDepartmentChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            departmentGuid: value ?? null,
            fieldGuid: null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.departmentZoom);
        }
    };

    private readonly onFieldChange = (value ?: string, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            fieldGuid: value ?? null,
            blockGuids: [],
        });

        if (bounds) {
            this.flyToBounds(bounds, this.fieldZoom);
        }
    };

    private readonly onBlockChange = (value : Array<string>, bounds ?: LngLatBoundsLike | null) => {
        this.setState({
            blockGuids: value,
        });

        if (bounds) {
            this.flyToBounds(bounds, this.blockZoom);
        }
    };

    private readonly onInfoClose = () => {
        this.setState({
            selected: undefined,
        });
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
    };

    private readonly getGeoserverBlocks = (props : IAvoBugAssignmentMapProps) => props.geoserverblocks;
    private readonly getBlockGuids = (props : IAvoBugAssignmentMapProps, state : IAvoBugAssignmentMapState) => state.blockGuids;
    private readonly getDepartmentGuid = (props : IAvoBugAssignmentMapProps, state : IAvoBugAssignmentMapState) => state.departmentGuid;

    private readonly getBlockCodes = createSelector([
        this.getGeoserverBlocks,
        this.getBlockGuids,
    ], (
        blocks,
        blockGuids,
    ) => {
        return blocks.filter(x => blockGuids.includes(x.guid)).map(x => x.code);
    });

    private readonly getDivision = createSelector([
        this.getGeoserverBlocks,
        this.getDepartmentGuid,
    ], (
        geoserverblocks,
        departmentGuid,
    ) => {
        const geoserverblock = geoserverblocks.find(x => x.departmentGuid === departmentGuid);

        if (!geoserverblock) return;
        
        
        return geoserverblock.departmentShortName;
    });

    public render = () => {
        const {
            divisionGuid,
            subDivisionGuid,
            blockGuids,
            departmentGuid,
            fieldGuid,
        } = this.state;

        const blockNames = this.getBlockCodes(this.props, this.state);

        return (
            <div className={'fdr hfill mh0'}>
                <Paper className={'flx1 fdr oxh p5 m5'}>
                    <BasicMap
                        id={'farmMap'}
                        onMap={this.setMapRef}
                        divisionGuid={divisionGuid}
                        subDivisionGuid={subDivisionGuid}
                        departmentGuid={departmentGuid}
                        fieldGuid={fieldGuid}
                        blockCodes={blockNames}
                    >
                    </BasicMap>
                    
                </Paper>
                <div className={'fdc mh0'}>
                    <VerticalExpander title='filter' location='right' initiallyExpanded>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDivisionAutocomplete
                                value={divisionGuid}
                                fullWidth
                                onChange={this.onDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerSubDivisionAutocomplete
                                value={subDivisionGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                onChange={this.onSubDivisionChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerDepartmentAutocomplete
                                value={departmentGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                onChange={this.onDepartmentChange}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerFieldAutocomplete
                                value={fieldGuid}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                onChange={this.onFieldChange}
                                type={null}
                            />
                        </div>
                        <div className={'fdc aic pr20 mt7'}>
                            <GeoServerBlockMultiAutocomplete
                                value={blockGuids}
                                fullWidth
                                divisionGuid={divisionGuid}
                                subDivisionGuid={subDivisionGuid}
                                departmentGuid={departmentGuid}
                                fieldGuid={fieldGuid}
                                onChange={this.onBlockChange}
                                type={null}
                            />
                        </div>
                    </VerticalExpander>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        geoserverblocks: state.masterData.geoserver.blocks,
    };
};

const AvoBugMap = connect(
    mapStateToProps,
)(withRouter(AvoBugMapComponent));

export default AvoBugMap;