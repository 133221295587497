import React, { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import AppFunctionsService from '../../../services/appFunctionServices';
import { IScoutingAssignment } from '../../../types/model/scouting/scoutingAssignment';
import { IScoutingEntry } from '../../../types/model/scouting/scoutingEntry';

interface IVerticalAssignmentReportProps {
    entries : Array<IScoutingEntry>;
    value : IScoutingAssignment;
}

interface IVerticalAssignmentReportState {}

interface IData {
    [key : string] : {
        [key : string] : Array<number | string | ReactElement>;
    };
}

export default class VerticalAssignmentReport extends React.PureComponent<IVerticalAssignmentReportProps, IVerticalAssignmentReportState> {
    private readonly types = ['disease', 'insects'];
    private readonly totalTimeHeader = 'Total Time';
    constructor(props : IVerticalAssignmentReportProps) {
        super(props);
        this.state = {};
    }

    /**
     * Get the values to be displayed.
     */
    private getData = (
        headers : Array<{
            name : string;
            type : string;
            subs : Array<string>;
        }>,
        entries : Array<IScoutingEntry>,
        value : IScoutingAssignment,
    ) => {
        const data : IData = {};

        // Loop through the headers
        headers.forEach((header) => {
            // Initialize header in data to empty object
            data[header.name] = {};

            // Loop through the sub headers, length = 1 if no sub headers and length > 1 if there are sub headers.
            header.subs.forEach((sub) => {
                // Fill header data with 'X' and last 3 columns with 0.
                data[header.name][sub] = [];
                data[header.name][sub].length = value.scoutingBlock.pointsArray.length + 3;
                data[header.name][sub].fill('X', 0, value.scoutingBlock.pointsArray.length - 1);
                data[header.name][sub].fill(0, value.scoutingBlock.pointsArray.length - 1);

                // Loop through point guids.
                value.scoutingBlock.pointsArray.forEach((pointGuid, i) => {
                    // Find entry for point
                    const entry = entries.find(x => x.pointGuid === pointGuid);

                    if (!entry) {
                        data[header.name][sub][i] = 'X';
                        return;
                    }

                    if (header.name === this.totalTimeHeader) {
                        data[header.name][sub][i] = AppFunctionsService.millisecondsFormat(entry.time);
                        return;
                    }

                    // Get all the sections for given header.
                    const sections = entry.sections.filter(section => section.type === header.type && section.name === header.name);

                    // Handle check entry types.
                    if (sections[0]?.counterType === 'check') {
                        // If count = 1, then just show a check.
                        if (sections.some(v => typeof v.count === 'number' && v.count > 0 && v.count < 2)) {
                            data[header.name][sub][i] = '✓';
                        // If count > 1 show number, some checks can be counters...
                        } else if (sections.some(v => typeof v.count === 'number' && v.count > 2)) {
                            data[header.name][sub][i] = lodash.sumBy(sections, v => typeof v.count === 'number' ? v.count : 0);
                        // If the check is a string show comma separated list of first caharacter.
                        } else if (sections.some(v => typeof v.count === 'string')) {
                            data[header.name][sub][i] = lodash.chain(sections)
                                .filter(v => typeof v.count === 'string')
                                .map((v : { count : string }) => v.count[0].toLocaleUpperCase())
                                .uniq()
                                .join(', ').value();
                        // If none of these show X.
                        } else {
                            data[header.name][sub][i] = 'X';
                        }
                    }

                    // If data type is counter, show number.
                    if (sections[0]?.counterType === 'counter') {
                        data[header.name][sub][i] = lodash.sumBy(sections, v => typeof v.count === 'number' ? v.count : 0);
                    }

                    // If data is a multi counter, push counter values.
                    if (sections[0]?.counterType === 'multi') {
                        data[header.name][sub][i] = lodash.chain(sections)
                            .sumBy(v => typeof v.count === 'object' ? v.count[sub] : 0)
                            .value();
                    }
                });
            });
        });

        return data;
    };

    /**
     * Calculates the last 3 column values.
     */
    private calculateTotals = (data : IData) => {
        Object.keys(data).forEach((headerName) => {
            Object.keys(data[headerName]).forEach((entryKey) => {
                // Get the indexes of last 3 columns
                const totalIndex = data[headerName][entryKey].length - 3;
                const countIndex = data[headerName][entryKey].length - 2;
                const percentageIndex = data[headerName][entryKey].length - 1;

                // We are only interested in the point data.
                const reportEntries = data[headerName][entryKey].slice(0, data[headerName][entryKey].length - 3);

                // Total time header needs to be handled differently.
                if (headerName === this.totalTimeHeader) {
                    data[headerName][entryKey][totalIndex] = AppFunctionsService.millisecondsFormat(lodash.sumBy(this.props.entries, n => n.time));
                    data[headerName][entryKey][countIndex] = '';
                    data[headerName][entryKey][percentageIndex] = '';
                    return;
                }

                // Sum the total values.
                data[headerName][entryKey][totalIndex] = lodash.sumBy(reportEntries, v => typeof v === 'number' ? v : (v !== 'X' ? 1 : 0));

                // Calculate count average.
                data[headerName][entryKey][countIndex] = (Number(data[headerName][entryKey][totalIndex]) / (reportEntries.length)).toFixed(2);

                // Calculate the percentage
                const percentage = lodash
                    .sumBy(reportEntries, v => typeof v === 'number' ?
                        (v > 0 ? 1 : 0) :
                        (v !== 'X' ? 1 : 0)) / (reportEntries.length) * 100;

                // TODO: Ask if acceptable? Rework maybe, just here now for being in a hurry.
                data[headerName][entryKey][percentageIndex] = <span className={`${percentage > 50 ? 'cpr' : percentage > 0 ? 'co' : ''}`}>{percentage.toFixed(2)}%</span>;
            });
        });
    };

    private getEntries = (props : IVerticalAssignmentReportProps) => props.entries;
    private getValue = (props : IVerticalAssignmentReportProps) => props.value;
    private getReportData = createSelector(
        [this.getEntries, this.getValue],
        (entries, value) => {
            // First calculate the headers
            const headers = lodash
                .chain(entries[0].sections)
                .filter(x => this.types.includes(x.type))
                .groupBy(s => s.name)
                .map((s, k) => {
                    return {
                        name: k,
                        type: s[0].type,
                        subs: typeof s[0].count !== 'object' ? [k] : Object.keys(s[0].count).sort(),
                    };
                })
                .value();

            // Add the total time header
            headers.push({
                name: this.totalTimeHeader,
                type: 'counter',
                subs: [this.totalTimeHeader],
            });

            // Get the data
            const data = this.getData(headers, entries, value);

            // Calculate the last 3 column values
            this.calculateTotals(data);

            return data;
        },
    );

    public render = () => {
        const data = this.getReportData(this.props);
        const { value } = this.props;
        return (
            <div className={'flx1 fdc oxa oya wfill hfill'}>
                {
                    !!Object.keys(data).length &&
                    <Table stickyHeader size='small' padding='none'>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} align='right'>
                                    POINT
                                </TableCell>
                                {
                                    value.scoutingBlock.pointsArray.map((p, i) => (
                                        <TableCell key={`${p}_${i}_point`} align='center'>
                                            {i + 1}
                                        </TableCell>
                                    ))
                                }
                                <TableCell align='center'>
                                    TOTAL
                                </TableCell>
                                <TableCell align='center'>
                                    AVERAGE COUNT
                                </TableCell>
                                <TableCell align='center'>
                                    PERCENTAGE
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            Object.keys(data).map(header => (
                                <TableBody key={`${header}_body`}>
                                    <TableRow>
                                        <TableCell className='w150' component='th' scope='rowgroup' rowSpan={Object.keys(data[header]).length} align='left'>
                                            <b>{header}</b>
                                        </TableCell>
                                        <TableCell className='w150' component='th' scope='row' align='left'>
                                            <b>{Object.keys(data[header]).length > 1 ? Object.keys(data[header])[0] : ''}</b>
                                        </TableCell>
                                        {
                                            data[header][Object.keys(data[header])[0]].map((d, i) => (
                                                <TableCell className={`w80 ${value.scoutingBlock.pointsArray.length > i ? '' : 'fwb'}`} key={`${header}_data_${i}`} align='center'>
                                                    {d}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                    {
                                        Object.keys(data[header]).length > 1 &&
                                        Object.keys(data[header]).slice(1).map(sub => (
                                            <TableRow key={`${header}_${sub}_sub`}>
                                                <TableCell className='w150' component='th' scope='row' align='left'>
                                                    <b>{sub}</b>
                                                </TableCell>
                                                {
                                                    data[header][sub].map((d, i) => (
                                                        <TableCell className={`w80 ${value.scoutingBlock.pointsArray.length > i ? '' : 'fwb'}`} align='center' key={`${header}_sub_data_${i}`}>
                                                            {d}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ))
                        }
                    </Table>
                }
            </div>
        );
    };
}
