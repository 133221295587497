import React, { SVGProps } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import AppFunctionsService from '../../../services/appFunctionServices';
import { MapBoxRef } from '../../customComponents/mapBox/MapBox';
import BasicMap from '../../customComponents/mapBox/BasicMap';
import GeoHelper from '../../../services/helper/geoHelper';
import lodash from 'lodash';
import MapboxBlockPointLayer from '../../customComponents/mapBox/layer/masterData/PointLayer';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IAvoBugAssignment } from '../../../types/model/avoBug/avoBugAssignment';
import AssignmentBlockHelper, { IAssignmentBlock } from '../../../types/model/masterData/block';
import AvoBugAssignmentLocationHistoryMarkers from '../../customComponents/mapBox/layer/avobug/LocationHistoryLayer';
import { Card } from '@material-ui/core';
import { CartesianGrid, Label, LabelList, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import randomColor from 'randomcolor';

type LabelProps = SVGProps<SVGElement>
& { value ?: string }
& { children ?: React.ReactNode }
interface IAvoBugAssignmentInfoViewProps {
    value : IAvoBugAssignment;
    geoserverblocks : Array<IGeoServerBlock>;
    isLoadingBlocks : boolean;
}

interface IAvoBugAssignmentInfoViewState {
}

class AvoBugAssignmentInfoViewComponent extends React.PureComponent<IAvoBugAssignmentInfoViewProps, IAvoBugAssignmentInfoViewState> {
    private mapRef : MapBoxRef | null = null;
    constructor(props : IAvoBugAssignmentInfoViewProps) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps : Readonly<IAvoBugAssignmentInfoViewProps>) : void {
        if (!this.mapRef) return;
        if (prevProps.isLoadingBlocks !== this.props.isLoadingBlocks) {
            this.flyToBlock(this.mapRef);
        }
    }
    
    private readonly getBlocks = (props : IAvoBugAssignmentInfoViewProps) => props.geoserverblocks;
    private readonly getValue = (props : IAvoBugAssignmentInfoViewProps) => props.value;
    private readonly getResults = (props : IAvoBugAssignmentInfoViewProps) => props.value.results;

    private readonly getScoutingBlock : (props : IAvoBugAssignmentInfoViewProps) => IAssignmentBlock | null = createSelector(
        [this.getBlocks, this.getValue],
        (blocks, value) => {
            const block = lodash.find(blocks, (geoserverBlock) => geoserverBlock.guid === value.block) ?? null;
            if (!block) return null;

            const scoutingBlock = AssignmentBlockHelper.fromGeoServerBlock(block, value.crop, []);

            return scoutingBlock;
        },
    );

    private readonly getPointsCount : (props : IAvoBugAssignmentInfoViewProps) => number = createSelector(
        [this.getValue],
        (value) => {
            let count = 0;
            const thresolds = Object.keys(value.results);

            thresolds.forEach((thresold) => {
                count += Number(Object.keys(value.results[thresold]).length);
            });

            return count;
        },
    );

    private readonly getBugs = createSelector(
        [this.getResults],
        (results) => {
            return lodash
                .chain(Object.keys(results))
                .map((thresold) => results[thresold])
                .map((treesObject) => Object.values(treesObject))
                .flatMap()
                .map((result) => Object.keys(result.result))
                .flatMap()
                .uniq()
                .value();
        },
    );

    private readonly getGraphData = createSelector(
        [this.getResults, this.getBugs],
        (results, bugs) => {
            const data : Array<{
                tree : number;
                [key : string] : number;
            }> = [];

            lodash
                .chain(Object.keys(results))
                .map((thresold) => results[thresold])
                .forEach((treesObject) => {
                    const trees = Object.keys(treesObject);
                    trees.forEach((tree) => {
                        const result : {
                            tree : number;
                            [key : string] : number;
                        } = {
                            tree: data.length + 1,
                        };

                        bugs.forEach((bug) => {
                            result[bug] = (treesObject[tree].result[bug] as number | null) ?? 0;
                        });

                        data.push(result);
                    });
                })
                .value();

            return data;
        },
    );

    private readonly flyToBlock = (mapRef : MapBoxRef) => {
        const scoutingBlock = this.getScoutingBlock(this.props);
        if (!scoutingBlock) return;

        mapRef.fitBounds(GeoHelper.getAssignmentBlockBounds([scoutingBlock]), {
            minZoom: 12,
            animate: false,
        });
    };

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;
        this.flyToBlock(mapRef);
    };

    private readonly CustomizedLabel : React.FunctionComponent<LabelProps> = (
        props : LabelProps,
    ) => {
        const { x, y, value } = props;

        return (
            <g className='recharts-layer'>
                <line
                    className='recharts-cartesian-axis-tick-line'
                    x1={x}
                    y1={Number(y) - 2}
                    x2={x}
                    y2={Number(y) - 24}
                    dy={20}
                    stroke={'#CCC'}
                />
                <text
                    x={x}
                    y={y}
                    dy={-30}
                    fill={props.fill}
                    fontSize={17}
                    fontWeight={'bold'}
                    textAnchor='middle'
                >
                    {value}
                </text>
            </g>
        );
    };

    public readonly render = () => {
        const { value } = this.props;

        const scoutingBlock = this.getScoutingBlock(this.props);
        const finishedPoints = this.getPointsCount(this.props);
        const data = this.getGraphData(this.props);
        const bugs = this.getBugs(this.props);

        return (
            <div className='fdr flx1 ais MuiDialogContent-root'>
                <div className='fdc flx1 ais mr5'>
                    <AppBar className='fdc ais' variant='outlined' position='static'>
                        <Toolbar variant='dense' className={'fdr aic jcc'}>
                            <Typography className={'cw'} variant='h6'>
                                BLOCK - {value.blockName}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1'>
                        <BasicMap
                            id={'scoutingMap'}
                            onMap={this.setMapRef}
                            blockGuid={value.block}
                            blockMaxZoom={22}

                        >
                            <MapboxBlockPointLayer
                                pointsId='point_farm_layer'
                                minZoom={15}
                                maxZoom={22}
                                type='scouting'
                                blockGuids={!value.block ? [] : [value.block]}
                            />
                            <AvoBugAssignmentLocationHistoryMarkers
                                polygonId={'point_history_layer'}
                                minZoom={15}
                                assignmentId={value.id}
                            />
                        </BasicMap>
                    </div>
                </div>
                <div className='fdc flx1 ais ml5'>
                    <Card className={'fdc flx1 bcw'}>
                        <div className={'fdr bcinfo cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                AVO BUG SCOUTING INFORMATION
                            </Typography>
                        </div>
                        <div className={'fdc ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        DATE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            AppFunctionsService.formatDateTimeToDateOnly(value.date)
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        COMPLETED POINTS
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {`${finishedPoints} / 100`}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        SCOUT
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            `${value.employeeNumber} - ${value.employeeName}`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={'fdc ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        DIVISION
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            scoutingBlock?.division.toLocaleUpperCase() ?? value.division.toLocaleUpperCase()
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        LAND NAME
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            scoutingBlock?.landName ?? value.landName
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        BLOCK NAME
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            scoutingBlock?.name ?? value.blockName
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={'fdc ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        LAND DESCRIPTION
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            scoutingBlock?.description ?? value.landName
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        CROP
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            scoutingBlock?.crop.toLocaleUpperCase() ?? value.crop.toLocaleUpperCase()
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        AGE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 lh24 pt10'}>
                                        {
                                            scoutingBlock?.age ?? 0
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={'fdc ml17 mr17 mb13'}>
                            <div className={'flx1 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        Ha
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            !!scoutingBlock?.ha &&
                                            `${scoutingBlock.ha.toFixed(2)} Ha`
                                        }
                                        {
                                            !scoutingBlock?.ha &&
                                            '-'
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        WEATHER
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            value.weather.map(x => x.toLocaleUpperCase()).join(', ')
                                        }
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        PHENOLOGY
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            value.phenology.map(x => x.toLocaleUpperCase()).join(', ')
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className={'fdc bcw flx1 jcc aic mt15'}>
                        
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            AVO BUG SCOUTING
                        </Typography>
                        <ResponsiveContainer
                            className='hp80 wp80'
                        >
                            <LineChart
                                data={data}
                                margin={{
                                    bottom: 65,
                                    left: 10,
                                    right: 10,
                                    top: 10,
                                }}
                                
                            >
                                <XAxis
                                    dataKey='tree'
                                    angle={-35}
                                    textAnchor='end'
                                >
                                    <Label
                                        value='TREE'
                                        position='bottom'
                                        offset={15}
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#575757',
                                        }}
                                    />
                                </XAxis>
                                <YAxis>
                                    <Label
                                        value='COUNT'
                                        angle={-90}
                                        position='left'
                                        offset={0}
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#575757',
                                        }}
                                    />
                                </YAxis>
                                <CartesianGrid vertical={false} />
                                <Tooltip allowEscapeViewBox={{
                                    x: false,
                                    y: false,
                                }} labelFormatter={(count) => `Tree ${count}`} />
                                {
                                    bugs.map((bug) => (
                                        <Line key={bug} type='monotone' dataKey={bug} stroke={randomColor({ seed: bug })}>
                                            <LabelList content={<this.CustomizedLabel fill={randomColor({ seed: bug })} />} />
                                        </Line>
                                    ))
                                }
                            </LineChart>
                        </ResponsiveContainer>
                        
                    </Card>
                </div>
                
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        geoserverblocks: state.masterData.geoserver.blocks,
        isLoadingBlocks: state.masterData.geoserver.isLoadingBlocks,
    };
};

const AvoBugAssignmentInfoView = connect(
    mapStateToProps,
)(AvoBugAssignmentInfoViewComponent);


export default AvoBugAssignmentInfoView;