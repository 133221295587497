import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import DeleteIcon from '@material-ui/icons/Delete';
import { motion } from 'framer-motion';
import Collapse from '@material-ui/core/Collapse';
import randomColor from 'randomcolor';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import Link from '@material-ui/core/Link';
import WarningIcon from '@material-ui/icons/Warning';
import { IAssignmentBlock } from '../../types/model/masterData/block';
import CheckToggleButton from '../customComponents/button/CheckToggleButton';

const LIMIT = 4;

interface IAssignmentFieldViewProps {
    name : string;
    scoutingBlocks : Array<IAssignmentBlock>;
    disabled ?: boolean;

    onDeleteClick ?: (landName : string) => void;
    onZoomClick ?: (landName : string) => void;
    onEditClick ?: (landName : string) => void;

    excludedBlocks : Array<string>;
}

interface IAssignmentFieldViewState {
    open : boolean;

    showMore : boolean;
}

export default class AssignmentFieldView extends React.PureComponent<IAssignmentFieldViewProps, IAssignmentFieldViewState> {
    constructor(props : IAssignmentFieldViewProps) {
        super(props);
        this.state = {
            open: true,
            showMore: false,
        };
    }

    private onClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    private readonly getBlocks = (props : IAssignmentFieldViewProps) => props.scoutingBlocks;
    private readonly getExcludeBlocks = (props : IAssignmentFieldViewProps) => props.excludedBlocks;
    private readonly getShowMore = (props : IAssignmentFieldViewProps, state : IAssignmentFieldViewState) => state.showMore;

    private readonly getBlockNames = createSelector([
        this.getBlocks, this.getShowMore,
    ], (scoutingBlocks, showMore) => {
        return scoutingBlocks.sort((a, b) => a.name.localeCompare(b.name)).slice(0, !showMore ? LIMIT : (scoutingBlocks.length)).map(n => n.name);
    });

    public readonly onDeleteClick = () => {
        if (this.props.onDeleteClick) this.props.onDeleteClick(this.props.name);
    };

    public readonly onZoomClick = () => {
        if (this.props.onZoomClick) this.props.onZoomClick(this.props.name);
    };

    public readonly onEditClick = () => {
        if (this.props.onEditClick) this.props.onEditClick(this.props.name);
    };

    public readonly onShowMoreClick = () => {
        this.setState({
            showMore: !this.state.showMore,
        });
    };

    public render = () => {
        const { name, scoutingBlocks, disabled } = this.props;
        const { open, showMore } = this.state;

        const blockNames = this.getBlockNames(this.props, this.state);
        const alreadyScoutedBlockNames = this.getExcludeBlocks(this.props);

        return (
            <Paper elevation={3}>
                <AppBar position='static' className='pbr' elevation={0}
                    style={{
                        backgroundColor: randomColor({ seed: name }),
                    }}
                >
                    <Toolbar variant='dense'>
                        <Typography className='fs16 cw'>
                            {name}
                        </Typography>
                        <span className='flx1' />
                        <div className='aic'>
                            <Tooltip title='Edit'>
                                <div>
                                    <IconButton size='small' disabled={disabled} onClick={this.onEditClick}>
                                        <EditIcon color='secondary' />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='Zoom'>
                                <div>
                                    <IconButton size='small' disabled={disabled} onClick={this.onZoomClick}>
                                        <MapIcon color='secondary' />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton size='small' disabled={disabled} onClick={this.onDeleteClick}>
                                        <DeleteIcon color='secondary' />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                        <Tooltip title='Expand'>
                            <div>
                                <IconButton size='small' disabled={disabled} onClick={this.onClick}>
                                    <motion.div
                                        initial={{ transform: 'rotate(0deg)' }}
                                        animate={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} className='mh0 aic'>
                                        <ExpandMoreIcon color='secondary' />
                                    </motion.div>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <div className='fdc p10'>
                        <div>
                            <Typography className='fs12 lh37'>
                                Blocks to be scouted
                            </Typography>
                        </div>
                        {
                            !!alreadyScoutedBlockNames.length &&
                            <div className='fdc mb10'>
                                <Typography className='fdr aifs co fs12 fwm'>
                                    <WarningIcon className='co mr7' />
                                    Block {alreadyScoutedBlockNames.join(', ')} has been selected to be scouted, but has already been scouted this week.
                                </Typography>
                            </div>
                        }
                        <div className='fdr fww mt5'>
                            {
                                blockNames.map(n => (
                                    <div key={`${name}_${n}`} className='aic jcc pr10 mt5'>
                                        <CheckToggleButton text={n} value={n} selected />
                                    </div>
                                ))
                            }
                        </div>
                        {
                            scoutingBlocks.length > LIMIT &&
                            <div className='fdc aife mt10'>
                                <Link component='button' className='fs11 lh35 cp fwm' onClick={this.onShowMoreClick}>
                                    {
                                        showMore ? 'View less blocks' : 'View more blocks'
                                    }
                                </Link>
                            </div>
                        }
                    </div>
                </Collapse>
            </Paper>
        );
    };
}