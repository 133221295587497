import React from 'react';

interface IThresholdHeaderProps {
    title : string;
}

interface IThresholdHeaderState {}

export default class ThresholdHeader extends React.PureComponent<IThresholdHeaderProps, IThresholdHeaderState> {
    constructor(props : IThresholdHeaderProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { title } = this.props;
        return (
            <div style={{
                minHeight: 50,
                backgroundColor: '#004B22',
            }} className='aic jcc'>
                <span style={{
                    font: '500 13px/18px Roboto',
                    letterSpacing: 0.2,
                }} className='cw'>{title}</span>
            </div>
        );
    };
}
