import React from 'react';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { IScoutingEntrySectionEntity } from '../../../types/model/scouting/scoutingEntry';

interface IScoutingEntrySectionEntityViewProps {
    entries : Array<IScoutingEntrySectionEntity>;
}

interface IScoutingEntrySectionEntityViewState {
}

interface IHeader {
    name : string;
    subs : Array<string>;
    count : number;
}

interface IData {
    [key : string] : Array<number | string>;
}

export default class ScoutingEntrySectionEntityView extends React.PureComponent<IScoutingEntrySectionEntityViewProps, IScoutingEntrySectionEntityViewState> {

    constructor(props : IScoutingEntrySectionEntityViewProps) {
        super(props);
        this.state = {
        };
    }

    private getEntries = (props : IScoutingEntrySectionEntityViewProps) => props.entries;
    private getHeaders = createSelector(
        [this.getEntries],
        (entries) => {
            if (!entries.length) return [];

            const headers : Array<IHeader> = [];
            entries.forEach((entry) => {
                if (!headers.find(x => x.name === entry.name)) {
                    headers.push({
                        name: entry.name,
                        subs: typeof entry.count === 'object' ? Object.keys(entry.count) : [],
                        count: typeof entry.count === 'object' ? Object.keys(entry.count).length : 1,
                    });
                }

            });

            return headers;
        },
    );
    private getData = createSelector(
        [this.getEntries, this.getHeaders],
        (entries, headers) => {
            if (!entries.length) return {};
            const data : IData = {};

            lodash.chain(entries).groupBy(x => x.entry).forEach((sections, section) => {
                data[section] = [];

                headers.forEach((header) => {
                    // Get all entry data for specific header.
                    const entryData = sections.filter(e => e.name === header.name && e.entry === section);

                    if (!entryData[0]) {
                        for (let i = 0; i < header.count; i++) {
                            data[section].push('X');
                        }
                        return;
                    }

                    // Handle check entry types.
                    if (entryData[0].counterType === 'check') {
                        if (entryData[0].type === 'damage') {
                            data[section].push(lodash.chain(entryData)
                                .map(v => typeof v.count === 'number' ? '' : v.count)
                                .join(', ').value());
                            return;
                        }
                        // If count = 1, then just show a check.
                        if (entryData.some(v => typeof v.count === 'number' && v.count > 0 && v.count < 2)) {
                            data[section].push('✓');
                            return;
                        // If count > 1 show number, some checks can be counters...
                        } else if (entryData.some(v => typeof v.count === 'number' && v.count > 2)) {
                            data[section].push(lodash.sumBy(entryData, v => typeof v.count === 'number' ? v.count : 0));
                            return;
                        // If the check is a string show first comma separated list of first caharacter.
                        } else if (entryData.some(v => typeof v.count === 'string')) {
                            data[section].push(lodash.chain(entryData)
                                .filter(v => typeof v.count === 'string')
                                .map((v : { count : string }) => v.count[0].toLocaleUpperCase())
                                .uniq()
                                .join(', ').value());
                            return;
                        // If none of these show X.
                        } else {
                            data[section].push('X');
                            return;
                        }
                    }

                    // If data type is counter, show number.
                    if (entryData[0].counterType === 'counter') {
                        data[section].push(lodash.sumBy(entryData, v => typeof v.count === 'number' ? v.count : 0));
                        return;
                    }

                    // If data is a multi counter, push counter values.
                    if (entryData[0].counterType === 'multi') {
                        header.subs.forEach((s) => {
                            data[section].push(lodash.sumBy(entryData, v => typeof v.count === 'object' ? v.count[s] : 0));
                        });
                        return;
                    }

                    data[section].push('X');
                });
            }).value();

            return data;
        },
    );

    public render = () => {

        const headers = this.getHeaders(this.props);
        const data = this.getData(this.props);
        return (
            <Table size='small' padding='none'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>
                            Section
                        </TableCell>
                        {
                            headers.map((n) => {
                                if (typeof n === 'object') {
                                    return (<TableCell align='center' key={`header_${n.name}`} colSpan={n.subs.length}>
                                        {n.name}
                                    </TableCell>);
                                }
                                return (<TableCell key={`header_${n}`} align='center'>
                                    {n}
                                </TableCell>);
                            })
                        }
                    </TableRow>
                    {
                        headers.some(n => !!n.subs.length) &&
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            {
                                headers.map((n) => {
                                    if (typeof n === 'object' && n.subs.length) {
                                        return n.subs.map(s => (
                                            <TableCell align='center' key={`header_${n.name}_${s}`}>
                                                {
                                                    n.name === 'Mite' &&
                                                    s[0]

                                                }
                                                {
                                                    n.name.includes('Damage') &&
                                                    s

                                                }
                                                {
                                                    n.name !== 'Mite' && !n.name.includes('Damage') &&
                                                    (s.split(' ')[1] ?? s[0])
                                                }
                                            </TableCell>));
                                    }
                                    return (<TableCell key={`header_sub_${n.name}`}></TableCell>);
                                })
                            }
                        </TableRow>
                    }
                </TableHead>
                <TableBody>
                    {
                        Object.keys(data).map((n, i) => (
                            <TableRow key={`${i}_section`}>
                                <TableCell align='center'>
                                    {n.toLocaleUpperCase()}
                                </TableCell>
                                {
                                    data[n].map((x, ii) => (
                                        <TableCell align='center' key={`${i}_section_${ii}_data`}>
                                            {x}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        );
    };
}
