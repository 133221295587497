import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { createSelector } from 'reselect';

interface IScoutingAssignmentFinishedMarkersProps {
    polygonId : string;

    polygonBeforeId ?: string;

    minZoom ?: number;
    maxZoom ?: number;

    visible ?: boolean;

    finishedPoints : Array<GeoJSON.Position>;
}

interface IScoutingAssignmentFinishedMarkersState {
}

export default class ScoutingAssignmentFinishedMarkers extends React.PureComponent<IScoutingAssignmentFinishedMarkersProps, IScoutingAssignmentFinishedMarkersState> {
    constructor(props : IScoutingAssignmentFinishedMarkersProps) {
        super(props);
        this.state = {
        };
    }

    private readonly getFinished = (props : IScoutingAssignmentFinishedMarkersProps) => props.finishedPoints;

    private readonly getFeatureCollection = createSelector(
        [
            this.getFinished,
        ],
        (
            finishedPoints,
        ) => {
            const geoJson : GeoJSON.FeatureCollection<GeoJSON.Point, {
                id : string;
                color : string;
            }> = {
                type: 'FeatureCollection',
                features: finishedPoints.map((n, key) => ({
                    geometry: {
                        coordinates: n,
                        type: 'Point',
                    },
                    type: 'Feature',
                    properties: {
                        id: `${key}`,
                        color: key === 0 || key === finishedPoints.length - 1 ? '#FF7F00' : 'blue',
                    },
                })),
            };

            return geoJson;
        },
    );
    
    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 24,
            polygonId,
            polygonBeforeId,
            visible = true,
        } = this.props;

        const data = this.getFeatureCollection(this.props);

        return (

            <Source
                id={`${polygonId}_source`}
                type='geojson'
                data={data}
                maxzoom={maxZoom}
            >
                <Layer
                    {...{
                        id: polygonId,
                        type: 'circle',
                        minzoom: minZoom,
                        maxzoom: maxZoom,
                        'layout': {
                            visibility: visible ? 'visible' : 'none',
                        },
                        'paint': {
                            'circle-color': ['get', 'color'],
                            'circle-stroke-color': ['get', 'color'],
                            'circle-opacity': 0,
                            'circle-radius': 8,
                            'circle-stroke-width': 1,
                        },
                        beforeId: polygonBeforeId,
                    }}
                />
            </Source>
        );
    };
}
