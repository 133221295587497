import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import Divider from '@material-ui/core/Divider';
import { IAssignmentBlock } from '../../../../types/model/masterData/block';
import { CROP, DATE_FORMAT_DEFAULT_NO_TIME } from '../../../../appConstants';
import { NematodeAssignmentType } from '../../../../types/model/nematode/nematodeAssignment';
import AppFunctionsService from '../../../../services/appFunctionServices';
import { IUser } from '../../../../types/model/user';
import NematodeFunctions from '../../../../store/nematode/functions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import ScoutingAssignmentEmployeeSelector from '../../../scouting/assignments/EmployeeSelector';

interface INematodeAssignmentCreateDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    open : boolean;
    onClose ?: (result : boolean) => void;

    date ?: moment.Moment;
    division ?: string;

    scoutingBlocks : Record<string, Array<IAssignmentBlock>>;
    assignmentType : NematodeAssignmentType;

    crop : CROP;
}

interface INematodeAssignmentCreateDialogState {
    employees : Record<string, IUser>;
}

class NematodeAssignmentCreateDialog extends React.PureComponent<INematodeAssignmentCreateDialogProps, INematodeAssignmentCreateDialogState> {
    constructor(props : INematodeAssignmentCreateDialogProps) {
        super(props);
        this.state = {
            employees: {},
        };
    }

    public readonly componentDidUpdate = (prevProps : INematodeAssignmentCreateDialogProps) => {
        if (prevProps.open && !this.props.open) {
            this.setState({
                employees: {},
            });
        }
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;
        if (this.props.onClose) this.props.onClose(false);
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    };

    private readonly save = async () => {
        const { employees } = this.state;
        const {
            date,
            scoutingBlocks,
            assignmentType,
        } = this.props;

        if (!Object.keys(employees).length) return;
        if (!Object.keys(scoutingBlocks).length) return;
        if (!date) return;

        await NematodeFunctions.createAssignments(
            date,
            scoutingBlocks,
            employees,
            assignmentType,
        );

        if (this.props.onClose) this.props.onClose(true);
    };

    private readonly getScoutingBlocks = (props : INematodeAssignmentCreateDialogProps) => props.scoutingBlocks;
    private readonly getEmployees = (props : INematodeAssignmentCreateDialogProps, state : INematodeAssignmentCreateDialogState) => state.employees;

    private readonly getScoutingFieldNames = createSelector([
        this.getScoutingBlocks,
    ], (scoutingBlocks) => {
        return Object.keys(scoutingBlocks).sort();
    });

    private readonly getIsValid = createSelector([
        this.getEmployees,
        this.getScoutingFieldNames,
    ], (employees, scoutingFieldNames) => {
        return Object.keys(employees).length === scoutingFieldNames.length;
    });

    private readonly onEmployeeChange = (landName : string, employee : IUser) => {
        const employees = lodash.cloneDeep(this.state.employees);

        employees[landName] = employee;

        this.setState({
            employees,
        });
    };

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            date,
            open,
            division,
            scoutingBlocks,
            assignmentType,
            crop,
        } = this.props;
        const { employees } = this.state;

        const scoutingFieldNames = this.getScoutingFieldNames(this.props);
        const valid = this.getIsValid(this.props, this.state);
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='scouting-assignment-dialog-title'
                aria-describedby='scouting-assignment-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            CONFIRM ASSIGNMENT
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1'>
                    <DialogContent className='fdc ais flx1 drawer'>
                        <Typography className='cp fs18 lh37 fwm'>
                            Assignment Details
                        </Typography>
                        <div className='fdr'>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    WEEK
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    Week {date?.week()}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DATE
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {date?.format(DATE_FORMAT_DEFAULT_NO_TIME)}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    SCOUT TYPE
                                </Typography>
                                <Typography component='span' className='fdr fs16 fw700 lh37'>
                                    {AppFunctionsService.toTitleCase(assignmentType)}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DIVISION
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {division}
                                </Typography>
                            </div>
                        </div>
                        <Divider />
                        <Typography className='cp fs18 lh37 fwm'>
                            Tasks
                        </Typography>
                        {
                            scoutingFieldNames.map(fieldName => (
                                <div key={`${fieldName}_field`} className='mb10'>
                                    <ScoutingAssignmentEmployeeSelector
                                        division={division}
                                        scoutingBlocks={scoutingBlocks[fieldName]}
                                        landName={fieldName}
                                        employee={employees[fieldName]}
                                        onChanged={this.onEmployeeChange}
                                        crop={crop}
                                    />
                                </div>
                            ))
                        }
                    </DialogContent>
                    <DialogActions>
                        <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                            CANCEL
                        </OutlinedStadiumButton>
                        <StadiumButton variant='contained' disabled={isLoading || !valid} type='submit' className='bcy cpd dbcg dcg bsd'>
                            SAVE
                        </StadiumButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };
}

export default NematodeAssignmentCreateDialog;
