import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { IScoutingSection, IScoutingSectionEntry, IScoutingSectionEntryCheck, ScoutingEntryChecks, ScoutingEntryCheckType } from '../../../../types/model/masterData/scouting';
import { Button, Card, DialogActions, FormControl, FormHelperText, TextField, Typography } from '@material-ui/core';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from '../../../customComponents/materialTable/Table';
import ScoutingEditCheckDialog from './EditCheck';

interface IScoutingEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value ?: IScoutingSection;

    onClose : (value ?: IScoutingSection) => void;

    isLoading : boolean;
}

interface IScoutingEditDialogState {
    open : boolean;

    name : string;
    entries : Array<IScoutingSectionEntry>;

    selected ?: IScoutingSectionEntry;
}

export default class ScoutingEditDialog extends React.PureComponent<IScoutingEditDialogProps, IScoutingEditDialogState> {
    constructor(props : IScoutingEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            entries: [],
        };
    }
    
    public readonly componentDidUpdate = (prevProps : IScoutingEditDialogProps) => {
        if (this.props.value && !prevProps.value) {
            this.show();

            this.setInitialValues(this.props.value);
        }

        if (!this.props.value && prevProps.value) {
            this.hide();
        }
    };

    public readonly show = () => {
        this.setState({
            open: true,
        });
    };

    public readonly hide = () => {
        this.setState({
            open: false,
        });
    };

    public readonly onClose = () => {
        this.props.onClose();
    };

    private readonly setInitialValues = (value : IScoutingSection) => {
        this.setState({
            name: value.name,
            entries: [...value.entries],
        });
    };

    private readonly onNameChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: e.target.value,
        });
    };

    private readonly onSaveClick = () => {
        this.props.onClose({
            name: this.state.name,
            entries: [...this.state.entries],
        });
    };

    private readonly onUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(event.currentTarget.value, true);
    };

    private readonly onDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.changeOrder(event.currentTarget.value, false);
    };

    private readonly changeOrder = (name : string, up : boolean) => {
        const entries = [...this.state.entries];
        const index = entries.findIndex(x => x.name === name);

        if (up && index > 0) {
            const option = entries[index];
            const prevOption = entries[index - 1];

            entries.splice(index, 1, prevOption);
            entries.splice(index - 1, 1, option);
        } else if (!up && index < entries.length - 1) {
            const option = entries[index];
            const prevOption = entries[index + 1];

            entries.splice(index, 1, prevOption);
            entries.splice(index + 1, 1, option);
        }

        this.setState({
            entries,
        });
    };

    private readonly onAddClick = () => {
        this.setState({
            selected: {
                name: '',
                checks: ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: []}), {}) as Record<ScoutingEntryCheckType, Array<IScoutingSectionEntryCheck>>,
                months: [],
            },
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selected: this.state.entries.slice().find(x => x.name === event.currentTarget.value),
        });
    };

    private readonly onCopyClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const current = this.state.entries.slice().find(x => x.name === event.currentTarget.value);

        if (!current) return;

        this.setState({
            selected: {
                ...current,
                name: '',
                checks: {
                    damage: [...current.checks.damage],
                    disease: [...current.checks.disease],
                    insects: [...current.checks.insects],
                },
                months: [],
            },
        });
    };
    
    private readonly onEditClose = (option ?: IScoutingSectionEntry) => {
        if (!option) {
            this.setState({
                selected: undefined,
            });
            return;
        }
        
        const entries = this.state.entries.slice();

        const index = entries.findIndex(x => x.name === option.name);

        if (index > -1) {
            entries.splice(index, 1, {
                ...option,
                checks: {
                    ...option.checks,
                },
            });
        } else {
            entries.push({
                ...option,
                checks: ScoutingEntryChecks.reduce((a, v) => ({ ...a, [v]: [...a[v]]}), option.checks),
            });
        }
        
        this.setState({
            entries,
            selected: undefined,
        });
    };

    private readonly onRowDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const entries = this.state.entries.slice();
        const index = entries.findIndex(x => x.name === event.currentTarget.value);

        entries.splice(index, 1);

        this.setState({
            entries,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value, isLoading } = this.props;
        const { open, name, entries, selected } = this.state;
        
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='scouting-general-dialog-title'
                aria-describedby='scouting-general-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {
                                !value?.name ? 'Create' : `Edit ${value.name}`
                            }
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'fdr jcfs aifs'}>
                        <div className={'fdc flx1 jcfs p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl>
                                <TextField
                                    required
                                    error={!name}
                                    autoFocus
                                    margin='dense'
                                    id='name'
                                    label='Name'
                                    type='text'
                                    fullWidth
                                    value={name}
                                    onChange={this.onNameChange}
                                    disabled={!!value?.name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'fdr flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                        </div>
                        <div className={'fdc flx1 aic p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                        </div>
                    </div>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            OPTIONS
                        </Typography>
                        <span className={'flx1'} />
                        <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                            <AddIcon />
                        </StandardFab>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IScoutingSectionEntry>
                            id='scoutingOptionTable'
                            data={entries}
                            rowsPerPage={50}
                            isLoading={isLoading}
                            columns={[{
                                header: '',
                                paddingRight: 4,
                                width: 10,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onEditClick}>
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Copy'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onCopyClick}>
                                                    <Icon>content_copy</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: '',
                                paddingRight: 4,
                                width: 15,
                                renderCell: (row) => (
                                    <div className='aic'>
                                        <Tooltip title='Up'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onUpClick}>
                                                    <Icon>expand_less</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Down'>
                                            <div>
                                                <IconButton value={row.name} disabled={isLoading} onClick={this.onDownClick}>
                                                    <Icon>expand_more</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 70,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Checks',
                                field: 'checks',
                                width: 75,
                                enableSort: true,
                                align: 'center',
                                renderCell: row => (Object.keys(row.checks).length),
                            }, {
                                header: '',
                                field: 'name',
                                width: 50,
                                enableSort: true,
                                renderCell: row =>
                                    <div className='fdr'>
                                        <Button className='cpr' value={row.name} onClick={this.onRowDeleteClick}>
                                            <Icon>delete</Icon>
                                        </Button>
                                    </div>,
                            }]}
                        />
                    </Card>
                    <ScoutingEditCheckDialog value={selected} fullWidth maxWidth='md' onClose={this.onEditClose} isLoading={isLoading} />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading || !name} onClick={this.onSaveClick}>
                        <SaveIcon style={{ marginRight: '10px' }} />
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}
