import React from 'react';
import { Layer, MapMouseEvent, MapboxGeoJSONFeature, Source } from 'react-map-gl';
import { createSelector } from 'reselect';
import { EventData } from 'mapbox-gl';
import { Position } from 'geojson';
import { MapboxContext, MapboxContextValue } from '../MapBox';

interface IBasicMarkerProps {
    location : Position;
    markerId : string;
    markerBeforeId ?: string;
    size ?: number;
    iconUrl ?: string;

    onMarkerClick ?: (ev : MapMouseEvent & { 
        features ?: Array<MapboxGeoJSONFeature> | undefined;
    } & EventData) => void;
}

interface IBasicMarkerState {
    imagesLoaded : boolean;
}

class BasicMarkerComponent extends React.PureComponent<IBasicMarkerProps, IBasicMarkerState> {
    declare context : MapboxContextValue;
    
    constructor(props : IBasicMarkerProps) {
        super(props);
        this.state = {
            imagesLoaded: false,
        };
    }

    public componentDidMount() : void {
        this.setLayer();
    }

    public componentWillUnmount() : void {
        if (!this.props.onMarkerClick) return;
        if (!this.props.markerId) return;

        this.context.map.off('click', this.props.markerId, this.props.onMarkerClick);
        this.context.map.off('mouseenter', this.props.markerId, this.onMarkerMouseOver);
        this.context.map.off('mouseleave', this.props.markerId, this.onMarkerMouseOver);
    }

    private readonly getData = (props : IBasicMarkerProps) => props.location;
    private readonly getMarkerId = (props : IBasicMarkerProps) => props.markerId;

    private readonly getIconId = createSelector(
        [
            this.getMarkerId,
        ],
        (
            markerId,
        ) => {
            return `${markerId}_image`;
        },
    );

    private readonly getFeature = createSelector(
        [
            this.getData,
            this.getIconId,
        ],
        (
            location,
            iconId,
        ) => {
            return {
                geometry: {
                    type: 'Point',
                    coordinates: location,
                },
                properties: {
                    iconId,
                },
                type: 'Feature',
            } as GeoJSON.Feature<GeoJSON.Point>;
        },
    );

    private readonly loadImages = () => {
        const mapRef = this.context.map;

        return new Promise<undefined>((res, rej) => {
            const id = this.getIconId(this.props);

            if (mapRef.hasImage(id)) {
                res(undefined);
                return;
            }

            mapRef.loadImage(this.props.iconUrl ?? '/assets/images/green_location.png', (error, result) => {
                if (error) {
                    rej();
                    return;
                }
                
                if (result) {
                    mapRef.addImage(id, result);
                    res(undefined);
                    return;
                }

                rej();
            });
        });
    };

    private readonly setLayer = async () => {
        await this.loadImages();

        this.setState({
            imagesLoaded: true,
        });

        if (!this.props.onMarkerClick) return;
        if (!this.props.markerId) return;

        this.context.map.on('click', this.props.markerId, this.props.onMarkerClick);
        this.context.map.on('mouseenter', this.props.markerId, this.onMarkerMouseOver);
        this.context.map.on('mouseleave', this.props.markerId, this.onMarkerMouseOver);
    };

    private readonly onMarkerMouseOver = (ev : MapMouseEvent) => {
        if (ev.type === 'mouseenter') {
            ev.target.getCanvas().style.cursor = 'pointer';
        }
        if (ev.type === 'mouseleave') {
            ev.target.getCanvas().style.cursor = '';
        }
    };

    public readonly render = () => {
        const {
            markerId,
            markerBeforeId,
            size = 1,
        } = this.props;

        const {
            imagesLoaded,
        } = this.state;

        const geoJson = this.getFeature(this.props);

        return (
            <Source
                id={`${markerId}_source`}
                type='geojson'
                data={geoJson}
            >
                {
                    imagesLoaded &&
                    <Layer
                        id={markerId}
                        beforeId={markerBeforeId}
                        type='symbol'
                        {
                            ...{
                                paint: {
                                },
                                layout: {
                                    'icon-image': ['get', 'iconId'],
                                    'icon-size': size,
                                    'icon-anchor': 'center',
                                    'icon-allow-overlap': true,
                                },
                            }
                        }
                    />
                }
            </Source>
        );
    };
}

BasicMarkerComponent.contextType = MapboxContext;

const BasicMarker = BasicMarkerComponent;

export default BasicMarker;