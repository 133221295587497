import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import StadiumButton from '../../customComponents/button/StadiumButton';
import IconButton from '@material-ui/core/IconButton';
import { IScoutingAssignment, ScoutingType } from '../../../types/model/scouting/scoutingAssignment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import ScoutingBlockLandNameDropdown from '../../customComponents/dropdowns/BlockLandNameDropdown';
import { CROP, DATE_FORMAT_DEFAULT_NO_TIME } from '../../../appConstants';
import { createSelector } from 'reselect';
import CheckToggleButton from '../../customComponents/button/CheckToggleButton';
import moment from 'moment';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import DialogContent from '@material-ui/core/DialogContent';
import AssignmentBlockHelper, { IAssignmentBlock } from '../../../types/model/masterData/block';
import { MapRef } from 'react-map-gl';
import GeoHelper from '../../../services/helper/geoHelper';
import { IGeoServerBlock } from '../../../types/model/masterData/geoserver/block';
import { IGeoServerPoint } from '../../../types/model/masterData/geoserver/point';
import lodash from 'lodash';

interface IScoutingAssignmentBlockSelectorProps {
    date ?: moment.Moment;
    division ?: string;
    scoutType ?: ScoutingType;
    crop : CROP;

    onBackClick ?: () => void;
    onAddClick ?: (landName : string, blocks : Array<IAssignmentBlock>) => void;

    geoserverblocks : Array<IGeoServerBlock>;
    points : Array<IGeoServerPoint>;
    assignments : Array<IScoutingAssignment>;
    
    selectedScoutingBlocks : Record<string, Array<IAssignmentBlock> | null>;

    defaultLandName ?: string;

    mapRef : MapRef | null;
}

interface IScoutingAssignmentBlockSelectorState {
    showInfo : boolean;

    landName ?: string;
    selectedScoutingBlocks : Array<IAssignmentBlock>;
}

class ScoutingAssignmentBlockSelectorComponent extends React.PureComponent<IScoutingAssignmentBlockSelectorProps, IScoutingAssignmentBlockSelectorState> {
    constructor(props : IScoutingAssignmentBlockSelectorProps) {
        super(props);
        this.state = {
            showInfo: false,
            selectedScoutingBlocks: [],
        };
    }
    
    public componentDidMount() : void {
        if (!this.props.defaultLandName) return;
        this.setState({
            landName: this.props.defaultLandName,
            selectedScoutingBlocks: this.props.selectedScoutingBlocks[this.props.defaultLandName] ?? [],
        });
    }

    private readonly getScoutedBlocks = (
        assignments : Array<IScoutingAssignment>,
        crop : CROP,
        date ?: moment.Moment,
        landName ?: string
    ) => {
        return assignments
            .filter(x => x.crop === crop)
            .filter(x => x.landName === landName)
            .filter(x => moment.utc(x.date).isSame(date))
            .map(n => n.blockName)
            .sort();
    };

    private readonly getCrop = (props : IScoutingAssignmentBlockSelectorProps) => props.crop;
    private readonly getLandName = (props : IScoutingAssignmentBlockSelectorProps, state : IScoutingAssignmentBlockSelectorState) => state.landName;
    private readonly getDivision = (props : IScoutingAssignmentBlockSelectorProps) => props.division;
    private readonly getScoutingBlocks = (props : IScoutingAssignmentBlockSelectorProps, state : IScoutingAssignmentBlockSelectorState) => state.selectedScoutingBlocks;
    private readonly getBlocks = (props : IScoutingAssignmentBlockSelectorProps) => props.geoserverblocks;
    private readonly getPoints = (props : IScoutingAssignmentBlockSelectorProps) => props.points;
    private readonly getAssignment = (props : IScoutingAssignmentBlockSelectorProps) => props.assignments;
    private readonly getDate = (props : IScoutingAssignmentBlockSelectorProps) => props.date;

    private readonly getBlockNames = createSelector([
        this.getBlocks,
        this.getLandName,
        this.getDivision,
        this.getCrop,
        this.getPoints,
    ], (
        blocks,
        landName,
        division,
        crop,
        points,
    ) => {
        if (!division) return [];
        
        const blockGuids = lodash.chain(points)
            .filter(n => n.type === 'scouting')
            .filter(n => n.crop === crop)
            .map(n => n.blockGuid)
            .uniq()
            .value();

        return blocks
            .filter(n => blockGuids.includes(n.guid))
            .filter(n => !division || n.departmentShortName.toLocaleUpperCase() === division.toLocaleUpperCase())
            .filter(n => n.fieldName.toLocaleUpperCase() === landName?.toLocaleUpperCase())
            .map(n => n.code)
            .sort();
    });

    private readonly getSelectedBlockNames = createSelector([
        this.getScoutingBlocks,
    ], (blocks) => {
        return blocks
            .map(n => n.name)
            .sort();
    });

    private readonly getAlreadyScoutedBlockNames = createSelector([
        this.getAssignment,
        this.getDate,
        this.getLandName,
        this.getCrop,
    ], (assignments, date, landName, crop) => {
        return this.getScoutedBlocks(assignments, crop, date, landName);
    });

    private readonly onBlockLandNameChange = (landName ?: string) => {
        const { division, geoserverblocks, points, crop, mapRef } = this.props;
        if (!division) return;

        const blockGuids = lodash.chain(points)
            .filter(n => n.type === 'scouting')
            .filter(n => n.crop === crop)
            .map(n => n.blockGuid)
            .uniq()
            .value();

        const selectedScoutingBlocks : Array<IAssignmentBlock> = geoserverblocks
            .filter(n => blockGuids.includes(n.guid))
            .filter(x => x.fieldName.toLocaleUpperCase() === landName?.toLocaleUpperCase() &&
            division.toLocaleUpperCase() === x.departmentShortName.toLocaleUpperCase())
            .map(block => {
                const selectedPoints = points
                    .filter(n => n.type === 'scouting')
                    .filter(x => x.blockGuid === block.guid);

                return AssignmentBlockHelper.fromGeoServerBlock(block, crop, selectedPoints);
            });

        this.setState({
            landName,
            selectedScoutingBlocks,
        });

        mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds(selectedScoutingBlocks), {
            minZoom: 15,
        });
    };

    private readonly onBlockNameClick = (blockName : string) => {
        const { division, geoserverblocks, points, crop, mapRef } = this.props;
        const { landName } = this.state;

        if (!division || !landName) return;

        const scoutingBlocks = this.state.selectedScoutingBlocks.slice();

        const index = scoutingBlocks.findIndex(x => x.name === blockName);

        if (index > -1) {
            scoutingBlocks.splice(index, 1);
        } else {
            const blockGuids = lodash.chain(points)
                .filter(n => n.type === 'scouting')
                .filter(n => n.crop === crop)
                .map(n => n.blockGuid)
                .uniq()
                .value();

            const block = geoserverblocks
                .filter(n => blockGuids.includes(n.guid))
                .find(x =>
                    x.fieldName.toLocaleUpperCase() === landName.toLocaleUpperCase() &&
                    x.code === blockName &&
                    division.toLocaleUpperCase() === x.departmentShortName.toLocaleUpperCase());

            if (block) {
                const selectedPoints = points.filter(x => x.blockGuid === block.guid);
                scoutingBlocks.push(AssignmentBlockHelper.fromGeoServerBlock(block, crop, selectedPoints));
            }
        }

        this.setState({
            selectedScoutingBlocks: scoutingBlocks,
        });
        
        mapRef?.fitBounds(GeoHelper.getAssignmentBlockBounds(scoutingBlocks), {
            minZoom: 15,
        });
    };

    private readonly onInfoClick = () => {
        this.setState({
            showInfo: !this.state.showInfo,
        });
    };

    public readonly onAddClick = () => {
        if (!this.props.onAddClick) return;
        if (!this.state.landName) return;

        const blocks = this.state.selectedScoutingBlocks.slice();

        this.props.onAddClick(this.state.landName, blocks);
    };

    public readonly getLandNameDisbaled = (
        option : {
            value : string;
            label : string;
        }
    ) => {
        return Object.keys(this.props.selectedScoutingBlocks).includes(option.value);
    };

    public render = () => {
        const {
            division,
            crop,
            onBackClick,
            date,
            scoutType,
            defaultLandName,
        } = this.props;

        const {
            landName,
            showInfo,
            selectedScoutingBlocks,
        } = this.state;

        const blockNames = this.getBlockNames(this.props, this.state);
        const selectedBlockNames = this.getSelectedBlockNames(this.props, this.state);
        const alreadyScoutedBlockNames = this.getAlreadyScoutedBlockNames(this.props, this.state);

        return (
            <Paper className={'flx1 fdc m5'}>
                <AppBar position='static' className='bcdg pbr' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            !!onBackClick &&
                            <Tooltip title='Back'>
                                <div>
                                    <IconButton size='small' onClick={onBackClick}>
                                        <ArrowBackIcon color='secondary' />
                                    </IconButton>
                                </div>
                            </Tooltip>

                        }
                        <Typography className='fs17'>
                            ADD FIELDS
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Info'>
                            <div>
                                <IconButton size='small' onClick={this.onInfoClick}>
                                    <InfoRoundedIcon className='cy' />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <div className='hfill oya drawer'>
                    <div className={'fdc aic p10'}>
                        <ScoutingBlockLandNameDropdown
                            crop={crop}
                            value={defaultLandName ?? landName}
                            disabled={!division || !!defaultLandName}
                            division={division}
                            fullWidth
                            required
                            onChange={this.onBlockLandNameChange}
                            getOptionDisabled={this.getLandNameDisbaled}
                            type='scouting'
                        />
                    </div>
                    <div className={'fdc aifs jcc p10 mt5'}>
                        <Typography className='fs17 fwb cp'>
                            Blocks
                        </Typography>
                    </div>
                    <div className={'fdc flx1 p10'}>
                        <div className='fdr fww'>
                            {
                                blockNames.map(n => (
                                    <div key={n} className='aic jcc pr15 pb15'>
                                        <CheckToggleButton
                                            text={n}
                                            value={n}
                                            selected={selectedBlockNames.includes(n)}
                                            onToggle={this.onBlockNameClick}
                                            warning={!selectedBlockNames.includes(n) && alreadyScoutedBlockNames.includes(n)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        {
                            !!alreadyScoutedBlockNames.filter(x => !selectedBlockNames.includes(x)).length &&
                            <div className='fdc mb10'>
                                <Typography className='fdr aifs co fs12 fwm'>
                                    <WarningIcon className='co mr7' />
                                    Block { alreadyScoutedBlockNames
                                        .filter(x => !selectedBlockNames.includes(x)).join(', ')
                                    } has already been scouted, if you want to scout it again please select it.
                                </Typography>
                            </div>
                        }
                        {
                            !!alreadyScoutedBlockNames.length &&
                            !!alreadyScoutedBlockNames.filter(x => selectedBlockNames.includes(x)).length &&
                            <div className='fdc mb10'>
                                <Typography className='fdr aifs co fs12 fwm'>
                                    <WarningIcon className='co mr7' />
                                    You have selected block { alreadyScoutedBlockNames
                                        .filter(x => selectedBlockNames.includes(x)).join(', ')
                                    } for scouting even though it has been scouted this week.
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className={'fdc aife jcc p10 mt5'}>
                        <StadiumButton
                            variant='contained'
                            className='bcy cpd dbcg dcg bsd'
                            disabled={!selectedScoutingBlocks.length}
                            onClick={this.onAddClick}
                        >
                            ADD FIELD
                        </StadiumButton>
                    </div>
                </div>
                <Dialog
                    open={showInfo}
                    TransitionComponent={Transitions.Down}
                    transitionDuration={500}
                    onClose={this.onInfoClick}
                    maxWidth='sm'
                    fullWidth
                    aria-labelledby='scouting-assignment-info-dialog-title'
                    aria-describedby='scouting-assignment-info-dialog-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                View Assignment
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onInfoClick} aria-label='Close'>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc ais flx1'>
                        <Typography className='cp fs18 lh37 fwm'>
                            Assignment Details
                        </Typography>
                        <div className='fdr'>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    WEEK
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    Week {date?.week()}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DATE
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {date?.format(DATE_FORMAT_DEFAULT_NO_TIME)}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    SCOUT TYPE
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {scoutType}
                                </Typography>
                            </div>
                            <div className='fdc flx1'>
                                <Typography className='fs13 lh37'>
                                    DIVISION
                                </Typography>
                                <Typography className='fs16 fw700 lh37'>
                                    {division}
                                </Typography>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </Paper>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        assignments: state.scouting.assignments,
        geoserverblocks: state.masterData.geoserver.blocks,
        points: state.masterData.geoserver.points,
    };
};

const ScoutingAssignmentBlockSelector = connect(
    mapStateToProps,
)(ScoutingAssignmentBlockSelectorComponent);

export default ScoutingAssignmentBlockSelector;
