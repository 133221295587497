import { getBounds, getCenter, getAreaOfPolygon, convertArea } from 'geolib';
import { IGeoServerBlock } from '../../types/model/masterData/geoserver/block';
import { LngLatBoundsLike } from 'react-map-gl';
import { GeolibInputCoordinates } from 'geolib/es/types';
import { IAssignmentBlock } from '../../types/model/masterData/block';

export type areaUnit = 'ha' | 'ft2' | 'yd2' | 'sqm' | 'sqkm' | 'km2' | 'sqft' | 'sqyd' | 'sqin' | 'm2' | 'km2' | 'a';

export default class GeoHelper {
    public static getPolygonBounds(blocks : Array<IGeoServerBlock>) : LngLatBoundsLike {
        if (!blocks.length) return [{
            lat: 0,
            lng: 0,
        }, {
            lat: 0,
            lng: 0,
        }];
        
        const polygons : Array<GeolibInputCoordinates> = blocks
            .filter(x => x.geometry.type === 'Polygon')
            .map(x => (x.geometry as GeoJSON.Polygon))
            .map(geometry => geometry.coordinates
                .map(coordinate => coordinate
                    .map(position => ({
                        lat: position[1],
                        lng: position[0],
                    }))
                    .flatMap(position => position))
                .flatMap(coordinate => coordinate)
            )
            .flatMap(geometry => geometry);

        const multiPolygon : Array<GeolibInputCoordinates> = blocks
            .filter(x => x.geometry.type === 'MultiPolygon')
            .map(x => (x.geometry as GeoJSON.MultiPolygon))
            .map(geometry => geometry.coordinates
                .map(coordinate => coordinate
                    .map(positions => positions
                        .map(position => ({
                            lat: position[1],
                            lng: position[0],
                        }))
                        .flatMap(position => position))
                    .flatMap(positions => positions))
                .flatMap(coordinate => coordinate)
            )
            .flatMap(geometry => geometry);


        const bounds = getBounds([
            ...polygons,
            ...multiPolygon,
        ]);

        return [{
            lat: bounds.maxLat,
            lng: bounds.maxLng,
        }, {
            lat: bounds.minLat,
            lng: bounds.minLng,
        }];
    }

    public static getAssignmentBlockBounds(blocks : Array<IAssignmentBlock>) : LngLatBoundsLike {
        if (!blocks.length) return [{
            lat: 0,
            lng: 0,
        }, {
            lat: 0,
            lng: 0,
        }];

        const polygons : Array<GeolibInputCoordinates> = blocks
            .map(x => (x.polygons.geometry as GeoJSON.MultiPolygon))
            .map(geometry => geometry.coordinates
                .map(coordinate => coordinate
                    .map(positions => positions
                        .map(position => ({
                            lat: position[1],
                            lng: position[0],
                        }))
                        .flatMap(position => position))
                    .flatMap(positions => positions))
                .flatMap(coordinate => coordinate)
            )
            .flatMap(geometry => geometry);


        const bounds = getBounds([
            ...polygons,
        ]);

        return [{
            lat: bounds.maxLat,
            lng: bounds.maxLng,
        }, {
            lat: bounds.minLat,
            lng: bounds.minLng,
        }];
    }

    public static getGeoServerBlockCenter(block : IGeoServerBlock) : GeoJSON.Point {
        let result : Array<GeolibInputCoordinates>;

        if (block.geometry.type === 'MultiPolygon') {
            result = block.geometry.coordinates
                .map(coordinate => coordinate
                    .map(positions => positions
                        .map(position => ({
                            lat: position[1],
                            lng: position[0],
                        }))
                        .flatMap(position => position))
                    .flatMap(positions => positions))
                .flatMap(coordinate => coordinate)
                .flatMap(geometry => geometry);
        } else {
            result = block.geometry.coordinates
                .map(coordinate => coordinate
                    .map(position => ({
                        lat: position[1],
                        lng: position[0],
                    }))
                    .flatMap(position => position))
                .flatMap(coordinate => coordinate)
                .flatMap(geometry => geometry);
        }

        const center = getCenter(result);

        return {
            coordinates: !center ? [0, 0] : [center.longitude, center.latitude],
            type: 'Point',
        };
    }

    public static getGeoServerBlockArea(block : IGeoServerBlock, targetUnit ?: areaUnit) : number {
        let result : Array<GeolibInputCoordinates>;

        if (block.geometry.type === 'MultiPolygon') {
            result = block.geometry.coordinates
                .map(coordinate => coordinate
                    .map(positions => positions
                        .map(position => ({
                            lat: position[1],
                            lng: position[0],
                        }))
                        .flatMap(position => position))
                    .flatMap(positions => positions))
                .flatMap(coordinate => coordinate)
                .flatMap(geometry => geometry);
        } else {
            result = block.geometry.coordinates
                .map(coordinate => coordinate
                    .map(position => ({
                        lat: position[1],
                        lng: position[0],
                    }))
                    .flatMap(position => position))
                .flatMap(coordinate => coordinate)
                .flatMap(geometry => geometry);
        }

        if (targetUnit) {
            return convertArea(getAreaOfPolygon(result), targetUnit);
        }

        return convertArea(getAreaOfPolygon(result), 'm2');
    }
}